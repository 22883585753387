import { createReducer } from "reduxsauce";
import actionTypes from "../actions/actionTypes";
import ApiStates from "../apiStates";
import { API_TRANSITIONS, getApiTransitionAction } from "../apiTransitionUtils";
import { PERIODICITY_OPTIONS } from "utils/constants";

const INITIAL_STATE = {
	earningsEstimatesCurrentDataStatus: ApiStates.IDLE,
	earningsEstimatesTrendsDataStatus: ApiStates.IDLE,
	earningsEstimatesTrendsAnnualDataStatus: ApiStates.IDLE,
	overviewInsightStatus: ApiStates.IDLE,
	equitiesMorningStarGeneralInfoStatus: ApiStates.IDLE,
	equitiesMorningStarManagementStatus: ApiStates.IDLE,
	equitiesMorningStarAssetClassificationStatus: ApiStates.IDLE,
	equitiesMorningStarPeerDataStatus: ApiStates.IDLE,
	earningsEstimatesCurrentData: {},
	earningsEstimatesTrendsData: {},
	earningsEstimatesTrendsAnnualData: {},
	equitiesMorningStarGeneralInfoData: {},
	equitiesMorningStarManagementData: {},
	equitiesMorningStarAssetClassificationData: {},
	equitiesMorningStarPeerData: [],
	earningsChartPeriodicity: PERIODICITY_OPTIONS.QUARTERLY,
};

export const earningsEstimatesCurrentTransition = (state = INITIAL_STATE, { name }) => {
	// NOSONAR
	const action = getApiTransitionAction(state.earningsEstimatesCurrentDataStatus, name);
	if (action) {
		// execute action
		return action.apply(API_TRANSITIONS, [
			state,
			{ key: "earningsEstimatesCurrentDataStatus" },
		]);
	}
	// return existing state if no action found
	return { ...state };
};

export const earningsEstimatesTrendsTransition = (state = INITIAL_STATE, { name }) => {
	// NOSONAR
	const action = getApiTransitionAction(state.earningsEstimatesTrendsDataStatus, name);
	if (action) {
		// execute action
		return action.apply(API_TRANSITIONS, [
			state,
			{ key: "earningsEstimatesTrendsDataStatus" },
		]);
	}
	// return existing state if no action found
	return { ...state };
};

export const earningsEstimatesTrendsAnnualTransition = (
	state = INITIAL_STATE,
	{ name }
) => {
	// NOSONAR
	const action = getApiTransitionAction(
		state.earningsEstimatesTrendsAnnualDataStatus,
		name
	);
	if (action) {
		// execute action
		return action.apply(API_TRANSITIONS, [
			state,
			{ key: "earningsEstimatesTrendsAnnualDataStatus" },
		]);
	}
	// return existing state if no action found
	return { ...state };
};

export const overviewInsightTransition = (state = INITIAL_STATE, { name }) => {
	// NOSONAR
	const action = getApiTransitionAction(state.overviewInsightStatus, name);
	if (action) {
		// execute action
		return action.apply(API_TRANSITIONS, [state, { key: "overviewInsightStatus" }]);
	}
	// return existing state if no action found
	return { ...state };
};

export const equitiesMorningStarGeneralInfoTransition = (
	state = INITIAL_STATE,
	{ name }
) => {
	// NOSONAR
	const action = getApiTransitionAction(state.equitiesMorningStarGeneralInfoStatus, name);
	if (action) {
		// execute action
		return action.apply(API_TRANSITIONS, [
			state,
			{ key: "equitiesMorningStarGeneralInfoStatus" },
		]);
	}
	// return existing state if no action found
	return { ...state };
};

export const equitiesMorningStarManagementTransition = (
	state = INITIAL_STATE,
	{ name }
) => {
	// NOSONAR
	const action = getApiTransitionAction(state.equitiesMorningStarManagementStatus, name);
	if (action) {
		// execute action
		return action.apply(API_TRANSITIONS, [
			state,
			{ key: "equitiesMorningStarManagementStatus" },
		]);
	}
	// return existing state if no action found
	return { ...state };
};

export const equitiesMorningStarAssetClassificationTransition = (
	state = INITIAL_STATE,
	{ name }
) => {
	// NOSONAR
	const action = getApiTransitionAction(
		state.equitiesMorningStarAssetClassificationStatus,
		name
	);
	if (action) {
		// execute action
		return action.apply(API_TRANSITIONS, [
			state,
			{ key: "equitiesMorningStarAssetClassificationStatus" },
		]);
	}
	// return existing state if no action found
	return { ...state };
};

export const equitiesMorningStarPeerTransition = (state = INITIAL_STATE, { name }) => {
	// NOSONAR
	const action = getApiTransitionAction(state.equitiesMorningStarPeerDataStatus, name);
	if (action) {
		// execute action
		return action.apply(API_TRANSITIONS, [
			state,
			{ key: "equitiesMorningStarPeerDataStatus" },
		]);
	}
	// return existing state if no action found
	return { ...state };
};

export const updateEarningsEstimatesCurrentData = (
	state = INITIAL_STATE,
	{ earningsEstimatesCurrentData }
) => {
	// NOSONAR
	if (!earningsEstimatesCurrentData) {
		return { ...state };
	}

	return {
		...state,
		earningsEstimatesCurrentData: earningsEstimatesCurrentData,
	};
};

export const updateEarningsEstimatesTrendsData = (
	state = INITIAL_STATE,
	{ earningsEstimatesTrendsData }
) => {
	// NOSONAR
	if (!earningsEstimatesTrendsData) {
		return { ...state };
	}

	return {
		...state,
		earningsEstimatesTrendsData: earningsEstimatesTrendsData,
	};
};

export const updateEarningsEstimatesTrendsAnnualData = (
	state = INITIAL_STATE,
	{ earningsEstimatesTrendsAnnualData }
) => {
	// NOSONAR
	if (!earningsEstimatesTrendsAnnualData) {
		return { ...state };
	}

	return {
		...state,
		earningsEstimatesTrendsAnnualData: earningsEstimatesTrendsAnnualData,
	};
};

export const updateEquitiesMorningStarGeneralInfoData = (
	state = INITIAL_STATE,
	{ equitiesMorningStarGeneralInfoData }
) => {
	// NOSONAR
	if (!equitiesMorningStarGeneralInfoData) {
		return { ...state, equitiesMorningStarGeneralInfoData: {} };
	}

	return {
		...state,
		equitiesMorningStarGeneralInfoData: equitiesMorningStarGeneralInfoData,
	};
};

export const updateEquitiesMorningStarManagementData = (
	state = INITIAL_STATE,
	{ equitiesMorningStarManagementData }
) => {
	// NOSONAR
	if (!equitiesMorningStarManagementData) {
		return { ...state, equitiesMorningStarManagementData: {} };
	}

	return {
		...state,
		equitiesMorningStarManagementData: equitiesMorningStarManagementData,
	};
};

export const updateEquitiesMorningStarAssetClassificationData = (
	state = INITIAL_STATE,
	{ equitiesMorningStarAssetClassificationData }
) => {
	// NOSONAR
	if (!equitiesMorningStarAssetClassificationData) {
		return { ...state, equitiesMorningStarAssetClassificationData: {} };
	}

	return {
		...state,
		equitiesMorningStarAssetClassificationData:
			equitiesMorningStarAssetClassificationData,
	};
};

export const updateEquitiesMorningStarPeerData = (
	state = INITIAL_STATE,
	{ equitiesMorningStarPeerData }
) => {
	// NOSONAR
	if (!equitiesMorningStarPeerData) {
		return { ...state, equitiesMorningStarPeerData: [] };
	}

	return {
		...state,
		equitiesMorningStarPeerData: equitiesMorningStarPeerData,
	};
};

export const updateEarningsChartPeriodicity = (
	state = INITIAL_STATE,
	{ earningsChartPeriodicity }
) => {
	// NOSONAR
	if (!earningsChartPeriodicity) {
		return { ...state };
	}

	return {
		...state,
		earningsChartPeriodicity,
	};
};

export const HANDLERS = {
	[actionTypes.EARNINGS_ESTIMATES_CURRENT_API_TRANSITION]:
		earningsEstimatesCurrentTransition,
	[actionTypes.EARNINGS_ESTIMATES_TRENDS_API_TRANSITION]:
		earningsEstimatesTrendsTransition,
	[actionTypes.EARNINGS_ESTIMATES_TRENDS_ANNUAL_API_TRANSITION]:
		earningsEstimatesTrendsAnnualTransition,
	[actionTypes.OVERVIEW_INSIGHT_TRANSITION]: overviewInsightTransition,
	[actionTypes.EQUITIES_MORNING_STAR_GENERAL_INFO_API_TRANSITION]:
		equitiesMorningStarGeneralInfoTransition,
	[actionTypes.EQUITIES_MORNING_STAR_MANAGEMENT_API_TRANSITION]:
		equitiesMorningStarManagementTransition,
	[actionTypes.EQUITIES_MORNING_STAR_ASSET_CLASSIFICATION_API_TRANSITION]:
		equitiesMorningStarAssetClassificationTransition,
	[actionTypes.EQUITIES_MORNING_STAR_PEER_API_TRANSITION]:
		equitiesMorningStarPeerTransition,
	[actionTypes.SAVE_EARNINGS_ESTIMATES_CURRENT_DATA]: updateEarningsEstimatesCurrentData,
	[actionTypes.SAVE_EARNINGS_ESTIMATES_TRENDS_DATA]: updateEarningsEstimatesTrendsData,
	[actionTypes.SAVE_EARNINGS_ESTIMATES_TRENDS_ANNUAL_DATA]:
		updateEarningsEstimatesTrendsAnnualData,
	[actionTypes.SAVE_EQUITIES_MORNING_STAR_GENERAL_INFO_DATA]:
		updateEquitiesMorningStarGeneralInfoData,
	[actionTypes.SAVE_EQUITIES_MORNING_STAR_MANAGEMENT_DATA]:
		updateEquitiesMorningStarManagementData,
	[actionTypes.SAVE_EQUITIES_MORNING_STAR_ASSET_CLASSIFICATION_DATA]:
		updateEquitiesMorningStarAssetClassificationData,
	[actionTypes.SAVE_EQUITIES_MORNING_STAR_PEER_DATA]: updateEquitiesMorningStarPeerData,
	[actionTypes.SAVE_EARNINGS_CHART_PERIODICITY]: updateEarningsChartPeriodicity,
};

export default createReducer(INITIAL_STATE, HANDLERS);
