import React, { useRef, useState, useEffect } from "react";
import { Popper } from "react-popper";
import styles from "./LineDropdown.module.scss";
import Chevron from "../Chevron/Chevron";

const LineStyles = [
	{ style: "line1", width: 1, linedash: "", name: "Solid" },
	{ style: "line2", width: 2, linedash: "", name: "Bold" },
	{ style: "line3", width: 2, linedash: "2,4", name: "Dotted" },
	{ style: "line4", width: 2, linedash: "6,2", name: "Dash" },
	{ style: "line5", width: 2, linedash: "12,2", name: "Long Dash" },
];

const getLineStyleMap = (width, linedash) => {
	width = parseInt(width);
	if (width === 1 && linedash === "") {
		return "line1";
	} else if (width === 2 && linedash === "") return "line2";
	else if (width === 2 && linedash === "2,4") return "line3";
	else if (width === 2 && linedash === "6,2") return "line4";
	else if (width === 2 && linedash === "12,2") return "line5";
	else return "line2";
};

const getLineName = (width, linedash) =>
	LineStyles.find((item) => item.width === parseInt(width) && item.linedash === linedash)
		?.name;

const LineDropdown = (props) => {
	const [toggle, setToggle] = useState(false);
	const buttonId = `btn_linedropdown_${props.id}`;
	const linedropdownId = `linedropdown_${props.id}`;
	let popperNode = null;
	let btnRef = useRef(null);
	let lineStyleClass = getLineStyleMap(props.width, props.linedash);
	const [selectedLineStyle, setSelectedLineStyle] = useState({
		width: props.width,
		linedash: props.linedash,
		styleClass: lineStyleClass,
		name: getLineName(props.width, props.linedash),
	});

	const lineSelectorHandler = (e) => {
		let width = e.target.dataset.width;
		let linedash = e.target.dataset.linedash;
		let linename = e.target.dataset.name;
		lineStyleClass = getLineStyleMap(width, linedash);
		setSelectedLineStyle({
			width: width,
			linedash: linedash,
			styleClass: lineStyleClass,
			name: linename,
		});
		closePopover();
		btnRef.current.focus();
		props.onSelected({ name: props.name, width: width, linedash: linedash });
	};

	const lineDiv = LineStyles.map((line, i) => (
		<div
			key={line.name}
			data-width={line.width}
			data-linedash={line.linedash}
			aria-label={props.name + " " + line.name}
			data-name={line.name}
			data-isfirst={i === 0}
			className={"lineItem"}
			onClick={lineSelectorHandler}
			tabIndex="0"
			onKeyDown={(e) => keyHandler(e)}
		>
			<span className={`${line.style} lines`} />
		</div>
	));
	const keyHandler = (e) => {
		e.key !== "Tab" && e.preventDefault();
		let keyCode = e.which;
		if (e.key === "Enter") {
			lineSelectorHandler(e);
		}
		if (e.key === "Escape") {
			closePopover();
			btnRef.current.focus();
		}
		if (e.target.dataset.isfirst === "true" && e.shiftKey && e.keyCode === 9) {
			closePopover();
		}

		if (keyCode === 40) {
			let nextElementSibling = e.currentTarget.nextElementSibling;
			if (nextElementSibling && nextElementSibling.className.includes("lineItem")) {
				nextElementSibling.focus({
					preventScroll: true,
				});
			}
		} else if (keyCode === 38) {
			let prevElementSibling = e.currentTarget.previousElementSibling;
			if (prevElementSibling && prevElementSibling.className.includes("lineItem")) {
				prevElementSibling.focus({
					preventScroll: true,
				});
			}
		}
	};

	useEffect(() => {
		toggle &&
			popperNode.firstChild.focus({
				preventScroll: true,
			});
	}, [toggle, popperNode]);

	const clickHandler = (e) => {
		setToggle(!toggle);
	};
	const onBlur = (e) => {
		e && e.stopPropagation();
		if (
			e.relatedTarget &&
			!e.currentTarget.contains(e.relatedTarget) &&
			e.relatedTarget.id !== buttonId
		) {
			closePopover();
		}
	};

	const closePopover = () => {
		setToggle(false);
	};

	return (
		<div className={styles.LineDropdown}>
			<button className={styles.btn} onClick={clickHandler} ref={btnRef} id={buttonId}>
				<span className="sr-only">{props.name + " " + selectedLineStyle.name}</span>
				<span
					data-name={selectedLineStyle.styleClass}
					className={`${selectedLineStyle.styleClass} lines`}
				/>
				<Chevron direction={toggle ? "up" : "down"} dimension="20" color="black" />
			</button>
			{toggle && (
				<Popper
					referenceElement={btnRef}
					placement={"bottom"}
					modifiers={{ offset: "10px, 10px" }}
					innerRef={(node) => (popperNode = node)}
				>
					{({ ref, style, placement, arrowProps }) => (
						<div
							className={styles.popOver}
							id={linedropdownId}
							ref={ref}
							data-placement={placement}
							onBlur={onBlur}
						>
							{lineDiv}
						</div>
					)}
				</Popper>
			)}
		</div>
	);
};

export default LineDropdown;
