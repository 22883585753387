import React, { Component } from "react";
import MSDropdown from "../../../Lib/MSDropdown/MSDropdown";
import SecFilingConstants from "./SecFilingConstant";
import styles from "./SecFilingTable.module.scss";
import formatter from "../../../../utils/formatUtils";
import MSSpinner from "../../../Lib/MSSpinner/MSSpinner";
import PaginationFooter from "../../../Lib/PaginationFooter/PaginationFooter";
import Utils from "../../../../utils/utils";
import SimpleSpinner from "components/Lib/SimpleSpinner/SimpleSpinner";

import SecFilingsAPIUtils from "services/APIs/SecFilings/SecFilingsAPI";
import NoData from "components/Lib/NoData/NoData";

export class SecFilingTable extends Component {
	filterParams = {
		startDate: formatter.moment().startOf("year").format("YYYY-MM-DD"),
		endDate: formatter.moment().format("YYYY-MM-DD"),
		pageOffset: 0,
		formType: "",
	};
	hasBeenCalled = false;

	constructor(props) {
		super(props);
		this.state = {
			tableData: {
				headersData: [
					{ CellValue: "Filing Date", CellMap: "filingDate" },
					{ CellValue: "Period", CellMap: "filingDate" },
					{ CellValue: "Type", CellMap: "formType" },
					{ CellValue: "Name", CellMap: "category" },
				],
				rowsData: [],
			},
			selectedFiling: SecFilingConstants.SEC_FILING_REPORT_TIME[0],
			selectedTimePeriod: SecFilingConstants.TIME_FRAMES[0].label,
			page: 1,
			totalPages: 1,
			totalItems: null,
			startingItem: 1,
			endingItem: 10,
			limit: 10,
			secFilingTableData: null,
			isContentReady: true,
		};
	}

	componentDidMount() {
		this.fetchNewSecFilingData(
			this.state.startingItem === 1 ? 0 : this.state.startingItem
		);
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.shouldFetchData(prevProps)) {
			this.fetchNewSecFilingData(
				this.state.startingItem === 1 ? 0 : this.state.startingItem
			);
		}

		if (prevState.secFilingTableData !== this.state.secFilingTableData) {
			this.hasBeenCalled = false;
			this.updatePagination();
		}
	}

	fetchNewSecFilingData = async (currentPage) => {
		this.setState({
			secFilingTableData: null,
			isContentReady: false,
		});
		const newSecFilingData = await SecFilingsAPIUtils.getDocumentList(
			this.props.apiInstance,
			{
				...this.filterParams,
				cik: this.props.cik,
				limit: this.state.limit,
				offset: currentPage,
			}
		);

		this.setState({
			secFilingTableData: newSecFilingData,
			isContentReady: true,
		});
	};

	updatePagination() {
		const limit = 10;
		const offset = limit * this.state.page - 10;
		const startingItem = offset + 1;
		const endingItem = offset + limit;
		let totalPages;
		const totalItems = Utils.getObjectPropertyValue(
			this.state.secFilingTableData,
			"pagination.totalItems"
		);
		totalPages = Math.ceil(totalItems / limit);
		let trueEndItem;
		if (endingItem > totalItems) {
			trueEndItem = totalItems;
		} else {
			trueEndItem = endingItem;
		}
		this.setState({
			totalPages: totalPages,
			totalItems: totalItems,
			startingItem: startingItem,
			endingItem: trueEndItem,
		});
	}
	//this function governs expected behavior for pagination, and is passed as a prop for handling the clicks in the pagination component. This updates state so that the pagination controls and api calls can remain in sync and updated correctly.
	arrowClickCallback = (behavior) => {
		let newPage = this.state.page;
		switch (behavior) {
			case "skipToBeginning":
				newPage = 1;
				break;
			case "skipToEnd":
				newPage = this.state.totalPages;
				break;
			case "previousPage":
				if (this.state.page > 1) {
					newPage = this.state.page - 1;
				}
				break;
			case "nextPage":
				if (this.state.page < this.state.totalPages) {
					newPage = this.state.page + 1;
				}
				break;
			default:
				break;
		}
		if (newPage !== this.state.page) {
			if (!this.hasBeenCalled) {
				this.fetchNewSecFilingData(
					this.state.startingItem === 1 ? 0 : newPage * this.state.limit - 10
				);

				this.setState({
					page: newPage,
				});
				this.hasBeenCalled = true;
			}
		}
	};

	shouldFetchData = (prevProps) => {
		if (!prevProps) {
			return false;
		}
		let fetchTableData = false;
		if (prevProps.venueXid !== this.props.venueXid) {
			fetchTableData = true;
		}
		return fetchTableData;
	};

	handleFilingTypeOnSelect = (selectedFilingTypeLabel) => {
		let secFilingFilterParam = SecFilingConstants.SEC_FILING_REPORT_TIME.filter(
			(filingType) => filingType.label === selectedFilingTypeLabel
		)[0];
		this.filterParams.formType = secFilingFilterParam.value;

		this.fetchNewSecFilingData(this.state.startingItem === 1 ? 0 : 1);

		//reset to first page
		this.setState({
			selectedFiling: secFilingFilterParam,
			page: 1,
		});
	};

	handleTimePeriodOnSelect = (selectedTimePeriodLabel) => {
		this.updateFilterParamOnTimePeriodChange(selectedTimePeriodLabel);

		this.fetchNewSecFilingData(this.state.startingItem === 1 ? 0 : 1);

		//reset to first page
		this.setState({
			selectedTimePeriod: selectedTimePeriodLabel,
			page: 1,
		});
	};

	updateFilterParamOnTimePeriodChange = (selectedFilingTypeLabel) => {
		switch (selectedFilingTypeLabel) {
			case "Current Year":
				this.filterParams.startDate = formatter
					.moment()
					.startOf("year")
					.format("YYYY-MM-DD");
				break;
			case "Last Month":
				this.filterParams.startDate = formatter
					.moment()
					.subtract(1, "months")
					.format("YYYY-MM-DD");
				break;
			case "Last 3 Months":
				this.filterParams.startDate = formatter
					.moment()
					.subtract(3, "months")
					.format("YYYY-MM-DD");
				break;
			case "Last 6 Months":
				this.filterParams.startDate = formatter
					.moment()
					.subtract(6, "months")
					.format("YYYY-MM-DD");
				break;
			case "12 Months":
				this.filterParams.startDate = formatter
					.moment()
					.subtract(12, "months")
					.format("YYYY-MM-DD");
				break;
			case "Last 2 Years":
				this.filterParams.startDate = formatter
					.moment()
					.subtract(24, "months")
					.format("YYYY-MM-DD");
				break;
			case "Last 3 Years":
				this.filterParams.startDate = formatter
					.moment()
					.subtract(36, "months")
					.format("YYYY-MM-DD");
				break;
			case "Last 4 Years":
				this.filterParams.startDate = formatter
					.moment()
					.subtract(48, "months")
					.format("YYYY-MM-DD");
				break;
			default:
				break;
		}
	};

	loadSecFilingsPdfData = (feedId, documentTag) => {
		window.open(
			`${window.MD.MSO_DOMAIN}/ms-research/markets-and-research/SecFilingsPdf?feedId=${feedId}&documentTag=${documentTag}`
		);
	};

	renderSecFilingFullTable = () => {
		const contentReady = this.state.secFilingTableDataStatus !== null;

		return (
			<MSSpinner style={styles.spinnerStyle} ready={contentReady} spinnerSize={"2x"}>
				{this.state.isContentReady ? (
					this.state.secFilingTableData && this.state.secFilingTableData.items ? (
						<div className={styles.secFilingTableWrapper}>
							<table className={`${styles.secFilingTable}`}>
								<caption className="sr-only">SEC Filing Data</caption>
								<thead>
									<tr key="heading" className={styles.columnsHeaderRow}>
										{this.renderDataColumnHeaders()}
									</tr>
								</thead>
								<tbody>{this.renderSecFilingDataTable()}</tbody>
							</table>

							<PaginationFooter
								page={this.state.page}
								totalPages={this.state.totalPages}
								totalItems={this.state.totalItems}
								startingItem={this.state.startingItem}
								endingItem={this.state.endingItem}
								arrowClickCallback={this.arrowClickCallback}
								ariaLabel="SecFiling Table Pagination"
							/>
						</div>
					) : (
						<NoData>
							{`There is no SEC Fillings data available for ${this.props.symbol}`}
						</NoData>
					)
				) : (
					<div className={styles.secFilingTableWrapper}>
						<SimpleSpinner />
					</div>
				)}
			</MSSpinner>
		);
	};

	renderSecFilingDataTable() {
		const header = this.state.tableData.headersData.map((x) => x.CellMap);
		return this.state.secFilingTableData.items.map((item, index) =>
			this.renderTableRow(item, index, header)
		);
	}

	renderTableRow = (item, parentIndex, header) => {
		return (
			<tr key={parentIndex}>
				{header.map((tdKey, index) => {
					switch (tdKey) {
						case "filingDate":
							return (
								<td key={"td_" + index}>
									{formatter.moment(item[tdKey]).format("MM/DD/YYYY")}
								</td>
							);
						case "formType":
							return (
								<td key={"td_" + index}>
									<a
										tabIndex={0}
										className={`btn btn-link ${styles["pdfButton"]} ${styles["noWordWrap"]}`}
										href={`${window.MD.MSO_DOMAIN}/ms-research/markets-and-research/SecFilingsPdf?feedId=${item.feedId}&documentTag=${item.documentTag}`}
										target="_blank"
										rel="noopener noreferrer"
										aria-label={item[tdKey]}
									>
										{item[tdKey]}
									</a>
								</td>
							);

						default:
							return <td key={"td_" + index}>{item[tdKey]}</td>;
					}
				})}
			</tr>
		);
	};
	renderDataColumnHeaders = () => {
		return this.state.tableData.headersData.map((header, index) => {
			return (
				<th key={"secFilingheader_" + index} scope="row">
					{header.CellValue}
				</th>
			);
		});
	};

	render() {
		const secFilingConstantsMapping = (filing) => ({
			option: filing.label,
		});
		return (
			<div id="secFilingContainer" className={styles.secFilingContainer}>
				<div className={styles.dataContainer}>
					<section className={styles.componentContainer}>
						<div className={styles.eventControlContainer}>
							<div className={styles.eventControlWrapper}>
								{
									<div className={styles.eventsControl}>
										<MSDropdown
											data={SecFilingConstants.SEC_FILING_REPORT_TIME.map(
												secFilingConstantsMapping
											)}
											title={this.state.selectedFiling.label}
											onSelectCallback={this.handleFilingTypeOnSelect}
										/>
									</div>
								}
								{
									<div className={styles.eventsControlRight}>
										<MSDropdown
											data={SecFilingConstants.TIME_FRAMES.map(secFilingConstantsMapping)}
											title={this.state.selectedTimePeriod}
											onSelectCallback={this.handleTimePeriodOnSelect}
										/>
									</div>
								}
							</div>
						</div>
						<div className={styles.legendContainer}>
							{this.renderSecFilingFullTable()}
						</div>
					</section>
				</div>
			</div>
		);
	}
}

export default SecFilingTable;
