import React from "react";

const CandlestickChart = (props) => {
	return (
		<svg
			aria-hidden="true"
			focusable="false"
			className="candlestickChart"
			width="17px"
			height="15px"
			viewBox="0 0 17 15"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			style={props.style}
		>
			<g
				id="Desktop---Interactive-States"
				stroke="none"
				strokeWidth="1"
				fill="none"
				fillRule="evenodd"
			>
				<g
					id="07_MSO-Company-Overview-1440-Charting-Module-States"
					transform="translate(-990.000000, -1479.000000)"
					stroke="none"
					strokeWidth="2"
				>
					<g id="Candlestick" transform="translate(32.000000, 1274.000000)">
						<g id="Group-15" transform="translate(959.115260, 205.000000)">
							<rect
								id="Rectangle"
								x="7.01660952e-14"
								y="6.46153846"
								width="4.61538462"
								height="5.53846154"
							></rect>
							<rect
								id="Rectangle"
								x="9.23076923"
								y="2.76923077"
								width="4.61538462"
								height="8.30769231"
							></rect>
							<line
								x1="2.30769231"
								y1="4.38459776"
								x2="2.30769231"
								y2="6.46153846"
								id="Path-3"
							></line>
							<line
								x1="2.30769231"
								y1="12"
								x2="2.30769231"
								y2="14.0769407"
								id="Path-3-Copy"
							></line>
							<line
								x1="11.5384615"
								y1="-2.62012634e-14"
								x2="11.5384615"
								y2="2.76923077"
								id="Path-3"
							></line>
							<line
								x1="11.5384615"
								y1="11.0769231"
								x2="11.5384615"
								y2="13.8461538"
								id="Path-3-Copy"
							></line>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default CandlestickChart;
