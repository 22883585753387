import Chartist from "chartist";

function ChartPointLabels(options) {
	function createFilterForOutline(data, options) {
		// Select the defs element within the chart or create a new one
		var defs = data.svg.querySelector("defs") || data.svg.elem("defs");

		// Create pattern for stripe
		let filter = defs.elem("filter", {
			id: "bg-text",
		});
		filter.elem("feMorphology", {
			in: "SourceAlpha",
			result: "DILATED",
			operator: "dilate",
			radius: "4",
		});
		filter.elem("feFlood", {
			"flood-color": "white",
			"flood-opacity": "1",
			result: "PINK",
		});
		filter.elem("feComposite", {
			in2: "DILATED",
			operator: "in",
			result: "OUTLINE",
			in: "PINK",
		});
		let feMerge = filter.elem("feMerge", {});
		feMerge.elem("feMergeNode", {
			in: "OUTLINE",
		});
		feMerge.elem("feMergeNode", {
			in: "SourceGraphic",
		});

		return defs;
	}
	return function ctPointLabels(chart) {
		let defaultOptions = {
			labelClass: "ct-label",
			labelOffset: {
				x: 0,
				y: -10,
			},
			textAnchor: "middle",
			labelAdditionalClass: "",
			labelInterpolationFnc: Chartist.noop,
		};

		options = Chartist.extend({}, defaultOptions, options);

		if (chart instanceof Chartist.Line) {
			chart.on("draw", function (data) {
				if (data.type === "point") {
					let circle = new Chartist.Svg(
						"circle",
						{
							cx: [data.x],
							cy: [data.y],
							r: [5],
						},
						"ct-circle"
					);
					data.element.replace(circle);
					if (options.showPointLabel) {
						// get the coordinated from hidden field and adjust the label position
						let xAxis = document.getElementById(options.chartGuid);
						let seriesAcoords = xAxis.getAttribute("series_0_coord").split(";");
						let seriesBcoords = xAxis.getAttribute("series_1_coord").split(";");
						let newdataX = data.x;
						if (parseInt(seriesBcoords[data.index]) + 5 < data.y) {
							newdataX = data.x + (options.pointLabelOffset || 45);
						} else if (parseInt(seriesAcoords[data.index]) - 5 < data.y) {
							newdataX = data.x + 23;
						}
						let newDataY = data.y + options.labelOffset.y;
						if (options.labelCount - 1 === data.index) {
							let gridCoords = xAxis.getAttribute("chart_grid_coord").split(";");
							let overlapGrid = gridCoords.filter(
								(x) => newDataY - 5 < parseInt(x) && parseInt(x) < newDataY + 5
							);
							if (overlapGrid.length > 0) newdataX = newdataX - 10;
						}

						data.group
							.elem(
								"text",
								{
									x: newdataX + options.labelOffset.x,
									y: newDataY,
									style: "text-anchor: " + options.textAnchor,
									filter: "url(#bg-text)",
								},
								options.labelClass + " ct-point-label " + options.labelAdditionalClass
							)
							.text(options.labelInterpolationFnc(data.value.y));
					}
				}
			});
			chart.on("created", function (data) {
				createFilterForOutline(data, options);
			});
		}
	};
}

export default ChartPointLabels;
