import React, { useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { default as Left } from "../../../../../Lib/Icons/Left/Left";
import { default as Right } from "../../../../../Lib/Icons/Right/Right";
import { default as Close } from "../../../../../Lib/Icons/Close/Close";
import formatUtils from "utils/formatUtils";
import styles from "./DateRangePicker.module.scss";
import "react-datepicker/dist/react-datepicker.css";
import languageEn from "assets/language/en";
import localeEn from "date-fns/locale/en-US";
import { RESPONSIVE_BREAKPOINTS } from "utils/constants";

const DateRangePickerModal = ({
	modalVisibility,
	onModalClose,
	onDatePickerDateChange,
	language,
	minStartDate,
	locale,
	maxNumberOfYears,
}) => {
	const currentYear = new Date().getFullYear();
	const [startDateFoist, setStartDateFoist] = useState("");
	const [endDateFoist, setEndDateFoist] = useState(false);
	const [switcher, setSwitchState] = useState(false);
	const [focusFrom, setFocusFromField] = useState(false);
	const [focusTo, setFocusToField] = useState(false);
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [dateError, setDateError] = useState("");
	const [focusMonth, setFocusMonth] = useState(false);
	const [focusYear, setFocusYear] = useState(false);
	const innerRefStartDate = useRef();
	const innerRefEndDate = useRef();

	const isMobile =
		window.matchMedia(
			`(max-width: ${RESPONSIVE_BREAKPOINTS.MEDIA_BREAKPOINTS_MAX.MEDIA_BREAKPOINT_MAX_LG}px)`
		).matches || false;

	const range = (start, end) => {
		return new Array(end - start).fill().map((d, i) => i + start);
	};
	let datepickerMargin;

	if (isMobile) {
		// true for mobile device
		datepickerMargin = "2";
	} else {
		datepickerMargin = "5";
	}

	const START_YEAR_RANGE = currentYear - (maxNumberOfYears ? maxNumberOfYears : 10);
	const years = range(START_YEAR_RANGE, currentYear);
	years.push(currentYear);
	years.reverse();

	useEffect(() => {
		setStartDate(
			startDateFoist ? `${formatUtils.date(startDateFoist, "MMM DD YYYY")}` : ""
		);
	}, [startDateFoist]);
	useEffect(() => {
		setEndDate(endDateFoist ? `${formatUtils.date(endDateFoist, "MMM DD YYYY")}` : "");
	}, [endDateFoist]);

	const months = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec",
	];

	const handleDateValidation = (date) => {
		let isValid = false;
		if (
			formatUtils.moment(date, "MMM DD YYYY", true).isValid() ||
			formatUtils.moment(date, "MM/DD/YYYY", true).isValid()
		) {
			isValid = true;
		} else {
			setDateError("Enter valid date format of MM/DD/YYYY or MMM DD YYYY");
		}
		return isValid;
	};

	const handleStartDateChange = (date, isBlurEvent) => {
		setDateError("");
		setFocusFromField(false);
		if (date) {
			if (handleDateValidation(date)) {
				let startDt;
				//If Start date is greater than todays date then use today's date.
				if (new Date(date) > new Date()) {
					startDt = new Date();
				} else if (new Date(date) < new Date(minStartDate())) {
					//If Start date is less than 10 years from current date then use today's date - 10 years date.
					startDt = new Date(minStartDate());
				} else {
					startDt = new Date(date);
				}

				setStartDateFoist(startDt);

				if (endDateFoist && endDateFoist.getTime() < startDt.getTime()) {
					setEndDateFoist(false);
					setSwitchState(true);
					setEndDate("");
					return;
				}
				if (!isBlurEvent) {
					setSwitchState(true);
					innerRefEndDate.current.focus();
				}
			}
		}
	};

	const handleEndDateChange = (date, isBlurEvent) => {
		setDateError("");
		setFocusToField(false);
		if (date) {
			if (handleDateValidation(date)) {
				let endDt;
				//If End date is greater than todays date then use today's date.
				if (new Date(date) > new Date()) {
					endDt = new Date();
				} else {
					endDt = new Date(date);
				}
				setEndDateFoist(endDt);

				if (startDateFoist && startDateFoist.getTime() > endDt.getTime()) {
					setStartDateFoist("");
					setSwitchState(false);
					setStartDate("");
					return;
				}
				if (!isBlurEvent) {
					setSwitchState(false);
					innerRefStartDate.current.focus();
				}
			}
		}
	};

	const handleApplyClick = () => {
		if (!startDate || !endDate) {
			setDateError("You must have a start and an end date selected to continue.");
		} else if (handleDateValidation(startDate) && handleDateValidation(endDate)) {
			onModalClose();
			setSwitchState(false);
			onDatePickerDateChange(startDateFoist, endDateFoist);
		}
	};

	const handleClose = () => {
		onModalClose();
	};

	const handleFocusEvent = (event, setFocus, switchState) => {
		if (event && event.type === "keyup") {
			// setting keyup event changes on tab only
			if (event.keyCode === 9) {
				setFocus(true);
				// This will help to change the value of switcher when user use TAB for next input.
				setSwitchState(switchState);
			} else {
				setFocus(false);
			}
		}
	};

	return (
		<div className={styles.wrapper}>
			<Modal
				style={
					datepickerMargin && {
						top: datepickerMargin + "%",
					}
				}
				onShow={() => {
					if (!switcher) {
						innerRefStartDate.current.focus();
					} else {
						innerRefEndDate.current.focus();
					}
				}}
				show={modalVisibility}
				onHide={handleClose}
				centered={!datepickerMargin}
				dialogClassName={styles.dialogWrap}
			>
				<div>
					<span className="modalClose" onClick={handleClose}>
						<Close />
					</span>
				</div>
				<Modal.Header>
					<Modal.Title>
						{language && language.SELECT_CUSTOM_DATE_RANGE
							? language.SELECT_CUSTOM_DATE_RANGE
							: languageEn.SELECT_CUSTOM_DATE_RANGE}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<DatePicker
						locale={locale ? locale : localeEn}
						filterDate={(date) =>
							// Only show dates that are in the past. And, if we're selecting a to date (switcher === true), then
							// only those values after the selected date are valid
							date.getTime() < new Date() &&
							(!switcher ||
								!startDateFoist ||
								(switcher && date.getTime() >= startDateFoist.getTime()))
						}
						renderCustomHeader={({
							date,
							changeYear,
							changeMonth,
							decreaseMonth,
							increaseMonth,
							prevMonthButtonDisabled,
							nextMonthButtonDisabled,
						}) => (
							<div>
								<div>
									<div className="calendarHeader-wrap"></div>
									<div className="calendarHeader mt-3 mb-3">
										<div>
											<label className={`mr-3 float-left ${!switcher ? "active" : ""}`}>
												<span id="dateRangePicker-from">
													{language && language.FROM ? language.FROM : languageEn.FROM}
												</span>
												<input
													type="text"
													name="fromDate"
													className={focusFrom ? "focusVisible" : "focusReset"}
													value={startDate}
													ref={innerRefStartDate}
													aria-label="Start Date"
													onClick={() => {
														setSwitchState(false);
													}}
													onChange={(e) => setStartDate(e.target.value)}
													onKeyUp={(event) =>
														handleFocusEvent(event, setFocusFromField, false)
													}
													onBlur={(e) => handleStartDateChange(e.target.value, true)}
													onMouseDown={() => setFocusFromField(false)}
													tabIndex="0"
												/>
											</label>
											<label className={switcher ? "active" : ""}>
												<span>
													{language && language.TO ? language.TO : languageEn.TO}
												</span>
												<input
													type="text"
													name="toDate"
													className={focusTo ? "focusVisible" : "focusReset"}
													value={endDate}
													ref={innerRefEndDate}
													aria-label="End Date"
													onClick={() => {
														setSwitchState(true);
													}}
													onChange={(e) => setEndDate(e.target.value)}
													onKeyUp={(event) =>
														handleFocusEvent(event, setFocusToField, true)
													}
													onBlur={(e) => handleEndDateChange(e.target.value, true)}
													onMouseDown={() => setFocusToField(false)}
													tabIndex="0"
												/>
											</label>
										</div>
										<div className="error mt-1">{dateError}</div>
									</div>
								</div>
								<div className={`mt-3`}>
									<div className="header-select-wrap">
										<select
											className={`mr-1 ${focusMonth ? "focusVisible" : "focusReset"}`}
											onKeyUp={(e) => setFocusMonth(true)}
											onMouseDown={() => setFocusMonth(false)}
											onBlur={() => setFocusMonth(false)}
											value={months[date.getMonth()]}
											onChange={({ target: { value } }) => {
												changeMonth(months.indexOf(value));
											}}
										>
											{months.map((option, index) => (
												<option key={option} value={option}>
													{option}
												</option>
											))}
										</select>
										<select
											className={`yearSelect ${
												focusYear ? "focusVisible" : "focusReset"
											}`}
											onKeyUp={(e) => setFocusYear(true)}
											onMouseDown={() => setFocusYear(false)}
											onBlur={() => setFocusYear(false)}
											onChange={({ target: { value } }) => {
												changeYear(value);
											}}
											value={date.getFullYear()}
										>
											{years.map((option) => (
												<option title={option} key={option} value={option}>
													{option}
												</option>
											))}
										</select>
									</div>
									<div className="header-month-wrap">
										<button
											title="Left arrow"
											className="mr-3"
											onClick={decreaseMonth}
											disabled={prevMonthButtonDisabled}
											aria-label="Decrease Month"
										>
											<Left color={prevMonthButtonDisabled ? "#ccc" : "#333333"} />
										</button>
										<button
											title="Right Arrow"
											onClick={increaseMonth}
											disabled={nextMonthButtonDisabled}
											aria-label="Increase Month"
										>
											<Right color={nextMonthButtonDisabled ? "#ccc" : "#333333"} />
										</button>
									</div>
								</div>
							</div>
						)}
						selected={switcher ? endDateFoist : startDateFoist}
						onChange={(value) =>
							switcher
								? handleEndDateChange(value, false)
								: handleStartDateChange(value, false)
						}
						formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 1).toUpperCase()}
						inline
						calendarClassName={styles.calendarWrap}
						showMonthDropdown
						showYearDropdown
						dropdownMode="select"
						maxDate={new Date()}
					/>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="primary"
						onClick={handleApplyClick}
						className="dateRangePicker-apply-button"
					>
						{language && language.APPLY ? language.APPLY : languageEn.APPLY}
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
};

export default DateRangePickerModal;
