import { createReducer } from "reduxsauce";
import actionTypes from "../actions/actionTypes";
import ApiStates from "../apiStates";
import { API_TRANSITIONS, getApiTransitionAction } from "../apiTransitionUtils";

const INITIAL_STATE = {
	dividendsGrowthRateDataStatus: ApiStates.IDLE,
	dividendsGrowthRateData: {},
};

export const updateDividendsGrowthRate = (
	state = INITIAL_STATE,
	{ dividendsGrowthRateData }
) => {
	// NOSONAR
	if (!dividendsGrowthRateData) {
		return { ...state, dividendsGrowthRateData: {} };
	}

	return {
		...state,
		dividendsGrowthRateData: dividendsGrowthRateData,
	};
};

export const dividendsGrowthRateTransition = (state = INITIAL_STATE, { name }) => {
	// NOSONAR
	const action = getApiTransitionAction(state.dividendsGrowthRateDataStatus, name);
	if (action) {
		// execute action
		return action.apply(API_TRANSITIONS, [
			state,
			{ key: "dividendsGrowthRateDataStatus" },
		]);
	}
	// return existing state if no action found
	return { ...state };
};

export const HANDLERS = {
	[actionTypes.DIVIDENDS_GROWTH_RATE_API_TRANSITION]: dividendsGrowthRateTransition,
	[actionTypes.SAVE_DIVIDENDS_GROWTH_RATE_DATA]: updateDividendsGrowthRate,
};

export default createReducer(INITIAL_STATE, HANDLERS);
