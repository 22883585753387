/**
 * Helper to interface with the `PaginationFooter` for components that consume the research endpoints.
 * It returns an object that can be included as props into the `PaginationFooter`. E.g.,
 *
 * <code>
 * 	<PaginationFooter
 * 		ariaLabel="Research Article Pagination"
 *    className={styles.researchPaginationFooter}
 * 		{...paginate(...)}
 *  />
 * </code>
 *
 * @param {(nextStartingItem: number) => void} onStartingItemChange The callback for when the navigation buttons are pressed
 * @param {number} limit The current page limit
 * @param {number} startingItem The current starting item index
 * @param {number} totalItems The total number of items in the current result set
 */
const paginationHelper = (onStartingItemChange, limit, startingItem, totalItems) => {
	// calculate total pages
	const totalPages = Math.ceil(totalItems / limit);

	let page = Math.floor(startingItem / limit) + 1;
	// ensure current page isn't out of range
	if (page < 1) {
		page = 1;
	} else if (page > totalPages) {
		page = totalPages;
	}

	// calculate start and end item indexes
	const startIndex = (page - 1) * limit;
	const endIndex = Math.min(startIndex + limit - 1, totalItems - 1);

	const beginningOffsetOfLastPage = Math.floor(totalPages - 1) * limit;
	return {
		page: page,
		totalPages: totalPages,
		totalItems: totalItems.toLocaleString(),
		startingItem: (startIndex + 1).toLocaleString(),
		endingItem: (endIndex + 1).toLocaleString(),
		arrowClickCallback: (behaviour) => {
			switch (behaviour) {
				case "skipToBeginning":
					onStartingItemChange(0);
					break;
				case "skipToEnd":
					// calculate start and end item indexes
					onStartingItemChange(beginningOffsetOfLastPage);
					break;
				case "previousPage":
					onStartingItemChange(Math.max(0, startingItem - limit));
					break;
				case "nextPage":
					onStartingItemChange(Math.min(beginningOffsetOfLastPage, startingItem + limit));
					break;
				default:
					break;
			}
		},
	};
};

export default paginationHelper;
