import React, { useState, useRef, forwardRef, useImperativeHandle } from "react";
import format from "@markit/format";
import styles from "./XAxisFlag.module.scss";

const XAxisFlag = forwardRef(({ timeInterval, chartData }, ref) => {
	const interval = timeInterval.value;
	const [val, setVal] = useState(null);
	const [position, setPosition] = useState({ top: null, left: null });
	const divRef = useRef(null);

	useImperativeHandle(ref, () => ({
		updateFlag,
		hideFlag,
		showFlag,
	}));
	const updateFlag = (args) => {
		setVal(formatValue(args.date));
		positionFlag(args);
	};
	const hideFlag = () => {
		if (divRef?.current?.style) {
			divRef.current.style.display = "none";
		}
	};
	const showFlag = () => {
		if (divRef?.current?.style) {
			divRef.current.style.display = "block";
		}
	};

	const positionFlag = (args) => {
		let offset = interval === "Minute" ? 60 : 30;
		let leftrightoffset = interval === "Minute" ? 40 : 20;
		const rect = chartData.rootMouse.node().getBoundingClientRect();
		offset = args.x + 140 >= rect.right ? offset + leftrightoffset : offset;
		offset = args.x <= rect.left ? offset - leftrightoffset : offset;
		setPosition({ top: -20, left: args.x - offset });
	};
	const formatValue = (value) => {
		if (interval === "Minute") return format().date(value, "MMM DD YYYY hh:mm a");
		return format().date(value, "MMM DD YYYY");
	};

	return (
		<div
			ref={divRef}
			style={{ left: position.left, top: position.top }}
			className={`${styles.xAxisFlag}`}
			tabIndex="0"
		>
			{val}
		</div>
	);
});
export default XAxisFlag;
