import ApiStates from "./apiStates";

const changeState = (currentState, stateKey, newValue) => {
	const newState = {};
	newState[stateKey] = newValue;

	return {
		...currentState,
		...newState,
	};
};

export const API_TRANSITIONS = {
	IDLE: {
		load: (state, data) => {
			return changeState(state, data.key, ApiStates.FETCHING);
		},
	},
	FETCHING: {
		success: (state, data) => {
			return changeState(state, data.key, ApiStates.IDLE);
		},
		failure: (state, data) => {
			return changeState(state, data.key, ApiStates.ERROR);
		},
		reset: (state, data) => {
			//note, can also use this to trigger an error message
			return changeState(state, data.key, ApiStates.IDLE);
		},
	},
	ERROR: {
		reset: (state, data) => {
			//note, can also use this to trigger an error message
			return changeState(state, data.key, ApiStates.IDLE);
		},
	},
};

export const getApiTransitionAction = (apiTransitionState, transitionName) => {
	const actions = API_TRANSITIONS[apiTransitionState] || [];
	if (actions.length === 0) {
		return undefined;
	}
	return actions[transitionName];
};
