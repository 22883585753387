import { createReducer } from "reduxsauce";
import actionTypes from "../actions/actionTypes";
import ApiStates from "../apiStates";
import { API_TRANSITIONS, getApiTransitionAction } from "../apiTransitionUtils";

const INITIAL_STATE = {
	researchDataStatus: ApiStates.IDLE,
	researchDocumentStatus: ApiStates.IDLE,
	researchPdfStatus: ApiStates.IDLE,
	researchFinancialsData: null,
	morningstarDocumentsData: {},
	msDocumentsData: {},
	researchPdfData: {},
};

export const updateResearchFinancialsData = (
	state = INITIAL_STATE,
	{ researchFinancialsData }
) => {
	// NOSONAR
	if (!researchFinancialsData) {
		return {
			...state,
		};
	}

	return {
		...state,
		researchFinancialsData: researchFinancialsData,
	};
};

export const updateMorningstarDocumentsData = (
	state = INITIAL_STATE,
	{ morningstarDocumentsData }
) => {
	// NOSONAR
	if (!morningstarDocumentsData) {
		return {
			...state,
		};
	}

	return {
		...state,
		morningstarDocumentsData: morningstarDocumentsData,
	};
};

export const updateMSDocumentsData = (state = INITIAL_STATE, { msDocumentsData }) => {
	// NOSONAR
	if (!msDocumentsData) {
		return {
			...state,
		};
	}

	return {
		...state,
		msDocumentsData: msDocumentsData,
	};
};

export const updateResearchPdfData = (state = INITIAL_STATE, { researchPdfData }) => {
	// NOSONAR
	if (!researchPdfData) {
		return { ...state };
	}

	return {
		...state,
		researchPdfData: researchPdfData,
	};
};

export const researchTransition = (state = INITIAL_STATE, { name }) => {
	// NOSONAR
	const action = getApiTransitionAction(state.researchDataStatus, name);
	if (action) {
		// execute action
		return action.apply(API_TRANSITIONS, [state, { key: "researchDataStatus" }]);
	}
	// return existing state if no action found
	return { ...state };
};

export const documentTransition = (state = INITIAL_STATE, { name }) => {
	// NOSONAR
	const action = getApiTransitionAction(state.researchDocumentStatus, name);
	if (action) {
		// execute action
		return action.apply(API_TRANSITIONS, [state, { key: "researchDocumentStatus" }]);
	}
	// return existing state if no action found
	return { ...state };
};

export const researchPdfTransition = (state = INITIAL_STATE, { name }) => {
	// NOSONAR
	const action = getApiTransitionAction(state.researchPdfStatus, name);
	if (action) {
		// execute action
		return action.apply(API_TRANSITIONS, [state, { key: "researchPdfStatus" }]);
	}
	// return existing state if no action found
	return { ...state };
};

export const HANDLERS = {
	[actionTypes.RESEARCH_API_TRANSITION]: researchTransition,
	[actionTypes.RESEARCH_DOCUMENT_TRANSITION]: documentTransition,
	[actionTypes.RESEARCH_PDF_API_TRANSITION]: researchPdfTransition,
	[actionTypes.SAVE_RESEARCH_FINANCIAL_DETAILS_DATA]: updateResearchFinancialsData,
	[actionTypes.SAVE_MORNINGSTAR_DOCUMENT_DETAILS_DATA]: updateMorningstarDocumentsData,
	[actionTypes.SAVE_MORGANSTANLEY_DOCUMENT_DETAILS_DATA]: updateMSDocumentsData,
	[actionTypes.SAVE_RESEARCH_PDF_DATA]: updateResearchPdfData,
};

export default createReducer(INITIAL_STATE, HANDLERS);
