import React, { useState } from "react";
import { useDispatch } from "react-redux";
import actionTypes from "../../../store/actions/actionTypes";

import Chevron from "../Chevron/Chevron";
import MSSpinner from "components/Lib/MSSpinner/MSSpinner";
import classNames from "classnames";
import { Link } from "react-router-dom";

import styles from "./CollapsibleCard.module.scss";

const CollapsibleCard = React.memo((props) => {
	const dispatch = useDispatch();
	const [isCollapsed, setIsCollapsed] = useState(false);
	const { showNavigationLink, updateCardCollapsedState } = props;

	const handleLinkClick = (e) => {
		dispatch({
			type: actionTypes.UPDATE_MARKETS_RESEARCH_ROUTE_NAME,
			routeName: props.componentName,
		});
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	};

	function onClickCollapseExpand() {
		const newIsCollapsed = !isCollapsed;
		setIsCollapsed(newIsCollapsed);
		window.dataLayer.push({
			event: "cardCollapseExpand",
			eventProps: {
				sectionName: props.headingText,
				isCollapsed: isCollapsed,
			},
		});

		// allow card components to reference collapsed state of collapsable card
		if (updateCardCollapsedState) {
			updateCardCollapsedState(newIsCollapsed);
		}

		if (props.onClickCollapseExpandCallback) {
			props.onClickCollapseExpandCallback(newIsCollapsed);
		}
	}

	const queryParams = window.location.search;
	const toPath = props.componentName + queryParams;

	const navLink = props.linkText && props.linkUrl && (
		<Link className={styles.cardLink} to={toPath} onClick={handleLinkClick}>
			{props.linkText}
		</Link>
	);

	const spinnerStyle = {
		minHeight: "100px",
	};

	return (
		<section className={classNames(styles.cardContainer, "md-card")}>
			<div className={styles.cardHeader}>
				<h2 className={styles.title}>{props.headingText}</h2>
				{props.additionalText && (
					<div className={styles.additionalText}>{props.additionalText}</div>
				)}
				<div className={styles.cardLinkContainer}>
					{showNavigationLink ? navLink : null}
					{!props.hideIcon && (
						<button
							className={styles.collapseExpand}
							onClick={onClickCollapseExpand}
							aria-expanded={!isCollapsed}
						>
							<span className="sr-only">{`select to ${
								isCollapsed ? "expand" : "collapse"
							} section`}</span>
							<Chevron
								direction={isCollapsed ? "down" : "up"}
								dimension={20}
								color="black"
							/>
						</button>
					)}
				</div>
			</div>
			{!isCollapsed && (
				<MSSpinner
					spinnerStyle={spinnerStyle}
					ready={props.contentReady}
					spinnerSize={"2x"}
				>
					<div
						className={props.noPadding ? styles.cardContentNoPadding : styles.cardContent}
					>
						{props.children}
					</div>
				</MSSpinner>
			)}
		</section>
	);
});

export default CollapsibleCard;
