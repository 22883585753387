import React from "react";

const OHLCCHart = (props) => {
	return (
		<svg
			aria-hidden="true"
			focusable="false"
			className="ohlcChart"
			width="16px"
			height="16px"
			viewBox="0 0 16 20"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			style={props.style}
		>
			<g
				id="Desktop---Interactive-States"
				stroke="none"
				strokeWidth="1"
				fill="none"
				fillRule="evenodd"
			>
				<g
					id="07_MSO-Company-Overview-1440-Charting-Module-States"
					transform="translate(-997.000000, -2095.000000)"
					stroke="none"
					strokeWidth="2"
				>
					<g id="OHLC" transform="translate(33.000000, 1861.000000)">
						<g id="Group-17" transform="translate(964.948220, 234.158100)">
							<path
								d="M5.6,2.83394041 L9.98352032,2.83394041 M9.98352032,7.78782732 L14.3670406,7.78782732 M9.53554688,3.48165941e-13 L9.53554688,11.111396"
								id="Combined-Shape-Copy-8"
							></path>
							<path
								d="M1.234568e-13,10.0339404 L4.38352032,10.0339404 M4.38352032,14.9878273 L8.76704065,14.9878273 M3.93554688,7.2 L3.93554688,18.311396"
								id="Combined-Shape-Copy-9"
							></path>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default OHLCCHart;
