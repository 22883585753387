import React, { useState, useEffect } from "react";
import { find, forEach, sortBy } from "lodash";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import formatter, { options } from "utils/formatUtils";
import styles from "./ChartEventsOverlay.module.scss";
import ChartEventConstants from "./ChartEventsConstants";
import utils from "utils/utils";
import { ReactComponent as UpgradeIcon } from "assets/icons/upgrade-icon.svg";
import { ReactComponent as DowngradeIcon } from "assets/icons/downgrade-icon.svg";
import { ReactComponent as NoChangeIcon } from "assets/icons/no-change-icon.svg";

/**
 * Renders event icons on a chart with tooltips.
 * @param {Array} props.eventsData - Events from a Modcharts chartseries data call
 */
const ChartEventsOverlay = ({
	className,
	eventsData,
	hoverInfoFilters,
	pageName,
	tooltipData,
}) => {
	// A transformed array of events data for rendering on the chart;
	// this is used in cases where multiple events have to be stacked on a single
	// point in time
	const [eventsRenderData, setEventsRenderData] = useState([]);

	const tooltip = { isRating: false, isFvEstimate: false, isPriceTarget: false };

	tooltip.isRating = hoverInfoFilters?.find(
		(item) => item.name === "ratings" || item.name === "msratings"
	)?.value;
	tooltip.isFvEstimate = hoverInfoFilters?.find(
		(item) => item.name === "fvEstimate"
	)?.value;
	tooltip.isPriceTarget = hoverInfoFilters?.find(
		(item) => item.name === "priceTarget"
	)?.value;

	// Update the events render data if and only if events data changes
	useEffect(() => {
		if (eventsRenderData.length && (!eventsData || !Object.keys(eventsData).length)) {
			setEventsRenderData([]);
			return;
		}

		// zipper the events together so that we can properly stack multiple events on a single point
		const xCoords = [];
		const zipped = {};

		//eslint-disable-next-line
		for (const eventType in eventsData) {
			eventsData[eventType].forEach((datapoint) => {
				// Round x coords to the nearest integer for grouping
				const x = Math.round(datapoint.x);

				if (xCoords.indexOf(x) === -1) {
					xCoords.push(x);
					zipped[x] = {
						meta: {
							coords: { x, y: datapoint.y },
							dates: [],
						},
					};
				}

				// Choose the highest y-axis position, since we are doing our
				// own stacking and this puts us closest to the line
				if (zipped[x].meta.coords.y < datapoint.y) {
					zipped[x].meta.coords.y = datapoint.y;
				}

				// Create a list of dates, if there are multiple ones on a given
				// x position
				zipped[x].meta.dates.push(datapoint.event.date);

				let eventsAtPoint = zipped[x].events;
				// Group all event types into an array
				if (!eventsAtPoint) {
					zipped[x].events = [];
					eventsAtPoint = zipped[x].events;
				}

				const eventTypeAtPoint = find(eventsAtPoint, (e) => {
					return e.type === eventType;
				});

				// Add to existing event type if it exists
				if (eventTypeAtPoint) {
					eventTypeAtPoint.events.push(datapoint.event);
					// Otherwise, create it and push the first datapoint
				} else {
					eventsAtPoint.push({
						type: eventType,
						events: [datapoint.event],
					});

					// Sort events for rendering
					zipped[x].events = sortBy(eventsAtPoint, (e) => {
						return ChartEventConstants.SORT_RANK[e.type];
					});
				}
			});
		}

		let eventsArray = [];

		forEach(zipped, (point) => {
			eventsArray.push(point);
		});

		eventsArray = sortBy(eventsArray, (point) => {
			return point.meta.coords.x;
		});

		setEventsRenderData([...eventsArray]);
	}, [eventsRenderData.length, eventsData, tooltipData]);

	const getFormattedPrice = (val, currency) => {
		return currency === "USD"
			? formatter.price(val)
			: formatter.price(val, undefined, undefined, "");
	};

	const formatRatingsPriceTargetChange = (item) => {
		let currentValue, priorValue;

		if (item.Label === "Price Target") {
			currentValue = getFormattedPrice(item.TargetPrice, item.Currency);
			priorValue = item.PriorTargetPrice
				? getFormattedPrice(item.PriorTargetPrice, item.Currency)
				: null;
		} else {
			currentValue = utils.ratingString(item.Rating);
			priorValue = item.PriorRating;
		}

		let changeTypeValue = item.ChangeType;

		if (changeTypeValue === "Upgrade") {
			changeTypeValue = "Upgraded ";
		} else if (changeTypeValue === "Downgrade") {
			changeTypeValue = "Downgraded ";
		}

		const changeTypeText = changeTypeValue
			? changeTypeValue
			: formatter.options.errorString;
		const currentValueText = currentValue ? currentValue : formatter.options.errorString;

		let result = formatter.options.errorString;
		if (changeTypeValue && currentValue) {
			result = `${changeTypeText} to ${currentValueText}`;
			if (priorValue) {
				result += ` from ${priorValue}`;
			}
		}

		return result;
	};

	const eventOverlay = (metaEvent, event, elementKey) => {
		let content = <></>;
		switch (metaEvent.type) {
			case ChartEventConstants.EVENT_TYPES.EARNINGS:
				content = (
					<>
						<div>
							{event.CurrentCurrency
								? `Actual Non-GAAP:
						${formatter.price(event.actual, 2, false, event.CurrentCurrency)}`
								: `Actual Non-GAAP:
						${options.errorString}`}
						</div>
						<div>{`Consensus Estimate: ${formatter.price(
							event.consensusEstimate,
							2,
							false,
							event.CurrentCurrency
						)}`}</div>
						<div>{`Estimate Range: ${formatter.price(
							event.estimateRangeLow,
							2,
							false,
							event.CurrentCurrency
						)}-${formatter.number(event.estimateRangeHigh, 2)}`}</div>
					</>
				);
				break;
			case ChartEventConstants.EVENT_TYPES.DIVIDENDS:
				content = (
					<>
						<div>{`Pay Date: ${formatter
							.moment(event.paymentDate)
							.format("MMM DD YYYY")}`}</div>
						<div>{`${event.typeDescription} Dividend: ${formatter.price(
							event.typeDescription === "Spin-off"
								? event.cashEquivalent
								: event.dividends,
							2,
							false,
							event.currency
						)}`}</div>
					</>
				);
				break;
			case ChartEventConstants.EVENT_TYPES.RATINGSMS:
			case ChartEventConstants.EVENT_TYPES.RATINGSMSTAR:
				let ratingsMSEvent = event;
				content = (
					<>
						<div>{`Provider: ${formatter.text(ratingsMSEvent.ProviderName)}`}</div>
						<div>{`Change: ${formatRatingsPriceTargetChange(ratingsMSEvent)}`}</div>
					</>
				);
				break;
			case ChartEventConstants.EVENT_TYPES.SPLITS:
				const splitsEvent = event[ChartEventConstants.EVENT_TYPES.SPLITS];
				content = (
					<>
						<div>{`Stock Split: ${formatter.text(splitsEvent)}`}</div>
					</>
				);
				break;
			default:
				break;
		}

		const style = {
			paddingBottom: "5px",
		};

		const tootltipStyle = {
			width: "auto",
			padding: 0,
			maxWidth: "270px",
		};

		return (
			<Tooltip
				style={tootltipStyle}
				key={`event-overlay-${elementKey}`}
				id={`event-overlay-${elementKey}`}
			>
				<div style={style} key={`event-overlay-div-${elementKey}`}>
					{metaEvent.type === ChartEventConstants.EVENT_TYPES.DIVIDENDS ? (
						<strong>
							{`Ex-Dividend: ${formatter.moment(event.date).format("MMM DD YYYY")}`}
						</strong>
					) : (
						<strong>{formatter.moment(event.date).format("MMM DD YYYY")}</strong>
					)}
				</div>
				{content}
			</Tooltip>
		);
	};

	const renderEventIcon = (point, tooltip) => {
		const VERTICAL_OFFSET = -5;
		const HORIZONTAL_OFFSET = 9;
		const style = {
			left: point.meta.coords.x - HORIZONTAL_OFFSET,
			top: point.meta.coords.y - VERTICAL_OFFSET,
			position: "absolute",
		};

		const hasMultipleEvents = (metaEvent, type = null) => {
			// check number of events nearby
			let eventsNum = 0;
			if (type) eventsNum = metaEvent.find((x) => x.type === type)?.events.length;
			else
				eventsNum = metaEvent.find(
					(x) =>
						x.type === "morningstarratingscustom" ||
						x.type === "morganstanleyratingscustom"
				)?.events.length;
			return eventsNum;
		};
		const ceilTop = Math.ceil(style.top);

		return point.events.map((metaEvent, metaEventIndex) => {
			return metaEvent.events.map((event, eventIndex) => {
				if (pageName !== "analystResearch")
					style.left = point.meta.coords.x - HORIZONTAL_OFFSET;

				if (ceilTop > 100 && ceilTop < 450) {
					// condition for chart top border
					style.top = ceilTop - 23;
				} else if (ceilTop > 450) {
					// condition for chart bottom border
					style.top = ceilTop - 35;
				} else if (ceilTop <= 100) {
					// condition for chart top most area
					style.top = ceilTop - 1;
				}

				//stop events overflowing from bottom of chart in analystResearch card
				if (pageName === "analystResearch" && style.top > 440) {
					style.top = style.top - 45;
				}

				// to place events in vertical row
				if (eventIndex > 0) style.marginTop = 22 * eventIndex;

				let type;
				let additionalStyles = [];
				const movePosition = (events, index, style) => {
					const others = hasMultipleEvents(events); // check for rating events
					if (others) {
						if (ceilTop > 100 && ceilTop < 450) style.top += index - 23;
					}
				};

				switch (metaEvent.type) {
					case ChartEventConstants.EVENT_TYPES.DIVIDENDS:
						type = "D";
						//move position only if there are other events nearby. they may overlap with others
						movePosition(point.events, eventIndex, style);
						const earningEvents = hasMultipleEvents(point.events, "earningscustom");
						if (earningEvents) {
							if (ceilTop > 100 && ceilTop < 450) {
								//with in the chart area
								// set top position below earningEvents
								style.top += eventIndex - 23 * earningEvents;
							}
						}
						if (style.top < 90) {
							// keep the event with in chart area and below earning events
							style.top = 70;
						}
						break;
					case ChartEventConstants.EVENT_TYPES.EARNINGS:
						type = "E";
						movePosition(point.events, eventIndex, style);
						if (style.top < 90) {
							// keep the event with in chart area
							style.top = 90;
						}
						break;
					case ChartEventConstants.EVENT_TYPES.SPLITS:
						type = "S";
						movePosition(point.events, eventIndex, style);
						break;
					case ChartEventConstants.EVENT_TYPES.RATINGSMS:
					case ChartEventConstants.EVENT_TYPES.RATINGSMSTAR:
						let ratingsIcon;
						switch (event.ChangeType) {
							case "Raised":
							case "Upgrade":
								ratingsIcon = <UpgradeIcon />;
								break;
							case "Lowered":
							case "Downgrade":
								/*
									Push lowered/downgrade rating events below the line chart.
									This will also pull raised/upgrade events down by the same
									amount, since they are added second.
								*/
								ratingsIcon = <DowngradeIcon />;
								break;
							default:
								ratingsIcon = <NoChangeIcon />;
						}
						type = ratingsIcon;
						const numEarningEvents =
							hasMultipleEvents(point.events, "earningscustom") || true;
						const numDividendEvents =
							hasMultipleEvents(point.events, "dividends") || true;
						const otherEvents = numEarningEvents + numDividendEvents;

						// place events below earnings and dividends
						if (style.top < 110) {
							style.top = 110 + 23 * otherEvents;
						}

						additionalStyles.push(styles.ratingsEventIcon);
						break;
					default:
						type = "";
						// eslint-disable-next-line array-callback-return
						return; // to avoid creation of overlay trigger for events like highlow
				}
				var eventType;
				if (metaEvent.type === "earningscustom") {
					eventType = metaEvent.type.replace(/custom/i, "").toLowerCase();
				} else {
					eventType = metaEvent.type;
				}

				const elementKey = `${metaEventIndex}-${eventIndex}`;
				let overlay = eventOverlay(metaEvent, event, elementKey);
				return (
					<OverlayTrigger
						rootClose={true}
						key={`event-${elementKey}`}
						trigger={utils.toolTipTrigger()}
						placement="top"
						overlay={overlay}
					>
						<div
							style={{ ...style }}
							className={`
								${styles.eventIcon}
								${additionalStyles.join(" ")}
								${className}
							`}
							data-testid={metaEvent.type}
							data-eventtype={eventType}
						>
							{type}
						</div>
					</OverlayTrigger>
				);
			});
		});
	};

	return (
		<div className={styles.eventsOverlay}>
			{eventsRenderData.map((point) => {
				return renderEventIcon(point, tooltip);
			})}
			{eventsData && Object.keys(eventsData).length > 0 && (
				<table className="sr-only">
					<caption>Event data</caption>
					<thead>
						<tr>
							<th>Date</th>
							<th>Event Type</th>
							<th>Value</th>
						</tr>
					</thead>
					<tbody>
						{Object.keys(eventsData).map((eventType, index) => {
							return eventsData[eventType].map((event, eventIndex) => {
								switch (eventType) {
									case ChartEventConstants.EVENT_TYPES.DIVIDENDS:
										return (
											<tr key={`events-data-table-${index}-${eventIndex}`}>
												<td>
													{formatter.moment(event?.event?.date).format("MMM DD YYYY")}
												</td>
												<td>Dividends</td>
												<td>
													{event?.event.dividends} {event?.event?.currency}
												</td>
											</tr>
										);
									case ChartEventConstants.EVENT_TYPES.EARNINGS:
										return (
											<tr key={`events-data-table-${index}-${eventIndex}`}>
												<td>
													{formatter.moment(event?.event?.date).format("MMM DD YYYY")}
												</td>
												<td>Earnings</td>
												<td>{event.event.actual}</td>
											</tr>
										);
									case ChartEventConstants.EVENT_TYPES.RATINGSMS:
									case ChartEventConstants.EVENT_TYPES.RATINGSMSTAR:
										return (
											<tr key={`events-data-table-${index}-${eventIndex}`}>
												<td>
													{formatter.moment(event?.event?.date).format("MMM DD YYYY")}
												</td>
												<td>{formatter.text(event.event.ProviderName + " Ratings")}</td>
												<td>{formatter.text(event.event.ChangeType)}</td>
											</tr>
										);
									case ChartEventConstants.EVENT_TYPES.SPLITS:
										return (
											<tr key={`events-data-table-${index}-${eventIndex}`}>
												<td>
													{formatter.moment(event?.event?.date).format("MMM DD YYYY")}
												</td>
												<td>Splits</td>
												<td>{event?.event?.splits}</td>
											</tr>
										);
									case ChartEventConstants.EVENT_TYPES.HIGHLOW:
										return (
											<tr key={`events-data-table-${index}-${eventIndex}`}>
												<td>
													{formatter.moment(event?.event?.date).format("MMM DD YYYY")}
												</td>
												<td>High / Low : {formatter.text(event?.event?.occurence)}</td>
												<td>{formatter.text(event?.event?.highlow)}</td>
											</tr>
										);
									default:
										return "";
								}
							});
						})}
					</tbody>
				</table>
			)}
		</div>
	);
};

export default ChartEventsOverlay;
