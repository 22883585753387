import React from "react";

const HistogramChart = () => {
	return (
		<svg
			aria-hidden="true"
			focusable="false"
			className="histogramChart"
			width="14px"
			height="13px"
			viewBox="0 0 14 13"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g
				id="Desktop---Interactive-States"
				stroke="none"
				strokeWidth="1"
				fillRule="evenodd"
			>
				<g
					id="07_MSO-Company-Overview-1440-Charting-Module-States"
					transform="translate(-989.000000, -2739.000000)"
				>
					<g id="Histogram" transform="translate(32.000000, 2438.000000)">
						<g id="Group-18" transform="translate(957.948220, 301.158100)">
							<rect
								id="Rectangle"
								x="-1.81854531e-13"
								y="8.8"
								width="2.4"
								height="3.2"
							></rect>
							<rect id="Rectangle-Copy-2" x="10" y="8.8" width="2.4" height="3.2"></rect>
							<rect id="Rectangle-Copy-10" x="7" y="4" width="2.4" height="8"></rect>
							<rect id="Rectangle-Copy-11" x="3.6" y="0" width="2.4" height="12"></rect>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default HistogramChart;
