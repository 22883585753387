import React, { Component, Suspense, lazy } from "react";
import { connect } from "react-redux";
import {
	QUOTE_MODULE_MARKETS_RESEARCH,
	MARKETS_AND_RESEARCH_PAGES,
	MARKETS_AND_RESEARCH_ROUTE_NAMES,
} from "utils/constants";
import SubNavigation from "./SubNavigation/SubNavigation";
import RedesignBanner from "components/Lib/RedesignBanner/RedesignBanner";
import MSSpinner from "components/Lib/MSSpinner/MSSpinner";
import { FINANCIAL_TABS } from "components/MarketsAndResearch/Financials/FinancialsCard/FinancialsConstants";
import "url-search-params-polyfill";
import Utils from "utils/utils";
import ActionCreators from "store/actions/actions";
import Disclosures from "components/Lib/Disclosures/Disclosures";
import actionTypes from "store/actions/actionTypes";
import Charting from "./Charting/Charting";
import Financials from "./Financials/Financials";
import XrefAPI from "services/APIs/Xref/XrefAPI";
import styles from "./MarketsAndResearch.module.scss";
import { ApiInstanceAndUserInfoContext } from "components/App";

const Quote = lazy(() => import("components/MarketsAndResearch/Quote/Quote"));
const Overview = lazy(() => import("components/MarketsAndResearch/Overview/Overview"));
const News = lazy(() => import("components/MarketsAndResearch/News/News"));
const AnalystResearch = lazy(() =>
	import("components/MarketsAndResearch/AnalystResearch/AnalystResearch")
);
const Earnings = lazy(() => import("components/MarketsAndResearch/Earnings/Earnings"));
const Dividends = lazy(() => import("components/MarketsAndResearch/Dividends/Dividends"));
const Options = lazy(() => import("components/MarketsAndResearch/Options/Options"));

class MarketsAndResearchApp extends Component {
	constructor(props) {
		super(props);
		this.state = {
			moduleName: QUOTE_MODULE_MARKETS_RESEARCH,
			symbol: null,
			viewType: null,
			venueXid: null,
			xrefName: null,
			componentRouteName: null,
			isContentReady: false,
			entityXid: null,
			cik: "",
			legacyCompany: null,
			xrefCallResolved: false,
		};
	}

	async componentDidMount() {
		const query = new URLSearchParams(this.props.location.search); // this needs to here, because location property is available in this lifecycle
		const symbol = query.get("symbol");
		const viewType = query.get("viewType") ? query.get("viewType").toLowerCase() : null; // modal, desktop, mobile

		const componentName = this.getRouteComponentName();

		this.setState({ symbol, viewType, componentRouteName: componentName });
	}
	getXrefErrorMessage = (errorDetails) => {
		if (errorDetails?.response?.data?.error?.code === 404) {
			this.props.history.replace(
				`/ms-research/markets-and-research/Stocks/QuoteError?symbol=${this.state.symbol}`
			);
		}
		this.props.dispatchXrefError();
	};
	async componentDidUpdate(prevProps, prevState) {
		const componentName = this.getRouteComponentName();

		if (
			prevProps.apiInstance?.internalAuthToken !==
				this.props.apiInstance?.internalAuthToken ||
			this.shouldFetchXref(prevProps, prevState)
		) {
			this.props.dispatchLoadedXref();
			const xrefData = await XrefAPI.getXrefBySymbol(
				this.props.apiInstance,
				this.state.symbol,
				this.props.dispatchXrefSuccess,
				this.getXrefErrorMessage
			);
			this.setState({
				xrefCallResolved: true,
			});
			if (xrefData) {
				const symbol = xrefData.symbol;
				const venueXid = xrefData.xids?.venue;
				const xrefName = xrefData.name;
				const entityXid = xrefData?.xids?.entity;
				const legacyCompany = xrefData.xids?.legacyCompany;
				const cik = xrefData.supplementalData?.find(({ name }) => name === "cik")?.value;

				this.setState({
					symbol,
					venueXid,
					xrefName,
					componentRouteName: componentName,
					entityXid,
					cik,
					contentReady: false,
					legacyCompany,
				});
				this.props.dispatchLoadDividendsData(this.props.apiInstance, venueXid);
				this.props.dispatchLoadHistoricalDividendsData(this.props.apiInstance, venueXid);
				this.props.dispatchLoadFinancialRatiosData(this.props.apiInstance, venueXid);
				this.props.dispatchLoadEarningsEstimatesCurrentData(
					this.props.apiInstance,
					venueXid
				);
				this.props.dispatchLoadEarningsEstimatesTrendsData(
					this.props.apiInstance,
					venueXid
				);
				this.props.dispatchDocumentDetailsData(
					this.props.apiInstance,
					venueXid,
					"MorningstarResearch"
				);
				this.props.dispatchDocumentDetailsData(
					this.props.apiInstance,
					venueXid,
					"MorganStanleyResearch"
				);
				this.props.dispatchDividendsGrowthRateData(this.props.apiInstance, venueXid);
			}
		}

		if (this.contentReady() && !this.state.isContentReady) {
			this.setState({ isContentReady: true });
		}
	}

	shouldFetchXref(prevProps, prevState) {
		if (!prevProps) {
			return false;
		}
		return this.state.symbol && prevState.symbol !== this.state.symbol;
	}

	getRouteComponentName() {
		let componentName = null;
		if (this.hasValidRoute()) {
			componentName = this.props.match.params.name;
		}
		return componentName;
	}

	hasValidRoute() {
		return (
			this.props.match &&
			this.props.match.params &&
			this.props.match.params.product &&
			this.props.match.params.name
		);
	}

	contentReady() {
		return (
			this.props.ratiosDataStatus &&
			this.props.ratiosDataStatus !== "FETCHING" &&
			this.props.dividendsDataStatus &&
			this.props.dividendsDataStatus !== "FETCHING" &&
			this.props.researchDataStatus &&
			this.props.researchDataStatus !== "FETCHING" &&
			this.props.researchDocumentStatus &&
			this.props.researchDocumentStatus !== "FETCHING" &&
			this.props.historicalDividendsDataStatus &&
			this.props.historicalDividendsDataStatus !== "FETCHING" &&
			this.props.earningsEstimatesCurrentDataStatus &&
			this.props.earningsEstimatesCurrentDataStatus !== "FETCHING" &&
			this.props.earningsEstimatesTrendsDataStatus &&
			this.props.earningsEstimatesTrendsDataStatus !== "FETCHING" &&
			this.props.dividendsGrowthRateDataStatus &&
			this.props.dividendsGrowthRateDataStatus !== "FETCHING"
		);
	}
	render() {
		let component;
		if (this.hasValidRoute()) {
			const componentName = this.props.match.params.name.toLowerCase();
			switch (componentName) {
				case MARKETS_AND_RESEARCH_PAGES.OVERVIEW:
					component = (
						<ApiInstanceAndUserInfoContext.Consumer>
							{({ userInfo }) => (
								<Overview
									apiInstance={this.props.apiInstance}
									contentReady={this.state.isContentReady}
									venueXid={this.state.venueXid}
									legacyCompany={this.state.legacyCompany}
									symbol={this.state.symbol}
									xrefName={this.state.xrefName}
									componentName={componentName}
									cik={this.state.cik}
									entityXid={this.state.entityXid}
									userInfo={userInfo}
								/>
							)}
						</ApiInstanceAndUserInfoContext.Consumer>
					);
					break;
				case MARKETS_AND_RESEARCH_PAGES.CHARTING:
					component = (
						<ApiInstanceAndUserInfoContext.Consumer>
							{({ userInfo, apiInstance }) => (
								<Charting
									apiInstance={apiInstance}
									userInfo={userInfo}
									contentReady={this.state.isContentReady}
									venueXid={this.state.venueXid}
									symbol={this.state.symbol}
								/>
							)}
						</ApiInstanceAndUserInfoContext.Consumer>
					);
					break;
				case MARKETS_AND_RESEARCH_PAGES.NEWS:
					component = (
						<News
							apiInstance={this.props.apiInstance}
							entityXid={this.state.entityXid}
							legacyCompany={this.state.legacyCompany}
							symbol={this.state.symbol}
						/>
					);
					break;
				case MARKETS_AND_RESEARCH_PAGES.ANALYST_RESEARCH:
					component = (
						<ApiInstanceAndUserInfoContext.Consumer>
							{({ userInfo, apiInstance }) => (
								<AnalystResearch
									apiInstance={apiInstance}
									contentReady={this.state.isContentReady}
									venueXid={this.state.venueXid}
									symbol={this.state.symbol}
									fullPage={true}
									componentName={componentName}
									userInfo={userInfo}
									legacyCompany={this.state.legacyCompany}
								/>
							)}
						</ApiInstanceAndUserInfoContext.Consumer>
					);
					break;
				case MARKETS_AND_RESEARCH_PAGES.EARNINGS:
					component = (
						<Earnings
							apiInstance={this.props.apiInstance}
							venueXid={this.state.venueXid}
							componentName={componentName}
							symbol={this.state.symbol}
							xrefName={this.state.xrefName}
						/>
					);
					break;
				case MARKETS_AND_RESEARCH_PAGES.DIVIDENDS:
					component = (
						<Dividends
							apiInstance={this.props.apiInstance}
							symbol={this.state.symbol}
							venueXid={this.state.venueXid}
						/>
					);
					break;
				case MARKETS_AND_RESEARCH_PAGES.FINANCIALS:
					component = (
						<Financials
							apiInstance={this.props.apiInstance}
							venueXid={this.state.venueXid}
							entityXid={this.state.entityXid}
							cik={this.state.cik}
							componentName={componentName}
							activeTab={this.props.match.params?.tab === "SECFilings" && "SEC Filings"}
							symbol={this.state.symbol}
						/>
					);
					break;
				case MARKETS_AND_RESEARCH_PAGES.OPTIONS:
					component = (
						<ApiInstanceAndUserInfoContext.Consumer>
							{({ userInfo, apiInstance }) => (
								<Options
									apiInstance={apiInstance}
									moduleName={this.state.moduleName}
									componentName={componentName}
									contentReady={this.state.isContentReady}
									userInfo={userInfo}
									venueXid={this.state.venueXid}
									symbol={this.state.symbol}
								/>
							)}
						</ApiInstanceAndUserInfoContext.Consumer>
					);
					break;
				default:
					return <div>Not Found!</div>;
			}
		}
		const spinnerStyle = {
			minHeight: "100px",
		};

		if (!this.state.xrefCallResolved) {
			return <div></div>;
		}

		return (
			<div id={styles.mainContainer}>
				<ApiInstanceAndUserInfoContext.Consumer>
					{({ userInfo, apiInstance }) =>
						userInfo?.redesignFlag?.toUpperCase() === "Y" && (
							<RedesignBanner
								apiInstance={apiInstance}
								ready={this.state.isContentReady}
								symbol={this.state.symbol}
								userInfo={userInfo}
							/>
						)
					}
				</ApiInstanceAndUserInfoContext.Consumer>
				<div className={styles.quoteAndNavContainer}>
					<div className={styles.quoteBarContainer}>
						<Suspense fallback={<></>}>
							<MSSpinner
								style={spinnerStyle}
								ready={this.state.isContentReady}
								spinnerSize={"2x"}
							>
								<Quote
									symbol={this.state.symbol}
									venueXid={this.state.venueXid}
									xrefName={this.state.xrefName}
								/>
							</MSSpinner>
						</Suspense>
					</div>
					<SubNavigation
						routeName={this.state.componentRouteName}
						queryClient={this.props.queryClient}
					/>
				</div>
				<section className={styles.contentContainer}>
					<Suspense fallback={<></>}>
						<MSSpinner
							style={spinnerStyle}
							ready={this.state.isContentReady}
							spinnerSize={"2x"}
						>
							{component}
							<Disclosures
								disclosures={this.props.match.params.name.toLowerCase()}
								tab={this.props.match.params?.tab}
							/>
						</MSSpinner>
					</Suspense>
				</section>
			</div>
		);
	}
}

// listen to redux state changes
const mapStateToProps = (state) => {
	//sharedReducer
	return {
		xrefDataStatus: state.optionsReducer.xrefDataStatus,
		dividendsDataStatus: state.optionsReducer.dividendsDataStatus,
		historicalDividendsDataStatus: state.optionsReducer.historicalDividendsDataStatus,
		ratiosDataStatus: state.optionsReducer.ratiosDataStatus,
		earningsEstimatesCurrentDataStatus:
			state.equitiesReducer.earningsEstimatesCurrentDataStatus,
		earningsEstimatesTrendsDataStatus:
			state.equitiesReducer.earningsEstimatesTrendsDataStatus,
		researchDataStatus: state.researchReducer.researchDataStatus,
		researchDocumentStatus: state.researchReducer.researchDocumentStatus,
		morningstarDocumentData: state.researchReducer.morningstarDocumentsData,
		msDocumentData: state.researchReducer.msDocumentsData,
		dividendsGrowthRateDataStatus:
			state.dividendGrowthRateReducer.dividendsGrowthRateDataStatus,
	};
};

// dispatch events from container to redux by  creating a mapping function
const mapDispatchToProps = (dispatch) => {
	return {
		dispatchLoadDividendsData: (apiInstance, venueXid) =>
			dispatch(ActionCreators.fetchDividendsData(apiInstance, venueXid)),
		dispatchLoadHistoricalDividendsData: (apiInstance, venueXid) =>
			dispatch(ActionCreators.fetchHistoricalDividendsData(apiInstance, venueXid)),
		dispatchLoadFinancialRatiosData: (apiInstance, venueXid) =>
			dispatch(ActionCreators.fetchFinancialRatiosData(apiInstance, venueXid)),
		dispatchLoadEarningsEstimatesCurrentData: (apiInstance, venueXid) =>
			dispatch(ActionCreators.fetchEarningsEstimatesCurrentData(apiInstance, venueXid)),
		dispatchLoadEarningsEstimatesTrendsData: (apiInstance, venueXid) =>
			dispatch(ActionCreators.fetchEarningsEstimatesTrendsData(apiInstance, venueXid)),
		dispatchLoadedXref: () =>
			dispatch({ type: actionTypes.XREF_API_TRANSITION, name: "load" }),
		dispatchXrefSuccess: () =>
			dispatch({ type: actionTypes.XREF_API_TRANSITION, name: "success" }),
		dispatchXrefError: () =>
			dispatch({ type: actionTypes.XREF_API_TRANSITION, name: "failure" }),
		dispatchUpdateRoute: (routeName) =>
			dispatch({
				type: actionTypes.UPDATE_MARKETS_RESEARCH_ROUTE_NAME,
				routeName: routeName,
			}),
		dispatchDocumentDetailsData: (apiInstance, venueXid, type) =>
			dispatch(ActionCreators.fetchDocumentDetailsData(apiInstance, venueXid, type)),
		dispatchDividendsGrowthRateData: (apiInstance, venueXid) =>
			dispatch(ActionCreators.fetchDividendsGrowthRateData(apiInstance, venueXid)),
		dispatchUserInfoSuccess: () =>
			dispatch({ type: actionTypes.USER_INFO_API_TRANSITION, name: "success" }),
		dispatchUserInfoError: () =>
			dispatch({ type: actionTypes.USER_INFO_API_TRANSITION, name: "failure" }),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MarketsAndResearchApp);
