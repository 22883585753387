import { useState, useEffect } from "react";

// This definition is not set in stone, awaiting finalization from the client.
const breakPointMaxWidths = {
	mobile: 575,
	"sm-tablet": 767,
	"lg-tablet": 1023,
	desktop: undefined,
};

function checkMediaMatch(width) {
	return window.matchMedia(`(max-width: ${width}px)`).matches;
}
function getBreakPoint() {
	const [breakPoint] = Object.entries(breakPointMaxWidths).find(
		([, width]) => !width || checkMediaMatch(width)
	);
	return breakPoint;
}

function useBreakPoint() {
	const [breakPoint, setBreakPoint] = useState(getBreakPoint());
	useEffect(() => {
		function resizeHandler() {
			setBreakPoint(getBreakPoint());
		}
		window.addEventListener("resize", resizeHandler);
		return () => window.removeEventListener("resize", resizeHandler);
	}, []);
	return breakPoint;
}

export default useBreakPoint;
