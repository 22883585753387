import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";

import styles from "./MSSpinner.module.scss";

const MSSpinner = React.memo((props) => {
	return (
		<div className={styles.MSSpinnerContainer} style={props.spinnerStyle}>
			<div role="status" className={classNames("spinnerContent", { hide: props.ready })}>
				<FontAwesomeIcon icon={faSpinner} pulse size={props.spinnerSize} />
				{props.msg ? <p> {props.msg}</p> : null}
			</div>
			<div className={classNames("childContent", { show: props.ready })}>
				{props.ready ? props.children : null}
			</div>
		</div>
	);
});

export default MSSpinner;
