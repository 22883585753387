import React from "react";
import classNames from "classnames/bind";
import styles from "./HeaderValue.module.scss";
import Utils from "utils/utils";
import LearnMoreTooltip from "../LearnMoreTooltip/LearnMoreTooltip";

const HeaderValue = React.memo(({ data = {} }) => {
	let classVal = "";
	if (data.setColor !== "") {
		if (data.setColor === "success") {
			classVal = styles.greenColor;
		} else if (data.setColor === "alert") {
			classVal = styles.redColor;
		}
	}

	const boldVal = data.setBold ? styles.bold : "";

	const headerValue = !data.hideHeader ? data.header : null;

	const headerClassName = {
		headerSmall: data.headerClass === "small",
	};

	const bodyClassName = {
		bodyLarge: data.bodyClass === "large",
	};

	const footerClassName = {
		valueChange: data.footerClass === "valueChange",
	};

	let footerClassCustom;
	if (data.footerClass === "valueChange") {
		if (data.footerSetColor === "success") {
			footerClassCustom = styles.greenColor;
		} else if (data.footerSetColor === "alert") {
			footerClassCustom = styles.redColor;
		}
	}

	return (
		<div id={data.id} className="headerValueItem">
			{headerValue ? (
				<div
					className={classNames(styles.header, headerClassName)}
					aria-hidden={data.hideHeader}
				>
					{headerValue}
					{data.icon?.overlay === "drawer" ? (
						<LearnMoreTooltip />
					) : (
						data.icon && Utils.buildTooltip(data.helpTextId, data.helpText, "button")
					)}
				</div>
			) : null}

			<div className={classNames(styles.bodyClassName, classVal, boldVal, bodyClassName)}>
				<span className="sr-only" aria-hidden={!data.hideHeader}>
					{data.header}
				</span>
				<span className={classNames("content", classVal)}> {data.value || ""}</span>
			</div>
			{data.footerValue && (
				<div className={classNames(styles.footer, footerClassName, footerClassCustom)}>
					<span>{data.footerValue}</span>
				</div>
			)}
		</div>
	);
});

export default HeaderValue;
