import React, { useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import CirclePlus from "components/Lib/Icons/CirclePlus/CirclePlus";
import COMPARISON_CONSTANTS from "../Comparisons.js";
import IndicatorMenu from "../Indicators/IndicatorMenu/IndicatorMenu";
import styles from "../ChartingCard/ChartingCard.module.scss";
import ComparisonQuoteSearch from "./ComparisonQuoteSearch/ComparisonQuoteSearch";
import FEATURE_FLAGS from "utils/featureFlags";
import AdvanceChartConstant from "components/MarketsAndResearch/ChartingCommon/Constants/Chart/AdvanceChart";
import { removeFocusfromMenu } from "components/MarketsAndResearch/ChartingCommon/Utility/CommonChartUtility";
import utils from "utils/utils";

const Indicators = React.memo((props) => {
	return <IndicatorMenu {...props} />;
});

const Comparisons = (props) => {
	const [comparisonsView, setComparisonsView] = useState("INDEXES");
	const [isShown, setIsShown] = useState(false);
	const compSearchInput = useRef(null);
	const dropdownref = useRef();

	const handleComparisonsView = () => {
		let newView = comparisonsView === "INDEXES" ? "PEERS" : "INDEXES";
		setComparisonsView(newView);
	};

	const renderExchanges = () => {
		return COMPARISON_CONSTANTS.EXCHANGES.map((comparison, index) => (
			<Dropdown.Item
				className={styles.compareItem}
				key={`${comparison.label}-${index}`}
				data-index={index}
				onClick={props.onExchangeClick}
			>
				<CirclePlus />
				<div className={styles.exchangeLabel}>{comparison.label}</div>
			</Dropdown.Item>
		));
	};

	const renderPeers = () => {
		const hasPeers = props.peersData && props.peersData.length;
		return hasPeers ? (
			props.peersData.map((peer, index) => (
				<Dropdown.Item
					className={`${styles.compareItem} ${styles.nonSelectableItem}`}
					key={`${peer.name}-${index}`}
					data-index={index}
					onClick={props.onPeerClick}
				>
					<div className={styles.peerContainer}>
						<div className={styles.peerSymbol}>{peer.symbol}</div>
						{utils.buildTooltip(
							"peerComparison-companyName",
							peer.companyName,
							"text",
							peer.companyName
						)}
					</div>
				</Dropdown.Item>
			))
		) : (
			<span className={styles.noPeers}>Peers were not found</span>
		);
	};

	const onToggleCallback = (isOpen, e, metadata) => {
		// Do not close the dropdown when a non-selectable item is selected
		// (i.e. symbol search, UI button)
		// using 6 as threshold as pricechart indicator is added by default
		if (
			props.updateError.indicatorsCount >= AdvanceChartConstant.INDICATOR_LIMITS.UPPER &&
			props.updateError.errorVisible
		) {
			setIsShown(props.updateError.errorVisible);
			if (!props.errorMSG) {
				if (metadata?.source === "rootClose") {
					setIsShown(false);
					props.resetErrorMSG();
				}
				return;
			}
		}
		onCheckError(isOpen, e, metadata);

		if (!isOpen && compSearchInput && compSearchInput.current) {
			compSearchInput.current.value = "";
		}
		if (e && e.detail === 1 && metadata?.source === "select") {
			removeFocusfromMenu(dropdownref.current);
		}
	};

	const onCheckError = (isOpen, e, metadata) => {
		if (metadata?.source === "rootClose") {
			setIsShown(false);
			props.resetErrorMSG();
		} else if (
			metadata?.source !== "select" ||
			!e?.currentTarget?.classList?.contains("nonselectable")
		) {
			setIsShown(isOpen);
			if (!isOpen && props.updateError.errorVisible) setIsShown(true);
		}
	};
	const onSelectCallback = (eventKey, event) => {
		// If a button group is selected, click on the active button
		if (event?.currentTarget?.classList?.contains("buttongroup")) {
			event.currentTarget.querySelector("button:not([disabled])").click();
			// If a search box is selected, focus into it so the user can start searching
		} else if (event?.currentTarget?.classList?.contains("search")) {
			event.currentTarget.querySelector("input").focus();
		}
	};

	return (
		<Dropdown
			id={"compare-dropdown"}
			className={styles.compareDropdown}
			show={isShown}
			ref={dropdownref}
			onToggle={onToggleCallback}
			onSelect={onSelectCallback}
		>
			<Dropdown.Toggle id="compareDropdown" className={styles.dropdownToggle}>
				<CirclePlus /> Compare
			</Dropdown.Toggle>
			<Dropdown.Menu className={styles.nonSelectableItem}>
				<p id="compareSearchDescription" className="sr-only">
					{
						"Select this item to search for symbols to compare.  Use the up and down arrows to select a symbol from the suggested results, or to move to a different menu item."
					}
				</p>
				<ComparisonQuoteSearch
					apiInstance={props.apiInstance}
					compSearchInput={compSearchInput}
					onQuoteClick={props.onQuoteClick}
					closeDropdown={onToggleCallback}
				/>
				<Dropdown.ItemText
					className={`${styles.comparisonsView} ${styles.nonSelectPeerIndex} nonselectable buttongroup`}
					aria-describedby={"compareButtonGroupDescription"}
				>
					<p id="compareButtonGroupDescription" className="sr-only">
						{
							"Select this item to toggle what types of comparison symbols appear below.  Switches between Indexes and Peers, where Indexes is the default view."
						}
					</p>
					<button
						disabled={comparisonsView === "INDEXES"}
						onClick={handleComparisonsView}
						className={`btn btn-link ${comparisonsView === "INDEXES" ? "disabled" : ""}`}
					>
						Indexes
					</button>{" "}
					|
					<button
						disabled={comparisonsView === "PEERS"}
						onClick={handleComparisonsView}
						className={`btn btn-link ${comparisonsView === "PEERS" ? "disabled" : ""}`}
					>
						Peers
					</button>
				</Dropdown.ItemText>
				{comparisonsView === "INDEXES" ? renderExchanges() : renderPeers()}
			</Dropdown.Menu>
		</Dropdown>
	);
};

const Events = (props) => {
	return (
		<Dropdown className={styles.eventsDropdown}>
			<Dropdown.Toggle id="eventsDropdown" className={styles.dropdownToggle}>
				<CirclePlus /> Events
			</Dropdown.Toggle>
			<Dropdown.Menu className={styles.eventDropdown}>
				<div>
					<span className={styles.dropdown_header}>Select Events</span>
					<Dropdown.Item className={styles.clear_all} onSelect={props.clearEvents}>
						Clear All
					</Dropdown.Item>
				</div>

				<hr />
				<Dropdown.Item eventKey="dividends" onSelect={props.onSelect}>
					<div className="dropdown_item">
						<input
							type="checkbox"
							onChange={props.onSelect}
							checked={props.selectedEvents["dividends"]}
						/>
						<span className="checkmark"></span>
						<span className="dropdown_text">Dividends</span>
					</div>
				</Dropdown.Item>
				<Dropdown.Item eventKey="earnings" onSelect={props.onSelect}>
					<div className="dropdown_item">
						<input
							type="checkbox"
							onChange={props.onSelect}
							checked={props.selectedEvents["earnings"]}
						/>
						<span className="checkmark"></span>
						<span className="dropdown_text">Earnings</span>
					</div>
				</Dropdown.Item>
				<Dropdown.Item eventKey="highlow" onSelect={props.onSelect}>
					<div className="dropdown_item">
						<input
							type="checkbox"
							onChange={props.onSelect}
							checked={props.selectedEvents["highlow"]}
						/>
						<span className="checkmark"></span>
						<span className="dropdown_text">High / Low</span>
					</div>
				</Dropdown.Item>
				{
					<>
						<Dropdown.Item
							eventKey="morganstanleyratingscustom"
							onSelect={props.onSelect}
						>
							<div className="dropdown_item">
								<input
									type="checkbox"
									onChange={props.onSelect}
									checked={props.selectedEvents["morganstanleyratingscustom"]}
								/>
								<span className="checkmark"></span>
								<span className="dropdown_text">Morgan Stanley Rating</span>
							</div>
						</Dropdown.Item>
						<Dropdown.Item eventKey="morningstarratingscustom" onSelect={props.onSelect}>
							<div className="dropdown_item">
								<input
									type="checkbox"
									onChange={props.onSelect}
									checked={props.selectedEvents["morningstarratingscustom"]}
								/>
								<span className="checkmark"></span>
								<span className="dropdown_text">Morningstar Rating</span>
							</div>
						</Dropdown.Item>
					</>
				}
				<Dropdown.Item eventKey="splits" onSelect={props.onSelect}>
					<div className="dropdown_item">
						<input
							type="checkbox"
							onChange={props.onSelect}
							checked={props.selectedEvents["splits"]}
						/>
						<span className="checkmark"></span>
						<span className="dropdown_text">Splits</span>
					</div>
				</Dropdown.Item>
			</Dropdown.Menu>
		</Dropdown>
	);
};

export default {
	Indicators,
	Comparisons,
	Events,
};
