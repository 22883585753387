import actionTypes from "./actionTypes";
import { URLS } from "../../utils/constants";

//action creator
const storeDividendsRatioResults = (results) => {
	return {
		type: actionTypes.SAVE_DIVIDENDS_GROWTH_RATE_DATA,
		dividendsGrowthRateData: results,
	};
};

const fetchDividendsGrowthRate = (apiInstance, venueXid) => {
	//async here - dispatch here will get set by redux-thunk
	return async (dispatch, getState) => {
		if (!apiInstance || !venueXid) {
			return;
		}
		dispatch({ type: actionTypes.DIVIDENDS_GROWTH_RATE_API_TRANSITION, name: "load" });
		try {
			const response = await apiInstance.get(
				`${URLS.FINANCIAL_RATIOS_DETAILS_PATH}/${venueXid}`,
				{
					params: {
						includeYearEndData: true,
					},
				}
			);
			const data = response.data.data || null;
			if (data) {
				data.venueXid = venueXid;
				dispatch({
					type: actionTypes.DIVIDENDS_GROWTH_RATE_API_TRANSITION,
					name: "success",
				});
				dispatch(storeDividendsRatioResults(data));
			}
		} catch (error) {
			dispatch({
				type: actionTypes.DIVIDENDS_GROWTH_RATE_API_TRANSITION,
				name: "failure",
			});
		}
	};
};

export default { fetchDividendsGrowthRate };
