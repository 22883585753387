/*
	When constructing indicator objects, value must be the indicator's modcharts id:
	https://confluence.ihsmarkit.com/display/MOD/MODCharts+Indicators+Reference
*/
const PHASE1_INDICATORS = [
	"wma",
	"volume",
	"sma",
	"mae",
	"bollinger",
	"dividendyield",
	"onbalancevolume",
	"peratio",
	"revenues",
	"rollingeps",
	"macd",
	"momentum",
	"proc",
	"rsi",
	"vroc",
];

const NO_CLIP_INDICATOR = [
	"highlow",
	"volumebyprice",
	"previousclose",
	"linearregression",
];

const PRICE = {
	column: ["close", "diff", "high", "hollow", "low", "open"],
};
const BOLLINGER_BANDS = {
	label: "Bollinger Bands",
	value: "bollinger",
	panel: "upper",
	column: ["bottom", "sma", "top"],
	config: {
		//(20, 2) need to be dynamic once user can modify inputs
		name: `Bollinger Bands${"\u00AE".sup()}`,
		inputs: [
			{ name: "period", value: 20 },
			{ name: "standardDeviations", value: 2 },
		],
		style: {
			fillColor: "rgba(176,195,12,0.2)",
			color: "rgb(176,195,12)",
			colorSMA: "#F69323",
			sma: {
				color: "#F69323",
				width: 2,
			},
			width: 2,
		},
	},
	displayCardColumns: [
		{
			name: "top",
			styleMarker: "color",
		},
		{
			name: "sma",
			styleMarker: "sma",
		},
		{
			name: "bottom",
			styleMarker: "color",
		},
	],
	isProductionReady: true,
};
const EMA = {
	label: "Exponential Moving Average (EMA)",
	value: "ema",
	panel: "upper",
	column: ["ema"],
	config: {
		//(20) need to be dynamic once user can modify inputs
		name: "EMA (20)",
		inputs: [{ name: "period", value: 20 }],
		style: {
			color: "orange",
			width: 2,
		},
	},
	isProductionReady: false,
};
const HIGH_LOW_PRICE = {
	label: "High/Low Price",
	value: "highlow",
	panel: "upper",
	config: {
		name: "High / Low",
		inputs: [{ name: "extentType", value: "ohlc" }],
		style: {
			highColor: "#006600",
			lowColor: "#AA0000",
			labelColor: "#000000",
		},
	},
	// TODO: Events Dropdown Doesn't have a check so These can be removed when only events and not indicators dropdown.
	isProductionReady: false,
};
const ICHIMOKU_CLOUD = {
	label: "Ichimoku Cloud",
	value: "ichimokucloud",
	panel: "upper",
	config: {
		name: "Ichimoku Cloud",
	},
	isProductionReady: false,
};
const LINEAR_REGRESSION = {
	label: "Linear Regression",
	value: "linearregression",
	panel: "upper",
	column: ["linearregression"],
	config: {
		name: "Linear Regression",
		style: {
			color: "brown",
			width: 2,
		},
	},
	isProductionReady: false,
};
const MAE = {
	label: "Moving Average Envelope (MAE)",
	value: "mae",
	panel: "upper",
	column: ["maehigh", "maelow"],
	config: {
		name: "MAE",
		inputs: [
			{ name: "period", value: 20 },
			{ name: "lowfactor", value: 0.94 },
			{ name: "highfactor", value: 1.06 },
			{ name: "averagetype", value: "sma" },
		],
		style: {
			color: "rgb(122, 29, 215)",
			width: 2,
			fillColor: "rgba(122, 29, 215, 0.25)",
		},
	},
	displayCardColumns: [
		{
			name: "maehigh",
		},
		{
			name: "maelow",
		},
	],
	isProductionReady: true,
};
const PREVIOUS_CLOSE = {
	label: "Previous Close",
	value: "previousclose",
	panel: "upper",
	config: {
		name: "Previous Close",
		inputs: [{ name: "Version", value: 1 }],
		numDaysLabel: 1, // <int> number of days to label (-1 = all days, 0 = none)
		numDaysLine: 1, // <int> number of days to render line (-1 = all days, 0 = none)
		labelAlignment: "nw", // ne, nw, sw, se
		showFlag: false,
		noClip: true,
		style: {
			color: "black",
			label: {
				fontSize: 12,
				fontWeight: 900,
				glowColor: "white",
			},
			lineWidth: 2,
			linePenPxOn: 5,
			linePenPxOff: 3,
		},
	},
	isProductionReady: true,
};
const PRICE_CHANNEL = {
	label: "Price Channel",
	value: "pricechannel",
	panel: "upper",
	column: ["pricechannellower", "pricechannelupper"],
	config: {
		name: "Price Channel",
		inputs: [{ name: "period", value: 25 }],
		style: {
			color: "rgb(52, 150, 248)",
			width: 2,
			colorDown: "rgb(6, 112, 217)",
			fillColor: "rgba(52, 150, 248, 0.25)",
		},
	},
	isProductionReady: false,
};
const PSAR = {
	label: "Parabolic Stop and Reverse (PSAR)",
	value: "psar",
	panel: "upper",
	column: ["psar"],
	config: {
		name: "PSAR",
		inputs: [
			{ name: "initialPosition", value: 0 },
			{ name: "accelerationIncrease", value: 0.02 },
			{ name: "accelerationMaximum", value: 0.02 },
		],
		style: {
			color: "rgb(242, 73, 242)",
			radiusWidth: 3,
			length: 5,
		},
	},
	isProductionReady: false,
};
const SMA = {
	label: "Simple Moving Average (SMA)",
	value: "sma",
	panel: "upper",
	column: ["sma"],
	config: {
		//(20) need to be dynamic once user can modify inputs
		name: "SMA",
		inputs: [{ name: "period", value: 9 }],
		style: {
			color: "rgb(255,105,180)",
			width: 2,
		},
	},
	displayCardColumns: [
		{
			name: "sma",
		},
	],
	isProductionReady: true,
};
const TSF = {
	label: "Time Series Forecast (TSF)",
	value: "tsf",
	panel: "upper",
	column: ["tsf"],
	config: {
		//(20) need to be dynamic once user can modify inputs
		name: "TSF (20)",
		inputs: [{ name: "period", value: 20 }],
		style: {
			color: "rgb(9, 123, 9)",
			width: 2,
		},
	},
	isProductionReady: false,
};
const VOLUME_BY_PRICE = {
	label: "Volume by Price",
	value: "volumebyprice",
	panel: "upper",
	config: {
		name: "Volume by Price",
		inputs: [
			{ name: "numBars", value: 50 },
			{ name: "gapSize", value: 5 },
			{ name: "barWidth", value: 1.0 },
		],
		style: {
			downColor: "rgba(255, 0, 0, 0.6)",
			upColor: "rgba(0, 128, 0, 0.6)",
		},
	},
	isProductionReady: false,
};
const WMA = {
	label: "Weighted Moving Average (WMA)",
	value: "wma",
	panel: "upper",
	column: ["wma"],
	config: {
		name: "WMA",
		inputs: [{ name: "period", value: 9 }],
		style: {
			color: "rgb(107, 243, 243)",
			width: 2,
		},
	},
	displayCardColumns: [
		{
			name: "wma",
			styleMarker: "line",
		},
	],
	isProductionReady: true,
};
const ADL = {
	label: "Accumulation/Distribution (ADL)",
	value: "adl",
	panel: "lower",
	column: ["adl"],
	config: {
		name: "ADL",
		style: {
			color: "rgb(38, 38, 221)",
			width: 2,
		},
	},
	isProductionReady: false,
};
const AVERAGE_TRUE_RANGE = {
	label: "Average True Range",
	value: "avgtruerange",
	panel: "lower",
	column: ["avgtruerange"],
	config: {
		//(20) need to be dynamic once user can modify inputs
		name: "Average True Range (20)",
		inputs: [{ name: "period", value: 20 }],
		style: {
			color: "rgb(229, 25, 25)",
			width: 2,
		},
	},
	isProductionReady: false,
};
const AWESOME_OSCILLATOR = {
	label: "Awesome Oscillator",
	value: "awesomeoscillator",
	panel: "lower",
	column: ["awesomeoscillator"],
	config: {
		//(10, 34) need to be dynamic once user can modify inputs
		name: "Awesome Oscillator (10, 34)",
		inputs: [
			{ name: "period1", value: 10 },
			{ name: "period2", value: 34 },
		],
		style: {
			fillColorPos: "green",
			fillColorNeg: "red",
		},
	},
	isProductionReady: false,
};
const BOLLINGER_BAND_B = {
	label: "Bollinger Band %B",
	value: "percentb",
	panel: "lower",
	column: ["percentb"],
	config: {
		//(20, 3) need to be dynamic once user can modify inputs
		name: `Bollinger Band${"\u00AE".sup()} %B (20, 3)`,
		inputs: [
			{ name: "period", value: 25 },
			{ name: "standardDeviations", value: 3 },
		],
		style: {
			color: "rgb(34, 140, 245)",
			width: 2,
			overbought: {
				label: {
					font: {
						color: "black",
					},
				},
			},
			oversold: {
				label: {
					font: {
						color: "black",
					},
				},
			},
		},
		bands: [
			{
				style: {
					lineColor: "green",
					lineWidth: 3,
				},
			},
			{
				style: {
					lineColor: "green",
					lineWidth: 3,
				},
			},
		],
	},
	isProductionReady: false,
};
const BOLLINGER_BANDS_WIDTH = {
	label: "Bollinger Bands Width",
	value: "bollingerbandwidth",
	panel: "lower",
	column: ["bandwidth"],
	config: {
		//(20, 3) need to be dynamic once user can modify inputs
		name: `Bollinger Bands${"\u00AE".sup()} Width (20, 3)`,
		inputs: [
			{ name: "period", value: 20 },
			{ name: "standardDeviations", value: 3 },
		],
		style: {
			color: "rgb(231, 124, 18)",
			width: 2,
		},
	},
	isProductionReady: false,
};
const CHAIKINS_VOLATILITY = {
	label: "Chaikin's Volatility",
	value: "chaikins",
	panel: "lower",
	column: ["chaikinsvolatility"],
	config: {
		//(15, 15) need to be dynamic once user can modify inputs
		name: "Chaikin's Volatility (15, 15)",
		inputs: [
			{ name: "EMAPeriod", value: 15 },
			{ name: "DifferencePeriod", value: 15 },
		],
		style: {
			color: "rgb(157, 20, 157)",
			width: 2,
		},
	},
	isProductionReady: false,
};
const DIVIDEND_YIELD = {
	label: "Dividend Yield",
	value: "dividendyield",
	panel: "lower",
	column: ["dividendyield"],
	config: {
		name: "Dividend Yield",
		style: {
			color: "rgb(169, 91, 13)",
			width: 2,
		},
	},
	displayCardColumns: [
		{
			name: "dividendyield",
		},
	],
	isProductionReady: true,
};
const DMI = {
	label: "Directional Movement Index (DMI)",
	value: "dmi",
	panel: "lower",
	column: ["minusdi", "plusdi"],
	config: {
		//(20) need to be dynamic once user can modify inputs
		name: "DMI (20)",
		inputs: [{ name: "period", value: 20 }],
		style: {
			colorDINeg: "red",
			colorDIPos: "green",
			colorADX: "blue",
			widthADX: 2,
			adx: {
				color: "blue",
				width: 2,
			},
			negative: {
				color: "red",
				width: 2,
			},
			positive: {
				color: "green",
				width: 2,
			},
		},
	},
	isProductionReady: false,
};
const FAST_STOCHASTICS = {
	label: "Fast Stochastics",
	value: "stochastics",
	panel: "lower",
	column: ["stochasticd", "stochastick"],
	config: {
		//(14, 3) need to be dynamic once user can modify inputs
		name: "Fast Stochastics (14, 3)",
		inputs: [
			{ name: "kperiod", value: 14 },
			{ name: "dperiod", value: 3 },
			{ name: "smoothing", value: 1 },
		],
		style: {
			color: "rgb(255,105,180)",
			width: 2,
			d: {
				color: "green",
				width: 2,
			},
		},
		bands: [
			{
				//labelText: "SELL SELL SELL",
				style: {
					fillColor: "red",
					color: "grey",
				},
			},
			{
				//labelText: "BUY BUY BUY",
				style: {
					fillColor: "green",
					color: "grey",
				},
			},
		],
	},
	isProductionReady: false,
};
// Commenting out until we figure out how to include this indicator and the feature is prioritized:
// Rolling Dividend is an option in modcharts, is this what we should use?
// const HISTORICAL_DIVIDEND = {
// 	label: 'Historical Dividend',
// 	value: '',
// 	panel: 'lower',
// 	config: {}
// };
const MACD = {
	label: "Moving Average Convergence Divergence (MACD)",
	value: "macd",
	panel: "lower",
	column: ["macd", "divergence", "ema"],
	config: {
		//(12, 26, 10) need to be dynamic once user can modify inputs
		name: "MACD",
		inputs: [
			{ name: "fastperiod", value: 12 },
			{ name: "slowperiod", value: 26 },
			{ name: "smoothing", value: 10 },
		],
		style: {
			color: "rgb(20, 96, 173)",
			width: 2,
			lineColorEMA: "orange",
			fillColorPos: "#006600",
			fillColorNeg: "#AA0000",
			ema: {
				color: "orange",
				width: 2,
			},
			negative: {
				color: "#AA0000",
			},
			positive: {
				color: "#006600",
			},
		},
	},
	displayCardColumns: [
		{
			name: "macd",
			styleMarker: "color",
		},
		{
			name: "ema",
			styleMarker: "ema",
		},
		{
			name: "divergence",
		},
	],
	isProductionReady: true,
};
const MASS_INDEX = {
	label: "Mass Index",
	value: "massindex",
	panel: "lower",
	column: ["massindex"],
	config: {
		//(25, 26, 24) need to be dynamic once user can modify inputs
		name: "Mass Index (25, 26, 24)",
		inputs: [{ name: "period", value: 25 }],
		style: {
			color: "rgb(242, 50, 2)",
			width: 2,
			setup: {
				color: "rgb(200, 96, 244)",
				width: 2,
			},
			trigger: {
				color: "rgb(27, 199, 243)",
				width: 2,
			},
		},
		extraInputs: [
			{ name: "setup", value: 26 },
			{ name: "trigger", value: 24 },
		],
	},
	isProductionReady: false,
};
const MOMENTUM = {
	label: "Momentum",
	value: "momentum",
	panel: "lower",
	column: ["momentum"],
	config: {
		//(20) need to be dynamic once user can modify inputs
		name: "Momentum",
		inputs: [{ name: "period", value: 20 }],
		style: {
			color: "rgb(1,62,116)",
			width: 2,
			fillColor: "rgb(1,62,116)",
		},
	},
	displayCardColumns: [
		{
			name: "momentum",
		},
	],
	isProductionReady: true,
};
const MONEY_FLOW = {
	label: "Money Flow",
	value: "moneyflow",
	panel: "lower",
	column: ["moneyflow"],
	config: {
		name: "Money Flow",
		style: {
			color: "green",
			width: 2,
		},
	},
	isProductionReady: false,
};
const MONEY_FLOW_INDEX = {
	label: "Money Flow Index",
	value: "moneyflowindex",
	panel: "lower",
	column: ["moneyflowindex"],
	config: {
		name: "Money Flow Index (15)",
		inputs: [{ name: "period", value: 15 }],
		style: {
			color: "green",
			width: 2,
		},
	},
	isProductionReady: false,
};
const ON_BALANCE_VOLUME = {
	label: "On Balance Volume (OBV)",
	value: "onbalancevolume",
	panel: "lower",
	column: ["onbalancevolume"],
	config: {
		name: "OBV",
		style: {
			color: "orange",
			width: 2,
		},
	},
	displayCardColumns: [
		{
			name: "onbalancevolume",
		},
	],
	isProductionReady: true,
};
const PE_Ratio = {
	label: "P/E Ratio",
	value: "peratio",
	panel: "lower",
	column: ["peratio"],
	config: {
		name: "P/E Ratio",
		style: {
			color: "rgb(169, 91, 13)",
			width: 2,
		},
	},
	displayCardColumns: [
		{
			name: "peratio",
		},
	],
	isProductionReady: true,
};
const PROC = {
	label: "Price Rate of Change (PROC)",
	value: "proc",
	panel: "lower",
	column: ["proc"],
	config: {
		//(25) need to be dynamic once user can modify inputs
		name: "PROC",
		inputs: [{ name: "period", value: 25 }],
		style: {
			color: "blue",
			width: 2,
			zero: {
				color: "black",
				width: 2,
			},
		},
	},
	displayCardColumns: [
		{
			name: "proc",
		},
	],
	isProductionReady: true,
};

const Revenues = {
	label: "Revenues",
	value: "revenues",
	panel: "lower",
	column: ["revenues"],
	config: {
		name: "Revenues",
		style: {
			color: "rgb(169, 91, 13)",
			width: 2,
		},
	},
	displayCardColumns: [
		{
			name: "revenues",
		},
	],
	isProductionReady: true,
};

const Rolling_EPS = {
	label: "Rolling EPS",
	value: "rollingeps",
	panel: "lower",
	column: ["rollingeps"],
	config: {
		name: "Rolling EPS",
		style: {
			lineColor: "rgb(3,46,83)",
			lineWidth: 3,
		},
	},
	displayCardColumns: [
		{
			name: "rollingeps",
		},
	],
	isProductionReady: true,
};
const RSI = {
	label: "Relative Strength Index (RSI)",
	value: "rsi",
	panel: "lower",
	column: ["rsi"],
	config: {
		//(14) need to be dynamic once user can modify inputs
		name: "RSI",
		inputs: [{ name: "period", value: 14 }],
		style: {
			color: "rgb(21, 80, 218)",
			width: 2,
			overbought: {
				label: {
					font: {
						color: "black",
					},
				},
			},
			oversold: {
				label: {
					font: {
						color: "black",
					},
				},
			},
		},
		overbought: {
			color: {
				background: "red",
			},
		},
		oversold: {
			color: {
				background: "green",
			},
		},
	},
	displayCardColumns: [
		{
			name: "rsi",
		},
	],
	isProductionReady: true,
};
// Comment out until the below issue until adding this indicator is prioritized and the below issue is resolved.
// Uses same value (modcharts id) as FAST_STOCHASTICS, so need a better way to create DICTIONARY
// const SLOW_STOCHASTICS = {
// 	label: 'Slow Stochastics',
// 	value: 'stochastics',
// 	panel: 'lower',
// 	config: {
// 		//(14, 3) need to be dynamic once user can modify inputs
// 		name: 'Slow Stochastics (14, 3)',
// 		inputs: [
// 			{name: "kperiod", value: 14},
// 			{name: "dperiod", value: 3},
// 			{name: "smoothing", value: 3}
// 		],
// 		style: {
// 			lineColor: "rgb(255,105,180)",
// 			lineWidth: 2,
// 			lineColorD: "green"
// 		},
// 		bands:[
// 			{
// 				//labelText: "SELL SELL SELL",
// 				style: {
// 					fillColor: "red",
// 					lineColor: "grey"
// 				}
// 			},{
// 				//labelText: "BUY BUY BUY",
// 				style: {
// 					fillColor: "green",
// 					lineColor: "grey"
// 				}
// 			}
// 		]
// 	}
// };
const ULTIMATE_OSCILLATOR = {
	label: "Ultimate Oscillator",
	value: "ultimateoscillator",
	panel: "lower",
	column: ["ultimateoscillator"],
	config: {
		//(7, 14, 28, 4, 2, 1) need to be dynamic once user can modify inputs
		name: "Ultimate Oscillator (7, 14, 28, 4, 2, 1)",
		inputs: [
			{ name: "period1", value: 7 },
			{ name: "period2", value: 14 },
			{ name: "period3", value: 28 },
			{ name: "factor1", value: 4 },
			{ name: "factor2", value: 2 },
			{ name: "factor3", value: 1 },
		],
		style: {
			color: "blue",
			width: 2,
			overbought: {
				label: {
					font: {
						color: "black",
					},
				},
			},
			oversold: {
				label: {
					font: {
						color: "black",
					},
				},
			},
		},
		bands: [
			{
				labelText: "SELL SELL SELL",
				style: {
					fillColor: "red",
					color: "grey",
				},
			},
			{
				labelText: "BUY BUY BUY",
				style: {
					fillColor: "green",
					color: "grey",
				},
			},
		],
	},
	isProductionReady: false,
};
const VOLUME0 = {
	label: "Volume",
	value: "volume",
	panel: "upper",
	column: ["volume"],
	config: {
		id: "volume",
		name: "Volume",
		markerType: "posneg",
		style: {
			color: "grey",
			negative: {
				color: "#AA0000",
			},
			neutral: {
				color: "grey",
			},
			positive: {
				color: "#006600",
			},
		},
	},
	displayCardColumns: [
		{
			name: "volume",
		},
	],
	isProductionReady: true,
};
const VOLUME = {
	label: "Volume",
	value: "volume",
	panel: "lower",
	column: ["volume"],
	config: {
		name: "Volume",
		markerType: "posneg",
		style: {
			color: "grey",
			negative: {
				color: "#AA0000",
			},
			neutral: {
				color: "grey",
			},
			positive: {
				color: "#006600",
			},
		},
	},
	displayCardColumns: [
		{
			name: "volume",
		},
	],
	isProductionReady: true,
};
const VROC = {
	label: "Volume Rate of Change (VROC)",
	value: "vroc",
	panel: "lower",
	column: ["vroc"],
	config: {
		//(10) need to be dynamic once user can modify inputs
		name: "VROC",
		inputs: [{ name: "period", value: 10 }],
		style: {
			color: "blue",
			width: 2,
			zero: {
				color: "black",
				width: 2,
			},
		},
	},
	displayCardColumns: [
		{
			name: "vroc",
		},
	],
	isProductionReady: true,
};
const WILLIAMS_R = {
	label: "Williams %R",
	value: "williamspctr",
	panel: "lower",
	column: ["williamspctr"],
	config: {
		//(10) need to be dynamic once user can modify inputs
		name: "Williams %R",
		inputs: [{ name: "period", value: 10 }],
		style: {
			color: "blue",
			width: 2,
			overbought: {
				label: {
					font: {
						color: "black",
					},
				},
			},
			oversold: {
				label: {
					font: {
						color: "black",
					},
				},
			},
		},
		bands: [
			{
				//labelText: "SELL SELL SELL",
				style: {
					fillColor: "red",
					color: "grey",
				},
			},
			{
				//labelText: "BUY BUY BUY",
				style: {
					fillColor: "green",
					color: "grey",
				},
			},
		],
	},
	isProductionReady: false,
};

const UPPER_LOWER = {
	UPPER: {
		label: "Upper Indicators",
		items: [
			BOLLINGER_BANDS,
			EMA,
			/*HIGH_LOW_PRICE,*/ /*ICHIMOKU_CLOUD,*/ LINEAR_REGRESSION,
			MAE,
			// PREVIOUS_CLOSE,
			PRICE_CHANNEL,
			PSAR,
			SMA,
			TSF,
			/*VOLUME_BY_PRICE,*/ WMA,
		],
	},
	LOWER: {
		label: "Lower Indicators",
		items: [
			ADL,
			AVERAGE_TRUE_RANGE,
			AWESOME_OSCILLATOR,
			BOLLINGER_BAND_B,
			BOLLINGER_BANDS_WIDTH,
			CHAIKINS_VOLATILITY,
			DIVIDEND_YIELD,
			DMI,
			FAST_STOCHASTICS,
			/*HISTORICAL_DIVIDEND,*/ MACD,
			MASS_INDEX,
			MOMENTUM,
			MONEY_FLOW,
			MONEY_FLOW_INDEX,
			ON_BALANCE_VOLUME,
			PE_Ratio,
			PROC,
			Revenues,
			Rolling_EPS,
			RSI,
			/*SLOW_STOCHASTICS,*/ ULTIMATE_OSCILLATOR,
			VOLUME,
			VROC,
			WILLIAMS_R,
		],
	},
};

const STUDIES = {
	FUNDAMENTAL: {
		label: "Fundamental",
		items: [DIVIDEND_YIELD /*HISTORICAL_DIVIDEND*/, PE_Ratio, Rolling_EPS, Revenues],
	},
	MOMENTUM: {
		label: "Momentum",
		items: [
			AVERAGE_TRUE_RANGE,
			AWESOME_OSCILLATOR,
			BOLLINGER_BAND_B,
			BOLLINGER_BANDS,
			BOLLINGER_BANDS_WIDTH,
			CHAIKINS_VOLATILITY,
			FAST_STOCHASTICS,
			MASS_INDEX,
			MOMENTUM,
			MACD,
			PROC,
			RSI,
			/*SLOW_STOCHASTICS,*/ ULTIMATE_OSCILLATOR,
			WILLIAMS_R,
		],
	},
	TREND: {
		label: "Trend",
		items: [
			DMI,
			EMA,
			/*HIGH_LOW_PRICE,*/ /*ICHIMOKU_CLOUD,*/ LINEAR_REGRESSION,
			MAE,
			// PREVIOUS_CLOSE,
			PRICE_CHANNEL,
			PSAR,
			SMA,
			TSF,
			WMA,
		],
	},
	VOLUME: {
		label: "Volume",
		items: [
			ADL,
			MONEY_FLOW,
			MONEY_FLOW_INDEX,
			ON_BALANCE_VOLUME,
			VOLUME,
			/*VOLUME_BY_PRICE,*/ VROC,
		],
	},
};

const DICTIONARY = {
	bollinger: BOLLINGER_BANDS,
	ema: EMA,
	highlow: HIGH_LOW_PRICE,
	ichimokucloud: ICHIMOKU_CLOUD,
	linearregression: LINEAR_REGRESSION,
	mae: MAE,
	previousclose: PREVIOUS_CLOSE,
	pricechannel: PRICE_CHANNEL,
	psar: PSAR,
	sma: SMA,
	tsf: TSF,
	volumebyprice: VOLUME_BY_PRICE,
	wma: WMA,
	adl: ADL,
	avgtruerange: AVERAGE_TRUE_RANGE,
	awesomeoscillator: AWESOME_OSCILLATOR,
	percentb: BOLLINGER_BAND_B,
	bollingerbandwidth: BOLLINGER_BANDS_WIDTH,
	chaikins: CHAIKINS_VOLATILITY,
	dividendyield: DIVIDEND_YIELD,
	dmi: DMI,
	stochastics: FAST_STOCHASTICS,
	macd: MACD,
	massindex: MASS_INDEX,
	momentum: MOMENTUM,
	moneyflow: MONEY_FLOW,
	moneyflowindex: MONEY_FLOW_INDEX,
	onbalancevolume: ON_BALANCE_VOLUME,
	peratio: PE_Ratio,
	proc: PROC,
	revenues: Revenues,
	rollingeps: Rolling_EPS,
	rsi: RSI,
	ultimateoscillator: ULTIMATE_OSCILLATOR,
	volume0: VOLUME0,
	volume: VOLUME,
	vroc: VROC,
	williamspctr: WILLIAMS_R,
};
const INDICATOR_ACCESSIBILITY = [
	"bollinger",
	"ema",
	"linearregression",
	"mae",
	"pricechannel",
	"psar",
	"sma",
	"tsf",
	"wma",
	"adl",
	"avgtruerange",
	"awesomeoscillator",
	"percentb",
	"bollingerbandwidth",
	"chaikins",
	"dividendyield",
	"dmi",
	"stochastics",
	"macd",
	"massindex",
	"momentum",
	"moneyflow",
	"moneyflowindex",
	"onbalancevolume",
	"peratio",
	"proc",
	"revenues",
	"rollingeps",
	"rsi",
	"ultimateoscillator",
	"volume",
	"vroc",
	"williamspctr",
];

const DEFAULT_USE = ["wma", "mae", "sma", "bollinger"];

export default {
	DICTIONARY,
	STUDIES,
	UPPER_LOWER,
	VOLUME,
	DEFAULT_USE,
	PHASE1_INDICATORS,
	PRICE,
	INDICATOR_ACCESSIBILITY,
	NO_CLIP_INDICATOR,
};
