import React from "react";

const SkipToEndLeft = (props) => {
	return (
		<svg
			data-value={props.dataValue}
			onClick={props.onClick}
			aria-label={props.ariaLabel}
			xmlns="http://www.w3.org/2000/svg"
			width="20px"
			height="20px"
			viewBox="0 0 30 31"
			version="1.1"
		>
			<title>{props.ariaLabel}</title>
			<g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="skip-to-end-left" transform="translate(0.000000, 0.031579)">
					<g
						id={props.dataValue}
						transform="translate(0.000000, 0.000000)"
						fill={props.color || "#187ABA"}
					>
						<path
							d="M16.6864059,5.69210289 C17.1577845,5.22072432 17.9247155,5.22339969 18.3940178,5.69270196 L18.5002805,5.7989647 C18.9719899,6.27067413 18.9743889,7.03306725 18.4933089,7.51414728 L12.0964912,13.9109649 L18.4933089,20.3077825 C18.9688686,20.7833423 18.9695828,21.5536628 18.5002805,22.0229651 L18.3940178,22.1292279 C17.9223083,22.6009373 17.150084,22.593505 16.6864059,22.1298269 L9.32105026,14.7644713 C8.84967169,14.2930927 8.85737219,13.5211366 9.32105026,13.0574585 L16.6864059,5.69210289 Z"
							id="Icon"
						/>
					</g>
					<line
						x1="4.23377193"
						y1="6.04824561"
						x2="4.23377193"
						y2="21.7736842"
						id="Line"
						stroke={props.color || "#187ABA"}
						strokeWidth="2.41929825"
						strokeLinecap="round"
					/>
				</g>
			</g>
		</svg>
	);
};

export default SkipToEndLeft;
