/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Carousel from "../../Lib/Carousel/Carousel";
import MSSpinner from "../../Lib/MSSpinner/MSSpinner";

import EarningsItem from "./EarningsItem/EarningsItem";
import DateFilterItem from "./DateFilterItem";
import Utils from "utils/utils";

const ExpirationDatesFilter = React.memo(({ isMobile }) => {
	const [contentReady, setContentReady] = useState(false);
	const expirationFilterDates = useSelector(
		(state) => state.optionsReducer.expirationFilterDates
	);
	const expirationDatesStatus = useSelector(
		(state) => state.optionsReducer.expirationDatesStatus
	);
	useEffect(() => {
		if (expirationDatesStatus && expirationDatesStatus === "IDLE") {
			setContentReady(true);
		}
	}, [expirationDatesStatus]);
	const selectedExpirationDates = useSelector(
		(state) => state.optionsReducer.selectedExpirationDates
	);

	const [earningsData, setEarningsData] = useState({});
	const [uniqueDates, setUniqueDates] = useState([]);

	const earningsEstimatesCurrentData = useSelector(
		(state) => state.equitiesReducer.earningsEstimatesCurrentData
	);
	useEffect(() => {
		const reportDate = Utils.getObjectPropertyValue(
			earningsEstimatesCurrentData,
			"recommendation.nextExpectedReportDate"
		);
		if (reportDate !== null) {
			const newEarnings = { ...earningsData, reportDate };
			setEarningsData(newEarnings);
			updateExpirationDatesFilter(newEarnings);
		}
	}, [earningsEstimatesCurrentData]);

	const earningsEstimatesTrendsData = useSelector(
		(state) => state.equitiesReducer.earningsEstimatesTrendsData
	);
	useEffect(() => {
		let newEarnings = null;
		if (
			earningsEstimatesTrendsData &&
			earningsEstimatesTrendsData.forward &&
			earningsEstimatesTrendsData.forward.length > 0
		) {
			const forwardData = earningsEstimatesTrendsData.forward[0];
			const estimate = Utils.getObjectPropertyValue(forwardData, "reportedEps.mean");
			newEarnings = { ...earningsData, estimate };
			setEarningsData(newEarnings);
		}
		updateExpirationDatesFilter(newEarnings);
	}, [earningsEstimatesTrendsData, expirationFilterDates]);

	const sortAndFilterExpirationDates = (expirationFilterDates) => {
		return expirationFilterDates.reduce((accum, curr, ind, arr) => {
			if (ind !== arr.length - 1) {
				if (
					curr.expirationDate === arr[ind + 1].expirationDate &&
					curr.contractTerm === "Weekly" &&
					arr[ind + 1].contractTerm === "Monthly"
				) {
					return accum.filter(
						(item) =>
							item.expirationDate === curr.expirationDate &&
							item.contractTerm === curr.contractTerm
					).length > 0
						? accum
						: [...accum, arr[ind + 1], curr];
				}
			}
			return accum.filter(
				(item) =>
					item.expirationDate === curr.expirationDate &&
					item.contractTerm === curr.contractTerm
			).length > 0
				? accum
				: [...accum, curr];
		}, []);
	};

	const updateExpirationDatesFilter = (earningsData) => {
		// insert the earnings object into the expiration dates array
		if (earningsData && earningsData.reportDate && earningsData.estimate) {
			// convert earnings date to timestamp
			const earnDateStamp = Date.parse(earningsData.reportDate);
			// convert expiration dates to timestamps
			const expDateStamps = expirationFilterDates.map((item) => {
				return Date.parse(item.expirationDate);
			});
			for (let stamp of expDateStamps) {
				if (stamp > earnDateStamp) {
					const index = expDateStamps.indexOf(stamp);
					expirationFilterDates.splice(index, 0, earningsData);
					break;
				}
			}
		}
		if (expirationFilterDates && expirationFilterDates.length > 0) {
			/*Remove duplicate dates - this will happen when there is data for standatard and non-starndard data.
			Also makes sure  duplicate dates with different contract terms do show up - ordered in Monthly then Weekly.
			*/
			setUniqueDates(sortAndFilterExpirationDates(expirationFilterDates));
		}
	};
	const getSelectedDates = () => {
		let tempSelectedDates = [];
		if (selectedExpirationDates) {
			tempSelectedDates = selectedExpirationDates
				? Object.keys(selectedExpirationDates)
				: [];
		}
		return tempSelectedDates;
	};

	const isSelected = ({ expirationDate, contractTerm }, tempSelectedDates) => {
		const key = `${expirationDate}-${contractTerm}`;
		return !!tempSelectedDates.find((item) => item.includes(key));
	};

	const spinnerStyle = {
		minHeight: "200px",
	};
	return (
		<MSSpinner
			style={spinnerStyle}
			spinnerStyle={{ minHeight: "30px" }}
			ready={contentReady}
			spinnerSize={"lg"}
		>
			<Carousel>
				{uniqueDates.map((item, index, array) => {
					if (item.hasOwnProperty("reportDate")) {
						return <EarningsItem key={index} data={item} />;
					}
					// If the date matches the next or the previous date when a weekly and monthly option date fall on the same day
					// (i.e. symbol .spx on the 3rd friday of the month).
					// This impacts the API call and the letter abbr in the display.
					// It seems brittle as it assumes duplicates will always be M and W and that M expires in the AM
					const isDuplicateDateFilterItem =
						item.expirationDate === array[index + 1]?.expirationDate ||
						(index && item.expirationDate === array[index - 1].expirationDate);
					const tempSelectedDates = getSelectedDates();
					const isChecked = isSelected(item, tempSelectedDates);
					return (
						<DateFilterItem
							key={index}
							data={item}
							type={"checkbox"}
							checked={isChecked}
							isDuplicateDate={isDuplicateDateFilterItem}
							isLastItemSelected={isChecked && tempSelectedDates.length === 1}
							isMobile={isMobile}
						/>
					);
				})}
			</Carousel>
		</MSSpinner>
	);
});

export default ExpirationDatesFilter;
