const FINANCIAL_PERIOD_TYPE = [
	{
		label: "Annual",
		value: "Annual",
	},
	{
		label: "Quarterly",
		value: "Interim",
	},
];

const CHART_LEGEND_NAMES = {
	Income_Statement: ["Total Revenue", "Net Income", "Profit Margin"],
	Balance_Sheet: ["Total Assets", "Total Liabilities", "Debt to Assets"],
	Cash_Flow: ["Operating", "Investing", "Financing"],
};

const CASHFLOW_ROW = {
	ICF: { description: "Investing Cash Flow", shortName: "Investing" },
	OCF: { description: "Operating Cash Flow", shortName: "Operating" },
	FCF: { description: "Financing Cash Flow", shortName: "Financing" },
};
const ALLOWED_BALANCESHEEET_COLUMNS = [
	"Total Assets",
	"Total Liabilities Net Minority Interest",
];
const CHANGE_BALANCESHEEET_COLUMNS = {
	Total_Liabilities: {
		apiColumnName: "Total Liabilities Net Minority Interest",
		displayName: "Total Liabilities",
	},
};
const FINANCIAL_TABS = {
	IncomeStatement: "Income Statement",
	BalanceSheet: "Balance Sheet",
	CashFlow: "Cash Flow",
	SECFillings: "SEC Filings",
};

const FINANCIAL_TABS_TOOLTIPS = {
	ToolTip_IncomeStatement:
		"This figure is a measure of profitability.  It is equal to annual net income divided by revenues over the trailing 12 months. The resulting figure is then multiplied by 100.",
	ToolTip_BalanceSheet:
		"Total-debt-to-total-assets is a leverage ratio that defines the total amount of debt relative to assets owned by the company.  It is equal to the total amount of a company's debt divided by the total amount of the company's assets. The resulting figure is then multiplied by 100.",
};

const FINANCIAL_DATA_FOR_HOME_PAGE = "home";

export {
	CASHFLOW_ROW,
	CHART_LEGEND_NAMES,
	ALLOWED_BALANCESHEEET_COLUMNS,
	CHANGE_BALANCESHEEET_COLUMNS,
	FINANCIAL_PERIOD_TYPE,
	FINANCIAL_TABS,
	FINANCIAL_TABS_TOOLTIPS,
	FINANCIAL_DATA_FOR_HOME_PAGE,
};
