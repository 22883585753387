import * as React from "react";
import Cell from "./Cell";
import styles from "./DataTable.module.scss";

class DataTable extends React.Component {
	constructor(props) {
		super(props);
		this.renderHeadingRow = this.renderHeadingRow.bind(this);
		this.renderRow = this.renderRow.bind(this);
	}

	renderHeadingRow = (_cell, cellIndex) => {
		const { headers } = this.props;

		let classNameCol = `${headers[cellIndex].IsBold ? "Cell-IsBold" : ""} ${
			headers[cellIndex].CellStyle ? "CellStyle" : ""
		} ${headers[cellIndex].CellBackground ? "CellBackground" : ""}`;

		return (
			<Cell
				key={`heading-${cellIndex}`}
				content={headers[cellIndex].CellValue}
				header={true}
				className={classNameCol.trim()}
			/>
		);
	};

	renderRow = (_row, rowIndex) => {
		const { rows, rowsStyle } = this.props;

		return (
			<tr key={`row-${rowIndex}`} className={rowsStyle}>
				{rows[rowIndex].map((_cell, cellIndex) => {
					let classNamesRow = `${rows[rowIndex][cellIndex].IsBold ? "Cell-IsBold" : ""} ${
						rows[rowIndex][cellIndex].IsChart ? "Cell-FixedWidth" : ""
					} ${rows[rowIndex][cellIndex].CellStyle ? "CellStyle" : ""} ${
						rows[rowIndex][cellIndex].CellBackground ? "CellBackground" : ""
					}`;
					return (
						<Cell
							key={`${rowIndex}-${cellIndex}`}
							content={rows[rowIndex][cellIndex].CellValue}
							className={classNamesRow.trim()}
							IsChart={rows[rowIndex][cellIndex].IsChart || false}
						/>
					);
				})}
			</tr>
		);
	};

	render() {
		const theadMarkup = (
			<tr key="heading" className={this.props.headerStyle}>
				{this.props.headers.map(this.renderHeadingRow)}
			</tr>
		);
		const tbodyMarkup = this.props.rows.map(this.renderRow);

		return (
			<table id="lib_Table_ID" className={styles.lib_Table}>
				<thead>{theadMarkup}</thead>
				<tbody>{tbodyMarkup}</tbody>
			</table>
		);
	}
}

export default DataTable;
