import actionTypes from "./actionTypes";
import { URLS } from "../../utils/constants";

//action creator
const storeDividendsResults = (results) => {
	return {
		type: actionTypes.SAVE_DIVIDENDS_DATA,
		dividendsData: results,
	};
};
const storeHistoricalDividendsResults = (results) => {
	return {
		type: actionTypes.SAVE_HISTORICAL_DIVIDENDS_DATA,
		historicalDividendsData: results,
	};
};

const fetchDividends = (apiInstance, venueXid) => {
	//async here - dispatch here will get set by redux-thunk
	return async (dispatch, getState) => {
		if (!apiInstance || !venueXid) {
			return;
		}
		dispatch({ type: actionTypes.DIVIDENDS_API_TRANSITION, name: "load" });

		const { optionsReducer } = getState();
		if (optionsReducer.dividendsDataStatus !== "FETCHING") {
			return;
		}

		try {
			const response = await apiInstance.get(
				`${URLS.DIVIDENDS_PATH}/${venueXid}/current`
			);
			const data = response.data.data || null;
			if (data) {
				dispatch({ type: actionTypes.DIVIDENDS_API_TRANSITION, name: "success" });
				dispatch(storeDividendsResults(data));
			}
		} catch (error) {
			if (error.response.data.error.message === "No results found for venueXid") {
				dispatch({ type: actionTypes.DIVIDENDS_API_TRANSITION, name: "success" });
				dispatch(storeDividendsResults({}));
			} else {
				dispatch({ type: actionTypes.DIVIDENDS_API_TRANSITION, name: "failure" });
				dispatch(storeDividendsResults(null));
			}
		}
	};
};

const fetchHistoricalDividends = (apiInstance, venueXid) => {
	//async here - dispatch here will get set by redux-thunk
	return async (dispatch, getState) => {
		if (!apiInstance || !venueXid) {
			return;
		}
		dispatch({ type: actionTypes.HISTORICAL_DIVIDENDS_API_TRANSITION, name: "load" });

		const { optionsReducer } = getState();
		if (optionsReducer.historicalDividendsDataStatus !== "FETCHING") {
			return;
		}

		try {
			const response = await apiInstance.get(
				`${URLS.DIVIDENDS_PATH}/${venueXid}/historical-events?sortDirection=desc&numberOfResults=16`
			);

			const data = response.data.data || null;
			if (data) {
				dispatch({
					type: actionTypes.HISTORICAL_DIVIDENDS_API_TRANSITION,
					name: "success",
				});
				dispatch(storeHistoricalDividendsResults(data));
			}
		} catch (error) {
			dispatch({
				type: actionTypes.HISTORICAL_DIVIDENDS_API_TRANSITION,
				name: "failure",
			});
			dispatch(storeHistoricalDividendsResults(null));
		}
	};
};

export default {
	fetchDividends,
	fetchHistoricalDividends,
};
