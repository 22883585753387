import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import ActionCreators from "../../../../../store/actions/actions";
import SimpleSpinner from "components/Lib/SimpleSpinner/SimpleSpinner";

const ResearchPdf = (props) => {
	const dispatch = useDispatch();
	const researchPdfDataStatus = useSelector(
		(state) => state.researchReducer.researchPdfStatus
	);

	let documentId;
	const urlParams = new URLSearchParams(window.location.search);
	for (const [key, value] of urlParams) {
		if (key === "documentId") {
			documentId = value;
			break;
		}
	}

	const loadResearchPdfData = useCallback(
		(documentId) => {
			dispatch(ActionCreators.fetchResearchPdfData(props.apiInstance, documentId));
		},
		[dispatch, props.apiInstance]
	);

	useEffect(() => {
		loadResearchPdfData(documentId);
	}, [loadResearchPdfData, documentId]);

	const isContentReady = () => {
		return researchPdfDataStatus && researchPdfDataStatus !== "FETCHING";
	};

	return (
		// the pdf blob is appended to the document body in the researchActions file
		<div className="research-pdf-simple-spinner-container">
			{!isContentReady() && <SimpleSpinner />}
		</div>
	);
};

export default ResearchPdf;
