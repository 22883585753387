import { createTypes } from "reduxsauce";

// createTypes take second optional argument, with options 'prefix' useful for namespacing
export default createTypes(`
	QUOTE_API_TRANSITION
	OPTIONS_API_TRANSITION
	NEWS_API_TRANSITION
	XREF_API_TRANSITION
	XREF_OPTION_API_TRANSITION
	DIVIDENDS_API_TRANSITION
	STOCK_SPLITS_API_TRANSITION
	TAKEOVERS_API_TRANSITION
	HISTORICAL_DIVIDENDS_API_TRANSITION
	EARNINGS_API_TRANSITION
	EARNINGS_ESTIMATES_CURRENT_API_TRANSITION
	EARNINGS_ESTIMATES_TRENDS_API_TRANSITION
	EARNINGS_ESTIMATES_TRENDS_ANNUAL_API_TRANSITION
	EARNINGS_DETAILS_API_TRANSITION
	EXP_DATES_API_TRANSITION
	TREASURY_YIELD_API_TRANSITION
	USER_INFO_API_TRANSITION
	STRIKES_FILTER_COUNT_UPDATE
	STRIKES_CUSTOM_LOW_HIGH_UPDATE
	EXP_FILTER_DATES_UPDATE
	SAVE_EXP_FILTER_DATES
	EXP_DATES_SELECTED
	UPDATE_SHOW_MONTHLY_EXPIRATION
	SAVE_EARNINGS_DATA
	UPDATE_OPTIONS_PAGE_PARAMS
	UPDATE_OPTIONS_VIEW_TYPE
	SAVE_SELECTED_OPTION_DATA
	SAVE_IS_OPTIONS_TRADE_ENABLED
	RESEARCH_API_TRANSITION
	RESEARCH_DOCUMENT_TRANSITION
	SAVE_RESEARCH_FINANCIAL_DETAILS_DATA
	SAVE_RESEARCH_PDF_DATA
	RESEARCH_PDF_API_TRANSITION
	MSTAR_CLASSIFICATION_TRANSITION
	FINANCIAL_RATIOS_API_TRANSITION
	FINANCIAL_RATIOS_HISTORY_API_TRANSITION
	OVERVIEW_INSIGHT_TRANSITION
	SAVE_QUOTE_DATA
	SAVE_DIVIDENDS_DATA
	SAVE_STOCK_SPLITS_DATA
	SAVE_TAKEOVERS_DATA
	SAVE_HISTORICAL_DIVIDENDS_DATA
	SAVE_FINANCIAL_RATIOS_DATA
	SAVE_EARNINGS_ESTIMATES_CURRENT_DATA
	SAVE_EARNINGS_ESTIMATES_TRENDS_DATA
	SAVE_EARNINGS_ESTIMATES_TRENDS_ANNUAL_DATA
	SAVE_EARNINGS_DETAILS_DATA
	SAVE_FINANCIAL_RATIOS_HISTORY_DATA
	SAVE_RESEARCH_FINANCIAL_DETAILS_DATA
	SAVE_MORNINGSTAR_DOCUMENT_DETAILS_DATA
	SAVE_MORGANSTANLEY_DOCUMENT_DETAILS_DATA
	SAVE_NEWS_DATA
	OVERVIEW_INSIGHT_TRANSITION
	EQUITIES_MORNING_STAR_PEER_API_TRANSITION
	SAVE_EQUITIES_MORNING_STAR_PEER_DATA
	EQUITIES_MORNING_STAR_GENERAL_INFO_API_TRANSITION
	EQUITIES_MORNING_STAR_MANAGEMENT_API_TRANSITION
	EQUITIES_MORNING_STAR_ASSET_CLASSIFICATION_API_TRANSITION
	SAVE_EQUITIES_MORNING_STAR_GENERAL_INFO_DATA
	SAVE_EQUITIES_MORNING_STAR_MANAGEMENT_DATA
	SAVE_EQUITIES_MORNING_STAR_ASSET_CLASSIFICATION_DATA
	INCOMESTATEMENTTABLE_API_TRANSITION
	SAVE_INCOMESTATEMENTTABLE_API_TRANSITION
	INCOMESTATEMENT_OVERVIEW_API_TRANSITION
	SAVE_INCOMESTATEMENT_OVERVIEW_API_TRANSITION
	INCOMESTATEMENT_OVERVIEW_RATIO_API_TRANSITION
	SAVE_INCOMESTATEMENT_OVERVIEW_RATIO_API_TRANSITION
	UPDATE_MARKETS_RESEARCH_ROUTE_NAME
	SAVE_EARNINGS_CHART_PERIODICITY
	SAVE_XREF_SYMBOLS_DATA
	XREF_SYMBOLS_API_TRANSITION
	SAVE_DIVIDENDS_GROWTH_RATE_DATA
	DIVIDENDS_GROWTH_RATE_API_TRANSITION
	USER_PREFERENCE_API_TRANSITION
`);
