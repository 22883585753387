/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import styles from "./ChartingCard.module.scss";
import AdvanceChartConstant from "components/MarketsAndResearch/ChartingCommon/Constants/Chart/AdvanceChart";
import { ReactComponent as PanLeft } from "assets/icons/chartControl_PanLeft.svg";
import { ReactComponent as PanRight } from "assets/icons/chartControl_PanRight.svg";
import { ReactComponent as ZoomIn } from "assets/icons/chartControl_ZoomIn.svg";
import { ReactComponent as ZoomOut } from "assets/icons/chartControl_ZoomOut.svg";
import { ReactComponent as Reset } from "assets/icons/chartControl_Reset.svg";

const PanZoomToolBar = React.memo(({ chart, pan, resetZoom, zoom }) => {
	let divRef = useRef(null);
	let [position, setPosition] = useState({ top: null, left: null });

	const [buttonDisabledStatus, setButtonDisabledStatus] = useState({
		panLeft: false,
		panRight: false,
		zoomIn: false,
		zoomOut: false,
	});

	useEffect(() => {
		if (chart) {
			chart.eventEmitter.on("RESIZE", positionToolbar);
			chart.eventEmitter.on("BOUNDARY_STATUS", updateButtonStatus);
			chart.eventEmitter.on("ZOOM_END", updateButtonStatus);
			return () => {
				chart.eventEmitter.off("RESIZE", positionToolbar);
				chart.eventEmitter.off("BOUNDARY_STATUS", updateButtonStatus);
				chart.eventEmitter.off("ZOOM_END", updateButtonStatus);
			};
		}
	}, [chart]);

	const updateButtonStatus = (args) => {
		setButtonDisabledStatus({
			panLeft: args.isAtLeftBoundary,
			panRight: args.isAtRightBoundary,
			zoomIn: args.isAtZoomInBoundary,
			zoomOut: args.isAtZoomOutBoundary,
		});
	};

	const positionToolbar = () => {
		const { size: panelSize } = chart.panels[0];
		let positionTop = panelSize.height - 10;
		setPosition({ top: positionTop });
	};

	return (
		chart &&
		chart.data &&
		Object.keys(chart.data).length > 0 && (
			<div
				className={styles.panzoomToolbar}
				ref={divRef}
				id="panzoom-toolbar"
				style={{
					left: position.left,
					top: position.top,
				}}
			>
				<div className="zoomSection">
					<button
						onClick={() => {
							if (!buttonDisabledStatus.zoomOut)
								zoom(1 / AdvanceChartConstant.PANZOOM.ZOOM_DISTANCE);
						}}
						aria-disabled={buttonDisabledStatus.zoomOut}
					>
						<span className="sr-only">Zoom Out</span>
						{buttonDisabledStatus.zoomOut ? (
							<ZoomOut title="Zoom Out" className="gray" />
						) : (
							<ZoomOut title="Zoom Out" className="pathStyle" />
						)}
					</button>
					<button
						onClick={() => {
							if (!buttonDisabledStatus.zoomIn)
								zoom(AdvanceChartConstant.PANZOOM.ZOOM_DISTANCE);
						}}
						aria-disabled={buttonDisabledStatus.zoomIn}
					>
						<span className="sr-only">Zoom In</span>
						{buttonDisabledStatus.zoomIn ? (
							<ZoomIn title="Zoom In" className="gray" />
						) : (
							<ZoomIn title="Zoom In" className="circleStyle" />
						)}
					</button>
				</div>
				<div className="panSection">
					<button
						onClick={() => {
							if (!buttonDisabledStatus.panLeft) pan(true);
						}}
						aria-disabled={buttonDisabledStatus.panLeft}
					>
						<span className="sr-only">Scroll Left</span>
						{buttonDisabledStatus.panLeft ? (
							<PanLeft title="Pan Left" className="gray" />
						) : (
							<PanLeft title="Pan Left" className="pathStyle" />
						)}
					</button>
					<button
						onClick={() => {
							if (!buttonDisabledStatus.panRight) pan(false);
						}}
						aria-disabled={buttonDisabledStatus.panRight}
					>
						<span className="sr-only">Scroll Right</span>
						{buttonDisabledStatus.panRight ? (
							<PanRight title="Pan Right" className="gray" />
						) : (
							<PanRight title="Pan Right" className="pathStyle" />
						)}
					</button>
				</div>
				<button
					onClick={() => resetZoom()}
					className="resetBtn"
					style={{ marginRight: "0" }}
				>
					<Reset title="Reset" className="pathStyle" />
				</button>
			</div>
		)
	);
});

export default PanZoomToolBar;
