import { useContext } from "react";
import { ApiInstanceAndUserInfoContext } from "components/App";
import QuoteSearch from "components/Lib/QuoteSearch/QuoteSearch";
import Disclosures from "components/Lib/Disclosures/Disclosures";
import styles from "./ErrorMessage.module.scss";

export const QuoteErrorMessage = (props) => {
	const { apiInstance = {} } = useContext(ApiInstanceAndUserInfoContext);
	const queryParameters = new URLSearchParams(window.location.search);
	const symbol = queryParameters.get("symbol");
	return (
		<div id={styles.mainErrorContainer}>
			<div className={styles.quoteBarContainer}>
				<div className={styles.symbolMessageContainer}>
					<div className={styles.messageText}>
						We couldn't find the symbol '{symbol}'.
					</div>
				</div>
				<div className={styles.quoteSearchContainer}>
					<QuoteSearch apiInstance={apiInstance} venueXid={null} />
				</div>
			</div>
			<Disclosures disclosures="overview" tab="" />
		</div>
	);
};
