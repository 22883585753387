import actionTypes from "./actionTypes";
import { URLS } from "../../utils/constants";

//action creator
const storeEquitiesMorningStarPeerResults = (results) => {
	return {
		type: actionTypes.SAVE_EQUITIES_MORNING_STAR_PEER_DATA,
		equitiesMorningStarPeerData: results,
	};
};

const fetchEquitiesMorningStarPeer = (apiInstance, venueXid) => {
	//async here - dispatch here will get set by redux-thunk
	return async (dispatch, getState) => {
		if (!apiInstance || !venueXid) {
			return;
		}
		dispatch({
			type: actionTypes.EQUITIES_MORNING_STAR_PEER_API_TRANSITION,
			name: "load",
		});

		const { equitiesReducer } = getState();
		if (equitiesReducer.equitiesMorningStarPeerDataStatus !== "FETCHING") {
			return;
		}

		try {
			const response = await apiInstance.get(`${URLS.PEERS_PATH}/${venueXid}`);
			const data = response.data.data || null;
			let peersData = [];
			if (data) {
				data.forEach((element) => {
					peersData.push({ wsodIssue: element.wsodIssue, peerRank: element.PeerRank });
				});
				dispatch({
					type: actionTypes.EQUITIES_MORNING_STAR_PEER_API_TRANSITION,
					name: "success",
				});
				dispatch(storeEquitiesMorningStarPeerResults(peersData));
			}
		} catch (error) {
			if (error && error.response && error.response.status === 404) {
				dispatch({
					type: actionTypes.EQUITIES_MORNING_STAR_PEER_API_TRANSITION,
					name: "success",
				});
				dispatch(storeEquitiesMorningStarPeerResults([]));
			} else {
				dispatch({
					type: actionTypes.EQUITIES_MORNING_STAR_PEER_API_TRANSITION,
					name: "failure",
				});
				dispatch(storeEquitiesMorningStarPeerResults(null));
			}
		}
	};
};

export default {
	fetchEquitiesMorningStarPeer,
};
