import React, { useEffect, useRef, useState } from "react";
import format from "@markit/format";
import styles from "./ChartingCard.module.scss";
import AdvanceChartConstant from "components/MarketsAndResearch/ChartingCommon/Constants/Chart/AdvanceChart";

const YAxisFlag = React.memo(({ chart, priceFormat, chartRef }) => {
	let [val, setVal] = useState(null);
	let [position, setPosition] = useState({ top: null, left: null });
	let divRef = useRef(null);

	useEffect(() => {
		if (chart) {
			chart.eventEmitter.on("FLAG_RENDER_CROSSHAIR", updateFlag);
			chart.eventEmitter.on("FLAG_HIDE_CROSSHAIR", hideFlag);
			chart.eventEmitter.on("FLAG_SHOW_CROSSHAIR", showFlag);
			return () => {
				chart.eventEmitter.off("FLAG_RENDER_CROSSHAIR", updateFlag);
				chart.eventEmitter.off("FLAG_HIDE_CROSSHAIR", hideFlag);
				chart.eventEmitter.off("FLAG_SHOW_CROSSHAIR", showFlag);
			};
		}
	});

	const updateFlag = (args) => {
		let value = formatValue(args.val);
		if (
			chart.params.symbolCompare &&
			chart.params.symbolCompare.length === 0 &&
			priceFormat === AdvanceChartConstant.PRICE_FORMATS.ABSOLUTE.value
		) {
			value = AdvanceChartConstant.PRICE_FORMATS.ABSOLUTE.label + value;
		} else {
			value = value + AdvanceChartConstant.PRICE_FORMATS.PERCENT.label;
		}
		setVal(value);
		positionFlag(args);
	};
	const hideFlag = () => {
		const el = divRef;
		if (el) {
			el.current.style.display = "none";
		}
	};
	const showFlag = () => {
		const el = divRef;
		if (el) {
			el.current.style.display = "block";
		}
	};
	const positionFlag = ({ x, y }) => {
		const [panel] = chartRef.current.getElementsByClassName("modcharts-panel");
		const left = x + 5;
		const top = Math.floor(y + panel.offsetHeight + 6);
		setPosition({ top: top, left: left });
	};
	const formatValue = (value) => {
		return format().number(value, getPrecision(value));
	};
	const getPrecision = (value) => {
		return value < 1 ? 3 : 2;
	};
	return (
		<div
			ref={divRef}
			className={styles.flagCrosshair}
			style={{ left: position.left, top: position.top }}
		>
			<div className={styles.arrowleft}></div> <div>{val}</div>
		</div>
	);
});

export default YAxisFlag;
