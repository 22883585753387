import React, { Component } from "react";
import { Table } from "react-bootstrap";
import { connect } from "react-redux";
import {
	OPTION_VIEW_TYPE_CALL,
	OPTION_VIEW_TYPE_PUT,
	OPTION_VIEW_TYPE_CALL_AND_PUT,
	DIVIDEND_YIELD_DEFAULT,
	RISK_FREE_RATE_DEFAULT,
	TREASURY_YIELD_VENUE_XID,
	URLS,
	OPTION_PAGE_VIEW_TYPE_MR_REDESIGN_DESKTOP,
} from "utils/constants";
import actionTypes from "store/actions/actionTypes";
import MSSpinner from "components/Lib/MSSpinner/MSSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faChevronCircleRight,
	faChevronCircleDown,
} from "@fortawesome/free-solid-svg-icons";
import HeaderValue from "components/Lib/HeaderValue/HeaderValue";
import OptionsTradeButton from "components/Options/OptionsTradeButton/OptionsTradeButton";
import classNames from "classnames";
import Utils from "utils/utils";

import styles from "./OptionsTable.module.scss";
import formatter from "utils/formatUtils";
import { isRealtimeOptionsUser } from "utils/utils";

export class OptionsTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			error: "",
			expandCollapsibleRow: false,
			dividendYield: null,
			riskFreeRate: null,
			contentReady: false,
		};
		this.handleRowExpandableClick = this.handleRowExpandableClick.bind(this);
		this.tableExpandableRowsRef = [];
		this.expandedRow = [];
	}

	componentDidMount() {
		if (this.shouldFetchData({})) {
			this.loadOptionsData();
		}
	}

	componentDidUpdate(prevProps) {
		if (this.shouldFetchData(prevProps)) {
			this.loadOptionsData();
		}
	}

	shouldFetchData(prevProps) {
		if (
			!prevProps ||
			!this.props.selectedExpirationDates ||
			!this.props.venueXid ||
			this.props.optionsDataStatus !== "IDLE"
		) {
			return false;
		}
		return (
			prevProps.strikesFilterCount !== this.props.strikesFilterCount ||
			prevProps.customStrikePrice.low !== this.props.customStrikePrice.low ||
			prevProps.customStrikePrice.high !== this.props.customStrikePrice.high ||
			prevProps.selectedExpirationDates !== this.props.selectedExpirationDates ||
			prevProps.venueXid !== this.props.venueXid ||
			prevProps.apiInstance !== this.props.apiInstance
		);
	}

	getDaysToExpiration(date) {
		return formatter.daysFromCurrentDate(date);
	}

	async loadOptionsData() {
		if (!this.props.venueXid) {
			return;
		}

		this.setState({ error: "", contentReady: false });
		let optionTypeInput,
			expirationDates = [],
			duplicateDates = [];
		switch (this.props.optionTypeInput) {
			case OPTION_VIEW_TYPE_CALL:
			case OPTION_VIEW_TYPE_PUT:
				optionTypeInput = this.props.optionTypeInput;
				break;
			case OPTION_VIEW_TYPE_CALL_AND_PUT:
				optionTypeInput = "all";
				break;
			default:
		}

		if (
			!this.props.expirationFilterDates ||
			this.props.expirationFilterDates.length === 0
		) {
			return;
		}

		// set default expiration if nothing has been selected
		const tempDates = {};
		for (const key in this.props.selectedExpirationDates) {
			const keyParts = key.split("-");

			const index = keyParts[0];
			const value = this.props.selectedExpirationDates[key];
			if (this.props.selectedExpirationDates[key].duplicateDate) {
				duplicateDates.push(this.props.selectedExpirationDates[key]);
			}
			tempDates[value.expirationDate] = `${index}_${value.expirationDate}`;
		}

		let tempSelectedDates = Object.values(tempDates);

		if (tempSelectedDates.length === 0) {
			expirationDates = this.props.expirationFilterDates[0].expirationDate;
		} else {
			tempSelectedDates.sort((el1, el2) => {
				const el1Parsed = el1.split("_");
				const el1Index = parseInt(el1Parsed[0]);

				const el2Parsed = el2.split("_");
				const el2Index = parseInt(el2Parsed[0]);

				return el2Index - el1Index;
			});

			const parsedDates = tempSelectedDates.map((el) => {
				const splitField = el.split("_");
				return splitField[1];
			});
			expirationDates = parsedDates.join(",");
		}

		this.props.dispatchLoadOptions();

		try {
			const dividendYield = await this.loadDividendData();
			const riskFreeRate = await this.loadTreasuryYieldQuoteData();

			// Dividend Rate and Risk Free Rate need to be divided by 100
			// before sending to the Options call
			const adjustedDividendYield = dividendYield
				? dividendYield / 100
				: DIVIDEND_YIELD_DEFAULT;
			const adjustedRiskFreeRate = riskFreeRate
				? riskFreeRate / 100
				: RISK_FREE_RATE_DEFAULT;
			const optionsResponse = await this.props.apiInstance.get(
				`${URLS.OPTIONS_CHAIN_PATH}/${this.props.venueXid}`,
				{
					params: {
						callsPuts: optionTypeInput,
						adjustment: "nonAdjusted",
						strikeCount:
							this.props.strikesFilterCount === "all" ||
							this.props.strikesFilterCount === "custom"
								? "all"
								: this.props.strikesFilterCount / 2,
						riskFreeRate: adjustedRiskFreeRate,
						dividendRate: adjustedDividendYield,
						expirationDates: expirationDates,
						realTimeQuotes: isRealtimeOptionsUser(this.props.userInfo),
					},
				}
			);

			if (optionsResponse.data.data) {
				let options = optionsResponse.data.data;
				if (options.items && options.items.length > 0) {
					const filterOptions = this.filterDuplicateOptions(
						options.items,
						duplicateDates
					);
					let optionResults = this.filterOptionResults(filterOptions);
					this.setState({ data: optionResults, contentReady: true });
					this.props.dispatchOptionsSuccess();
				}
			}
		} catch (error) {
			this.props.dispatchOptionsError();
		}
	}

	async loadDividendData() {
		if (this.state.dividendYield || this.state.dividendYield === 0) {
			return this.state.dividendYield;
		}

		this.props.dispatchLoadDividends();
		let dividendYield;
		try {
			const dividendResponse = await this.props.apiInstance.get(
				`${URLS.DIVIDENDS_PATH}/${this.props.venueXid}/current`,
				{}
			);

			if (dividendResponse.data && dividendResponse.data.data) {
				const dividend = dividendResponse.data.data;
				dividendYield = Utils.getObjectPropertyValue(
					dividend,
					"annualizedDividend.yieldGross"
				);

				this.props.dispatchDividendsSuccess();
			} else {
				//set default
				dividendYield = DIVIDEND_YIELD_DEFAULT;
			}
		} catch (error) {
			//Since this isn't shown anywhere and is just an input to the options call, we don't really care all that much if
			//the API fails. Set to default of 0.
			dividendYield = DIVIDEND_YIELD_DEFAULT;
			this.props.dispatchDividendsError();
		}

		this.setState({ dividendYield: dividendYield });
		return dividendYield;
	}

	async loadTreasuryYieldQuoteData() {
		if (this.state.riskFreeRate) {
			return this.state.riskFreeRate;
		}

		this.props.dispatchLoadTreasuryYield();
		let riskFreeRate;
		try {
			const treasuryYieldXid = TREASURY_YIELD_VENUE_XID;
			const quoteResponse = await this.props.apiInstance.get(`${URLS.QUOTE_PATH}`, {
				params: {
					venueXids: treasuryYieldXid,
					marketHoursTradeType: "normal",
				},
			});

			const quote = (quoteResponse.data && quoteResponse.data.data) || {};

			if (quote.quotes && quote.quotes.length > 0 && quote.quotes[0].data) {
				const quoteData = quote.quotes[0].data;
				riskFreeRate = Utils.getObjectPropertyValue(quoteData, "lastTrade.last");

				this.props.dispatchTreasuryYieldSuccess();
			} else {
				//set default
				riskFreeRate = RISK_FREE_RATE_DEFAULT;
			}
		} catch (error) {
			//Since this isn't shown anywhere and is just an input to the options call, we don't really care all that much if
			//the API fails. Set to default of 0.01.
			riskFreeRate = RISK_FREE_RATE_DEFAULT;
			this.props.dispatchTreasuryYieldError();
		}

		this.setState({ riskFreeRate: riskFreeRate });
		return riskFreeRate;
	}
	/*
	Used when a weekly and monthly expiration date fall on the same day(i.e. symbol .spx on the 3rd friday of the month).
	The API option chains greeks returns option chains that fall on the same day in the same object.
	This function determines which option chains returned have duplicate dates and filters and separates them out as needed.
	*/
	filterDuplicateOptions(items, duplicateDates) {
		let filteredItems = [];
		items.map((item) => {
			if (duplicateDates.length === 0) {
				return filteredItems.push(item);
			}
			let filterCriteria = [];
			duplicateDates.forEach((date) => {
				if (date.expirationDate === item.expirationDate) {
					filterCriteria.push(date.contractTerm);
				}
			});
			if (filterCriteria.length === 0) {
				return filteredItems.push(item);
			} else if (filterCriteria.length === 1) {
				let filterOptions = item.options.filter((option) => {
					return option.call?.periodicity === filterCriteria[0];
				});
				item.options = filterOptions;
				item.periodicity = filterCriteria[0];

				return filteredItems.push(item);
			} else if (filterCriteria.length === 2) {
				let secondSelectedOption = JSON.parse(JSON.stringify(item));
				let firstSelectedOption = JSON.parse(JSON.stringify(item));

				let filteredSecondSelectedOption = secondSelectedOption.options.filter(
					(option) => {
						return option.call?.periodicity === filterCriteria[1];
					}
				);
				let filteredFirstSelectedOption = firstSelectedOption.options.filter((option) => {
					return option.call?.periodicity === filterCriteria[0];
				});
				secondSelectedOption.options = filteredSecondSelectedOption;
				secondSelectedOption.periodicity = filterCriteria[1];
				firstSelectedOption.options = filteredFirstSelectedOption;
				firstSelectedOption.periodicity = filterCriteria[0];

				return filteredItems.push(secondSelectedOption, firstSelectedOption);
			} else {
				return filteredItems.push(item);
			}
		});
		return filteredItems;
	}
	//optionResults should iterate over the results like it does in parseStandarOptions on line 255.
	filterOptionResults(results) {
		// had to move this in here to rectify Sonar Congnitive complexity error
		if (this.props.strikesFilterCount === "custom") {
			results.forEach((result) => {
				const optionResults = result.options;
				const regex = new RegExp("[$,]", "g");

				// set low and high strike prices
				const lowPrice =
					this.props.customStrikePrice.low !== ""
						? parseFloat(this.props.customStrikePrice.low.replace(regex, ""))
						: 0;

				const highPrice =
					this.props.customStrikePrice.high !== ""
						? parseFloat(this.props.customStrikePrice.high.replace(regex, ""))
						: optionResults[optionResults.length - 1].strikePrice;

				const newResults = optionResults.filter(
					(x) => x.strikePrice >= lowPrice && x.strikePrice <= highPrice
				);
				result.options = newResults;
			});
		}

		//moving this out to cover case when options call succeeds but it comes back with empty response
		if (results[0].options.length === 0) {
			this.setState({ error: "No data available" });
		}
		return this.parseStandardOptions(results);
	}

	parseStandardOptions(optionResults) {
		if (!optionResults) {
			return [];
		}
		const finalOptions = [];

		//loop through list of items and split standard and nonstandard options
		optionResults.forEach((result) => {
			let standardOptions, nonStandardOptions;
			result.options.forEach((option) => {
				const callIsAdjusted = Utils.getObjectPropertyValue(option, "call.isAdjusted");
				const putIsAdjusted = Utils.getObjectPropertyValue(option, "put.isAdjusted");
				if (callIsAdjusted || putIsAdjusted) {
					if (!nonStandardOptions) {
						nonStandardOptions = { ...result };
						//resetting array of options as it mayb have standard options as well
						nonStandardOptions.options = [];
					}
					//only inserting non-standard option found.
					nonStandardOptions.options.push(option);
				} else {
					//addResult(standardOptions, result, option);
					if (!standardOptions) {
						standardOptions = { ...result };
						//resetting array of options as it mayb have non-standard options as well
						standardOptions.options = [];
					}
					//only inserting standard option found.
					standardOptions.options.push(option);
				}
			});
			if (standardOptions) {
				finalOptions.push(standardOptions);
			}

			if (nonStandardOptions) {
				finalOptions.push(nonStandardOptions);
			}
		});
		return finalOptions;
	}

	renderPeriodicity(periodicity) {
		if (periodicity) {
			if (periodicity === "Weekly") {
				return " PM";
			} else {
				return " AM";
			}
		} else {
			return "";
		}
	}

	renderSmallScreenOptionTypeHeaderRow(item) {
		const daysToExpiration = this.getDaysToExpiration(item.expirationDate);
		const optionTypeLabel =
			this.props.optionType === OPTION_VIEW_TYPE_CALL ? "Calls" : "Puts";

		return this.props.optionType === OPTION_VIEW_TYPE_CALL_AND_PUT ? (
			<>
				<th id="callsHeader">Calls</th>
				<th colSpan="3">
					{formatter.date(item.expirationDate, "MMM DD, YYYY ")}
					{this.renderPeriodicity(item.periodicity)}
					<span
						className={styles.expirationDays}
					>{` (In ${daysToExpiration} days)`}</span>
				</th>
				<th id="putsHeader">Puts</th>
			</>
		) : (
			<>
				<th colSpan="6" className="text-left">
					{`${optionTypeLabel} for ${formatter.date(
						item.expirationDate,
						"MMM DD, YYYY "
					)}${this.renderPeriodicity(item.periodicity)}`}
					<span
						className={styles.expirationDays}
					>{` (In ${daysToExpiration} days)`}</span>
				</th>
			</>
		);
	}

	renderMediumScreenOptionTypeHeaderRow(item) {
		let mediumScreenOptionTypeHeaderRow;
		const daysToExpiration = this.getDaysToExpiration(item.expirationDate);
		const displayDate = formatter.date(item.expirationDate, "MMM DD, YYYY");
		switch (this.props.optionType) {
			case OPTION_VIEW_TYPE_CALL:
			case OPTION_VIEW_TYPE_PUT:
				const optionTypeLabel =
					this.props.optionType === OPTION_VIEW_TYPE_CALL ? "Calls" : "Puts";
				mediumScreenOptionTypeHeaderRow = (
					<>
						<th scope="col" colSpan="8">
							{`${optionTypeLabel} for ${displayDate}${this.renderPeriodicity(
								item.periodicity
							)}`}
							<span
								className={styles.expirationDays}
							>{` (In ${daysToExpiration} days)`}</span>
						</th>
					</>
				);
				break;
			case OPTION_VIEW_TYPE_CALL_AND_PUT:
				mediumScreenOptionTypeHeaderRow = (
					<>
						<th scope="col" id="callsHeader" colSpan="6">
							Calls
						</th>
						<th scope="col" colSpan="3">
							{displayDate}
							{this.renderPeriodicity(item.periodicity)}
							<span
								className={styles.expirationDays}
							>{` (In ${daysToExpiration} days)`}</span>
						</th>
						<th scope="col" id="putsHeader" colSpan="6">
							Puts
						</th>
					</>
				);
				break;
			default:
		}

		return mediumScreenOptionTypeHeaderRow;
	}

	renderTradeColumn() {
		return (
			!this.props.viewType ||
			(this.props.viewType &&
				this.props.viewType !== OPTION_PAGE_VIEW_TYPE_MR_REDESIGN_DESKTOP)
		);
	}

	renderDataColumnHeaders() {
		let dataColumnHeaderRow;
		switch (this.props.optionType) {
			case OPTION_VIEW_TYPE_CALL:
			case OPTION_VIEW_TYPE_PUT:
				dataColumnHeaderRow = (
					<>
						<th
							scope="col"
							id="expandCollapseHeader"
							className="d-sm-table-cell d-md-none"
						></th>
						<th scope="col" id="strikeHeader" className="">
							Strike
						</th>
						<th scope="col" id="lastHeader" className="">
							Last
						</th>
						<th scope="col" id="changeHeader" className="">
							Change
						</th>
						<th scope="col" id="bidHeader" className="">
							Bid
						</th>
						<th scope="col" id="askHeader" className="">
							Ask
						</th>
						<th scope="col" id="volumeHeader" className="d-none d-md-table-cell">
							Volume
						</th>
						<th scope="col" id="openIntHeader" className="d-none d-md-table-cell">
							Open Interest
						</th>
						<th scope="col" id="impliedVolHeader" className="d-none d-md-table-cell">
							Implied Volatility
						</th>
						{/* Removing for now until MS can add logic to handle trade postMessage see ticket MSQR-6462*/}
						{this.renderTradeColumn() && (
							<th scope="col" id="tradeHeader" className="d-none d-md-table-cell">
								Trade
							</th>
						)}
					</>
				);
				break;
			case OPTION_VIEW_TYPE_CALL_AND_PUT:
				dataColumnHeaderRow = (
					<>
						{/* Removing for now until MS can add logic to handle trade postMessage see ticket MSQR-6462 */}
						{this.renderTradeColumn() && (
							<th scope="col" id="tradeHeader" className="d-none d-md-table-cell">
								Trade
							</th>
						)}
						<th scope="col" id="callsLastHeader" className="d-none d-md-table-cell">
							Last
						</th>
						<th scope="col" id="callsChangeHeader" className="d-none d-md-table-cell">
							Change
						</th>
						<th scope="col" id="callsBidHeader">
							Bid
						</th>
						<th scope="col" id="callsAskHeader">
							Ask
						</th>
						<th scope="col" id="callsVolumeHeader" className="d-none d-md-table-cell">
							Volume
						</th>
						<th scope="col" id="callsOpenIntHeader" className="d-none d-md-table-cell">
							Open Interest
						</th>
						<th scope="col" id="callsImpliedVolHeader" className="d-none d-md-table-cell">
							Implied Volatility
						</th>
						<th scope="col" id="strikeHeader" className={styles.strikeHeaderCell}>
							Strike
						</th>
						<th scope="col" id="putsLastHeader" className="d-none d-md-table-cell">
							Last
						</th>
						<th scope="col" id="putsChangeHeader" className="d-none d-md-table-cell">
							Change
						</th>
						<th scope="col" id="putsBidHeader">
							Bid
						</th>
						<th scope="col" id="putsAskHeader">
							Ask
						</th>
						<th scope="col" id="putsVolumeHeader" className="d-none d-md-table-cell">
							Volume
						</th>
						<th scope="col" id="putsOpenIntHeader" className="d-none d-md-table-cell">
							Open Interest
						</th>
						<th scope="col" id="putsImpliedVolHeader" className="d-none d-md-table-cell">
							Implied Volatility
						</th>
						{/*Removing for now until MS can add logic to handle trade postMessage see ticket MSQR-6462 */}
						{this.renderTradeColumn() && (
							<th scope="col" id="tradeHeader" className="d-none d-md-table-cell">
								Trade
							</th>
						)}
					</>
				);
				break;
			default:
		}

		return dataColumnHeaderRow;
	}

	renderTableRow(item, expirationDate, index) {
		let tableRows;
		switch (this.props.optionType) {
			case OPTION_VIEW_TYPE_CALL:
				tableRows = this.renderCallsOrPutsTableRow(
					item.call,
					item.strikePrice,
					expirationDate,
					index
				);
				break;
			case OPTION_VIEW_TYPE_CALL_AND_PUT:
				tableRows = this.renderCallsAndPutsTableRow(item, index, expirationDate);
				break;
			case OPTION_VIEW_TYPE_PUT:
				tableRows = this.renderCallsOrPutsTableRow(
					item.put,
					item.strikePrice,
					expirationDate,
					index
				);
				break;
			default:
		}

		return tableRows;
	}

	renderChangeCell(
		change,
		changePercent,
		index,
		openInterestHeaderValue,
		callInTheMoneyCell
	) {
		let changeCellContent;
		let changeCellFormatClass;

		if (Math.abs(change) < 0.001) {
			changeCellContent = "0.00";
		} else {
			changeCellContent = (
				<>
					<span>{formatter.price(change, 2, { showSign: true })}/</span>
					<span>
						{formatter.percent(changePercent, 2, {
							showSign: true,
							percentModifier: 0.01,
						})}
					</span>
				</>
			);

			if (change > 0) {
				changeCellFormatClass = styles.positiveChangeCell;
			} else {
				changeCellFormatClass = styles.negativeChangeCell;
			}
		}

		const idxAdden = index > 0 ? index : 0;
		let headerValueIdx = index * 2 + 1 + idxAdden;

		return (
			<>
				<td
					headers="changeHeader"
					className={`${changeCellFormatClass} ${styles.changeCell} ${callInTheMoneyCell}`}
				>
					{changeCellContent}
					<div
						className={styles.collapseContent}
						aria-hidden={this.expandableRowAriaHidden(headerValueIdx)}
						ref={(col) => (this.tableExpandableRowsRef[headerValueIdx] = col)}
					>
						<HeaderValue
							data={openInterestHeaderValue}
							key={openInterestHeaderValue.id}
						/>
					</div>
				</td>
			</>
		);
	}

	handleRowClick = (tradeMessageData) => {
		this.props.dispatchSelectedOption(tradeMessageData);
		this.props.rowClickCallback();
	};

	/*
	 * input: index => index number for row clicked to expand.
	 *
	 * This determines which DOM Ref to add class, based for the row clicked.
	 * It will toggle class 'showExpandableContent' accross all the three HeaderValueComponents and creating the exapdanle row effect.
	 */
	handleRowExpandableClick(event, index) {
		event.stopPropagation();
		const baseIdx = index * 3; // since there are 3 header/value components
		const classNames = this.tableExpandableRowsRef[baseIdx].className.split(" ");
		const idx = classNames.indexOf(styles.showExpandableContent);

		if (idx > 0) {
			// remove class showExpandableContent
			classNames.splice(idx, 1);
			this.tableExpandableRowsRef[baseIdx].icon = "right";
			this.tableExpandableRowsRef[baseIdx].className = classNames.join(" ");
			this.tableExpandableRowsRef[baseIdx + 1].className = classNames.join(" ");
			this.tableExpandableRowsRef[baseIdx + 2].className = classNames.join(" ");
			this.setState({ expandCollapsibleRow: false });
		} else {
			// add class showExpandableContent
			this.tableExpandableRowsRef[baseIdx].icon = "down";
			this.tableExpandableRowsRef[
				baseIdx
			].className += ` ${styles.showExpandableContent}`;
			this.tableExpandableRowsRef[
				baseIdx + 1
			].className += ` ${styles.showExpandableContent}`;
			this.tableExpandableRowsRef[
				baseIdx + 2
			].className += ` ${styles.showExpandableContent}`;
			this.setState({ expandCollapsibleRow: true });
		}
	}

	getExpandableRowIcon(index) {
		let chevronIcon = faChevronCircleRight;
		if (
			this.tableExpandableRowsRef[index] &&
			this.tableExpandableRowsRef[index].icon === "down"
		) {
			chevronIcon = faChevronCircleDown;
		}
		return chevronIcon;
	}

	expandableRowAriaHidden(index) {
		if (!this.tableExpandableRowsRef[index] || !this.tableExpandableRowsRef[index].icon) {
			return true;
		}
		return this.tableExpandableRowsRef[index].icon !== "down";
	}

	getTradeMessageData(data) {
		if (!data) return;

		const { xid, expirationDate, strikePrice, symbol, optionType } = data;
		const optionTypeLetter = this.props.optionType === OPTION_VIEW_TYPE_CALL ? "C" : "P";
		const formattedExpirationDate = formatter.date(expirationDate, "MM/DD/YY ");
		const optionText = `${this.props.symbol} ${optionTypeLetter} ${formattedExpirationDate} ${strikePrice}`;

		return {
			underlyingSymbol: symbol,
			optionXid: xid,
			optionType: optionType === OPTION_VIEW_TYPE_CALL ? "C" : "P",
			expirationDate: expirationDate,
			strikePrice: strikePrice,
			optionText: optionText,
		};
	}

	renderCallsOrPutsTableRow(optionData, strikePrice, expirationDate, index) {
		if (!optionData) {
			return null;
		}

		const tradeMessageData = this.getTradeMessageData({
			xid: optionData.xid,
			expirationDate,
			strikePrice,
			symbol: this.props.symbol,
			optionType: this.props.optionType,
		});

		const rowOnClickEvent =
			this.props.isMobile && this.props.isOptionsTradeEnabled
				? () => this.handleRowClick(tradeMessageData)
				: null;

		const lastVolumnVal =
			Utils.getObjectPropertyValue(optionData, "quote.volume.last") || 0;
		const openIntVal =
			Utils.getObjectPropertyValue(optionData, "quote.openInterest") || 0;
		const implVolVal =
			Utils.getObjectPropertyValue(optionData, "greeks.impliedVolatility") || 0;
		const volumeHeaderValue = {
			id: `table_row_expand_volume-idx${index}`,
			header: "Volume",
			value: formatter.number(lastVolumnVal),
			hideHeader: false,
		};
		const openInterestHeaderValue = {
			id: `table_row_expand_open_int-idx${index}`,
			header: "Open int.",
			value: formatter.number(openIntVal),
			hideHeader: false,
		};
		const impliedVolatilityHeaderValue = {
			id: `table_row_expand_implied_vol-idx${index}`,
			header: "Implied Vol.",
			value: formatter.percent(implVolVal, 2, { percentModifier: 1 }),
			hideHeader: false,
		};

		/*
		 * tableExpandableRowsRef[] : is an array holding a DOM reference to each Container holding expandanble row content using ref{}
		 * this is acting as having and ID to DOM element, but this avoids us having to lookup by id which is prone to errors
		 * Avoid this: document.getElementById("volumeHeaderContainer")
		 *
		 * There are 3 HeaderValue components per row, so that means tableExpandableRowsRef array will have 3 entries per row.
		 * The math below is the find the proper index to insert each reference in the right order.
		 * Example: First row would have will have the first three items in the array, the second row from 3-6, 3rd from 7-9, etc...
		 */
		const idxAdden = index > 0 ? index : 0;
		let volumeHeaderValueIdx = index * 2 + idxAdden;
		let impliedVolHeaderValueIdx = index * 2 + 2 + idxAdden;

		const lastTradeVal =
			Utils.getObjectPropertyValue(optionData, "quote.lastTrade.last") || 0;
		const lastTradeChange =
			Utils.getObjectPropertyValue(optionData, "quote.lastTrade.change") || 0;
		const quoteChangePercent =
			Utils.getObjectPropertyValue(optionData, "quote.changePercent.today") || 0;
		const quoteBidPrice =
			Utils.getObjectPropertyValue(optionData, "quote.bid.price") || 0;
		const quoteAskPrice =
			Utils.getObjectPropertyValue(optionData, "quote.ask.price") || 0;
		return (
			<tr
				key={index}
				onClick={rowOnClickEvent}
				className={optionData.calculation.inOutMoney > 0 ? styles.inTheMoneyRow : null}
			>
				<td
					headers="expandCollapseHeader"
					onClick={(evt) => {
						this.handleRowExpandableClick(evt, index);
					}}
					className={`d-sm-table-cell d-md-none ${styles.expandCollapseIconCell}`}
				>
					<FontAwesomeIcon
						icon={this.getExpandableRowIcon(volumeHeaderValueIdx)}
						size={"lg"}
					/>
				</td>
				<td
					headers="strikeHeader"
					className={classNames("callsOrPutsStrikeCell", {
						expandableHeight: !this.expandableRowAriaHidden(volumeHeaderValueIdx),
					})}
				>
					{formatter.number(strikePrice, 2)}
					<div
						className={styles.collapseContent}
						aria-hidden={this.expandableRowAriaHidden(volumeHeaderValueIdx)}
						ref={(col) => {
							this.tableExpandableRowsRef[volumeHeaderValueIdx] = col;
						}}
					>
						<HeaderValue data={volumeHeaderValue} key={volumeHeaderValue.id} />
					</div>
				</td>
				<td
					headers="lastHeader"
					className={classNames({
						expandableHeight: !this.expandableRowAriaHidden(volumeHeaderValueIdx),
					})}
				>
					{formatter.number(lastTradeVal, 2)}
				</td>
				{this.renderChangeCell(
					lastTradeChange,
					quoteChangePercent,
					index,
					openInterestHeaderValue
				)}
				<td
					headers="bidHeader"
					className={classNames({
						expandableHeight: !this.expandableRowAriaHidden(volumeHeaderValueIdx),
					})}
				>
					{formatter.number(quoteBidPrice, 2)}
					<div
						className={styles.collapseContent}
						aria-hidden={this.expandableRowAriaHidden(impliedVolHeaderValueIdx)}
						ref={(col) => (this.tableExpandableRowsRef[impliedVolHeaderValueIdx] = col)}
					>
						<HeaderValue
							data={impliedVolatilityHeaderValue}
							key={impliedVolatilityHeaderValue.id}
						/>
					</div>
				</td>
				<td headers="askHeader">{formatter.number(quoteAskPrice, 2)}</td>
				<td headers="volumeHeader" className="d-none d-md-table-cell">
					{formatter.number(lastVolumnVal)}
				</td>
				<td headers="openIntHeader" className="d-none d-md-table-cell">
					{formatter.number(openIntVal)}
				</td>
				<td headers="impliedVolHeader" className="d-none d-md-table-cell">
					{formatter.percent(implVolVal, 2, { percentModifier: 1 })}
				</td>
				{/* Removing for now until MS can add logic to handle trade postMessage see ticket MSQR-6462 */}
				{this.renderTradeColumn() && (
					<td headers="actionHeader" className={`d-none d-md-table-cell`}>
						<OptionsTradeButton
							apiInstance={this.props.apiInstance}
							text="Select"
							tradeMessageData={tradeMessageData}
							customStyles={styles.tradeButton}
						></OptionsTradeButton>
					</td>
				)}
			</tr>
		);
	}

	renderCallsAndPutsTableRow(item, index, expirationDate) {
		if (!item.call || !item.put) {
			return null;
		}

		const callInTheMoneyCell =
			item.call.calculation?.inOutMoney > 0 ? styles.inTheMoneyCell : null;
		const putInTheMoneyCell =
			item.put.calculation?.inOutMoney > 0 ? styles.inTheMoneyCell : null;
		const callsTradeMessageData = this.getTradeMessageData({
			xid: item.call.xid,
			expirationDate,
			strikePrice: item.strikePrice,
			symbol: this.props.symbol,
			optionType: OPTION_VIEW_TYPE_CALL,
		});
		const putsTradeMessageData = this.getTradeMessageData({
			xid: item.put.xid,
			expirationDate,
			strikePrice: item.strikePrice,
			symbol: this.props.symbol,
			optionType: OPTION_VIEW_TYPE_PUT,
		});

		const callsOpenIntVal =
			Utils.getObjectPropertyValue(item.call, "quote.openInterest") || 0;
		const callsOpenInterestHeaderValue = {
			id: `table_calls_row_expand_open_int-idx${index}`,
			header: "Open int.",
			value: formatter.number(callsOpenIntVal),
			hideHeader: false,
		};
		const putsOpenIntVal =
			Utils.getObjectPropertyValue(item.put, "quote.openInterest") || 0;
		const putsOpenInterestHeaderValue = {
			id: `table_puts_row_expand_open_int-idx${index}`,
			header: "Open int.",
			value: formatter.number(putsOpenIntVal),
			hideHeader: false,
		};
		const callsLastTradeChange =
			Utils.getObjectPropertyValue(item.call, "quote.lastTrade.change") || 0;
		const callsQuoteChangePercent =
			Utils.getObjectPropertyValue(item.call, "quote.changePercent.today") || 0;
		const putsLastTradeChange =
			Utils.getObjectPropertyValue(item.put, "quote.lastTrade.change") || 0;
		const putsQuoteChangePercent =
			Utils.getObjectPropertyValue(item.put, "quote.changePercent.today") || 0;

		return (
			<tr key={index} onClick={this.handleClick}>
				{/* Removing for now until MS can add logic to handle trade postMessage see ticket MSQR-6462 */}
				{this.renderTradeColumn() && (
					<td
						headers="callsHeader callsTradeHeader"
						className={`d-none d-md-table-cell ${callInTheMoneyCell}`}
					>
						<OptionsTradeButton
							apiInstance={this.props.apiInstance}
							text="Select"
							tradeMessageData={callsTradeMessageData}
							customStyles={styles.tradeButton}
						></OptionsTradeButton>
					</td>
				)}
				<td
					headers="callsHeader callsLastHeader"
					className={`d-none d-md-table-cell ${callInTheMoneyCell}`}
				>
					{formatter.number(item?.call?.quote?.lastTrade?.last, 2)}
				</td>
				{this.renderChangeCell(
					callsLastTradeChange,
					callsQuoteChangePercent,
					index,
					callsOpenInterestHeaderValue,
					callInTheMoneyCell
				)}
				<td headers="callsHeader callsBidHeader" className={`${callInTheMoneyCell}`}>
					{formatter.number(item.call.quote?.bid?.price, 2)}
				</td>
				<td headers="callsHeader callsAskHeader" className={callInTheMoneyCell}>
					{formatter.number(item.call.quote?.ask?.price, 2)}
				</td>
				<td
					headers="callsHeader callsVolumeHeader"
					className={`d-none d-md-table-cell ${callInTheMoneyCell}`}
				>
					{formatter.number(item.call.quote?.volume?.last)}
				</td>
				<td
					headers="callsHeader callsOpenIntHeader"
					className={`d-none d-md-table-cell ${callInTheMoneyCell}`}
				>
					{formatter.number(item.call.quote?.openInterest)}
				</td>
				<td
					headers="callsHeader callsImpliedVolHeader"
					className={`d-none d-md-table-cell ${callInTheMoneyCell}`}
				>
					{formatter.percent(item.call.greeks?.impliedVolatility, 2, {
						percentModifier: 1,
					})}
				</td>
				<td headers="strikeHeader">{formatter.number(item.strikePrice, 2)}</td>
				<td
					headers="putsHeader putsLastHeader"
					className={`d-none d-md-table-cell ${putInTheMoneyCell}`}
				>
					{formatter.number(item.put.quote?.lastTrade?.last, 2)}
				</td>
				{this.renderChangeCell(
					putsLastTradeChange,
					putsQuoteChangePercent,
					index,
					putsOpenInterestHeaderValue,
					putInTheMoneyCell
				)}
				<td headers="putsHeader putsBidHeader" className={putInTheMoneyCell}>
					{formatter.number(item.put.quote?.bid?.price, 2)}
				</td>
				<td headers="putsHeader putsAskHeader" className={`${putInTheMoneyCell}`}>
					{formatter.number(item.put.quote?.ask?.price, 2)}
				</td>
				<td
					headers="putsHeader putsVolumeHeader"
					className={`d-none d-md-table-cell ${putInTheMoneyCell}`}
				>
					{formatter.number(item.put.quote?.volume?.last)}
				</td>
				<td
					headers="putsHeader putsOpenIntHeader"
					className={`d-none d-md-table-cell ${putInTheMoneyCell}`}
				>
					{formatter.number(item.put.quote?.openInterest)}
				</td>
				<td
					headers="putsHeader putsImpliedVolHeader"
					className={`d-none d-md-table-cell ${putInTheMoneyCell}`}
				>
					{formatter.percent(item.put.greeks?.impliedVolatility, 2, {
						percentModifier: 1,
					})}
				</td>
				{/* Removing for now until MS can add logic to handle trade postMessage see ticket MSQR-6462 */}
				{this.renderTradeColumn() && (
					<td
						headers="putsHeader putsTradeHeader"
						className={`d-none d-md-table-cell ${putInTheMoneyCell}`}
					>
						<OptionsTradeButton
							apiInstance={this.props.apiInstance}
							text="Select"
							tradeMessageData={putsTradeMessageData}
							customStyles={styles.tradeButton}
						></OptionsTradeButton>
					</td>
				)}
			</tr>
		);
	}

	renderOptionsDataTable(data) {
		if (!data || !data.options) {
			return null;
		}
		return data.options.map((item, index) =>
			this.renderTableRow(item, data.expirationDate, index)
		);
	}

	renderOptionsFullTable(item, index) {
		return (
			<div key={index}>
				<Table className={`${styles.basicOptionsTable} ${styles[this.props.optionType]}`}>
					<thead>
						<tr className={`d-table-row d-md-none ${styles.optionTypeHeaderRow}`}>
							{this.renderSmallScreenOptionTypeHeaderRow(item)}
						</tr>
						<tr className={styles.columnsHeaderRow}>{this.renderDataColumnHeaders()}</tr>
						<tr className={`d-none d-md-table-row ${styles.optionTypeHeaderRow}`}>
							{this.renderMediumScreenOptionTypeHeaderRow(item)}
						</tr>
					</thead>
					<tbody>{this.renderOptionsDataTable(item)}</tbody>
				</Table>
				{this.state.error !== "" && (
					<p className="text-danger text-center">{this.state.error}</p>
				)}
			</div>
		);
	}

	render() {
		const spinnerStyle = {
			minHeight: "100px",
		};
		return (
			<MSSpinner
				spinnerStyle={spinnerStyle}
				ready={this.state.contentReady}
				spinnerSize={"2x"}
			>
				{this.state.data
					? this.state.data.map((item, index) => this.renderOptionsFullTable(item, index))
					: null}
			</MSSpinner>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		optionsDataStatus: state.optionsReducer.optionsDataStatus,
		dividendsDataStatus: state.optionsReducer.dividendsDataStatus,
		treasuryYieldDataStatus: state.optionsReducer.treasuryYieldDataStatus,
		strikesFilterCount: state.optionsReducer.strikesFilterCount,
		expirationFilterDates: state.optionsReducer.expirationFilterDates,
		selectedExpirationDates: state.optionsReducer.selectedExpirationDates,
		customStrikePrice: state.optionsReducer.customStrikePrice,
		isOptionsTradeEnabled: state.optionsReducer.isOptionsTradeEnabled,
		isMobile: state.optionsReducer.isMobile,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		dispatchLoadOptions: () =>
			dispatch({ type: actionTypes.OPTIONS_API_TRANSITION, name: "load" }),
		dispatchOptionsSuccess: () =>
			dispatch({ type: actionTypes.OPTIONS_API_TRANSITION, name: "success" }),
		dispatchOptionsError: () =>
			dispatch({ type: actionTypes.OPTIONS_API_TRANSITION, name: "failure" }),
		dispatchSelectedOption: (selectedOption) =>
			dispatch({
				type: actionTypes.SAVE_SELECTED_OPTION_DATA,
				selectedOption: selectedOption,
			}),
		dispatchLoadDividends: () =>
			dispatch({ type: actionTypes.DIVIDENDS_API_TRANSITION, name: "load" }),
		dispatchDividendsSuccess: () =>
			dispatch({ type: actionTypes.DIVIDENDS_API_TRANSITION, name: "success" }),
		dispatchDividendsError: () =>
			dispatch({ type: actionTypes.DIVIDENDS_API_TRANSITION, name: "failure" }),
		dispatchLoadTreasuryYield: () =>
			dispatch({ type: actionTypes.TREASURY_YIELD_API_TRANSITION, name: "load" }),
		dispatchTreasuryYieldSuccess: () =>
			dispatch({ type: actionTypes.TREASURY_YIELD_API_TRANSITION, name: "success" }),
		dispatchTreasuryYieldError: () =>
			dispatch({ type: actionTypes.TREASURY_YIELD_API_TRANSITION, name: "failure" }),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(OptionsTable);
