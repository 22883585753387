export const EVENT_TYPES = {
	EARNINGS: "earningscustom",
	DIVIDENDS: "dividends",
	HIGHLOW: "highlow",
	RATINGSMS: "morganstanleyratingscustom",
	RATINGSMSTAR: "morningstarratingscustom",
	SPLITS: "splits",
	CUSTOM: "custom",
};

// Ranking for how event icons should be stacked; e.g. Earnings under Dividents
const SORT_RANK = {};
SORT_RANK[EVENT_TYPES.EARNINGS] = 1;
SORT_RANK[EVENT_TYPES.DIVIDENDS] = 2;
SORT_RANK[EVENT_TYPES.RATINGSMS] = 3;
SORT_RANK[EVENT_TYPES.RATINGSMSTAR] = 4;
SORT_RANK[EVENT_TYPES.HIGHLOW] = 5;
SORT_RANK[EVENT_TYPES.SPLITS] = 6;

const DATA_INTERFACE =
	"bin\\MODClients\\MorganStanley\\Research\\MorganStanley.Research.DataInterface.dll;MorganStanley.Research.DataInterface";

export default {
	EVENT_TYPES,
	SORT_RANK,
	DATA_INTERFACE,
};
