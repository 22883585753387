import { createReducer } from "reduxsauce";
import actionTypes from "../actions/actionTypes";
import ApiStates from "../apiStates";
import { API_TRANSITIONS, getApiTransitionAction } from "../apiTransitionUtils";

const INITIAL_STATE = {
	stockSplitsDataStatus: ApiStates.IDLE,
	takeoversDataStatus: ApiStates.IDLE,
	stockSplitsData: {},
	takeoversData: {},
};

export const updateStockSplitsData = (state = INITIAL_STATE, { stockSplitsData }) => {
	// NOSONAR
	if (!stockSplitsData) {
		return { ...state };
	}

	return {
		...state,
		stockSplitsData: stockSplitsData,
	};
};

export const stockSplitsTransition = (state = INITIAL_STATE, { name }) => {
	// NOSONAR
	const action = getApiTransitionAction(state.stockSplitsDataStatus, name);

	if (action) {
		// execute action
		return action.apply(API_TRANSITIONS, [state, { key: "stockSplitsDataStatus" }]);
	}

	// return existing state if no action found
	return { ...state };
};

export const takeoversTransition = (state = INITIAL_STATE, { name }) => {
	// NOSONAR
	const action = getApiTransitionAction(state.takeoversDataStatus, name);
	if (action) {
		// execute action
		return action.apply(API_TRANSITIONS, [state, { key: "takeoversDataStatus" }]);
	}

	// return existing state if no action found
	return { ...state };
};

export const updateTakeoversData = (state = INITIAL_STATE, { takeoversData }) => {
	// NOSONAR
	if (!takeoversData) {
		return { ...state };
	}

	return {
		...state,
		takeoversData: takeoversData,
	};
};

export const HANDLERS = {
	[actionTypes.STOCK_SPLITS_API_TRANSITION]: stockSplitsTransition,
	[actionTypes.SAVE_STOCK_SPLITS_DATA]: updateStockSplitsData,
	[actionTypes.TAKEOVERS_API_TRANSITION]: takeoversTransition,
	[actionTypes.SAVE_TAKEOVERS_DATA]: updateTakeoversData,
};

export default createReducer(INITIAL_STATE, HANDLERS);
