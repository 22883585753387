import CommonTimeFrameConstant from "components/MarketsAndResearch/ChartingCommon/Constants/TimeFrame/Common";

export const CUSTOM_DATE_ID_VALUE = 99;
export const CUSTOM_DATE_ID = "CUSTOM_DATE_ID";

const DATE_OPTIONS = {
	...CommonTimeFrameConstant.DATE_OPTIONS,
	ABOVEFIVEYEAR: {
		key: "",
		label: "Above 5 Year",
	},
	ABOVEFIVEDAY: {
		key: "",
		label: "Above 5 Day",
	},
	ABOVETWENTYONEDAY: {
		key: "",
		label: "Above 21 Day",
	},
	TENYEAR: {
		key: "10Y",
		label: "10 Year",
	},
	CUSTOM_DATE: {
		key: "",
		label: "Custom Date",
	},
};

export const TIME_FRAMES = [
	...CommonTimeFrameConstant.TIME_FRAMES,
	{
		key: 9,
		keyLabel: DATE_OPTIONS.ABOVEFIVEYEAR.key,
		label: DATE_OPTIONS.ABOVEFIVEYEAR.label,
		numDays: "",
		defaultPeriod: CommonTimeFrameConstant.PERIODS.MONTH,
		periods: [
			CommonTimeFrameConstant.PERIODS.DAY,
			CommonTimeFrameConstant.PERIODS.WEEK,
			CommonTimeFrameConstant.PERIODS.MONTH,
		],
		enabled: false,
	},
	{
		key: 10,
		keyLabel: DATE_OPTIONS.ABOVEFIVEDAY.key,
		label: DATE_OPTIONS.ABOVEFIVEDAY.label,
		numDays: "",
		defaultPeriod: CommonTimeFrameConstant.PERIODS.FIVEMINUTE,
		periods: [
			CommonTimeFrameConstant.PERIODS.FIVEMINUTE,
			CommonTimeFrameConstant.PERIODS.DAY,
		],
		enabled: false,
	},
	{
		key: 11,
		keyLabel: DATE_OPTIONS.ABOVETWENTYONEDAY.key,
		label: DATE_OPTIONS.ABOVETWENTYONEDAY.label,
		numDays: "",
		defaultPeriod: CommonTimeFrameConstant.PERIODS.DAY,
		periods: [CommonTimeFrameConstant.PERIODS.DAY],
		enabled: false,
	},
	{
		key: 12,
		keyLabel: DATE_OPTIONS.TENYEAR.key,
		label: DATE_OPTIONS.TENYEAR.label,
		numDays: "3650",
		defaultPeriod: CommonTimeFrameConstant.PERIODS.MONTH,
		periods: [
			CommonTimeFrameConstant.PERIODS.DAY,
			CommonTimeFrameConstant.PERIODS.WEEK,
			CommonTimeFrameConstant.PERIODS.MONTH,
		],
		enabled: true,
	},
	{
		key: CUSTOM_DATE_ID,
		keyLabel: DATE_OPTIONS.CUSTOM_DATE.key,
		label: DATE_OPTIONS.CUSTOM_DATE.label,
		periods: [],
		numDays: "0",
		defaultPeriod: {},
		enabled: true,
	},
];

const UTILITY = {
	findByKey(key) {
		return TIME_FRAMES.find((rs) => rs.key === key);
	},
	findByLabel(label) {
		return TIME_FRAMES.find((rs) => rs.label === label);
	},
};

export const DATEPICKERFILTERSTATE = {
	startDate: false,
	endDate: false,
	dateSpan: CommonTimeFrameConstant.DEFAULT_TIMEFRAME_INDEX,
};

export default {
	TIME_FRAMES,
	UTILITY,
	CUSTOM_DATE_ID,
	CUSTOM_DATE_ID_VALUE,
	DATEPICKERFILTERSTATE,
};
