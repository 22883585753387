import React, { Component } from "react";
import DataTable from "../../../Lib/Table/DataTable";
import styles from "./StatementOverview.module.scss";
import formatter from "../../../../utils/formatUtils";
import { FINANCIAL_STATEMENT_TYPE, PERIOD_TYPE } from "../../../../utils/constants";
import Utils from "utils/utils";
import {
	CHANGE_BALANCESHEEET_COLUMNS,
	FINANCIAL_TABS,
	FINANCIAL_TABS_TOOLTIPS,
	CASHFLOW_ROW,
} from "../FinancialsCard/FinancialsConstants";
import MSSpinner from "../../../Lib/MSSpinner/MSSpinner";
import filterEmptyData from "utils/financialsEmptyColumns";
import utils from "utils/utils";

const StatementOverviewTooltip = (tooltipText, rowText) => {
	return (
		<div>
			<label>{rowText}</label>
			{Utils.buildTooltip(
				`income-statement-${rowText.toLowerCase().replace(" ", "-")}`,
				tooltipText,
				"button"
			)}
		</div>
	);
};

export class StatementOverview extends Component {
	constructor(props) {
		super(props);
		this.state = {
			tableData: {
				headersData: [],
				rowsData: [],
			},
		};
		this.parseTableData = this.parseTableData.bind(this);
	}

	componentDidMount() {
		this.parseTableData(
			this.props.statementType === FINANCIAL_STATEMENT_TYPE.CASH_FLOW
				? this.props.financialStatementsData
				: this.props.financialStatementsKeyData,
			this.props.financialRatiosHistoryData
		);
	}

	componentDidUpdate(prevProps) {
		if (
			this.props.statementType !== prevProps.statementType ||
			this.props.periodType !== prevProps.periodType ||
			this.props.financialStatementsKeyData !== prevProps.financialStatementsKeyData ||
			this.props.financialStatementsData !== prevProps.financialStatementsData ||
			this.props.financialRatiosHistoryData !== prevProps.financialRatiosHistoryData
		) {
			let ydata = this.props.financialStatementsKeyData;
			if (this.props.statementType === FINANCIAL_STATEMENT_TYPE.CASH_FLOW) {
				ydata = this.props.financialStatementsData;
			}
			this.parseTableData(ydata, this.props.financialRatiosHistoryData);
		}
	}

	toolTipText(statementType) {
		if (statementType != null) {
			switch (statementType) {
				case FINANCIAL_TABS.IncomeStatement:
					return FINANCIAL_TABS_TOOLTIPS.ToolTip_IncomeStatement;
				case FINANCIAL_TABS.BalanceSheet:
					return FINANCIAL_TABS_TOOLTIPS.ToolTip_BalanceSheet;
				case FINANCIAL_TABS.CashFlow:
					return FINANCIAL_TABS_TOOLTIPS.ToolTip_CashFlow;
				default:
					return "";
			}
		}
	}

	reverseRowValues = (arr) => {
		if (this.props.statementType !== FINANCIAL_STATEMENT_TYPE.CASH_FLOW) {
			return arr.reverse();
		}
		return arr;
	};

	parseTableData = (ydata, yratioDataComplete) => {
		if (!ydata) {
			return;
		}

		let data = Object.assign(
			{},
			{
				fiscalYears: this.reverseRowValues(
					ydata.fiscalYears ? [...ydata.fiscalYears] : []
				),
				rows: ydata.rows
					? ydata.rows.map((x) => {
							if (x.values) {
								x.values = this.reverseRowValues([...x.values]);
							}
							return x;
					  })
					: [],
				periodEndDates: this.reverseRowValues(
					ydata.periodEndDates ? [...ydata.periodEndDates] : []
				),
				fiscalQuarters: this.reverseRowValues(
					ydata.fiscalQuarters ? [...ydata.fiscalQuarters] : []
				),
				currencies: this.reverseRowValues(ydata.currencies ? [...ydata.currencies] : []),
			}
		);
		let filterValue = this.props.periodType === PERIOD_TYPE.ANNUAL ? "1Y" : "3M";
		let ratioData;
		if (yratioDataComplete && Object.keys(yratioDataComplete).length) {
			let ratioDataComplete = [...yratioDataComplete].reverse();
			ratioData = ratioDataComplete.filter(function (operation) {
				return operation.period === filterValue;
			});
		}

		let tableColumnsArray = [];
		let tableRowsArray = [];
		this.dynamicHeader = [];

		data.fiscalYears.forEach((item, index) =>
			this.createColumnData(
				item,
				index,
				data.fiscalQuarters,
				data.periodEndDates,
				tableColumnsArray,
				data.currencies
			)
		);

		let emptyIteratorLength = data.fiscalQuarters.length;

		data.rows.forEach((item, index) =>
			this.createRowData(
				item,
				index,
				tableRowsArray,
				ratioData,
				data.rows.length,
				emptyIteratorLength
			)
		);

		let filteredRowsArr =
			this.props.statementType === FINANCIAL_STATEMENT_TYPE.CASH_FLOW
				? this.filterCashFlowRowData(tableRowsArray, emptyIteratorLength)
				: tableRowsArray;
		if (filteredRowsArr.length > 0) {
			let obj = filterEmptyData.filterEmptyData(
				filteredRowsArr,
				emptyIteratorLength,
				tableColumnsArray
			);
			this.setState({ tableData: obj });
		} else {
			this.setState({
				tableData: {
					headersData: tableColumnsArray,
					rowsData: filteredRowsArr,
				},
			});
		}
	};

	filterCashFlowRows(emptyIteratorLength, cellValue) {
		const tempRowArray = [];
		tempRowArray.push({ CellValue: cellValue });

		for (let count = 0; count < emptyIteratorLength; count++) {
			tempRowArray.push({ CellValue: formatter.options.errorString });
		}

		return tempRowArray;
	}

	createCashFlowReturnArray(tableRowsArray) {
		let returnArray = [];
		tableRowsArray.forEach((item) => {
			let cellVal = item && item[0] && item[0].CellValue;
			if (cellVal === CASHFLOW_ROW.OCF.shortName) {
				returnArray[0] = item;
			}
			if (cellVal === CASHFLOW_ROW.ICF.shortName) {
				returnArray[1] = item;
			}
			if (cellVal === CASHFLOW_ROW.FCF.shortName) {
				returnArray[2] = item;
			}
			if (returnArray.length === 3) return;
		});

		return returnArray;
	}

	filterCashFlowRowData(tableRowsArray, emptyIteratorLength) {
		let investingCashFlow = false;
		let operatingCashFlowExists = false;

		for (var rowData of tableRowsArray) {
			let val = rowData && rowData[0] && rowData[0].CellValue;
			if (val === CASHFLOW_ROW.ICF.shortName) {
				investingCashFlow = true;
			} else if (val === CASHFLOW_ROW.OCF.shortName) {
				operatingCashFlowExists = true;
			}
		}

		if (!operatingCashFlowExists) {
			tableRowsArray.push(
				this.filterCashFlowRows(emptyIteratorLength, CASHFLOW_ROW.OCF.shortName)
			);
		}

		if (!investingCashFlow) {
			tableRowsArray.push(
				this.filterCashFlowRows(emptyIteratorLength, CASHFLOW_ROW.ICF.shortName)
			);

			tableRowsArray.push(
				this.filterCashFlowRows(emptyIteratorLength, CASHFLOW_ROW.FCF.shortName)
			);
		}
		return this.createCashFlowReturnArray(tableRowsArray);
	}

	createColumnData(item, index, fiscalQuarters, periodEndDates, colsArr, currencies) {
		let colObj = {};
		if (index === 0) {
			colObj.CellValue = ``;
			colsArr.push(colObj);
			colObj = {};
		}
		if (this.props.periodType === "Annual") {
			colObj.CellValue =
				item +
				(utils.getUniqueCurrencies(currencies).length === 1
					? ""
					: `<br/> (${currencies[index]})`);
		} else if (this.props.periodType === PERIOD_TYPE.INTERIM) {
			colObj.CellValue = `Q${fiscalQuarters[index]} ${item}`;
		} else {
			colObj.CellValue = "";
		}
		colsArr.push(colObj);
	}

	mapRowHeaders(item) {
		const rowObj = {};
		const isMapBalanceSheetRowHeader =
			this.props.statementType === FINANCIAL_STATEMENT_TYPE.BALANCE_SHEET &&
			item.dataPointLabel ===
				CHANGE_BALANCESHEEET_COLUMNS.Total_Liabilities.apiColumnName;

		if (isMapBalanceSheetRowHeader)
			rowObj.CellValue = CHANGE_BALANCESHEEET_COLUMNS.Total_Liabilities.displayName;
		else {
			const dataPointLabel = Object.keys(CASHFLOW_ROW).filter(
				(x) => CASHFLOW_ROW[x].description === item.dataPointLabel
			);
			rowObj.CellValue = dataPointLabel.length
				? CASHFLOW_ROW[dataPointLabel].shortName
				: item.dataPointLabel;
		}

		return rowObj;
	}

	createFormattedRowArray(item, emptyIterationLength) {
		const tempRowArray = [];
		tempRowArray.push(this.mapRowHeaders(item));

		if (item.values) {
			item.values.forEach((inneritem) => {
				let currency =
					this.props.statementType === FINANCIAL_STATEMENT_TYPE.CASH_FLOW
						? this.props.financialStatementsData?.currencies[0]
						: this.props.financialStatementsKeyData?.currencies[0];
				tempRowArray.push({
					CellValue: formatter.currencyMagnitude(
						inneritem,
						2,
						currency === "USD" ? "USD" : " "
					),
				});
			});
		} else {
			for (let count = 0; count < emptyIterationLength; count++) {
				tempRowArray.push({ CellValue: formatter.options.errorString });
			}
		}

		return tempRowArray;
	}

	createRowData(item, index, tableRowsArray, ratioData, noOfItems, emptyIterationLength) {
		let tooltipText = this.toolTipText(this.props.statementType);
		let tempRowObject = {};
		let tempRowArray = [];

		tableRowsArray.push(this.createFormattedRowArray(item, emptyIterationLength));

		if (
			ratioData &&
			this.props.statementType !== FINANCIAL_STATEMENT_TYPE.CASH_FLOW &&
			noOfItems - 1 === index
		) {
			tempRowObject = {};
			tempRowArray = [];
			if (this.props.statementType === FINANCIAL_STATEMENT_TYPE.BALANCE_SHEET) {
				tempRowObject.CellValue = StatementOverviewTooltip(tooltipText, "Debt to Assets");
			} else if (this.props.statementType === FINANCIAL_STATEMENT_TYPE.INCOME_STATEMENT) {
				tempRowObject.CellValue = StatementOverviewTooltip(tooltipText, "Profit Margin");
			} else {
				tempRowObject.CellValue = "";
			}
			tempRowArray.push(tempRowObject);
			tempRowObject = {};
			for (const ratio of ratioData) {
				let ratioValue;
				if (this.props.statementType === FINANCIAL_STATEMENT_TYPE.INCOME_STATEMENT) {
					ratioValue = ratio.margin.netProfitNormalized;
				} else if (this.props.statementType === FINANCIAL_STATEMENT_TYPE.BALANCE_SHEET) {
					ratioValue = ratio.leverage.debtToAssets;
				}
				tempRowObject.CellValue = formatter.percent(ratioValue, 2, {
					showSign: false,
				});
				tempRowArray.push(tempRowObject);
				tempRowObject = {};
			}
			tableRowsArray.push(tempRowArray);
		}
	}

	render() {
		let isContentReady =
			(this.props.statementType === FINANCIAL_STATEMENT_TYPE.CASH_FLOW &&
				this.props.financialStatementsData !== null) ||
			this.props.statementType !== FINANCIAL_STATEMENT_TYPE.CASH_FLOW ||
			(this.props.financialStatementsKeyData !== null &&
				this.props.financialRatiosHistoryData !== null);

		return (
			<MSSpinner style={styles.spinnerStyle} ready={isContentReady} spinnerSize={"2x"}>
				<div className={styles.incomeStatementTable}>
					<DataTable
						headers={this.state.tableData.headersData}
						rows={this.state.tableData.rowsData}
					/>
				</div>
			</MSSpinner>
		);
	}
}

export default StatementOverview;
