import { createReducer } from "reduxsauce";
import actionTypes from "../actions/actionTypes";
import ApiStates from "../apiStates";
import { API_TRANSITIONS, getApiTransitionAction } from "../apiTransitionUtils";

const INITIAL_STATE = {
	optionsDataStatus: ApiStates.IDLE,
	xrefDataStatus: ApiStates.IDLE,
	xrefOptionDataStatus: ApiStates.IDLE,
	dividendsDataStatus: ApiStates.IDLE,
	historicalDividendsDataStatus: ApiStates.IDLE,
	ratiosDataStatus: ApiStates.IDLE,
	ratiosHistoryDataStatus: ApiStates.IDLE,
	treasuryYieldDataStatus: ApiStates.IDLE,
	expirationDatesStatus: ApiStates.IDLE,
	earningsDataStatus: ApiStates.IDLE,
	userInfoStatus: ApiStates.IDLE,
	strikesFilterCount: "10",
	customStrikePrice: {
		low: "",
		high: "",
	},
	expirationFilterDates: [],
	showMonthlyExpiration: false,
	optionsPageParams: {},
	isMobile: false,
	earnings: {},
	selectedOption: {},
	isOptionsTradeEnabled: false,
	dividendsData: {},
	historicalDividendsData: {},
	financialRatiosData: {},
	financialRatiosHistoryData: {},
};

export const optionsTransition = (state = INITIAL_STATE, { name }) => {
	// NOSONAR
	const action = getApiTransitionAction(state.optionsDataStatus, name);
	if (action) {
		// execute action
		return action.apply(API_TRANSITIONS, [state, { key: "optionsDataStatus" }]);
	}
	// return existing state if no action found
	return { ...state };
};

const xrefTransition = (state = INITIAL_STATE, { name }) => {
	// NOSONAR
	const action = getApiTransitionAction(state.xrefDataStatus, name);
	if (action) {
		// execute action
		return action.apply(API_TRANSITIONS, [state, { key: "xrefDataStatus" }]);
	}
	// return existing state if no action found
	return { ...state };
};

const xrefOptionTransition = (state = INITIAL_STATE, { name }) => {
	// NOSONAR
	const action = getApiTransitionAction(state.xrefOptionDataStatus, name);
	if (action) {
		// execute action
		return action.apply(API_TRANSITIONS, [state, { key: "xrefOptionDataStatus" }]);
	}
	// return existing state if no action found
	return { ...state };
};

export const dividendsTransition = (state = INITIAL_STATE, { name }) => {
	// NOSONAR
	const action = getApiTransitionAction(state.dividendsDataStatus, name);
	if (action) {
		// execute action
		return action.apply(API_TRANSITIONS, [state, { key: "dividendsDataStatus" }]);
	}
	// return existing state if no action found
	return { ...state };
};

export const historicalDividendsTransition = (state = INITIAL_STATE, { name }) => {
	// NOSONAR
	const action = getApiTransitionAction(state.historicalDividendsDataStatus, name);
	if (action) {
		// execute action
		return action.apply(API_TRANSITIONS, [
			state,
			{ key: "historicalDividendsDataStatus" },
		]);
	}
	// return existing state if no action found
	return { ...state };
};

export const ratiosTransition = (state = INITIAL_STATE, { name }) => {
	// NOSONAR
	const action = getApiTransitionAction(state.ratiosDataStatus, name);
	if (action) {
		// execute action
		return action.apply(API_TRANSITIONS, [state, { key: "ratiosDataStatus" }]);
	}
	// return existing state if no action found
	return { ...state };
};

export const ratiosHistoryTransition = (state = INITIAL_STATE, { name }) => {
	// NOSONAR
	const action = getApiTransitionAction(state.ratiosHistoryDataStatus, name);
	if (action) {
		// execute action
		return action.apply(API_TRANSITIONS, [state, { key: "ratiosHistoryDataStatus" }]);
	}
	// return existing state if no action found
	return { ...state };
};

export const earningsTransition = (state = INITIAL_STATE, { name }) => {
	// NOSONAR
	const action = getApiTransitionAction(state.earningsDataStatus, name);
	if (action) {
		// execute action
		return action.apply(API_TRANSITIONS, [state, { key: "earningsDataStatus" }]);
	}
	// return existing state if no action found
	return { ...state };
};

export const expirationDatesTransition = (state = INITIAL_STATE, { name }) => {
	// NOSONAR
	const action = getApiTransitionAction(state.expirationDatesStatus, name);
	if (action) {
		// execute action
		return action.apply(API_TRANSITIONS, [state, { key: "expirationDatesStatus" }]);
	}
	// return existing state if no action found
	return { ...state };
};

export const treasuryYieldTransition = (state = INITIAL_STATE, { name }) => {
	// NOSONAR
	const action = getApiTransitionAction(state.treasuryYieldDataStatus, name);
	if (action) {
		// execute action
		return action.apply(API_TRANSITIONS, [state, { key: "treasuryYieldDataStatus" }]);
	}
	// return existing state if no action found
	return { ...state };
};

export const userInfoTransition = (state = INITIAL_STATE, { name }) => {
	// NOSONAR
	const action = getApiTransitionAction(state.userInfoStatus, name);
	if (action) {
		// execute action
		return action.apply(API_TRANSITIONS, [state, { key: "userInfoStatus" }]);
	}
	// return existing state if no action found
	return { ...state };
};

// FILTERS
export const updateStrikesCountFilter = (state = INITIAL_STATE, { strikeCount }) => {
	// NOSONAR
	if (!strikeCount) {
		return { ...state };
	}

	return {
		...state,
		strikesFilterCount: strikeCount,
	};
};

export const updateCustomPrice = (state = INITIAL_STATE, { customPrice }) => {
	// NOSONAR

	return {
		...state,
		customStrikePrice: customPrice,
	};
};

const defaultSelectedDate = (dates) => {
	if (!dates?.length) {
		return null;
	}
	const dateObj = dates[0];
	const defaultSelected = {};
	const key = `1-${dateObj.expirationDate}-${dateObj.contractTerm}`;
	//used to help filter the options when weekly and monthly options fall on the same date.
	if (dates[0].expirationDate === dates[1].expirationDate) {
		defaultSelected[key] = {
			expirationDate: dateObj.expirationDate,
			contractTerm: dateObj.contractTerm,
			isAdjusted: dateObj.isAdjusted,
			duplicateDate: true,
		};
	} else {
		defaultSelected[key] = {
			expirationDate: dateObj.expirationDate,
			contractTerm: dateObj.contractTerm,
			isAdjusted: dateObj.isAdjusted,
		};
	}

	return defaultSelected;
};

export const updateExpirationDatesFilter = (state = INITIAL_STATE, { dates }) => {
	// NOSONAR
	if (!dates) {
		return { ...state };
	}
	const { selectedExpirationDates = defaultSelectedDate(dates) } = state;
	return {
		...state,
		expirationFilterDates: dates,
		selectedExpirationDates,
	};
};

//value is object of expiration date {expirationDate, contractTerm, isAdjusted}
export const updateExpirationDateSelected = (state = INITIAL_STATE, { value }) => {
	// NOSONAR
	//for mobile only single selection allowed
	const tempSelectedDates =
		state.isMobile || !state.selectedExpirationDates
			? {}
			: { ...state.selectedExpirationDates };
	//remove item
	const index = Object.keys(tempSelectedDates).length + 1;
	const key = `${value.expirationDate}-${value.contractTerm}`;

	var Reg = new RegExp(key, "i");
	let foundKey = null;
	for (const dateKey in tempSelectedDates) {
		if (Reg.test(dateKey)) {
			foundKey = dateKey;
		}
	}
	if (tempSelectedDates && foundKey) {
		delete tempSelectedDates[foundKey];
	} else {
		// add item
		tempSelectedDates[`${index}-${key}`] = value;
	}

	return {
		...state,
		selectedExpirationDates: tempSelectedDates,
	};
};

// used for desktop toggle and mobile drawer menu
export const updateShowMonthlyExpiration = (
	state = INITIAL_STATE,
	{ showMonthlyExpiration }
) => {
	// NOSONAR
	//note this will also trigger updateExpirationDatesFilter event which will then reset selectedExpirationDates
	// toggle showMonthlyExpiration state if no updated value is passed in, otherwise set the state to the updated value
	const newMonthlyExpirationState =
		typeof showMonthlyExpiration !== "undefined" && showMonthlyExpiration !== null
			? showMonthlyExpiration
			: !state.showMonthlyExpiration;

	return {
		...state,
		selectedExpirationDates: undefined,
		showMonthlyExpiration: newMonthlyExpirationState,
	};
};

export const updateEarningsData = (state = INITIAL_STATE, { earnings }) => {
	// NOSONAR
	if (!earnings) {
		return { ...state };
	}

	return {
		...state,
		earnings: earnings,
	};
};

export const updateOptionsViewType = (state = INITIAL_STATE, { view }) => {
	// NOSONAR
	if (!view) {
		return { ...state };
	}
	const params = {
		...state.optionsPageParams,
		optionViewType: view,
	};
	return {
		...state,
		optionsPageParams: params,
	};
};

export const updateOptionsPageParams = (state = INITIAL_STATE, { params }) => {
	// NOSONAR
	if (!params) {
		return { ...state };
	}
	return {
		...state,
		isMobile: params.viewType === "mobile",
		optionsPageParams: params,
	};
};

// OPTIONS TABLE EVENTS
export const updateSelectedOptionData = (state = INITIAL_STATE, { selectedOption }) => {
	// NOSONAR
	if (!selectedOption) {
		return { ...state };
	}

	return {
		...state,
		selectedOption: selectedOption,
	};
};

// USER INFO
export const updateIsOptionsTradeEnabled = (
	state = INITIAL_STATE,
	{ isOptionsTradeEnabled }
) => {
	// NOSONAR
	if (typeof isOptionsTradeEnabled === "undefined" || isOptionsTradeEnabled === null) {
		return { ...state };
	}

	return {
		...state,
		isOptionsTradeEnabled: isOptionsTradeEnabled,
	};
};

export const updateDividendsData = (state = INITIAL_STATE, { dividendsData }) => {
	// NOSONAR
	if (!dividendsData) {
		return { ...state };
	}

	return {
		...state,
		dividendsData: dividendsData,
	};
};

export const updateHistoricalDividendsData = (
	state = INITIAL_STATE,
	{ historicalDividendsData }
) => {
	// NOSONAR
	if (!historicalDividendsData) {
		return { ...state };
	}

	return {
		...state,
		historicalDividendsData: historicalDividendsData,
	};
};

export const updateFinancialRatiosData = (
	state = INITIAL_STATE,
	{ financialRatiosData }
) => {
	// NOSONAR
	if (!financialRatiosData) {
		return { ...state };
	}

	return {
		...state,
		financialRatiosData: financialRatiosData,
	};
};

export const updateFinancialRatiosHistoryData = (
	state = INITIAL_STATE,
	{ financialRatiosHistoryData }
) => {
	// NOSONAR
	if (!financialRatiosHistoryData) {
		return { ...state };
	}

	return {
		...state,
		financialRatiosHistoryData: financialRatiosHistoryData,
	};
};

export const HANDLERS = {
	[actionTypes.OPTIONS_API_TRANSITION]: optionsTransition,
	[actionTypes.XREF_API_TRANSITION]: xrefTransition,
	[actionTypes.XREF_OPTION_API_TRANSITION]: xrefOptionTransition,
	[actionTypes.DIVIDENDS_API_TRANSITION]: dividendsTransition,
	[actionTypes.HISTORICAL_DIVIDENDS_API_TRANSITION]: historicalDividendsTransition,
	[actionTypes.EXP_DATES_API_TRANSITION]: expirationDatesTransition,
	[actionTypes.EARNINGS_API_TRANSITION]: earningsTransition,
	[actionTypes.TREASURY_YIELD_API_TRANSITION]: treasuryYieldTransition,
	[actionTypes.USER_INFO_API_TRANSITION]: userInfoTransition,
	[actionTypes.STRIKES_FILTER_COUNT_UPDATE]: updateStrikesCountFilter,
	[actionTypes.STRIKES_CUSTOM_LOW_HIGH_UPDATE]: updateCustomPrice,
	[actionTypes.SAVE_EXP_FILTER_DATES]: updateExpirationDatesFilter,
	[actionTypes.EXP_DATES_SELECTED]: updateExpirationDateSelected,
	[actionTypes.UPDATE_SHOW_MONTHLY_EXPIRATION]: updateShowMonthlyExpiration,
	[actionTypes.SAVE_EARNINGS_DATA]: updateEarningsData,
	[actionTypes.UPDATE_OPTIONS_PAGE_PARAMS]: updateOptionsPageParams,
	[actionTypes.UPDATE_OPTIONS_VIEW_TYPE]: updateOptionsViewType,
	[actionTypes.SAVE_SELECTED_OPTION_DATA]: updateSelectedOptionData,
	[actionTypes.SAVE_IS_OPTIONS_TRADE_ENABLED]: updateIsOptionsTradeEnabled,
	[actionTypes.FINANCIAL_RATIOS_API_TRANSITION]: ratiosTransition,
	[actionTypes.FINANCIAL_RATIOS_HISTORY_API_TRANSITION]: ratiosHistoryTransition,
	[actionTypes.SAVE_DIVIDENDS_DATA]: updateDividendsData,
	[actionTypes.SAVE_HISTORICAL_DIVIDENDS_DATA]: updateHistoricalDividendsData,
	[actionTypes.SAVE_FINANCIAL_RATIOS_DATA]: updateFinancialRatiosData,
	[actionTypes.SAVE_FINANCIAL_RATIOS_HISTORY_DATA]: updateFinancialRatiosHistoryData,
};

export default createReducer(INITIAL_STATE, HANDLERS);
