import React from "react";

import styles from "../PeerComparisonCard.module.scss";

import utils from "utils/utils";
import formatter from "utils/formatUtils";

import NoData from "components/Lib/NoData/NoData";
import SimpleSpinner from "components/Lib/SimpleSpinner/SimpleSpinner";

const PeerComparisonProfitabilityCard = (props) => {
	const populatePeersData = () => {
		const horizontalPeerData = [];

		horizontalPeerData.push({
			header: "Gross Profit Margin % (TTM)",
			values: props.peersFinancialRatiosQueries.map(
				(query) => query.data?.grossProfitMarginTTM
			),
		});

		horizontalPeerData.push({
			header: "Operating Profit Margin % (TTM)",
			values: props.peersFinancialRatiosQueries.map(
				(query) => query.data?.operatingProfitMarginTTM
			),
		});

		horizontalPeerData.push({
			header: "Return on Equity % (TTM)",
			values: props.peersFinancialRatiosQueries.map(
				(query) => query.data?.returnOnEquityTTM
			),
		});

		horizontalPeerData.push({
			header: "Cash Flow Margin % (TTM)",
			values: props.peersFinancialRatiosQueries.map(
				(query) => query.data?.cashFlowMarginTTM
			),
		});

		horizontalPeerData.push({
			header: "Cash Flow / Share (TTM)",
			values: props.peersFinancialRatiosQueries.map((query) =>
				displayCurrency(query.data?.currencyId, query.data?.cashFlowPerShare)
			),
		});

		horizontalPeerData.push({
			header: "Cash / Share (MRQ)",
			values: props.peersFinancialRatiosQueries.map((query) =>
				displayCurrency(query.data?.currencyId, query.data?.cashPerShare)
			),
		});

		return horizontalPeerData.map((peer, index) => {
			return (
				<tr key={`peers-profitability-${index}`}>
					<th className={styles.headcol}>{peer.header}</th>
					{peer.values.map((value, index) => {
						return (
							<td key={index} className={index === 0 ? styles.primarySymbolColumn : ""}>
								{value ? value : formatter.options.errorString}
							</td>
						);
					})}
				</tr>
			);
		});
	};

	const displayCurrency = (currencyId, currency) => {
		return currency !== "—"
			? (currencyId === "USD" ? "$" : currencyId + " ") + currency
			: currency;
	};

	// render helpers
	const renderPeerComparisonProfitabilityTable = () => {
		if (props.venueXids === null) {
			return <SimpleSpinner />;
		} else if (!props.symbol || props.venueXids?.length <= 1) {
			return (
				<NoData>
					{`Peer Comparison is not available ${
						props.symbol ? `for ${props.symbol}` : ""
					}`}
				</NoData>
			);
		} else {
			return (
				<div className={styles.dvPeerComparison}>
					<table className={styles.peersBeforeTable}>
						<caption className="sr-only">Peer Comparison</caption>
						<tbody>
							<tr className={styles.topRow}>
								<th className={`${styles.headcol} ${styles.lastPrice}`}>
									<div>Last Price</div>
								</th>
								{props.peersGeneralInformationQueries.map(
									(peersGeneralInfoQuery, index) => {
										return (
											<td
												key={`peer-comparison-profitability-${index}`}
												className={
													index === 0
														? `${styles.primarySymbolColumn} ${styles.primarySymbolColumnHighlight}`
														: styles.peerCompareSymbol
												}
											>
												<div className={styles.ranking}>
													{index === 0 ? (
														<span className={styles.symbols}>{props.symbol}</span>
													) : (
														<a
															className={styles.symbols}
															href={utils.replaceSymbolInUrl(
																peersGeneralInfoQuery.data?.symbol
															)}
														>
															{peersGeneralInfoQuery.data?.symbol}
														</a>
													)}
												</div>
												<div>
													{utils.buildTooltip(
														"peerComparison-companyName-tooltip-desc",
														peersGeneralInfoQuery.data?.companyName,
														"text",
														<div className={styles.dvCompanyNameContainer}>
															<span
																className={styles.dvCompanyName}
																aria-label="tool tip"
																aria-describedby={
																	"peerComparison-companyName-tooltip-desc"
																}
															>
																{peersGeneralInfoQuery.data?.companyName}
															</span>
														</div>
													)}
												</div>
												<div className={styles.lastPrice}>
													{props.peersQuoteQueries?.length &&
														props.peersQuoteQueries[index]?.data?.lastPrice}
												</div>
											</td>
										);
									}
								)}
							</tr>
							{populatePeersData()}
						</tbody>
					</table>
				</div>
			);
		}
	};

	return (
		<div className={styles.dataContainer}>{renderPeerComparisonProfitabilityTable()}</div>
	);
};

//custom comparison for props that are passed down as objects
const deepComparison = (prevProps, nextProps) => {
	return (
		prevProps.peersQuoteQueries === nextProps.peersQuoteQueries &&
		prevProps.peersFinancialRatiosQueries === nextProps.peersFinancialRatiosQueries &&
		prevProps.peersGeneralInformationQueries === nextProps.peersGeneralInformationQueries
	);
};

export default React.memo(PeerComparisonProfitabilityCard, deepComparison);
