import React, { useState, useEffect } from "react";
import InputConfig from "./IndicatorInputsConfig";
import { Modal } from "react-bootstrap";

import ColorPallete from "components/Lib/ColorPallete/ColorPallete";
import LineDropdown from "components/Lib/LineDropdown/LineDropdown";

import styles from "./IndicatorInputPopUp.module.scss";
import MSDropdown from "components/Lib/MSDropdown/MSDropdown";
import { ReactComponent as Close } from "assets/icons/Close.svg";
import { IndicatorFormatter } from "./IndicatorMappers";
import { MergedIndicatorConfig } from "./IndicatorMapperEdit";

const IndicatorInputPopUp = React.memo((props) => {
	const { indicator } = props;
	let { defaultLineStyle } = indicator;
	let config = indicator.config
		? MergedIndicatorConfig[indicator.config.id](indicator.config)
		: InputConfig.INDICATOR_INPUT_CONFIG[indicator.name];
	let isEdit = indicator.config ? true : false;

	const { inputs } = config;
	const inputList = inputs;
	const [textDict, setTextDict] = useState({});
	const [dropDownDict, setDropDownDict] = useState({});
	const [colorDict, setColorDict] = useState({});
	const [lineDict, setLineDict] = useState({});

	const handleDropdownCallback = (name, data) => {
		dropDownDict[name] = data;
		setDropDownDict(dropDownDict);
	};

	const colorSelectionCallback = (data) => {
		colorDict[data.name] = data.color;
		setColorDict(colorDict);
	};

	const lineSelectionCallback = (data) => {
		lineDict[data.name] = data;
		setLineDict(lineDict);
	};

	const onTextNumberChange = (e, range) => {
		const reg = /^[0-9\b]+$/;
		if (e.value !== "") {
			if (!reg.test(e.value)) {
				e.value = textDict[e.name];
				return;
			}
			if (
				parseInt(e.value) < parseInt(range[0]) ||
				parseInt(e.value) > parseInt(range[1])
			) {
				let elem = document.getElementById(e.name);
				elem.removeAttribute("hidden");
				textDict[e.name] = "";
				return;
			} else {
				let elem = document.getElementById(e.name);
				elem.setAttribute("hidden", true);
			}
		}
		textDict[e.name] = e.value;
		setTextDict(textDict);
	};
	const setDefaultValues = (type, input) => {
		switch (input.type) {
			case "number":
			case "text":
				textDict[input.name] = input.default;
				break;
			case "color":
				colorDict[input.name] = input.default;
				break;
			case "linestyle":
				colorDict[input.color.name] = input.color.default;
				lineDict[input.line.name] = input.line.default;
				break;
			case "dropdown":
				dropDownDict[input.name] = input.selected;
				break;
			default:
				return;
		}
	};

	let inputItems = Object.keys(inputList).map((key) => {
		let input = inputList[key];
		switch (key.replace(/[0-9]/g, "")) {
			case "number":
				setDefaultValues("number", input);
				return (
					<div key={key}>
						<div className={styles.flexibleDiv}>
							<label className={styles.lblLineStyle}>{input.name}</label>
							<input
								className={styles.txtLineStyle}
								type="text"
								name={input.name}
								onChange={({ currentTarget }) =>
									onTextNumberChange(currentTarget, input.range)
								}
								defaultValue={input.default}
								aria-label={input.name}
								tabIndex="0"
								onKeyDown={(event) =>
									["e", "E", ".", "-"].includes(event.key) && event.preventDefault()
								}
							></input>
						</div>
						<label className={styles.errorMsg} id={input.name} hidden={true}>
							Value must be a whole number between {input.range[0]} and {input.range[1]}.
						</label>
					</div>
				);
			case "color":
				let defaultColor =
					(!isEdit &&
						indicator.name === "bollinger" &&
						input.name === "Median" &&
						defaultLineStyle.color) ||
					input.default;
				let inputDefaultColor = {
					default: defaultColor,
					name: input.name,
					type: "color",
				};
				setDefaultValues("color", inputDefaultColor);
				return (
					<div key={key} className={styles.flexibleDiv}>
						<label className={styles.lblLineStyle}>{input.name}</label>
						<ColorPallete
							className={styles.txtLineStyle}
							onSelected={colorSelectionCallback}
							id={input.name}
							name={input.name}
							defaultValue={defaultColor}
						/>
					</div>
				);
			case "linestyle":
				let width = (!isEdit && defaultLineStyle.width) || input.line.default.width;
				let linedash =
					(!isEdit && defaultLineStyle.lineDash) || input.line.default.linedash;
				let color = (!isEdit && defaultLineStyle.color) || input.color.default;
				let defaultInputlineStyle = {
					type: "linestyle",
					color: { name: input.color.name, default: color },
					line: { name: input.line.name, default: { width: width, linedash: linedash } },
				};
				setDefaultValues("linestyle", defaultInputlineStyle);
				return (
					<div key={key} className={styles.flexibleDiv}>
						<label className={styles.lblLineStyle}>{input.line.name}</label>
						<LineDropdown
							className={styles.txtLineStyle}
							id={input.line.name}
							name={input.line.name}
							width={width}
							linedash={linedash}
							onSelected={lineSelectionCallback}
						/>
						<ColorPallete
							className={styles.txtLineStyle}
							onSelected={colorSelectionCallback}
							id={input.color.name}
							name={input.color.name}
							defaultValue={color}
						/>
					</div>
				);
			case "dropdown":
				setDefaultValues("dropdown", input);
				return (
					<div key={key} className={styles.flexibleDiv}>
						<label className={styles.lblLineStyle}>{input.name}</label>
						<label aria-label={input.name} style={{ marginBottom: "0rem" }}>
							<MSDropdown
								className={styles.dropdown}
								data={input.default}
								title={input.selected}
								onSelectCallback={(data) => handleDropdownCallback(input.name, data)}
							></MSDropdown>
						</label>
					</div>
				);
			default:
				return <></>;
		}
	});
	const onApply = () => {
		try {
			let isValid = false;
			Object.keys(textDict).forEach((key) => {
				if (textDict[key] === "") {
					let elem = document.getElementById(key);
					elem.removeAttribute("hidden");
					if (!isValid) {
						isValid = true;
					}
				}
			});
			if (isValid) {
				throw Error.name;
			}
		} catch (e) {
			return e;
		}
		let indicatorObjFormatter =
			IndicatorFormatter[indicator.config ? indicator.config.id : indicator.name];
		props.onApply(
			indicatorObjFormatter({
				dropDownDict,
				textDict,
				lineDict,
				colorDict,
				inputs,
				indicator,
			})
		);
		props.onClose();
	};

	// Below code to set the focus in Modal popup only when opened in dialog
	useEffect(() => {
		const focusableElements =
			'button, MSDropdown, LineDropdown, ColorPallete, input, select, textarea, [tabindex]:not([tabindex="-1"])';
		const modal = document.querySelector("#indicatorModal"); // select the modal by it's id
		const firstFocusableElement = modal.querySelectorAll(focusableElements)[0]; // get first element to be focused inside modal
		const focusableContent = modal.querySelectorAll(focusableElements);
		const lastFocusableElement = focusableContent[focusableContent.length - 1]; // get last element to be focused inside modal

		document.addEventListener("keydown", function (e) {
			let isTabPressed = e.key === "Tab" || e.code === "Tab";

			if (!isTabPressed) {
				return;
			}

			if (e.shiftKey) {
				// if shift key pressed for shift + tab combination
				if (document.activeElement === firstFocusableElement) {
					lastFocusableElement.focus(); // add focus for the last focusable element
					e.preventDefault();
				}
			} else {
				// if tab key is pressed
				if (document.activeElement === lastFocusableElement) {
					// if focused has reached to last focusable element then focus first focusable element after pressing tab
					firstFocusableElement.focus(); // add focus for the first focusable element
					e.preventDefault();
				}
			}
		});
		firstFocusableElement.focus();
	}, []);

	return (
		<Modal id="indicatorModal" className={styles.modal} show={!!props.onClose}>
			<Modal.Header className={styles.modalHeader}>
				<Modal.Title className={styles.header}>{config.name} </Modal.Title>
				<button
					aria-label="Close"
					className={styles.crossButton}
					tabIndex={0}
					onClick={props.onClose}
				>
					<Close style={{ height: 16, width: 16 }} />
				</button>
			</Modal.Header>
			<Modal.Body className={styles.modalBody}>
				<div className={styles.modalContent}>{inputItems}</div>
				<div className={styles.applyButtonDiv}>
					<button className={styles.applyButton} type="button" onClick={onApply}>
						Apply
					</button>
				</div>
			</Modal.Body>
		</Modal>
	);
});

export default IndicatorInputPopUp;
