const INDICATOR_INPUT_CONFIG = {
	bollinger: {
		name: "Bollinger Bands",
		inputs: {
			number0: {
				name: "Period",
				type: "text",
				default: "20",
				range: [0, 99],
			},
			number1: {
				name: "Standard Deviation",
				type: "text",
				default: "2",
				range: [0, 10],
			},
			linestyle0: {
				line: {
					name: "Line Style",
					type: "line",
					default: { width: 1, linedash: "" },
				},
				color: {
					name: "Line Color",
					type: "color",
					default: "#333333",
				},
			},
			linestyle1: {
				line: {
					name: "Median",
					type: "line",
					default: { width: 1, linedash: "" },
				},
				color: {
					name: "Median Line Color",
					type: "color",
					default: "#333333",
				},
			},
			color1: {
				name: "Band (fill)",
				type: "color",
				default: "#F5F5F5",
			},
		},
	},
	mae: {
		name: "Moving Average Envelops (MAE)",
		inputs: {
			number0: {
				name: "Period",
				type: "text",
				default: "20",
				range: [0, 99],
			},
			number1: {
				name: "Percent",
				type: "text",
				default: "6",
				range: [0, 99],
			},
			dropdown: {
				name: "Average Type",
				type: "dropdown",
				selected: "Simple (SMA)",
				default: [
					{ option: "Exponential (EMA)" },
					{ option: "Simple (SMA)" },
					{ option: "Weighted (WMA)" },
				],
			},
			linestyle: {
				line: {
					name: "Line Style",
					type: "line",
					default: { width: 1, linedash: "" },
				},
				color: {
					name: "Line Color",
					type: "color",
					default: "#333333",
				},
			},
			color: {
				name: "Band (fill)",
				type: "color",
				default: "#F5F5F5",
			},
		},
	},
	sma: {
		name: "Simple Moving Average (SMA)",
		inputs: {
			number: {
				name: "Period",
				type: "text",
				default: "9",
				range: [0, 200],
			},
			linestyle: {
				line: {
					name: "Line Style",
					type: "line",
					default: { width: 1, linedash: "" },
				},
				color: {
					name: "Line Color",
					type: "color",
					default: "#333333",
				},
			},
		},
	},
	volume: {
		name: "Volume",
		inputs: {
			color0: {
				name: "Up",
				type: "color",
				default: "#006600",
			},
			color1: {
				name: "Down",
				type: "color",
				default: "#AA0000",
			},
		},
	},
	wma: {
		name: "Weighted Moving Average(WMA)",
		inputs: {
			number: {
				name: "Period",
				type: "text",
				default: "9",
				range: [0, 99],
			},
			linestyle: {
				line: {
					name: "Line Style",
					type: "line",
					default: { width: 1, linedash: "" },
				},
				color: {
					name: "Line Color",
					type: "color",
					default: "#333333",
				},
			},
		},
	},
	dividendyield: {
		name: "Dividend Yield",
		inputs: {
			linestyle: {
				line: {
					name: "Line Style",
					type: "line",
					default: { width: 2, linedash: "" },
				},
				color: {
					name: "Line Color",
					type: "color",
					default: "#002B51",
				},
			},
		},
	},
	onbalancevolume: {
		name: "On Balance Volume (OBV)",
		inputs: {
			linestyle: {
				line: {
					name: "Line Style",
					type: "line",
					default: { width: 2, linedash: "" },
				},
				color: {
					name: "Line Color",
					type: "color",
					default: "#002B51",
				},
			},
		},
	},
	peratio: {
		name: "P/E Ratio",
		inputs: {
			linestyle: {
				line: {
					name: "Line Style",
					type: "line",
					default: { width: 2, linedash: "" },
				},
				color: {
					name: "Line Color",
					type: "color",
					default: "#002B51",
				},
			},
		},
	},
	revenues: {
		name: "Revenues",
		inputs: {
			linestyle: {
				line: {
					name: "Line Style",
					type: "line",
					default: { width: 2, linedash: "" },
				},
				color: {
					name: "Line Color",
					type: "color",
					default: "#002B51",
				},
			},
		},
	},
	rollingeps: {
		name: "Rolling EPS",
		inputs: {
			linestyle: {
				line: {
					name: "Line Style",
					type: "line",
					default: { width: 2, linedash: "" },
				},
				color: {
					name: "Line Color",
					type: "color",
					default: "#002B51",
				},
			},
		},
	},
	macd: {
		name: "Moving Average Convergence Divergence (MACD)",
		inputs: {
			number0: {
				name: "Fast",
				type: "text",
				default: "12",
				range: [0, 99],
			},
			number1: {
				name: "Slow",
				type: "text",
				default: "26",
				range: [0, 99],
			},
			number2: {
				name: "Smooth",
				type: "text",
				default: "9",
				range: [0, 99],
			},
			linestyle0: {
				line: {
					name: "MACD",
					type: "line",
					default: { width: 2, linedash: "" },
				},
				color: {
					name: "Line Color",
					type: "color",
					default: "#005C90",
				},
			},
			linestyle1: {
				line: {
					name: "Smooth",
					type: "line",
					default: { width: 2, linedash: "" },
				},
				color: {
					name: "Smooth Line Color",
					type: "color",
					default: "#187ABA",
				},
			},
			linestyle2: {
				line: {
					name: "Increasing Bar",
					type: "line",
					default: { width: 2, linedash: "" },
				},
				color: {
					name: "Increasing Bar Color",
					type: "color",
					default: "#006600",
				},
			},
			linestyle3: {
				line: {
					name: "Decreasing Bar",
					type: "line",
					default: { width: 2, linedash: "" },
				},
				color: {
					name: "Decreasing Bar Color",
					type: "color",
					default: "#AA0000",
				},
			},
		},
	},
	momentum: {
		name: "Momentum",
		inputs: {
			number: {
				name: "Period",
				type: "text",
				default: "9",
				range: [0, 99],
			},
			linestyle: {
				line: {
					name: "Line Style",
					type: "line",
					default: { width: 2, linedash: "" },
				},
				color: {
					name: "Line Color",
					type: "color",
					default: "#002B51",
				},
			},
			color0: {
				name: "Fill Color",
				type: "color",
				default: "#8FD3FF",
			},
		},
	},
	proc: {
		name: "Price Rate of Change (PROC)",
		inputs: {
			number: {
				name: "Period",
				type: "text",
				default: "14",
				range: [0, 99],
			},
			linestyle: {
				line: {
					name: "Line Style",
					type: "line",
					default: { width: 2, linedash: "" },
				},
				color: {
					name: "Line Color",
					type: "color",
					default: "#002B51",
				},
			},
		},
	},
	rsi: {
		name: "Relative Strength Index (RSI)",
		inputs: {
			number: {
				name: "Period",
				type: "text",
				default: "14",
				range: [0, 99],
			},
			linestyle: {
				line: {
					name: "Line Style",
					type: "line",
					default: { width: 2, linedash: "" },
				},
				color: {
					name: "Line Color",
					type: "color",
					default: "#002B51",
				},
			},
			color0: {
				name: "Over Bought Fill",
				type: "color",
				default: "#F5F5F5",
			},
			color1: {
				name: "Over Sold Fill",
				type: "color",
				default: "#F5F5F5",
			},
		},
	},
	vroc: {
		name: "Volume Rate of Change (VROC)",
		inputs: {
			number: {
				name: "Period",
				type: "text",
				default: "14",
				range: [0, 99],
			},
			linestyle: {
				line: {
					name: "Line Style",
					type: "line",
					default: { width: 2, linedash: "" },
				},
				color: {
					name: "Line Color",
					type: "color",
					default: "#002B51",
				},
			},
		},
	},
	indicator: {
		name: "Indicator",
		inputs: [
			{
				name: "input 1 text",
				type: "text",
				default: "value text 1",
			},
			{
				name: "input 1 text",
				type: "text",
				default: "value text 2",
			},
			{
				name: "input 2",
				type: "color",
				default: "#AA0000",
			},
			{
				name: "input 3",
				type: "line",
				default: { width: 1, linedash: "" },
			},
			{
				name: "input 4 dropdown",
				type: "dropdown",
				default: [{ option: "value 1" }, { option: "value 2" }],
			},
			{
				name: "input 5 dropdown",
				type: "dropdown",
				default: [{ option: "value 1" }, { option: "value 2" }],
			},
		],
	},
};

const DEFAULT_USE_LINE_STYLES = [
	{ style: "line2", width: 2, lineDash: "", color: "#187ABA", isAvailable: true },
	{ style: "line4", width: 2, lineDash: "2,4", color: "#AA0000", isAvailable: true },
	{ style: "line3", width: 2, lineDash: "2,4", color: "#002B51", isAvailable: true },
	{
		style: "line2",
		width: 2,
		lineDash: "",
		color: "#333333",
		isAvailable: true,
	},
	{ style: "line2", width: 2, lineDash: "", color: "#D6A241", isAvailable: true },
];

const AverageType = {
	"Exponential (EMA)": "ema",
	"Simple (SMA)": "sma",
	"Weighted (WMA)": "wma",
	ema: "Exponential (EMA)",
	sma: "Simple (SMA)",
	wma: "Weighted (WMA)",
};

module.exports = {
	INDICATOR_INPUT_CONFIG,
	DEFAULT_USE_LINE_STYLES,
	AverageType,
};
