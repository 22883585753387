import axios from "axios";
import Utils from "../utils/utils";
import { CLIENTS } from "../utils/constants";
import * as Sentry from "@sentry/browser";
window.MD = window.MD || {};

// Set up auth and initialize axios
// Only call on initial App load or when we explicitly need to set a new auth token
const init = (newInternalAuthToken) => {
	// Store the auth token in local storage so we can load the iframe in a new tab internally
	if (newInternalAuthToken) {
		window.localStorage.setItem("internalAuthToken", newInternalAuthToken);
	}
	let internalAuthToken = window.localStorage.getItem("internalAuthToken");

	let apiUrl;
	let basePath;

	switch (window.MD.CLIENT) {
		case CLIENTS.MORGAN_STANLEY:
			apiUrl = window.MD.MSO_DOMAIN
				? window.MD.MSO_DOMAIN
				: "https://markets-dev.morganstanleyclientserv.com";
			basePath = `${apiUrl}/ms-research/api`;
			break;
		case undefined && window.location.hostname === "localhost":
			apiUrl = window.MD.MSO_DOMAIN
				? window.MD.MSO_DOMAIN
				: "https://markets-dev.morganstanleyclientserv.com";
			basePath = `${apiUrl}/ms-research/api`;
			break;
		case CLIENTS.CANACCORD_GENUITY:
			apiUrl = window.MD.CG_API_DOMAIN
				? window.MD.CG_API_DOMAIN
				: "https://markets.junodev.finlogik.com";
			basePath = `${apiUrl}/cg-research/api`;
			break;
		default:
			break;
	}

	const config = {
		baseURL: basePath,
		headers: {
			Accept: "application/json",
		},
	};

	// Only add an authorization header if we don't have an access_token cookie.
	// Otherwise, the Bearer token in the auth header will override the cookie value
	// and internal users could see issues when switching between environments or between
	// integrated and non-integrated/storybook.
	const accessTokenCookie = Utils.getCookieValue("access_token");

	if (!accessTokenCookie && internalAuthToken) {
		config.headers = {
			Accept: "application/json",
			authorization: `Bearer ${internalAuthToken}`,
		};
	} else {
		config.withCredentials = true;
	}

	const instance = axios.create(config);
	let CancelToken = axios.CancelToken;
	instance.interceptors.response.use(
		(response) => {
			return response;
		},
		async (error) => {
			const isAuthError = error && error.response && error.response.status === 401;
			const isNetworkError =
				error && error.message && error.message.toLowerCase() === "network error";
			if (isNetworkError || isAuthError) {
				//fetch new token and reload page
				Utils.fetchToken();
				return Promise.resolve({ message: "redirecting" });
			}
			return Promise.reject({ ...error });
		}
	);
	instance.interceptors.response.use(
		(response) => response,
		(error) => {
			Sentry.captureException(error);
			return Promise.reject(error);
		}
	);

	const get = (url, parameters) => instance.get(url, parameters);
	const post = (url, parameters) => instance.post(url, parameters);

	return {
		instance,
		get,
		post,
		config: {
			CancelToken,
		},
		baseUrl: config.baseURL,
		internalAuthToken,
	};
};

export default { init };
