import React from "react";

const LineChart = () => {
	return (
		<svg
			aria-hidden="true"
			focusable="false"
			className="lineChart"
			width="13px"
			height="13px"
			viewBox="0 0 13 13"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g stroke="none" strokeWidth="1" fillRule="evenodd">
				<g
					id="02_MSO-Company-Overview-Interactive-States"
					transform="translate(-210.000000, -708.000000)"
				>
					<g id="Charting-Module" transform="translate(32.000000, 578.000000)">
						<g
							id="Charting-Module-Chart-Controls"
							transform="translate(15.000000, 126.000000)"
						>
							<path
								d="M164.087722,16.9861288 C164.520325,16.9109297 165.232917,16.7173785 166.133366,16.3554003 C167.61692,15.7590149 169.097938,14.8933121 170.480131,13.7069288 C170.86765,13.3743083 171.242593,13.0199635 171.603593,12.6432521 C171.801815,12.436403 171.994379,12.2259056 172.181367,12.0119633 C173.482979,10.5227242 174.469244,8.9279322 175.184752,7.33096232 C175.618126,6.36369686 175.867242,5.60032336 175.975156,5.14122381 C176.092499,4.64200795 175.78293,4.14218786 175.283714,4.02484439 C174.784499,3.90750093 174.284679,4.21706967 174.167335,4.71628553 C174.1494,4.79258591 174.10374,4.95703788 174.025468,5.19688884 C173.890976,5.60901684 173.714073,6.07150109 173.48999,6.57163998 C172.846223,8.00848982 171.956287,9.44751753 170.783079,10.7898435 C170.614662,10.9825377 170.441249,11.1720997 170.262768,11.3583495 C169.943851,11.6911459 169.612741,12.0040653 169.270585,12.2977499 C168.051114,13.3444633 166.744635,14.1081422 165.440693,14.6323238 C164.987513,14.8145013 164.568844,14.9534122 164.19635,15.0545874 C163.981087,15.1130563 163.834947,15.1451286 163.769674,15.1564748 C163.264429,15.2443012 162.926045,15.7250806 163.013871,16.2303255 C163.101698,16.7355705 163.582477,17.0739551 164.087722,16.9861288 Z"
								id="Chart-Style-Line"
							></path>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default LineChart;
