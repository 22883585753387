import React, { useContext } from "react";
import styles from "./RealTimeBanner.module.scss";
import { ApiInstanceAndUserInfoContext } from "components/App";
import { postMSVendorRedirectToScript } from "utils/utils";

const RealTimeBanner = () => {
	const { userInfo } = useContext(ApiInstanceAndUserInfoContext);

	const handleClick = () => {
		window.parent.postMessage(
			JSON.stringify({ name: "MDRedirectEvent" }),
			window.MD.PARENT_DOMAIN
		); // NOSONAR
		postMSVendorRedirectToScript({
			Service: "MarketDataAgmt",
		});
	};

	const showMessage = () => {
		//additional checks on OPTIONS flow specific attributes (userQuotePreference, userProfessionalStatus) to ensure user is in options flow vs regular
		//since tier 4287 is shared between options flow and regular delayed flow.
		return (
			userInfo?.userQuotePreference &&
			userInfo?.userProfessionalStatus &&
			[5847, 4287].indexOf(userInfo?.userTier) >= 0
		);
	};

	return (
		<div className={styles.bannerContainer}>
			{showMessage() && (
				<section aria-label="real time banner" className={styles.BannerMain}>
					<div className={styles.BannerContent}>
						<p>
							You are currently viewing delayed market data. You can enroll in real-time
							market data at any time.
						</p>
						<span>
							<button className="GetStartedLink btn btn-link" onClick={handleClick}>
								Get started
							</button>
						</span>
					</div>
				</section>
			)}
		</div>
	);
};

export default RealTimeBanner;
