import React, { Component } from "react";
import Chevron from "../../Lib/Chevron/Chevron";

import { connect } from "react-redux";
import actionTypes from "../../../store/actions/actionTypes";

import styles from "./MonthlyExpirationButton.module.scss";

class MonthlyExpirationButton extends Component {
	render() {
		return (
			<button
				id="monthlyExpirationButton"
				className={`${styles.button} btn`}
				onClick={this.props.handleOnClick}
			>
				{this.props.showMonthlyExpiration
					? "Only monthly expirations"
					: "Show all expirations"}
				<Chevron direction="down" dimension={16} color="#187ABA" />
			</button>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		showMonthlyExpiration: state.optionsReducer.showMonthlyExpiration,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		dispatchToggleMonthlyExpirationDrawer: () =>
			dispatch({ type: actionTypes.TOGGLE_MONTHLY_EXPIRATION_DRAWER }),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MonthlyExpirationButton);
