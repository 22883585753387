import actionTypes from "./actionTypes";
import { URLS, RESEARCH_IDS } from "utils/constants";

const storeMorningstarDocumentDetailsResults = (results) => {
	return {
		type: actionTypes.SAVE_MORNINGSTAR_DOCUMENT_DETAILS_DATA,
		morningstarDocumentsData: results,
	};
};

const storeMSDocumentDetailsResults = (results) => {
	return {
		type: actionTypes.SAVE_MORGANSTANLEY_DOCUMENT_DETAILS_DATA,
		msDocumentsData: results,
	};
};

const storeResearchPdfResults = (results) => {
	return {
		type: actionTypes.SAVE_RESEARCH_PDF_DATA,
		researchPdfData: results,
	};
};

const fetchDocumentDetails = (apiInstance, venueXid, reportType) => {
	//async here - dispatch here will get set by redux-thunk
	return async (dispatch, getState) => {
		if (!apiInstance || !venueXid) {
			return;
		}
		dispatch({ type: actionTypes.RESEARCH_API_TRANSITION, name: "load" });

		const { researchReducer } = getState();
		if (researchReducer.researchDataStatus !== "FETCHING") {
			return;
		}

		try {
			const response = await apiInstance.post(
				`${URLS.RESEARCH_PATH}/${RESEARCH_IDS.RESEARCH}/documents`,
				{
					argsOperator: "AND",
					arguments: [
						{
							field: "WSODIssue",
							conditions: [
								{
									operator: "EqualTo",
									values: [`${venueXid}`],
								},
							],
						},
						{
							field: "Module",
							conditions: [
								{
									operator: "EqualTo",
									values: [reportType],
								},
							],
						},
					],
					limit: 5,
					offset: 0,
					sortArgument: {
						direction: "desc",
						field: "DocumentDate",
					},
				}
			);

			const documentData = response.data || {};
			if (documentData && documentData.data && documentData.data.items.length) {
				dispatch({
					type: actionTypes.RESEARCH_API_TRANSITION,
					name: "success",
				});
				switch (reportType) {
					case "MorningstarResearch":
						dispatch(storeMorningstarDocumentDetailsResults(documentData.data));
						break;
					case "MorganStanleyResearch":
						dispatch(storeMSDocumentDetailsResults(documentData.data));
						break;
					default:
						break;
				}
			}
		} catch (error) {
			dispatch({ type: actionTypes.RESEARCH_API_TRANSITION, name: "failure" });
		}
	};
};

const fetchResearchPDF = (apiInstance, documentId) => {
	//async here - dispatch here will get set by redux-thunk
	return async (dispatch, getState) => {
		if (!apiInstance || !documentId) {
			return;
		}
		dispatch({ type: actionTypes.RESEARCH_PDF_API_TRANSITION, name: "load" });
		const { researchReducer } = getState();
		if (researchReducer.researchPdfStatus !== "FETCHING") {
			return;
		}

		try {
			const response = await apiInstance.get(
				`${URLS.RESEARCH_PATH}/${RESEARCH_IDS.RESEARCH}/documents/${documentId}/component-type/PRES`,
				{
					headers: {
						Accept: "application/pdf",
					},
					responseType: "blob",
				}
			);
			const data = response.data || null;
			if (data) {
				//Create a Blob from the PDF Stream
				const file = new Blob([data], { type: "application/pdf" });

				const fileURL = URL.createObjectURL(file);
				const a = document.createElement("a");
				document.body.appendChild(a);
				a.style = "display: none";
				a.href = fileURL;
				a.click();
				a.remove();
				setTimeout(function () {
					window.URL.revokeObjectURL(fileURL);
				}, 100);
				dispatch({ type: actionTypes.RESEARCH_PDF_API_TRANSITION, name: "success" });
			}
		} catch (error) {
			dispatch({ type: actionTypes.RESEARCH_PDF_API_TRANSITION, name: "failure" });
			dispatch(storeResearchPdfResults(null));
		}
	};
};

export default { fetchDocumentDetails, fetchResearchPDF };
