import React from "react";
import { useDispatch, useSelector } from "react-redux";
import actionTypes from "../../../store/actions/actionTypes";
import formatter from "../../../utils/formatUtils";
import styles from "./ExpirationDatesFilter.module.scss";
import apiStates from "../../../store/apiStates";

const DateFilterItem = ({
	checked,
	data,
	isDuplicateDate,
	isLastItemSelected,
	isMobile,
}) => {
	const optionsDataStatus = useSelector(
		(state) => state.optionsReducer.optionsDataStatus
	);
	const dispatch = useDispatch();

	const toggleInput = (item) => {
		// don't toggle if this is the only item selected or the options API is currently fetching data,
		// as this can cause a race condition when displaying multiple tables
		if (isLastItemSelected || optionsDataStatus === apiStates.FETCHING) {
			return;
		}
		const selectedExpirationDate = {
			expirationDate: item.expirationDate,
			contractTerm: item.contractTerm,
			isAdjusted: item.isAdjusted,
			duplicateDate: isDuplicateDate,
		};
		dispatch({ type: actionTypes.EXP_DATES_SELECTED, value: selectedExpirationDate });
	};

	const classes = isMobile
		? styles.expirationDatesFilter
		: `${styles.expirationDatesFilter} ${styles.desktop}`;

	const currentYear = new Date().getFullYear();
	const expiraDateYearString = formatter.date(data.expirationDate, "YYYY");
	const displayDate =
		parseInt(expiraDateYearString) > currentYear
			? formatter.date(data.expirationDate, "MMM D, YY")
			: formatter.date(data.expirationDate, "MMM D");

	return (
		<div className={classes}>
			<input
				id={`exp_${data.expirationDate}_${data.contractTerm}`}
				type={"checkbox"}
				name="expiration"
				checked={checked}
				onChange={() => toggleInput(data)}
				value={data.expirationDate}
			/>
			<label htmlFor={`exp_${data.expirationDate}_${data.contractTerm}`}>
				{displayDate}
				{data.contractTerm === "Weekly" && (
					<abbr title={data.contractTerm} aria-label={data.contractTerm}>
						{isDuplicateDate ? " PM" : "(W)"}
					</abbr>
				)}
				{data.contractTerm === "Monthly" && (
					<abbr title={data.contractTerm} aria-label={data.contractTerm}>
						{isDuplicateDate ? " AM" : "(M)"}
					</abbr>
				)}
				{data.contractTerm === "Quarterly" && (
					<abbr title={data.contractTerm} aria-label={data.contractTerm}>
						(Q)
					</abbr>
				)}
			</label>
		</div>
	);
};

export default DateFilterItem;
