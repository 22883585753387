const max = Number.MAX_SAFE_INTEGER;
const defaultRowTemplateThresholds = [
	// Small charts
	{
		width: 150,
		intraday: [
			{
				timeSpan: max,
				upper: "days",
				lower: "months",
			},
		],
		interday: [
			{
				timeSpan: 15,
				upper: "days",
				lower: "months",
			},
			{
				timeSpan: 365,
				upper: "weeks",
				lower: "years",
			},
			{
				timeSpan: 365 * 10,
				upper: "months",
				lower: "years",
			},
			{
				timeSpan: max,
				upper: "years",
				lower: null,
			},
		],
	},
	// Medium charts
	{
		width: 900,
		intraday: [
			{
				timeSpan: 4,
				upper: "minutes",
				lower: "days",
			},
			{
				timeSpan: 24,
				upper: "hours",
				lower: "days",
			},
			{
				timeSpan: 60,
				upper: "hours",
				lower: "days",
			},
			{
				timeSpan: max,
				upper: "days",
				lower: "months",
			},
		],
		interday: [
			{
				timeSpan: 17,
				upper: "days",
				lower: "months",
			},
			{
				timeSpan: 150,
				upper: "weeks",
				lower: "months",
			},
			{
				timeSpan: 365 * 3,
				upper: "months",
				lower: "years",
			},
			{
				timeSpan: max,
				upper: "years",
				lower: null,
			},
		],
	},
	// Large charts
	{
		width: max,
		intraday: [
			{
				timeSpan: 4,
				upper: "minutes",
				lower: "days",
			},
			{
				timeSpan: 50,
				upper: "hours",
				lower: "days",
			},
			{
				timeSpan: 130,
				upper: "days",
				lower: "months",
			},
			{
				timeSpan: max,
				upper: "days",
				lower: "months",
			},
		],
		interday: [
			{
				timeSpan: 30,
				upper: "days",
				lower: "months",
			},
			{
				timeSpan: 80,
				upper: "weeks",
				lower: "months",
			},
			{
				timeSpan: 365 * 5,
				upper: "months",
				lower: "years",
			},
			{
				timeSpan: max,
				upper: "years",
				lower: null,
			},
		],
	},
];

export default defaultRowTemplateThresholds;
