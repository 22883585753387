import React from "react";
import Chevron from "components/Lib/Chevron/Chevron";
import classNames from "classnames";

import styles from "./CollapsibleResponsiveFilter.module.scss";

const CollapsibleResponsiveFilter = ({
	title,
	children,
	onToggle,
	expanded = false,
	id = null,
}) => {
	return (
		<section className={classNames(styles.cardContainer)} aria-expanded={expanded}>
			<div
				className={styles.cardHeader}
				id={id}
				tabIndex="0"
				onClick={(e) => {
					e.preventDefault();
					onToggle();
				}}
			>
				<span className={styles.title}>{title}</span>
				<div className={styles.cardLinkContainer}>
					<div className={styles.collapseExpand}>
						<span className="sr-only">{`select to ${
							expanded ? "collapse" : "expand"
						} section`}</span>
						<Chevron direction="down" dimension={20} color="black" />
					</div>
				</div>
			</div>
			{expanded && <div className={styles.cardContent}>{children}</div>}
		</section>
	);
};

export default CollapsibleResponsiveFilter;
