import React from "react";

const SkipToEndRight = (props) => {
	return (
		<svg
			data-value={props.dataValue}
			onClick={props.onClick}
			aria-label={props.ariaLabel}
			xmlns="http://www.w3.org/2000/svg"
			width="20px"
			height="20px"
			viewBox="0 0 30 30"
			version="1.1"
		>
			<title>{props.ariaLabel}</title>
			<g
				id={props.dataValue}
				stroke="none"
				strokeWidth="1"
				fill="none"
				fillRule="evenodd"
			>
				<g id="skip-to-end-right">
					<g
						id={props.dataValue}
						transform="translate(0.000000, 0.000000)"
						fill={props.color || "#187ABA"}
					>
						<path
							d="M9.42791207,5.69270196 C9.8996215,5.22099254 10.6718459,5.22842482 11.1355239,5.69210289 L18.5008796,13.0574585 C18.9722581,13.5288371 18.9645576,14.3007932 18.5008796,14.7644713 L11.1355239,22.1298269 C10.6641454,22.6012055 9.89721434,22.5985301 9.42791207,22.1292279 L9.32164933,22.0229651 C8.8499399,21.5512557 8.84754093,20.7888626 9.32862096,20.3077825 L15.7254386,13.9109649 L9.32862096,7.51414728 C8.85306121,7.03858753 8.85234706,6.26826698 9.32164933,5.7989647 L9.42791207,5.69270196 Z"
							id="Icon"
						/>
					</g>
					<line
						x1="23.5881579"
						y1="6.44342105"
						x2="23.5881579"
						y2="22.1688596"
						id="Line"
						stroke={props.color || "#187ABA"}
						strokeWidth="2.41929825"
						strokeLinecap="round"
					/>
				</g>
			</g>
		</svg>
	);
};

export default SkipToEndRight;
