import Chartist from "chartist";

function ChartResizeLineChart(options) {
	return function ctResizeLineChart(chart) {
		if (chart instanceof Chartist.Bar) {
			chart.on("draw", function (data) {
				if (data.type === "label" && data.text.includes("$0")) {
					let xAxisHdn = document.getElementById(options.chartGuid);
					if (!xAxisHdn) {
						xAxisHdn = createHiddenField();
					}
					let bottomXAxis = xAxisHdn.getAttribute("pointLabelYaxisBottom") || "0";
					xAxisHdn.setAttribute(
						"pointlabelyaxisbottom",
						Math.abs(parseFloat(bottomXAxis) - data.y)
					);
				}
				if (data.type === "label" && data.text.includes("-$")) {
					let xAxisHdn = document.getElementById(options.chartGuid);
					if (!xAxisHdn) {
						xAxisHdn = createHiddenField();
					}
					data.element.attr({ x: data.element.attr("x") - 5 });
					if (!xAxisHdn.getAttribute("pointLabelYaxisBottom", data.y))
						xAxisHdn.setAttribute("pointLabelYaxisBottom", data.y);
				}
				if (data.type === "bar") {
					let xAxis = document.getElementById(options.chartGuid);
					if (data.index === 0 && data.seriesIndex === 0) {
						if (!xAxis) {
							xAxis = createHiddenField();
						}
						xAxis.setAttribute("startPoint", data.x2 + 3);
						xAxis.setAttribute("series_" + data.seriesIndex + "_coord", `${data.y2}`);
						xAxis.setAttribute("series_1_coord", "");
					} else if (xAxis !== null) {
						let coords = xAxis.getAttribute("series_" + data.seriesIndex + "_coord");
						if (coords) coords += `;${data.y2}`;
						else coords = `${data.y2}`;
						xAxis.setAttribute("series_" + data.seriesIndex + "_coord", coords);
					}
				}
				if (data.type === "grid") {
					let xAxis = document.getElementById(options.chartGuid);
					if (!xAxis) {
						xAxis = createHiddenField();
					}
					let yAxisMaxValue = getMaxYAxis(data);
					xAxis.setAttribute("yAxisMaxValue", yAxisMaxValue);
					let yAxisLabelToPreventOverlap = xAxis.getAttribute("chart_grid_coord");
					if (yAxisLabelToPreventOverlap && data.index !== 0)
						yAxisLabelToPreventOverlap += `;${data.y2}`;
					else yAxisLabelToPreventOverlap = `${data.y2}`;
					xAxis.setAttribute("chart_grid_coord", yAxisLabelToPreventOverlap);
				}
			});
		}
	};
	function createHiddenField() {
		let hdnField = document.createElement("INPUT");
		hdnField.setAttribute("type", "hidden");
		hdnField.setAttribute("id", options.chartGuid);
		document.body.appendChild(hdnField);
		return document.getElementById(options.chartGuid);
	}
	function getMaxYAxis(data) {
		let yAxisMaxValue = data.axis.bounds.high;
		if (
			data.axis.bounds.values[data.axis.bounds.values.length - 1] <= data.axis.bounds.high
		) {
			yAxisMaxValue =
				data.axis.bounds.values[data.axis.bounds.values.length - 1] +
				data.axis.bounds.step;
		}
		return yAxisMaxValue;
	}
}

export default ChartResizeLineChart;
