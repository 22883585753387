class StateStorage {
	constructor({ key }) {
		this.key = key;
	}

	get() {
		if (this.parsedValue) {
			return this.parsedValue;
		}
		this.parsedValue = JSON.parse(localStorage.getItem(this.key)) || {};
		return this.parsedValue;
	}

	set(args, overrideExisting) {
		let storedState = {};
		if (!overrideExisting) {
			storedState = this.get();
		}
		storedState = { ...storedState, ...args };
		this.parsedValue = storedState;
		localStorage.setItem(this.key, JSON.stringify(storedState));
	}
}
export default StateStorage;
