import { createReducer } from "reduxsauce";
import actionTypes from "../actions/actionTypes";
import ApiStates from "../apiStates";
import { API_TRANSITIONS, getApiTransitionAction } from "../apiTransitionUtils";

const INITIAL_STATE = {
	routeName: null,
	userPreferenceStatus: ApiStates.IDLE,
};

export const updateRouteName = (state = INITIAL_STATE, { routeName }) => {
	// NOSONAR
	if (!routeName) {
		return { ...state };
	}

	return {
		...state,
		routeName,
	};
};

export const userPreferenceTransition = (state = INITIAL_STATE, { name }) => {
	// NOSONAR
	const action = getApiTransitionAction(state.userInfoStatus, name);
	if (action) {
		// execute action
		return action.apply(API_TRANSITIONS, [state, { key: "userInfoStatus" }]);
	}
	// return existing state if no action found
	return { ...state };
};

export const HANDLERS = {
	[actionTypes.UPDATE_MARKETS_RESEARCH_ROUTE_NAME]: updateRouteName,
	[actionTypes.USER_PREFERENCE_API_TRANSITION]: userPreferenceTransition,
};

export default createReducer(INITIAL_STATE, HANDLERS);
