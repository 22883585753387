const filterEmptyData = (tableRowsArray, emptyIteratorLength, tableColumnsArray) => {
	const tableData = tableRowsArray.map((x) =>
		x.filter((y, index) => index > 0 ?? (y.CellValue || y)).map((x) => x.CellValue || x)
	);
	const transposeTableData = [];
	for (let k = 0; k < emptyIteratorLength; k++) {
		const o = tableData.map((x) => (x[k] === "—" ? undefined : x[k]));
		transposeTableData.push({ row: k, data: o });
	}
	transposeTableData.forEach((x) => {
		const arrValues = x.data.filter((x) => x);
		if (arrValues.length === 0) {
			tableColumnsArray = tableColumnsArray.filter((y, index) => index !== x.row + 1);
			tableRowsArray = tableRowsArray.map((z) =>
				z.filter((y, index) => index !== x.row + 1)
			);
		}
	});
	return {
		headersData: tableColumnsArray,
		rowsData: tableRowsArray,
	};
};

const filterEmptyDataWithoutHeader = (
	tableRowsArray,
	emptyIteratorLength,
	tableColumnsArray
) => {
	const tableData = tableRowsArray.map((x) => x.map((x) => x.CellValue || x));
	const transposeTableData = [];
	for (let k = 0; k < emptyIteratorLength; k++) {
		const o = tableData.map((x) => x[k]);
		transposeTableData.push({ row: k, data: o });
	}
	transposeTableData.forEach((x) => {
		const arrValues = x.data.filter((x) => x);
		if (arrValues.length === 0) {
			tableColumnsArray = tableColumnsArray.filter((y, index) => index !== x.row);
			tableRowsArray = tableRowsArray.map((z) => z.filter((y, index) => index !== x.row));
		}
	});
	return {
		headersData: tableColumnsArray,
		rowsData: tableRowsArray,
	};
};

export default { filterEmptyData, filterEmptyDataWithoutHeader };
