import Utils from "utils/utils";
import formatter from "utils/formatUtils";
import { URLS, PERIODICITY_OPTIONS } from "utils/constants";

/**
 * Utility for fetching API data for Equities Morningstar Estimates Details Data
 * @param {Object} apiInstance - api instance
 * @param {Number} venueXid - the Xid for the symbol selected
 */
export const getEstimatesCurrent = async (apiInstance, venueXid) => {
	if (!apiInstance || !venueXid) {
		return;
	}

	let estimates = {};

	try {
		const response = await apiInstance.get(
			`${URLS.EARNINGS_ESTIMATES_CURRENT_PATH}/${venueXid}`
		);

		const data = response.data.data || null;
		if (data) {
			estimates.venueXid = venueXid;

			let concensusLongTermGrowth = Utils.getObjectPropertyValue(
				data,
				"annual.longTermGrowthRate.mean"
			);

			// Explicitly check for null and undefined, as 0 is a valid falsey result
			if (
				typeof concensusLongTermGrowth !== "undefined" &&
				concensusLongTermGrowth !== null
			) {
				if (Math.sign(concensusLongTermGrowth) === 1) {
					estimates.concensusLongTermGrowth = `+${formatter.percent(
						concensusLongTermGrowth,
						null,
						{ percentModifier: 0.01 }
					)}`;
				} else {
					estimates.concensusLongTermGrowth = formatter.percent(
						concensusLongTermGrowth,
						null,
						{ percentModifier: 0.01 }
					);
				}
			} else {
				estimates.concensusLongTermGrowth = formatter.options.errorString;
			}

			const pegcyEstimateLtgConsensus = Utils.getObjectPropertyValue(
				data,
				"pe.pegcyEstimateLtgConsensus"
			);
			estimates.pegcyEstimateLtgConsensus = formatter.number(
				pegcyEstimateLtgConsensus,
				2
			);

			estimates.penyEstimate = formatter.number(
				Utils.getObjectPropertyValue(data, "annual.pe.penyEstimate"),
				2
			);

			estimates.nextAnnounceDate = formatter.date(
				Utils.getObjectPropertyValue(data, "recommendation.nextExpectedReportDate"),
				"MMM D YYYY"
			);
			estimates.forwardPeRatio = formatter.number(
				Utils.getObjectPropertyValue(data, "annual.pe.pecyEstimate"),
				2
			);

			return estimates;
		} else {
			return estimates;
		}
	} catch (error) {
		return estimates;
	}
};

/**
 * Utility for fetching API data for Equities Morningstar Estimates Trends Data
 * @param {Object} apiInstance - api instance
 * @param {Number} venueXid - the Xid for the symbol selected
 * @param {String} periodicity - Defines the period of time returned in intervals. Quarterly or annually
 * @param {String} componentRouteName - the route/page of the application (e.g. Overview, Earnings)
 */
export const getEstimatesTrends = async (
	apiInstance,
	venueXid,
	periodicity,
	numForwardEstimatePeriods,
	numHistoricalEstimatePeriods
) => {
	if (!apiInstance || !venueXid || !periodicity) {
		return;
	}

	let estimates = {};

	try {
		let payload = {};

		// set periodicity to "quarterly" if no periodicity provided
		periodicity = periodicity ? periodicity.toLowerCase() : PERIODICITY_OPTIONS.QUARTERLY;

		payload.params = {
			periodicity: periodicity
				? periodicity.toLowerCase()
				: PERIODICITY_OPTIONS.QUARTERLY,
			numberOfEstimatePeriods: numForwardEstimatePeriods,
			includeHistory: true,
			numberOfHistoricalPeriods: numHistoricalEstimatePeriods,
		};

		const response = await apiInstance.get(
			`${URLS.EARNINGS_ESTIMATES_TRENDS_PATH}/${venueXid}`,
			payload
		);

		const data = response.data.data || null;
		if (data) {
			return data;
		} else {
			return estimates;
		}
	} catch (error) {
		return estimates;
	}
};

export default { getEstimatesCurrent, getEstimatesTrends };
