import { renderToString } from "react-dom/server";
import UpAndDownArrow from "components/Lib/Icons/UpDownArrow/UpDownArrow";
import styles from "./LegendMovePanel.module.scss";

const UpArrowClickedString = "Up";
const DownArrowClickedString = "Down";

const renderUpAndDownRepositionArrowIcon = (
	div,
	width,
	panelUid,
	chart,
	isReshuffle,
	updateChartState
) => {
	let isFirstLowerIndicator = isFirstPanel(panelUid, chart);
	let isLastLowerIndicator = isLastPanel(panelUid, chart);

	//if first lower indicator - down arrow active / up arrow in active
	if (isFirstLowerIndicator && !isLastLowerIndicator) {
		return UpAndDownArrow.DownActiveUpInactiveArrow.map((x) =>
			setUpLIforUpAndDownRepositionArrowIcon(
				x,
				div,
				panelUid,
				UpAndDownArrow.DownActiveUpInactiveArrow,
				chart,
				isReshuffle,
				updateChartState
			)
		);
	}
	//if not first or last lower indicator - both the arrows active
	else if (!isFirstLowerIndicator && !isLastLowerIndicator) {
		return UpAndDownArrow.UpAndDownArrowActive.map((x) =>
			setUpLIforUpAndDownRepositionArrowIcon(
				x,
				div,
				panelUid,
				UpAndDownArrow.UpAndDownArrowActive,
				chart,
				isReshuffle,
				updateChartState
			)
		);
	}
	//if last lower indicator - up arrow active / down arrow in active
	else if (!isFirstLowerIndicator && isLastLowerIndicator) {
		return UpAndDownArrow.UpActiveDownInactiveArrow.map((x) =>
			setUpLIforUpAndDownRepositionArrowIcon(
				x,
				div,
				panelUid,
				UpAndDownArrow.UpActiveDownInactiveArrow,
				chart,
				isReshuffle,
				updateChartState
			)
		);
	}
};

const reRenderButton = (
	li,
	panelUid,
	chart,
	buttonStyle,
	title,
	htmlString,
	updateChartFunction
) => {
	let isActive = htmlString.props.isActive;
	let button = document.createElement("button");
	button.classList.add(styles.buttonArrow);
	if (title === "Move chart down") {
		button.classList.add(styles.buttonArrowDown);
	}
	button.setAttribute("style", buttonStyle);
	button.setAttribute("isActive", isActive);
	if (isActive === "false") {
		button.setAttribute("aria-disabled", "true");
	}
	button.innerHTML = renderToString(htmlString);
	let clickedArrowDirection =
		title === "Move chart down" ? DownArrowClickedString : UpArrowClickedString;
	button.onclick = (x) =>
		onArrowClickHandler(
			x,
			panelUid,
			chart,
			updateChartFunction,
			clickedArrowDirection,
			isActive
		);
	createSpan(title, button);
	return li.append(button);
};

const createSpan = (title, button) => {
	const span = document.createElement("span");
	span.classList.add("sr-only");
	span.setAttribute("aria-label", title);
	span.innerHTML = title;
	button.append(span);
};

const setUpLIforUpAndDownRepositionArrowIcon = (
	currentInstance,
	div,
	panelUid,
	htmlString,
	chart,
	isReshuffle,
	updateChartState
) => {
	let title =
		currentInstance.props.isarrowdown === "true" ? "Move chart down" : "Move chart up";
	let buttonStyle = ""; //pointer-events: none;
	if (isReshuffle) {
		title = "Move chart down";
		reRenderButton(
			div,
			panelUid,
			chart,
			buttonStyle,
			title,
			htmlString[0],
			updateChartState
		);

		//second button
		buttonStyle = `margin-left: 38px !important;`;
		title = "Move chart up";
		reRenderButton(
			div,
			panelUid,
			chart,
			buttonStyle,
			title,
			htmlString[1],
			updateChartState
		);
	} else {
		if (currentInstance.props.order === "1") {
			return addButton(
				div,
				panelUid,
				chart,
				buttonStyle,
				title,
				htmlString[0],
				updateChartState
			);
		} else if (currentInstance.props.order === "2") {
			buttonStyle = `margin-left: 38px !important;`;
			title = "Move chart up";
			addButton(
				div,
				panelUid,
				chart,
				buttonStyle,
				title,
				htmlString[1],
				updateChartState
			);
		}
	}

	updateChartState(chart);
};

const addButton = (
	div,
	panelUid,
	chart,
	buttonStyle,
	title,
	htmlString,
	updateChartFunction
) => {
	let isActive = htmlString.props.isActive;
	let buttonClasses = styles.buttonArrow;
	if (title === "Move chart down") {
		buttonClasses = buttonClasses.concat(" ", styles.buttonArrowDown);
	}
	let clickedArrowDirection =
		title === "Move chart down" ? DownArrowClickedString : UpArrowClickedString;
	return div
		.append("button")
		.attr("class", buttonClasses)
		.attr("aria-disabled", isActive === "true" ? "false" : "true")
		.attr("style", buttonStyle)
		.attr("isActive", isActive)
		.html(renderToString(htmlString))
		.on("click", (elem) =>
			onArrowClickHandler(
				elem,
				panelUid,
				chart,
				updateChartFunction,
				clickedArrowDirection,
				isActive
			)
		)
		.append("span")
		.attr("class", "sr-only")
		.html(title);
};

const onArrowClickHandler = (
	event,
	panelUid,
	chart,
	updateChartState,
	clickedArrowDirection,
	isActive
) => {
	if (isActive === "false") {
		event.preventDefault();
	} else if (isActive === "true") {
		let isDownArray = true;
		let panels = chart.panels;
		let index = panels.findIndex((x) => x.indicators[0].params.uid === panelUid);
		const chartEl = chart.rootModchart.node();
		const panelEl = panels[index].rootPanel.node();
		let thisPanel = panels.splice(index, 1)[0];
		if (clickedArrowDirection === UpArrowClickedString) {
			chartEl.insertBefore(panelEl, panelEl.previousSibling);
			panels.splice(index - 1, 0, thisPanel);
			isDownArray = false;
		} else {
			chartEl.insertBefore(panelEl.nextSibling, panelEl);
			panels.splice(index + 1, 0, thisPanel);
		}
		chart.loadData();

		//setUpAccessibility
		let span = document.createElement("span");
		span.classList.add("sr-only");
		span.setAttribute("aria-label", isDownArray ? "Move chart down" : "Move chart up");
		span.innerHTML = isDownArray ? "Move chart down" : "Move chart up";
		event.target.appendChild(span);
		updateChartState(chart);
	}
};

//Add the Reposiotion Panel to lower indicators, will be adding once the count of the lower indicators is greater then 2 and
//will re arrange if lower indicator is removed
const AddArrowRepositionPanel = (
	li,
	width,
	panelUid,
	chart,
	isReshuffle,
	updateChartState
) => {
	let div;
	//Re arrange the arrows if one of the lower indicator is removed
	if (isReshuffle) {
		li.childNodes[li.childNodes.length - 1].innerHTML = "";
		div = li.childNodes[li.childNodes.length - 1];
	}
	//Add reposition arrow lower indicators
	else {
		div = li
			.append("div")
			.attr("class", styles.upDownArrowWrapperDiv)
			.attr("style", `left: ${width - 63}px;`);
	}
	renderUpAndDownRepositionArrowIcon(
		div,
		width,
		panelUid,
		chart,
		isReshuffle,
		updateChartState
	);
};

//Reshuffle and Reposition Arrows
const ReshuffleArrowRepositionPanel = (width, chart, updateChartState) => {
	document
		.getElementsByClassName("advancedChartLegend")
		.forEach((element, index, items) => {
			if (index > 0 && items.length !== 2) {
				AddArrowRepositionPanel(
					element.childNodes[0],
					width,
					items[index].childNodes[0].getAttribute("data-uid"),
					chart,
					true,
					updateChartState
				);
			}
		});
};

//Check if current panel is the first one in the chart
const isFirstPanel = (panelUid, chart) => {
	return panelUid === chart.panels[1].indicators[0].params.uid;
};

//Check if current panel is the last one in the chart
const isLastPanel = (panelUid, chart) => {
	const panels = chart.panels;
	return panelUid === panels[panels.length - 1].indicators[0].params.uid;
};

export default {
	AddArrowRepositionPanel,
	ReshuffleArrowRepositionPanel,
};
