import React from "react";
import styles from "./Drawer.module.scss";

const Drawer = (props) => {
	// open class flag
	const isOpen = props.open ? styles.drawerOpen : "";
	const isFullscreen = props.full ? styles.drawerFull : "";
	// click overlay emitter
	const onClick = (e) => {
		// only emit on wrapper click
		if (e.currentTarget === e.target) {
			e.preventDefault();
			props.onClose(e);
		}
	};

	return (
		<div className={[styles.drawer, isFullscreen, isOpen].join(" ")} onClick={onClick}>
			<div className={styles.drawerContent}>
				{props.title && <div className={styles.drawerTitle}>{props.title}</div>}
				{props.children}
			</div>
		</div>
	);
};

export default Drawer;
