// This logic is based on the `BuildInputs()` method in
//  MOD.Web.Clients.MorganStanley.MSOnline.Data.Research.ResearchHistory
//  from the `morganstanley-msonline` project
import languageEn from "assets/language/en";
import formatter from "utils/formatUtils";

const ALL = "all";
const CUSTOM_DATE_ID = 99;
const DATE_OPTIONS = {
	TODAY: languageEn.TODAY,
	YESTERDAY: languageEn.YESTERDAY,
	LAST_7_DAYS: languageEn.LAST_7_DAYS,
	LAST_30_DAYS: languageEn.LAST_30_DAYS,
	LAST_3_MONTHS: languageEn.LAST_3_MONTHS,
	LAST_6_MONTHS: languageEn.LAST_6_MONTHS,
	LAST_12_MONTHS: languageEn.LAST_12_MONTHS,
	CUSTOM_DATE: languageEn.CUSTOM_DATE,
};

export const getTranslationLabel = (val) => {
	let keyValuePair = Object.entries(DATE_OPTIONS).find((i) => i[1] === val);
	return keyValuePair ? keyValuePair[0] : null;
};

/**
 * Convenience method for constructing an equal to parameter
 *
 * @param {string} field Field name
 * @param {string[]} values Array of values
 */
const equalTo = (field, values) => {
	return {
		field: field,
		conditions: [
			{
				operator: "EqualTo",
				values: values,
			},
		],
	};
};

/**
 * Common functionality for the discrete options
 */
export const commonMethods = {
	findByKey(key) {
		return this.values.find((rs) => rs.key === key);
	},
	findByLabel(label) {
		return this.values.find((rs) => rs.label === label);
	},
	// this function is a setter for labels in the filter dropdowns (e.g. for language translations)
	translateLabels(keys, translationLabels) {
		// if multiple keys exist, iterate through the list of keys and update the default label for each key provided
		if (Array.isArray(keys) && keys.length && translationLabels) {
			keys.forEach((element) => {
				const currentTranslationLabel = element.translationLabel;

				if (translationLabels[currentTranslationLabel]) {
					element.label = translationLabels[currentTranslationLabel];
				}
			});
			// if there is one key provided, update the component's label at the key provided
		} else {
			const updateIndex = this.values.findIndex((element) => element.key === keys);
			this.values[updateIndex].label = translationLabels;
		}
	},
	hasAll(arr) {
		return !arr || arr.length === 0 || this.hasType(arr, ALL);
	},
	hasType(arr, field) {
		return arr && arr.some((rt) => rt === field);
	},

	toggleValue(arr, field) {
		if (arr.some((x) => x === field)) {
			return arr.filter((x) => x !== field);
		} else {
			return [...arr, field];
		}
	},
};

export const ResearchSource = {
	...commonMethods,
	values: [
		{
			key: ALL,
			label: "All Providers",
			module: undefined,
		},
		{
			key: "morningstar",
			label: "Morningstar",
			module: "MorningstarResearch",
		},
		{
			key: "morgan-stanley",
			label: "Morgan Stanley",
			module: "MorganStanleyResearch",
		},
	],
	DEFAULT_RESEARCH_SOURCE: ALL,

	buildArgs(provider) {
		const providerSearch = this.findByKey(provider);
		if (!providerSearch || !providerSearch.module) {
			return undefined;
		}

		return equalTo("Module", [providerSearch.module]);
	},
};

export const DateSpan = {
	...commonMethods,
	values: [
		{
			key: 1,
			label: DATE_OPTIONS.TODAY,
			translationLabel: getTranslationLabel(DATE_OPTIONS.TODAY),
			numberPrior: 0,
			unit: "days",
		},
		{
			key: 2,
			label: DATE_OPTIONS.YESTERDAY,
			translationLabel: getTranslationLabel(DATE_OPTIONS.YESTERDAY),
			numberPrior: 1,
			unit: "days",
		},
		{
			key: 3,
			label: DATE_OPTIONS.LAST_7_DAYS,
			translationLabel: getTranslationLabel(DATE_OPTIONS.LAST_7_DAYS),
			numberPrior: 7,
			unit: "days",
		},
		{
			key: 4,
			label: DATE_OPTIONS.LAST_30_DAYS,
			translationLabel: getTranslationLabel(DATE_OPTIONS.LAST_30_DAYS),
			numberPrior: 30,
			unit: "days",
		},
		{
			key: 5,
			label: DATE_OPTIONS.LAST_3_MONTHS,
			translationLabel: getTranslationLabel(DATE_OPTIONS.LAST_3_MONTHS),
			numberPrior: 3,
			unit: "months",
		},
		{
			key: 6,
			label: DATE_OPTIONS.LAST_6_MONTHS,
			translationLabel: getTranslationLabel(DATE_OPTIONS.LAST_6_MONTHS),
			numberPrior: 6,
			unit: "months",
		},
		{
			key: 8,
			label: DATE_OPTIONS.LAST_12_MONTHS,
			translationLabel: getTranslationLabel(DATE_OPTIONS.LAST_12_MONTHS),
			numberPrior: 12,
			unit: "months",
		},
		{
			key: CUSTOM_DATE_ID,
			label: DATE_OPTIONS.CUSTOM_DATE,
			translationLabel: getTranslationLabel(DATE_OPTIONS.CUSTOM_DATE),
			numberPrior: "",
			unit: "CUSTOM_DATE_ID",
		},
	],
	DEFAULT_DATE_SPAN_ID: 4,
	CUSTOM_DATE_ID: CUSTOM_DATE_ID,

	buildArgs(dateSelection, startDate, endDate) {
		const args = {
			field: "DocumentDate",
			type: "date",
			conditions: [],
		};
		if (dateSelection === CUSTOM_DATE_ID) {
			// If the custom date is selected, then normalize values to Eastern and the bottom/top of the day
			args.startDate = formatter
				.moment(startDate)
				.tz("America/New_York")
				.startOf("day")
				.toDate();
			args.endDate = formatter
				.moment(endDate)
				.tz("America/New_York")
				.endOf("day")
				.toDate();
		} else {
			// Otherwise, use the `numberPrior` and `unit` values to calculate a relative range
			const dateValue = this.findByKey(dateSelection);
			args.startDate = formatter
				.moment()
				.tz("America/New_York")
				.subtract(dateValue.numberPrior, dateValue.unit)
				.startOf("day")
				.toDate();
			args.endDate = formatter.moment().tz("America/New_York").endOf("day").toDate(); // Today
		}

		return args;
	},
};

export const ReportType = {
	...commonMethods,
	values: [
		{
			key: ALL,
			label: "All Reports",
			module: undefined,
		},
		{
			key: "update",
			label: "Update",
			module: "RatingsTargets",
		},
		// KNOWN ISSUE: foundations do not currently existin in the QBID
		{
			key: "foundation",
			label: "Foundation",
			module: "Foundation", // TODO: Update the "module" once the QBID is updated
		},
		{
			key: "initiation",
			label: "Initiation of Coverage",
			module: "Initiations",
		},
		{
			key: "audio",
			label: "Audio",
			module: "Audio",
		},
		{
			key: "video",
			label: "Video",
			module: "Video",
		},
	],

	buildArgs(reportTypes) {
		if (this.hasAll(reportTypes)) {
			return undefined;
		}

		return equalTo(
			"Module",
			reportTypes.map((rt) => this.findByKey(rt).module)
		);
	},
};

const buildWSODIssueArgs = (wsodIssue) =>
	wsodIssue && wsodIssue.length > 0 ? equalTo("WSODIssue", [wsodIssue]) : undefined;

const buildFreeTextArgs = (keyword) =>
	keyword && keyword.length > 0 ? equalTo("FreeText", [keyword]) : undefined;

const buildCompanyOnly = (companyOnly) =>
	companyOnly ? equalTo("Module", ["CompanySpecific"]) : undefined;

export const buildDefaultFilterState = (overrides = {}) => {
	return {
		keyword: "",
		provider: ResearchSource.DEFAULT_RESEARCH_SOURCE,
		reportTypes: [],
		audioVideo: [],
		companyOnly: false,
		startDate: false,
		endDate: false,
		dateSpan: DateSpan.DEFAULT_DATE_SPAN_ID,
		...overrides,
	};
};

const buildPrimaryWSODIssue = (primaryWSODIssue, companyOnly) =>
	primaryWSODIssue && companyOnly && primaryWSODIssue.toString().length > 0
		? equalTo("PrimaryWSODIssue", [`${primaryWSODIssue}`])
		: undefined;
const buildWSODCompanyArgs = (legacyCompany) =>
	legacyCompany && legacyCompany.toString().length > 0
		? equalTo("WSODCompany", [legacyCompany.toString()])
		: undefined;

export const mapFiltersToParams = (
	venueXid,
	{ keyword, provider, reportTypes, companyOnly, dateSpan, startDate, endDate },
	sortArgument,
	limit = 5,
	offset = 0,
	legacyCompany = undefined
) => {
	const args = [
		buildWSODIssueArgs(venueXid),
		buildPrimaryWSODIssue(venueXid, companyOnly),
		buildCompanyOnly(companyOnly),
		buildWSODCompanyArgs(legacyCompany),
		buildFreeTextArgs(keyword),
		ResearchSource.buildArgs(provider),
		ReportType.buildArgs(reportTypes),
		DateSpan.buildArgs(dateSpan, startDate, endDate),
	].filter((a) => a !== undefined);
	return {
		argsOperator: "AND",
		arguments: args,
		limit,
		offset,
		sortArgument,
	};
};
