import React, { Component } from "react";
import FinancialsCard from "./FinancialsCard/FinancialsCard";
import styles from "./Financials.module.scss";

import FinancialStatementsAPIUtils from "services/APIs/Equities/Morningstar/FinancialStatements/FinancialStatementsAPI";
import FinancialRatiosAPIUtils from "services/APIs/Equities/Morningstar/FinancialRatios/FinancialRatiosAPI";

import { RESPONSIVE_BREAKPOINTS, FINANCIAL_STATEMENT_TYPE } from "utils/constants";
import { FINANCIAL_PERIOD_TYPE } from "./FinancialsCard/FinancialsConstants";

class Financials extends Component {
	constructor(props) {
		super(props);

		let mobile = window.matchMedia(
			`(max-width: ${RESPONSIVE_BREAKPOINTS.MEDIA_BREAKPOINTS_MAX.MEDIA_BREAKPOINT_MAX_SM}px)`
		).matches;

		this.state = {
			financialStatementsKeyData: null,
			financialRatiosHistoryData: null,
			financialStatementsData: null,
			SecFilingsData: null,
			numPeriods: mobile ? 3 : 5,
			isMobile: mobile,
		};
	}

	resizeListener = () => {
		let mobile = window.matchMedia(
			`(max-width: ${RESPONSIVE_BREAKPOINTS.MEDIA_BREAKPOINTS_MAX.MEDIA_BREAKPOINT_MAX_SM}px)`
		).matches;
		this.setState({ isMobile: mobile, numPeriods: mobile ? 3 : 5 });
	};

	componentDidMount() {
		this.fetchFinancialStatementsKeyData();
		this.fetchFinancialRatiosHistoryData();
		this.fetchFinancialStatementsData();
		window.addEventListener("resize", this.resizeListener);
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.isPropUpdated(prevProps, "venueXid")) {
			this.fetchFinancialRatiosHistoryData();
		}
		if (this.isPropUpdated(prevProps, "entityXid")) {
			this.fetchFinancialStatementsKeyData();
			this.fetchFinancialStatementsData();
		}
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.resizeListener);
	}

	isPropUpdated(prevProps, verificationProp) {
		if (!prevProps) {
			return false;
		}

		return prevProps[verificationProp] !== this.props[verificationProp];
	}

	async fetchFinancialStatementsKeyData() {
		if (this.props.entityXid) {
			const financialStatementsKeyData = await FinancialStatementsAPIUtils.getKeyData(
				this.props.apiInstance,
				this.props.entityXid,
				FINANCIAL_STATEMENT_TYPE.INCOME_STATEMENT,
				FINANCIAL_PERIOD_TYPE[1].value,
				this.state.numPeriods
			);

			if (financialStatementsKeyData && financialStatementsKeyData.rows) {
				this.setState({
					financialStatementsKeyData: financialStatementsKeyData,
				});
			} else {
				this.setState({
					financialStatementsKeyData: {},
				});
			}
		}
	}

	async fetchFinancialRatiosHistoryData() {
		if (this.props.venueXid) {
			const financialRatiosHistoryData =
				await FinancialRatiosAPIUtils.getFinancialRatiosHistory(
					this.props.apiInstance,
					this.props.venueXid,
					true,
					this.state.numPeriods
				);

			if (financialRatiosHistoryData && financialRatiosHistoryData.operations) {
				this.setState({
					financialRatiosHistoryData: financialRatiosHistoryData.operations,
				});
			} else {
				this.setState({
					financialRatiosHistoryData: [],
				});
			}
		}
	}

	async fetchFinancialStatementsData() {
		if (this.props.entityXid) {
			const financialStatementsData =
				await FinancialStatementsAPIUtils.getFinancialStatements(
					this.props.apiInstance,
					this.props.entityXid,
					FINANCIAL_STATEMENT_TYPE.INCOME_STATEMENT,
					FINANCIAL_PERIOD_TYPE[1].value,
					this.state.numPeriods
				);

			if (financialStatementsData) {
				this.setState({
					financialStatementsData: financialStatementsData,
				});
			} else {
				this.setState({
					financialStatementsData: {},
				});
			}
		}
	}

	render() {
		return (
			<section className={styles.mainContainer}>
				<FinancialsCard
					apiInstance={this.props.apiInstance}
					entityXid={this.props.entityXid}
					venueXid={this.props.venueXid}
					cik={this.props.cik}
					activeTab={this.props.activeTab}
					heightIncrement={this.props.heightIncrement}
					symbol={this.props.symbol}
					numPeriods={this.state.numPeriods}
					financialStatementsKeyData={this.state.financialStatementsKeyData}
					financialRatiosHistoryData={this.state.financialRatiosHistoryData}
					financialStatementsData={this.state.financialStatementsData}
					isMobile={this.state.isMobile}
				/>
			</section>
		);
	}
}

export default Financials;
