import React from "react";

const Right = (props) => {
	return (
		<svg
			data-value={props.dataValue}
			onClick={props.onClick}
			aria-label={props.ariaLabel}
			xmlns="http://www.w3.org/2000/svg"
			width="11px"
			height="14px"
			viewBox="0 0 9 15"
			version="1.1"
		>
			<title>{props.ariaLabel}</title>
			<g id="page-right" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g
					id={props.dataValue}
					transform="translate(-7.000000, -4.000000)"
					fill={props.color || "#187ABA"}
				>
					<path d="M7.79392296,4.70607704 C8.18387854,4.31612146 8.82226563,4.32226562 9.20558179,4.70558179 L15.2944182,10.7944182 C15.6841003,11.1841003 15.6777344,11.8222656 15.2944182,12.2055818 L9.20558179,18.2944182 C8.81589973,18.6841003 8.18188858,18.6818886 7.79392296,18.293923 L7.70607704,18.206077 C7.31612146,17.8161215 7.32258606,17.1774139 7.71184039,16.7881596 L13,11.5 L7.71184039,6.21184039 C7.3187018,5.8187018 7.31811142,5.18188858 7.70607704,4.79392296 L7.79392296,4.70607704 Z" />
				</g>
			</g>
		</svg>
	);
};

export default Right;
