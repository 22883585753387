import React, { Component } from "react";
import PropTypes from "prop-types";
import tabStyle from "./tabs.module.scss";
import classNames from "classnames";
import { Link } from "react-router-dom";

class Tabs extends Component {
	references = {};
	constructor(props) {
		super(props);
		this.state = {
			activeTab: this.props.activeTab || this.props.children[0].props.label,
			numberOfTabs: this.props.children.length,
			navigationCursor: 0,
		};
		this.references = Array(this.props.children.length)
			.fill()
			.map(() => React.createRef());
	}

	onClickTabItem = (tab) => {
		this.setState({ activeTab: tab });
		this.props.onClick && this.props.onClick(tab);
	};

	onKeyPressHandler = (tab, e) => {
		e.preventDefault();
		if (e.key === "Enter") {
			this.onClickTabItem(tab);
		}
	};
	handleKeyDown = (e) => {
		let newNavigationCursor = this.state.navigationCursor;
		// arrow up/down button should select next/previous list element
		if (e.keyCode === 38 || e.keyCode === 37) {
			if (newNavigationCursor === 0) {
				newNavigationCursor = this.state.numberOfTabs - 1;
			} else {
				newNavigationCursor--;
			}
			this.setState({ navigationCursor: newNavigationCursor });
		} else if (e.keyCode === 40 || e.keyCode === 39) {
			if (newNavigationCursor === this.state.numberOfTabs - 1) {
				newNavigationCursor = 0;
			} else {
				newNavigationCursor++;
			}
			this.setState({ navigationCursor: newNavigationCursor });
		}
		this.focusById(newNavigationCursor);
	};

	getOrCreateRef = (id) => {
		if (!this.references.hasOwnProperty(id)) {
			this.references[id] = React.createRef();
		}
		return this.references[id];
	};

	focusById = (id) => {
		this.references[id].current && this.references[id].current.focus();
	};
	renderTabs = (activeTab) => {
		const { shouldUseLinks, navName } = this.props;
		return (
			<ul
				role="tablist"
				aria-label="Financial Tabs"
				className={`${tabStyle.tab_list} `}
				onKeyDown={(e) => this.handleKeyDown(e)}
			>
				{this.props.children.map((child, index) => {
					const { label } = child.props;
					const textElement = <span className={`${tabStyle.tab_text} `}>{label}</span>;
					const className = classNames(tabStyle.tab_list_item, {
						[tabStyle.tab_list_active]: activeTab === label,
					});
					return shouldUseLinks ? (
						<Link
							to={`${navName}/${label.replace(/\s/g, "")}${window.location.search}`}
							className={className}
							onClick={() => this.onClickTabItem(label)}
						>
							{textElement}
						</Link>
					) : (
						<li
							ref={this.references[index]}
							className={className}
							title={label}
							tabIndex={index === this.state.navigationCursor ? 0 : -1}
							role="tab"
							aria-selected={activeTab === label ? "true" : "false"}
							key={index}
							onClick={() => this.onClickTabItem(label)}
							onKeyPress={(e) => this.onKeyPressHandler(label, e)}
						>
							{textElement}
						</li>
					);
				})}
			</ul>
			// </div>
		);
	};

	render() {
		const {
			props: { children },
			state: { activeTab },
		} = this;
		return (
			<div className={`${tabStyle.tabs}`}>
				{this.renderTabs(activeTab)}
				<div
					className={`${tabStyle.tab}`}
					role="tabpanel"
					tabIndex="0"
					aria-hidden="false"
					aria-live="polite"
				>
					{children.map((child) => {
						if (typeof child !== "boolean" && child.props.label === activeTab) {
							return child.props.children;
						}
						return undefined;
					})}
				</div>
			</div>
		);
	}
}
Tabs.propTypes = {
	children: PropTypes.instanceOf(Array).isRequired,
	activeTab: PropTypes.string,
	onClick: PropTypes.func,
};

export default Tabs;
