import React, { useState, useEffect, useCallback, useRef } from "react";
import { Dropdown } from "react-bootstrap";
import classNames from "classnames";

import SearchIcon from "components/Lib/Icons/Search/Search";
import MSDropdown from "components/Lib/MSDropdown/MSDropdown";
import Chevron from "components/Lib/Chevron/Chevron";
import DateRangePickerModal from "./DateRangePickerModal/DateRangePickerModal";
import MSCheckbox from "components/Lib/MSCheckbox/MSCheckbox";
import formatUtils from "utils/formatUtils";

import CollapsibleResponsiveFilter from "./CollapsibleResponsiveFilter/CollapsibleResponsiveFilter";
import {
	ResearchSource,
	ReportType,
	buildDefaultFilterState,
	DateSpan,
} from "../ResearchAPIParams";

import styles from "./ResearchFilters.module.scss";
import dropdownStyles from "components/Lib/MSDropdown/MSDropdown.module.scss";
import languageEn from "assets/language/en";
import Utils from "utils/utils";

const minStartDate = () => {
	return formatUtils.moment().subtract(10, "year");
};

// When the responsive filter opens or changes height for mobile it will update the entire iframe's height to match. Only works in integrated.
const updateResponsiveFilterIframe = () => {
	setTimeout(() => {
		let responsiveFilter = document.getElementById("responsive-filters-modal-container");
		Utils.postDimensions(
			responsiveFilter?.clientHeight,
			responsiveFilter?.clientWidth,
			0
		);
	}, 25);
};

// When the responsive filter closes in mobile via X, Cancel, or Apply this updates the entire iframe's height back . Only works in integrated.
const updatePagesIframe = () => {
	setTimeout(() => {
		let rootHeight = document.getElementById("root");
		Utils.postDimensions(rootHeight?.clientHeight, rootHeight?.clientWidth);
	}, 25);
};

// TODO: Check if we need an `id` and `for` here for accessibility.
//      The `MSDropdown` doesn't currently have an `id` parameter so that will need to be added, if so.
const DesktopWrapInput = React.memo(({ label, labelId, children }) => (
	<div className="form-group">
		<label className="text-muted" id={labelId}>
			{label}
		</label>
		{children}
	</div>
));

// NOTE: Intentionally not using <Dropdown.Item /> so that the dropdown doesn't
//  close on click.
const ReportTypeToggle = React.memo(
	({ id, label, checked, onClick, className = "dropdown-item" }) => {
		return (
			<button
				className={classNames(
					className,
					styles.checkboxFilter,
					checked ? styles.checkboxFilterChecked : undefined
				)}
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
					onClick();
				}}
				role="checkbox"
				aria-checked={checked}
				tabIndex="0"
			>
				<label id={`reportToggle_${id}`}>
					<span className={styles.checkboxFilterCheck} />
					{label}
				</label>
			</button>
		);
	}
);

export const CommonReportTypeFilters = React.memo(
	({
		idNamespace,
		reportTypes,
		onReportTypeChange,
		className = "dropdown-item",
		language,
	}) => {
		return (
			<>
				<ReportTypeToggle
					id={`reportType_${idNamespace}_all`}
					className={className}
					label={
						language && language.ALL_REPORTS
							? language.ALL_REPORTS
							: languageEn.ALL_REPORTS
					}
					// Note that "all" is a special case where all other values are replaced
					checked={ReportType.hasAll(reportTypes)}
					onClick={() => onReportTypeChange([])}
				/>
				<ReportTypeToggle
					id={`reportType_${idNamespace}_video`}
					className={className}
					label={language && language.VIDEO ? language.VIDEO : languageEn.VIDEO}
					checked={ReportType.hasType(reportTypes, "video")}
					onClick={() => onReportTypeChange(ReportType.toggleValue(reportTypes, "video"))}
				/>
				<ReportTypeToggle
					id={`reportType_${idNamespace}_audio`}
					className={className}
					label={language && language.AUDIO ? language.AUDIO : languageEn.AUDIO}
					checked={ReportType.hasType(reportTypes, "audio")}
					onClick={() => onReportTypeChange(ReportType.toggleValue(reportTypes, "audio"))}
				/>
			</>
		);
	}
);

const DesktopReportTypeFilter = React.memo((props) => {
	return (
		<Dropdown language={props.language}>
			<Dropdown.Toggle
				className={dropdownStyles.dropdownToggle}
				aria-labelledby={props["aria-labelledby"]}
			>
				{props.reportTypes
					.map((rt) => props.language[ReportType.findByKey(rt).label.toUpperCase()])
					.join(", ") ||
					(props.language && props.language.ALL_REPORTS
						? props.language.ALL_REPORTS
						: languageEn.ALL_REPORTS)}
				<Chevron direction="down" dimension={16} color="black" />
			</Dropdown.Toggle>
			<Dropdown.Menu
				className={classNames(dropdownStyles.dropdownMenu, styles.reportType)}
			>
				<CommonReportTypeFilters
					idNamespace="desktop"
					divider={<Dropdown.Divider className={styles.dropdownDivider} />}
					{...props}
				/>
			</Dropdown.Menu>
		</Dropdown>
	);
});

const FilterReportByHeadlineOnly = React.memo(
	({ headlineOnlyVisibilityState, onHeadlineOnlyChange, language }) => {
		return (
			<MSCheckbox
				id={`headlineOnlyVisibility`}
				checked={headlineOnlyVisibilityState}
				onChange={(e) => onHeadlineOnlyChange()}
				label={
					language && language.SHOW_HEADLINE_ONLY
						? language.SHOW_HEADLINE_ONLY
						: languageEn.SHOW_HEADLINE_ONLY
				}
				className={styles.headlineCheckboxFilter}
			/>
		);
	}
);

/**
 * Responsive variations on the filters. Note that the behaviour in this
 * component is different than the desktop filters: here we make a copy
 * of the actual filter state and hold that locally until "Apply" is pressed.
 */
const ResponsiveFilter = React.memo(
	({ filterData, onFilterChange, visible, onClose, language, locale, client }) => {
		// Make a copy of the current state. We'll modify this and then apply it after.
		const [localFilterData, setLocalFilterData] = useState(
			JSON.parse(JSON.stringify(filterData))
		);

		const [isVisibilitySet, setIsVisibilitySet] = useState(false);
		const resetState = useCallback(() => {
			setLocalFilterData(JSON.parse(JSON.stringify(filterData)));
		}, [filterData]);

		const [datePickerVisible, setDatePickerVisible] = useState(false);

		const closeFilters = (e) => {
			if (typeof e === "object") onClose(e);
			else onClose();
			document.querySelector("#allResearchCard-title").scrollIntoView();
		};
		const apply = () => {
			onFilterChange(localFilterData);
			closeFilters();
		};
		const cancelFilterUpdates = (e) => {
			closeFilters(e);
			setIsVisibilitySet(false);
			setLocalFilterData(JSON.parse(JSON.stringify(filterData)));
		};
		const resetToDefault = () =>
			setLocalFilterData(buildDefaultFilterState({ keyword: filterData.keyword }));
		const onLocalFilterChange = (field, value) =>
			setLocalFilterData({ ...localFilterData, [field]: value });
		const onProviderChange = (provider) => onLocalFilterChange("provider", provider);
		const onReportTypeChange = (reportTypes) =>
			onLocalFilterChange("reportTypes", reportTypes);

		const onDateChange = (dateSpan) => {
			if (dateSpan === DateSpan.CUSTOM_DATE_ID) {
				setDatePickerVisible(true);
			} else {
				onLocalFilterChange("dateSpan", dateSpan);
			}
		};
		const onDatePickerChange = (startDate, endDate) => {
			setLocalFilterData({
				...localFilterData,
				dateSpan: DateSpan.CUSTOM_DATE_ID,
				startDate: startDate,
				endDate: endDate,
			});
		};

		// Make sure we reset the state any time the panel is displayed
		useEffect(() => {
			if (visible && !isVisibilitySet) {
				resetState();
				setIsVisibilitySet(true);
			}
		}, [isVisibilitySet, resetState, visible]);

		// Keep track of the current filter so only one is displayed.
		const [currentFilter, setCurrentFilter] = useState("provider");
		const toggleFilter = (option) => {
			if (option === currentFilter) {
				setCurrentFilter("");
			} else {
				setCurrentFilter(option);
			}
			updateResponsiveFilterIframe();
		};

		return (
			<div
				className={`card ${styles.responsiveFilter} ${
					visible ? styles.responsiveFilterVisible : ""
				}`}
				aria-hidden={!visible}
				id="responsive-filters-modal-container"
			>
				<DateRangePickerModal
					onDatePickerDateChange={onDatePickerChange}
					modalVisibility={datePickerVisible}
					onModalClose={() => setDatePickerVisible(false)}
					language={language}
					minStartDate={minStartDate}
					locale={locale}
				/>
				<div className="card-header bg-transparent">
					<div className="d-flex">
						<div className="w-100 order-0">
							<button
								type="button"
								className="btn btn-link"
								onClick={(e) => resetToDefault()}
							>
								{language && language.RESET ? language.RESET : languageEn.RESET}
							</button>
						</div>
						<div className="mx-auto order-1">
							<div className={classNames(styles.filterModalTitle, "w-100")}>
								{language && language.FILTERS ? language.FILTERS : languageEn.FILTERS}
							</div>
						</div>
						<div className="w-100 mx-auto order-2 text-right">
							<button
								type="button"
								className={styles.xButton}
								onClick={(e) => cancelFilterUpdates(e)}
							>
								X
							</button>
						</div>
					</div>
				</div>
				<div className="card-body overflow-auto">
					<CollapsibleResponsiveFilter
						title={
							language && language.PROVIDER ? language.PROVIDER : languageEn.PROVIDER
						}
						expanded={currentFilter === "provider"}
						onToggle={() => toggleFilter("provider")}
					>
						{ResearchSource.values.map(({ label, key }) => (
							<ReportTypeToggle
								id={`provider_responsive_${key}`}
								key={key}
								className={styles.responsiveFilterOption}
								label={label}
								checked={localFilterData.provider === key}
								onClick={() => onProviderChange(key)}
								language={language}
							/>
						))}
					</CollapsibleResponsiveFilter>
					<CollapsibleResponsiveFilter
						title={
							language && language.REPORT_TYPE
								? language.REPORT_TYPE
								: languageEn.REPORT_TYPE
						}
						expanded={currentFilter === "reportType"}
						onToggle={() => toggleFilter("reportType")}
					>
						<CommonReportTypeFilters
							idNamespace="responsive"
							divider={<hr />}
							className={styles.responsiveFilterOption}
							reportTypes={localFilterData.reportTypes}
							onReportTypeChange={onReportTypeChange}
							language={language}
						/>
					</CollapsibleResponsiveFilter>
					<CollapsibleResponsiveFilter
						title={
							language && language.DATE_SELECTION
								? language.DATE_SELECTION
								: languageEn.DATE_SELECTION
						}
						expanded={currentFilter === "dateSpan"}
						onToggle={() => toggleFilter("dateSpan")}
						id={"research-date-container"}
					>
						{DateSpan.values.map(({ label, key }) => (
							<ReportTypeToggle
								id={`dateSpan_responsive_${key}`}
								key={key}
								className={styles.responsiveFilterOption}
								label={label}
								checked={localFilterData.dateSpan === key}
								onClick={() => onDateChange(key)}
							/>
						))}
					</CollapsibleResponsiveFilter>
					{client === "CG" ? null : (
						<div className={classNames("row mt-5", styles.companySpecificFilterModal)}>
							<div className="col-12">
								<MSCheckbox
									id={`responsive_companyOnlyToggle`}
									checked={localFilterData.companyOnly}
									onChange={(e) =>
										onLocalFilterChange("companyOnly", !localFilterData.companyOnly)
									}
									label={
										language && language.COMPANY_SPECIFIC
											? language.COMPANY_SPECIFIC
											: languageEn.COMPANY_SPECIFIC
									}
								/>
							</div>
						</div>
					)}
					<button
						type="button"
						className={classNames("btn btn-link", styles.resetFilters)}
						onClick={(e) => resetToDefault()}
					>
						{language && language.RESET_FILTERS
							? language.RESET_FILTERS
							: languageEn.RESET_FILTERS}
					</button>
				</div>
				<div className="card-footer bg-transparent border-top-0 mb-3 mt-2">
					<div className="d-flex text-center">
						<button
							type="button"
							className={classNames(
								styles.buttonSecondaryCustom,
								"btn",
								"ml-auto",
								"mr-2"
							)}
							onClick={cancelFilterUpdates}
						>
							{language && language.CANCEL ? language.CANCEL : languageEn.CANCEL}
						</button>
						<button
							type="button"
							className={classNames(styles.buttonPrimaryCustom, "btn", "ml-2", "mr-auto")}
							onClick={apply}
						>
							{language && language.APPLY ? language.APPLY : languageEn.APPLY}
						</button>
					</div>
				</div>
			</div>
		);
	}
);

/**
 * The main research filter container. Bootstrap classes are used to toggle between
 * desktop and responsive mode.
 */
const ResearchFilters = ({
	filterData,
	onFilterChange,
	headlineOnlyVisibilityState,
	onHeadlineOnlyChange,
	onDatePickerDateChange,
	language,
	locale,
	clearFilterCallback,
	client,
}) => {
	const [responsiveFilterVisible, setResponsiveFilterVisible] = useState(false);
	const [modalVisibility, setModalVisibility] = useState(false);
	const [focus, setFocus] = useState(false);
	const datepickerRef = useRef(null);

	let customDateRange, inputClass;

	const interceptDateFilterSelection = (value) => {
		if (DateSpan.findByLabel(value).key === DateSpan.CUSTOM_DATE_ID) {
			// Intercept the date value so that if the custom date range is selected, we trigger the modal to open.
			// The `onFilterChange()` will be triggered after the user presses Apply in that case.
			let researchFilters;
			//checks to see if it is the mobile or desktop/ipad view of the filters. based on this, will set the scroll target to the correct id.
			if (responsiveFilterVisible) {
				researchFilters = document.querySelectorAll("[id^=research-date-container]")[0];
			} else {
				researchFilters = document.querySelectorAll("[id^=date_picker_div]")[0];
			}
			researchFilters.scrollIntoView();
			setModalVisibility(true);
		} else {
			onFilterChange("dateSpan", DateSpan.findByLabel(value).key);
		}
	};

	const handleFocusEvent = (event) => {
		if (event && event.type === "keyup") {
			// setting keyup event changes on tab only
			if (event.keyCode === 9) {
				setFocus(true);
			} else {
				setFocus(false);
			}
		}
	};

	useEffect(() => {
		const setLanguages = () => {
			if (language.ALL_PROVIDERS) {
				ResearchSource.translateLabels("all", language.ALL_PROVIDERS);
				DateSpan.translateLabels(DateSpan.values, language);
			}
		};

		if (language) {
			setLanguages();
		}
	}, [language]);

	// KB Sept. 10, 2020: I don't like this, but can't think of a better way that doesn't introduce a bunch of complexity.
	useEffect(() => {
		if (responsiveFilterVisible) {
			let element = document.getElementById("research-filter-container");
			updateResponsiveFilterIframe();
			element.scrollIntoView({ behavior: "auto", block: "start", inline: "nearest" });
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "inherit";
			updatePagesIframe();
		}
	}, [responsiveFilterVisible]);

	if (
		DateSpan.findByKey(filterData.dateSpan).label === "Custom Date" &&
		filterData.startDate &&
		filterData.endDate
	) {
		customDateRange = `${formatUtils.date(
			filterData.startDate,
			"MM/DD/YY"
		)} - ${formatUtils.date(filterData.endDate, "MM/DD/YY")}`;
	} else {
		customDateRange = false;
	}
	inputClass = `${styles.filterKeyword} ${focus ? "focusVisible" : "focusReset"}`;

	const filterFunctions = () => {
		if (client === "CG") {
			clearFilterCallback();
		} else {
			onFilterChange(buildDefaultFilterState({ keyword: filterData.keyword }));
		}
	};

	return (
		<div className={styles.filters}>
			<ResponsiveFilter
				filterData={filterData}
				onFilterChange={onFilterChange}
				visible={responsiveFilterVisible}
				language={language}
				locale={locale}
				client={client}
				onClose={() => setResponsiveFilterVisible(false)}
			/>
			<div>
				<div className={styles.searchContainer}>
					<SearchIcon color="#212529" dimension="20px" />
					<input
						autoFocus={true}
						tabIndex="0"
						className={inputClass}
						onChange={(e) => onFilterChange("keyword", e.target.value)}
						placeholder={
							language && language.ENTER_KEYWORDS
								? language.ENTER_KEYWORDS
								: languageEn.ENTER_KEYWORDS
						}
						type="text"
						value={filterData.keyword}
						onKeyUp={handleFocusEvent}
						onBlur={() => setFocus(false)}
						onMouseDown={() => setFocus(false)}
					/>
				</div>
				<div
					className={classNames("row", styles.dropdowns)}
					id="research-filter-container"
				>
					<div className="col-lg-3 col-sm-12 d-none d-sm-block">
						<DesktopWrapInput
							label={language && language.SOURCE ? language.SOURCE : languageEn.SOURCE}
							labelId="desktop_research_source"
						>
							<MSDropdown
								data={ResearchSource.values.map(({ label, key }) => {
									return {
										option: label,
										action: key,
									};
								})}
								title={ResearchSource.findByKey(filterData.provider).label}
								onSelectCallback={(value) =>
									onFilterChange("provider", ResearchSource.findByLabel(value).key)
								}
								aria-labelledby="desktop_research_source"
							/>
						</DesktopWrapInput>
					</div>
					<div
						className={`col-lg-3 col-sm-12 d-none d-sm-block ${styles.dropdownsStyle}`}
					>
						<DesktopWrapInput
							label={
								language && language.REPORT_TYPE
									? language.REPORT_TYPE
									: languageEn.REPORT_TYPE
							}
							labelId="desktop_report_type"
							language={language}
						>
							<DesktopReportTypeFilter
								reportTypes={filterData.reportTypes}
								onReportTypeChange={(nextReportTypes) =>
									onFilterChange("reportTypes", nextReportTypes)
								}
								aria-labelledby="desktop_report_type"
								language={language}
							/>
						</DesktopWrapInput>
					</div>
					<div
						ref={datepickerRef}
						className={`col-lg-3 col-sm-12 d-none d-sm-block ${styles.dropdownsStyle}`}
						id="date_picker_div"
					>
						<DesktopWrapInput
							label={
								language && language.DATE_SELECTION
									? language.DATE_SELECTION
									: languageEn.DATE_SELECTION
							}
							labelId="desktop_date_range"
						>
							<MSDropdown
								data={DateSpan.values.map(({ label, key }) => {
									return {
										option: label,
										action: key,
									};
								})}
								title={DateSpan.findByKey(filterData.dateSpan).label}
								onSelectCallback={(value) => interceptDateFilterSelection(value)}
								controlled={true}
								aria-labelledby="desktop_date_range"
								titleOverride={customDateRange}
							/>
							<DateRangePickerModal
								onDatePickerDateChange={onDatePickerDateChange}
								modalVisibility={modalVisibility}
								onModalClose={() => {
									setModalVisibility(false);
								}}
								language={language}
								locale={locale}
								minStartDate={minStartDate}
							/>
						</DesktopWrapInput>
					</div>
					<div
						className={classNames(
							`col-lg-3 col-sm-12 d-flex flex-sm-nowrap align-items-center ${styles.dropdownsStyle} ${styles.companySpecificFullScreen}`
						)}
					>
						{client === "CG" ? null : (
							<MSCheckbox
								id={`companyOnlyToggle`}
								checked={filterData.companyOnly}
								onChange={(e) => onFilterChange("companyOnly", !filterData.companyOnly)}
								label={
									language && language.COMPANY_SPECIFIC
										? language.COMPANY_SPECIFIC
										: languageEn.COMPANY_SPECIFIC
								}
								className="my-auto mr-3"
								inline={true}
							/>
						)}
					</div>
					<div className="d-none d-sm-block d-lg-none col-12">
						<hr />
					</div>
				</div>
				<div className={styles.lowerFilterContainer}>
					<FilterReportByHeadlineOnly
						headlineOnlyVisibilityState={headlineOnlyVisibilityState}
						onHeadlineOnlyChange={onHeadlineOnlyChange}
						language={language}
					/>
					<div className={classNames("row", styles.sortBy)}>
						<div className="col-sm-12 col-xs-12">
							<div className=" d-none d-sm-flex flex-wrap justify-content-between">
								<div className={classNames("my-auto", styles.resetFiltersContainer)}>
									<button
										type="button"
										className={classNames("btn", "btn-link", styles.resetFilters)}
										onClick={(e) => filterFunctions()}
									>
										{language && language.RESET_FILTERS
											? language.RESET_FILTERS
											: languageEn.RESET_FILTERS}
									</button>
								</div>
							</div>
							<div
								className={classNames(
									"d-inline-block d-sm-none float-right",
									styles.viewFilters
								)}
							>
								<button
									type="button"
									className="btn btn-link"
									onClick={(e) => {
										e.preventDefault();
										setResponsiveFilterVisible(true);
										Utils.postScrollTop();
									}}
								>
									{language?.VIEW_FILTERS
										? language.VIEW_FILTERS
										: languageEn.VIEW_FILTERS}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ResearchFilters;
