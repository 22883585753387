import React from "react";
import DefaultChartConstant from "components/MarketsAndResearch/ChartingCommon/Constants/Chart/DefaultChartConstant";
import CandlestickChart from "components/Lib/Icons/CandlestickChart/CandlestickChart";
import HistogramChart from "components/Lib/Icons/HistogramChart/HistogramChart";
import DotChart from "components/Lib/Icons/DotChart/DotChart";
import OHLCChart from "components/Lib/Icons/OHLCChart/OHLCChart";
import Indicators from "components/MarketsAndResearch/Charting/Indicators";
const EVENT_DICT = {
	events: {
		dividends: "dividends",
		earnings: "earnings",
		earningscustom: "earnings",
		highlow: "highlow",
		splits: "splits",
		morganstanleyratingscustom: "morganstanleyratingscustom",
		morningstarratingscustom: "morningstarratingscustom",
	},
	earnings: {
		eventKey: "earnings",
		eventName: "Earnings",
	},
	dividends: {
		eventKey: "dividends",
		eventName: "Dividends",
	},
	highlow: {
		eventKey: "highlow",
		eventName: "High / Low",
	},
	morningstarratingscustom: {
		eventKey: "morningstarratingscustom",
		eventName: "Morningstar Ratings",
	},
	morganstanleyratingscustom: {
		eventKey: "morganstanleyratingscustom",
		eventName: "Morgan Stanley Ratings",
	},
	splits: {
		eventKey: "splits",
		eventName: "Splits",
	},
};

const PRICE_FORMATS = {
	PERCENT: {
		label: "%",
		value: "percent",
		disable: false,
	},
	ABSOLUTE: {
		label: "$",
		value: "absolute",
		disable: false,
	},
};

const INDICATOR_LIMITS = {
	UPPER: 6,
	LOWER: 5,
};

const PANZOOM = {
	RANGE_RATIO: 0.2,
	DURATION: 300,
	ZOOM_DISTANCE: 1.5,
	TOOLBAR_WIDTH: 253,
};

const DEFAULT_PANEL_PARAMS = JSON.parse(
	JSON.stringify(DefaultChartConstant.DEFAULT_PANEL_PARAMS)
);

DEFAULT_PANEL_PARAMS.style.panel.yAxis.padding.top = 30;
DEFAULT_PANEL_PARAMS.style.panel.yAxis.padding.bottom = 20;
DEFAULT_PANEL_PARAMS.style.panel.border.color.bottom = "#767676";
DEFAULT_PANEL_PARAMS.style.panel.border.color.default = null;
DEFAULT_PANEL_PARAMS.style.panel.margin.bottom = 62;
DEFAULT_PANEL_PARAMS.style.indicator.volume = Indicators.DICTIONARY["volume0"].config;
const CHART_CONFIG = {
	...DefaultChartConstant.CHART_CONFIG,
	params: {
		...DefaultChartConstant.CHART_CONFIG.params,
		dateStart: false,
		dateStop: false,
		zoomEnabled: true,
		showXAxisFlag: true,
		lastClose: false,
		dataNormalized: false,
		crosshair: null,
		style: JSON.parse(JSON.stringify(DEFAULT_PANEL_PARAMS.style)),
		returnPreviousPoint: true,
	},
	panels: [
		{
			indicators: [
				DefaultChartConstant.CHART_CONFIG.panels[0].indicators[0],
				Indicators.DICTIONARY["volume0"].config,
			],
		},
	],
};

const DEFAULT_PANEL_PARAMS_LOWER = {
	primaryOhlcType: "posneg",
	style: {
		yAxis: {
			...DEFAULT_PANEL_PARAMS.style.panel.yAxis,
			label: {
				format: {
					default: {
						format: "0,.0f",
						formatPercent: "0,.0%",
					},
					large: {
						format: "0,.2s",
						formatPercent: "0,.2%",
					},
					small: {
						format: "0.2f",
						formatPercent: "0.2%",
						threshold: 1,
					},
					micro: {
						format: ".4f",
						formatPercent: "0.2%",
					},
					medium: {
						format: "0,.2f",
						formatPercent: "0,.1%",
					},
				},
			},
		},
	},
};

const LINE_STYLES = [
	...DefaultChartConstant.LINE_STYLES,
	{
		label: "Candlestick",
		markerType: "candlestick",
		icon: <CandlestickChart />,
		lineColor: DefaultChartConstant.CHART_COLORS.LINE,
	},
	{
		label: "Open / High / Low / Close",
		markerType: "ohlc",
		icon: <OHLCChart />,
		lineColor: DefaultChartConstant.CHART_COLORS.LINE,
	},
	{
		label: "Histogram",
		markerType: "bar",
		icon: <HistogramChart />,
		lineColor: DefaultChartConstant.CHART_COLORS.HISTOGRAM,
	},
	{
		label: "Dot",
		markerType: "shape",
		icon: <DotChart />,
		lineColor: DefaultChartConstant.CHART_COLORS.DOT,
	},
];

const ERROR_MESSAGES = {
	UPPER_ERROR:
		"You have reached the maximum number of upper indicators and comparisons. Please remove one and try again or click reset chart.",
	LOWER_ERROR:
		"You have reached the maximum number of lower indicators.  Please remove one and try again or click reset chart.",
};

export default {
	DEFAULT_PANEL_PARAMS,
	DEFAULT_PANEL_PARAMS_LOWER,
	PRICE_FORMATS,
	INDICATOR_LIMITS,
	EVENT_DICT,
	PANZOOM,
	CHART_CONFIG,
	LINE_STYLES,
	ERROR_MESSAGES,
};
