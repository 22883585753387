/*
Author : MSOnline
Description : Functionality proviced by MSOR, that will be consumed by vendors
*/

function GetDomainNameForMSO() {
	var defaultdomain = "ms.com";
	try {
		var host = document.domain;
		var match = /[^.]*\.[^.]*(\.)?$/.exec(host);

		if (!(match == null)) {
			return match[0];
		}
	} catch (err) {}

	return defaultdomain;
}

try {
	//document.domain = "morganstanleyclientserv.com";
	document.domain = GetDomainNameForMSO();
} catch (e) {
	// alert("Error setting document domain : " + e);
}

//Functionality proviced by MSOR, that will be consumed by vendors

var MSORVendor = {
	LogMessage: function (type, msg) {
		if (window.console) {
			console.log(type + ":" + msg);
		}
	},

	ShowMarketDataForSymbol: function (symbolName) {
		if (
			typeof window.parent !== "undefined" &&
			typeof window.parent.MSORShell !== "undefined" &&
			typeof window.parent.MSORShell.ShowMarketDataForSymbol === "function"
		) {
			window.parent.MSORShell.ShowMarketDataForSymbol(symbolName);
		}
	},

	ShowMarketDataPopUp: function (symbol) {
		if (
			typeof window.parent !== "undefined" &&
			typeof window.parent.MSORShell !== "undefined" &&
			typeof window.parent.MSORShell.ShowMarketDataPopup === "function"
		) {
			window.parent.MSORShell.ShowMarketDataPopup(symbol);
		}
	},

	//CheckMethod: function(methodname)
	//{
	//    if (typeof window.parent !== 'undefined'
	//        && typeof window.parent.MSORShell !== 'undefined'
	//        && typeof window.parent.MSORShell[methodname] === 'function') {
	//        return true;
	//    }
	//    else {
	//        LogMessage("Error : Method '" + methodname + "' not found");
	//        return false;
	//    }
	//},

	//....other functions

	/**********************************************
    OpenModal(ModalSettings)

    This function used to open modal popup
    Parameters
        ModalSettings=
        {
            Title   : title for the popup,
            HTML    : html template,
            OR
            URL     : url of template
            OR
            WellKnownName: name of predefined well known common modal window (e.g. MarketData,CreateAlert,Definitions),
            WindowsSettings: { Width: width; Height: height},
            Context : { Symbol : symbolname,
                        DefinitionType: name of service. e.g. marketresearch,portfolio,billpay, etc}
        }

    *************************************************/
	OpenModal: function (ModalSettings) {
		return window.parent.MSORShell.OpenModal(ModalSettings);
	},

	/**********************************************
    GetData(DataRequest)

    This function return the portfolio data in json
    Parameters
        DataRequest=
        {
         WellKnownName: name of predefined type like 'GetTopHoldings'
        }

    *************************************************/

	GetData: function (DataRequest) {
		return window.parent.MSORShell.GetData(DataRequest);
	},

	/**********************************************
    ResizeIframe(WindowSettings)

    This function provides ability to resize iframe
    Parameters
        WindowSettings=
        {
         Width: width,
         Height: Height
        }

    *************************************************/

	ResizeIframe: function (WindowSettings) {
		window.parent.MSORShell.ResizeIframe(WindowSettings);
	},

	/**********************************************
    RedirectTo(RedirectData)

    This function provides ability to redirect user to differect location
    Parameters
        RedirectData=
        {
         Service: service name where to redirect. e.g. Trade,Transfer,MarketResearch
         Action: action name. e.g. Buy,Sell
         Symbol: symbol,
         Type : 'iframe'
         Context: context
        }

    *************************************************/

	RedirectTo: function (RedirectData) {
		window.parent.MSORShell.RedirectTo(RedirectData);
	},

	/**********************************************
    CloseModal(CloseParams,funct,result)


    Parameters
        CloseParams = {
            RedirectOnClose: true|false - true will redirect to other page when popup is closed
            RedirectToURL: url - if RedirectOnClose is set to true then url must be set for redirection
            //OR
            ReloadIframe:  true|false - true will reload the underlying page when popup is closed
            //OR
            ReloadWindow:  true|false - true will reload the window when popup is closed
        }

    *************************************************/
	CloseModal: function (CloseParams) {
		window.parent.MSORShell.CloseModal(CloseParams);
	},

	GetRequest: function () {
		return window.parent.MSORShell.Request();
	},

	SetResponse: function (result) {
		window.parent.MSORShell.Response = result;
	},
};

window.MSORVendor = MSORVendor;
