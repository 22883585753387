const getQueryParam = (queryParams, param) => {
	const query = new URLSearchParams(queryParams, param);
	const outputParam = query.get(param);
	if (outputParam) {
		return outputParam.toLowerCase();
	}
	return outputParam;
};

export default {
	getQueryParam,
};
