/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, useRef } from "react";
import { useQuery } from "react-query";
import { renderToString } from "react-dom/server";

import { ModchartsInteractive as Modcharts, Crosshair } from "@markit/modcharts";
import ChartAnalytics from "@markit/analytics-modcharts";

import MSDropdown from "components/Lib/MSDropdown/MSDropdown";
import TextToggle from "components/Lib/TextToggle/TextToggle";
import CollapsibleCard from "components/Lib/CollapsibleCard/CollapsibleCard";
import CandlestickLegend from "components/Lib/Icons/CandlestickLegend/CandlestickLegend";
import ChartEventsOverlay from "components/Lib/ChartEventsOverlay/ChartEventsOverlay";
import ChartEventsConstants from "components/Lib/ChartEventsOverlay/ChartEventsConstants";
import SimpleSpinner from "components/Lib/SimpleSpinner/SimpleSpinner";

import XrefAPI from "services/APIs/Xref/XrefAPI";
import { getSingleQuoteDetails } from "services/APIs/Quote/QuoteAPI";
import { getPeersDetails } from "services/APIs/MorganStanley/Peers/PeersAPI";

import { URLS } from "utils/constants";
import { getCookieValue, getFormattedNumber, isRealtimeNasdaqUser } from "utils/utils";
import formatter from "utils/formatUtils";
import FEATURE_FLAGS from "utils/featureFlags";

import { ReactComponent as Close } from "assets/icons/Close.svg";
import { ReactComponent as Visible } from "assets/icons/Visible.svg";
import { ReactComponent as Hidden } from "assets/icons/Unhide.svg";
import { ReactComponent as Edit } from "assets/icons/Edit.svg";
import ErrorIcon from "assets/icons/ErrorIcon.svg";

import LegendMovePanel from "components/MarketsAndResearch/Charting/LegendMovePanel/LegendMovePanel";
import Comparisons from "components/MarketsAndResearch/Charting/Comparisons";
import Menus from "components/MarketsAndResearch/Charting/Menus/Menus";

import Indicators from "components/MarketsAndResearch/Charting/Indicators";
import IndicatorInputPopUp from "components/MarketsAndResearch/Charting/Indicators/IndicatorsPopUp/IndicatorInputPopUp";
import IndicatorAccessibility from "components/MarketsAndResearch/Charting/Indicators/IndicatorAccessibility/IndicatorAccessibility";
import {
	addIndicatorDataToLegend,
	addStyleToIndicatorDataLegends,
} from "components/MarketsAndResearch/Charting/ChartingCard/IndicatorDataOnLegendsCard";
import { DEFAULT_USE_LINE_STYLES } from "components/MarketsAndResearch/Charting/Indicators/IndicatorsPopUp/IndicatorInputsConfig";

import YAxisFlag from "components/MarketsAndResearch/Charting/ChartingCard/YAxisFlag";
import XAxisFlag from "components/MarketsAndResearch/Charting/xAxisFlag/XAxisFlag";
import PanZoomToolBar from "components/MarketsAndResearch/Charting/ChartingCard/PanZoomToolBar";
import CrosshairTooltip from "components/MarketsAndResearch/Charting/ChartingCard/Crosshair/CrosshairTooltip";
import TimeFrame from "components/MarketsAndResearch/Charting/TimeFrame/TimeFrame";
import Interval from "components/MarketsAndResearch/Charting/Interval/Interval";

import {
	eventCustomData,
	IndicatorHighLowRender,
} from "components/MarketsAndResearch/ChartingCommon/Utility/CommonChartUtility";
import { DEFAULT_TIMEFRAME_INDEX } from "components/MarketsAndResearch/ChartingCommon/Constants/TimeFrame/Common";
import {
	CUSTOM_DATE_ID_VALUE,
	DATEPICKERFILTERSTATE,
	TIME_FRAMES,
} from "components/MarketsAndResearch/ChartingCommon/Constants/TimeFrame/AdvanceTimeFrame";
import AdvanceChartConstant from "components/MarketsAndResearch/ChartingCommon/Constants/Chart/AdvanceChart";
import Loader from "components/MarketsAndResearch/ChartingCommon/Loader/Loader";
import CustomLocale from "components/MarketsAndResearch/ChartingCommon/Constants/Locale/CustomLocale";

import styles from "components/MarketsAndResearch/Charting/ChartingCard/ChartingCard.module.scss";
import useBreakPoint from "utils/useBreakPoint";
import StateStorage from "utils/StateStorage";
import moment from "moment";

const CloseHtmlString = renderToString(<Close style={{ height: 12, width: 12 }} />);
const HiddenHtmlString = renderToString(<Hidden style={{ height: 15, width: 15 }} />);
const VisibleHtmlString = renderToString(<Visible style={{ height: 15, width: 15 }} />);
const EditHtmlString = renderToString(<Edit style={{ height: 15, width: 15 }} />);

const getLineStyleConfig = (id) =>
	AdvanceChartConstant.LINE_STYLES.find(({ label }) => label === id) ||
	AdvanceChartConstant.LINE_STYLES[0];
const getTimeFrameConfig = (id) =>
	id && typeof id !== "number"
		? id
		: TIME_FRAMES.find((timeFrame) => timeFrame.key === id) ||
		  TIME_FRAMES[DEFAULT_TIMEFRAME_INDEX];
const ChartingCard = (props) => {
	const { venueXid, userInfo, apiInstance } = props;
	const stateStorage = new StateStorage({ key: "chartingChartingCard" });
	const breakPoint = useBreakPoint();
	const isMobile = breakPoint === "mobile";
	const chartLoaderRef = useRef({});
	const chartRef = useRef({});
	const xAxisRef = useRef();
	const isRealTimeQuote = isRealtimeNasdaqUser(userInfo);

	const quoteQuery = useQuery(
		[URLS.QUOTE_PATH, apiInstance?.internalAuthToken, userInfo, venueXid],
		() => {
			return getSingleQuoteDetails({
				apiInstance,
				venueXid,
				isRealTimeQuote,
			});
		}
	);

	const [selectedTimeFrame, setSelectedTimeFrame] = useState(
		getTimeFrameConfig(stateStorage.get().timeFrame)
	);
	const [selectedInterval, setSelectedInterval] = useState(
		stateStorage.get().interval || getTimeFrameConfig().defaultPeriod
	);
	const [datePicker, setDatePicker] = useState(
		stateStorage.get().datePicker || DATEPICKERFILTERSTATE
	);
	const [selectedLineStyle, setSelectedLineStyle] = useState(
		getLineStyleConfig(stateStorage.get().lineStyle)
	);
	const setLineStyleConfig = (lineStyle) => {
		stateStorage.set({ lineStyle: lineStyle.label });
		setSelectedLineStyle(lineStyle);
	};
	const [selectedPriceFormat, setSelectedPriceFormat] = useState(
		stateStorage.get().priceFormat || AdvanceChartConstant.PRICE_FORMATS.ABSOLUTE.value
	);
	const setPriceFormatConfig = (priceFormat) => {
		stateStorage.set({ priceFormat });
		setSelectedPriceFormat(priceFormat);
	};
	const [chart, setChart] = useState(null);
	const [chartData, setChartData] = useState({});
	const [selectedIndicators, setSelectedIndicators] = useState([]);
	const [selectedComparisons, setSelectedComparisons] = useState([]);
	const [peersXids, setPeersXids] = useState([]);
	const [peersXrefs, setPeersXrefs] = useState(null);
	const [peersData, setPeersData] = useState(null);
	const [researchMSData, setResearchMSData] = useState(null);
	const [researchMstarData, setResearchMstarData] = useState(null);
	const [earningsData, setEarningsData] = useState(null);
	const [dividendsData, setDividendsData] = useState(null);
	const [finishedLoadingData, setFinishedLoadingData] = useState(false);
	const [selectedEvents, setSelectedEvents] = useState({
		dividends: false,
		earnings: false,
		highlow: false,
		morganstanleyratingscustom: false,
		morningstarratingscustom: false,
		splits: false,
	});
	const [eventsData, setEventsData] = useState({});
	const [eventVisible, setEventVisible] = useState({
		dividends: true,
		earnings: true,
		highlow: true,
		morganstanleyratingscustom: true,
		morningstarratingscustom: true,
		splits: true,
	});
	const [indicatorsVisiblitity, setIndicatorsVisiblity] = useState({});
	const [indicatorInputConfig, setIndicatorInputConfig] = useState({
		isOpen: false,
		config: null,
		name: null,
		panel: null,
		uid: null,
	});
	const [defaultStyle, setDefaultStyle] = useState(
		JSON.parse(JSON.stringify(DEFAULT_USE_LINE_STYLES))
	);
	const [availableStyle, setAvailableStyle] = useState([]);
	const [updateError, setUpdateError] = useState({});
	const [errorMSG, setErrorMSG] = useState(false);
	const [errorMessageForUpper, setErrorMessageForUpper] = useState(false);
	const [percentPrice] = useState(AdvanceChartConstant.PRICE_FORMATS.PERCENT);
	const [absolutePrice, setAbsolutePrice] = useState(
		AdvanceChartConstant.PRICE_FORMATS.ABSOLUTE
	);
	const xAxisLabels = () => {
		const xAxisLabel = CustomLocale.labelTemplates.xAxis.doubleRow;
		// Use abbreviated day format for smaller breakpoints in the 5 day view
		const intradayDateFormat =
			breakPoint === "mobile" || breakPoint === "sm-tablet" ? "%a" : "%A";
		const selectedNumDays = parseInt(selectedTimeFrame.numDays);
		if (selectedNumDays === 1) {
			xAxisLabel.hours = { default: "%X", first: "%A" };
			xAxisLabel.days.intraday = "%A";
		} else if (selectedNumDays < 6) {
			xAxisLabel.hours = { default: intradayDateFormat, first: "%-m/%-d" };
			xAxisLabel.days.intraday = "%-m/%-d";
		} else if (selectedNumDays <= 30) {
			xAxisLabel.hours = { default: "%e", first: "%b %Y" };
			xAxisLabel.days.intraday = " ";
		} else if (selectedNumDays < 91) {
			xAxisLabel.weeks = { default: "%e", first: "%b %Y" };
		} else if (selectedNumDays < 181) {
			xAxisLabel.months = { default: "%b", first: "%Y" };
		}
		chart.setLocale(CustomLocale);
	};

	useEffect(() => {
		fetchPeersData();
		eventCustomData(props, "charting", [
			setDividendsData,
			setEarningsData,
			setResearchMSData,
			setResearchMstarData,
		]);
	}, [venueXid]);

	useEffect(() => {
		fetchPeersXrefData(peersXids);
	}, [peersXids]);

	const fetchPeersData = useCallback(async () => {
		if (!venueXid) {
			return;
		}

		const equitiesPeersData = await getPeersDetails(apiInstance, venueXid);

		if (!equitiesPeersData || !equitiesPeersData.length) {
			return;
		}

		const NUM_PEER_COMPARISONS = 4;
		let newPeersXids = equitiesPeersData.map((peer) => peer.wsodIssue);
		newPeersXids =
			newPeersXids.length > NUM_PEER_COMPARISONS
				? newPeersXids.slice(0, NUM_PEER_COMPARISONS)
				: newPeersXids;
		setPeersXids(newPeersXids);

		let peersArray = [];
		equitiesPeersData
			.filter((peer) => peer.peerRank <= 4)
			.forEach((peer) => {
				peersArray.push(peer);
			});
		setPeersData(peersArray);
	}, [venueXid]);

	const fetchPeersXrefData = useCallback(
		async (venueXids) => {
			const peersXrefData = await XrefAPI.getXrefs(apiInstance, venueXids);
			setPeersXrefs(peersXrefData);
		},
		[peersXids]
	);

	const processPeersXrefData = useCallback(() => {
		if (!peersXrefs || !peersXrefs.length || peersXrefs === "error") {
			return;
		}

		const newPeersData = peersXrefs.map((peer) => {
			const xid = peer?.xids?.venue;
			if (xid && peer.symbol) {
				return {
					xid: xid.toString(),
					symbol: peer.symbol,
					companyName: peer.name,
					name: peer.symbol,
				};
			}
			return {};
		});

		setPeersData(newPeersData);
	}, [peersXrefs]);

	useEffect(() => {
		processPeersXrefData();
	}, [peersXrefs]);

	const initNewChart = useCallback(() => {
		const newChart = (window.chart = new Modcharts("#chartingCard"));
		new ChartAnalytics(newChart);

		// Only set the auth token if we are in non-integrated
		const accessTokenCookie = getCookieValue("access_token");
		if (!accessTokenCookie) {
			const authToken = window.localStorage.getItem("internalAuthToken");
			newChart.setAuthToken(authToken);
		}

		const newChartConfig = AdvanceChartConstant.CHART_CONFIG;
		newChartConfig.params.symbol = venueXid;
		newChartConfig.params.dataNormalized = selectedPriceFormat === "percent";
		newChartConfig.params.panelHeightUpper = 339;
		newChartConfig.params.panelHeightLower = 181;
		newChartConfig.params.panelXAxis = "all";
		newChartConfig.params.apiPath = `${apiInstance.baseUrl}${URLS.CHARTWORKS_SERIES_PATH}`;
		newChartConfig.params.realtime = isRealTimeQuote;
		const hasDatesSelected = datePicker.startDate && datePicker.endDate;
		newChartConfig.prependNormalizedPoint = hasDatesSelected;
		// Set returnPreviousPoint to false for intraday charts and true for EOD charts
		newChartConfig.params.returnPreviousPoint = selectedInterval.value !== "Minute";
		if (hasDatesSelected) {
			newChartConfig.params.days = moment(datePicker.endDate).diff(
				moment(datePicker.startDate),
				"days"
			);
			// set date range directly to chart rather than params, params didn't work as expected
			newChart.setDateRange(
				formatter.date(datePicker.startDate, "YYYY-MM-DD"),
				formatter.date(datePicker.endDate, "YYYY-MM-DD")
			);
		} else {
			newChartConfig.params.days = parseInt(selectedTimeFrame.numDays);
		}
		newChartConfig.params.dataPeriod = selectedInterval.value;
		newChartConfig.params.dataInterval = selectedInterval.interval;
		newChartConfig.panels[0].indicators[0].markerType = selectedLineStyle.markerType;
		newChart.load(newChartConfig, () => {
			const chartData = newChart.exportData();
			setChartData(chartData);
		});
		newChart.primaryOhlcType = AdvanceChartConstant.DEFAULT_PANEL_PARAMS.primaryOhlcType;
		setFinishedLoadingData(true);

		newChart.crosshair = new Crosshair(newChart, {
			enabled: true,
			flagEnabled: true,
			persist: false,
			primaryOnly: false,
			snapHorizontalToData: true,
			showFlagCrosshairDisabled: true,
			showCircle: true,
		});

		setChart(newChart);
		setErrorMSG(false);
		setUpdateError({
			indicatorsCount: 2,
			errorVisible: false,
		});
	});

	useEffect(() => {
		if (!quoteQuery.data || chart || !venueXid) return;
		initNewChart();
	}, [venueXid, quoteQuery.data]);

	useEffect(() => {
		if (!chart || !document.getElementById("chartingCard")) return;
		chart.params.dataNormalized = selectedPriceFormat === "percent";
		chart.loadData(() => {
			setChartData(chart ? chart.exportData() : {});
		});
		registerChartEvents();

		return () => unregisterChartEvents();
	}, [selectedPriceFormat]);
	useEffect(() => {
		if (!chart || !document.getElementById("chartingCard")) return;
		registerChartEvents();
		chart.setSymbolCompare(
			// xids to compare
			selectedComparisons.map((comparison) => comparison.xid),
			//Styles
			availableStyle.map((item) => {
				return {
					width: item.width,
					color: item.color,
					lineDash: item.lineDash,
				};
			})
		);
		chart.loadData(() => {
			setChartData(chart ? chart.exportData() : {});
		});
		return () => unregisterChartEvents();
	}, [selectedComparisons]);

	useEffect(() => {
		if (!chart || !document.getElementById("chartingCard")) return;
		registerChartEvents();
		chart.loadData(() => {
			setChartData(chart ? chart.exportData() : {});
		});
		return () => unregisterChartEvents();
	}, [selectedIndicators]);

	useEffect(() => {
		if (!chart || !document.getElementById("chartingCard")) return;
		registerChartEvents();
		if (chart.panels?.length) {
			const mainPanel = chart.panels[0];
			LineStyle(mainPanel);
		}
		chart.render();
		return () => unregisterChartEvents();
	}, [selectedLineStyle]);
	useEffect(() => {
		if (!chart || !document.getElementById("chartingCard")) return;
		registerChartEvents();
		return () => {
			unregisterChartEvents();
		};
	}, [chart]);

	useEffect(() => {
		if (!chart || !document.getElementById("chartingCard")) return;
		registerChartEvents();
		chart.setDataPeriod(selectedInterval.value);
		chart.setDataInterval(selectedInterval.interval);
		xAxisLabels();
		const hasDatesSelected = datePicker.startDate && datePicker.endDate;
		if (chart.params) {
			chart.params.prependNormalizedPoint = hasDatesSelected;
			// Set returnPreviousPoint to false for intraday charts and true for EOD charts
			chart.params.returnPreviousPoint = selectedInterval.value !== "Minute";
		}
		// This manually resets the domain to address MOID-384788. This can be removed after ModCharts is updated.
		chart.resetDomain();
		if (hasDatesSelected) {
			chart.setDateRange(
				formatter.date(datePicker.startDate, "YYYY-MM-DD"),
				formatter.date(datePicker.endDate, "YYYY-MM-DD")
			);
		} else {
			chart.setDays(selectedTimeFrame.numDays);
		}
		chart.loadData(() => {
			setChartData(chart ? chart.exportData() : {});
		});

		return () => unregisterChartEvents();
	}, [chart, selectedTimeFrame, selectedInterval, datePicker]);

	useEffect(() => {
		if (!chart?.panels?.length) return;
		registerChartEvents();
		const mainPanel = chart.panels[0];

		AddEvents(mainPanel);
		chart.loadData(() => {
			setChartData(chart ? chart.exportData() : {});
		});
		return () => unregisterChartEvents();
	}, [selectedEvents]);

	//To check line styles for upper indicator/comparison on removing
	const checkStylesRemove = (e, isComparison) => {
		let index = defaultStyle.findIndex(
			(item) =>
				item.isAvailable === false &&
				item.color === e.params.style.color &&
				item.lineDash === e.params.style.lineDash
		);
		if (isComparison) {
			//compare style that is to be removed from comparison styles array
			let i = availableStyle.findIndex(
				(item) =>
					item.isAvailable === false &&
					item.color === defaultStyle[index].color &&
					item.lineDash === defaultStyle[index].lineDash
			);
			availableStyle.splice(i, 1);
		}
		if (index >= 0) {
			defaultStyle[index].isAvailable = true;
			setDefaultStyle([...defaultStyle]);
		}
	};

	const getIndexOfAvailableStyle = () =>
		defaultStyle.findIndex((item) => item.isAvailable === true);

	//To check line styles for upper indicator/comparison on adding
	const checkStylesAdd = () => {
		const index = getIndexOfAvailableStyle();
		defaultStyle[index].isAvailable = false;
		availableStyle.push(defaultStyle[index]);
		setDefaultStyle([...defaultStyle]);
	};

	const AddEvents = (mainPanel) => {
		if (mainPanel?.events?.length) {
			mainPanel.removeEvent(ChartEventsConstants.EVENT_TYPES.EARNINGS);
			mainPanel.removeEvent(ChartEventsConstants.EVENT_TYPES.CUSTOM);
			mainPanel.removeEvent(ChartEventsConstants.EVENT_TYPES.SPLITS);
		}

		// remove all events as well as highlow indicator.
		mainPanel.indicators.forEach((indicator) => {
			if (indicator.params.id === "highlow") mainPanel.removeIndicator(indicator);
		});

		if (selectedEvents.earnings && earningsData) {
			mainPanel.addEvent(ChartEventsConstants.EVENT_TYPES.CUSTOM, {
				name: ChartEventsConstants.EVENT_TYPES.EARNINGS,
				dataset: earningsData,
			});
		}

		if (selectedEvents.dividends && dividendsData) {
			mainPanel.addEvent(ChartEventsConstants.EVENT_TYPES.CUSTOM, {
				name: ChartEventsConstants.EVENT_TYPES.DIVIDENDS,
				dataset: dividendsData,
			});
		}

		if (selectedEvents.morganstanleyratingscustom && researchMSData) {
			mainPanel.addEvent(ChartEventsConstants.EVENT_TYPES.CUSTOM, {
				name: ChartEventsConstants.EVENT_TYPES.RATINGSMS,
				dataset: researchMSData,
			});
		}

		if (selectedEvents.morningstarratingscustom && researchMstarData) {
			mainPanel.addEvent(ChartEventsConstants.EVENT_TYPES.CUSTOM, {
				name: ChartEventsConstants.EVENT_TYPES.RATINGSMSTAR,
				dataset: researchMstarData,
			});
		}

		if (selectedEvents.highlow && eventVisible.highlow) {
			mainPanel.addIndicator(
				Indicators.DICTIONARY.highlow.value,
				Indicators.DICTIONARY.highlow.config
			);
		}
		if (selectedEvents.splits) {
			mainPanel.addEvent(ChartEventsConstants.EVENT_TYPES.SPLITS);
		}
	};

	const LineStyle = (mainPanel) => {
		if (mainPanel.indicators && mainPanel.indicators.length) {
			const firstIndicator = mainPanel.indicators[0];
			if (firstIndicator.params) {
				firstIndicator.params.markerType = selectedLineStyle.markerType;

				if (firstIndicator.params.style) {
					firstIndicator.params.style.color = selectedLineStyle.lineColor;
					firstIndicator.params.style.width = 2;
				}
			}
		}
	};

	const updateLastCloseLabels = () => {
		const i = chart.getIndicatorsById("previousclose");
		if (i.length) {
			const indicatorId = i[0].params.uid;
			const lastCloseDate = formatter.date(
				chart.data[venueXid].previousclose[indicatorId][0].date,
				"M/D/YYYY"
			);
			const lastClosePrice = formatter.price(
				chart.data[venueXid].previousclose[indicatorId][0].previousclose
			);
			i[0].params.text = `${lastCloseDate} Close ${lastClosePrice}`;
		}
	};

	const dataLoadComplete = () => {
		updateLastCloseLabels();
		updateChartingCardHeight();
	};

	const registerChartEvents = () => {
		chart.eventEmitter.on("PANEL_LEGEND_UPDATE", panelLegendUpdate);
		chart.eventEmitter.on("EVENT_RENDER", eventRenderDefault);
		chart.eventEmitter.on("EVENT_REMOVE", eventRenderDefault);
		chart.eventEmitter.on("INDICATOR_HIGHLOW_RENDER", (highLowData) =>
			IndicatorHighLowRender(highLowData, {
				minSpaceTop: 20,
				minSpaceBottom: 50,
				defaultHighTextPosition: 0,
			})
		);
		chart.eventEmitter.on("LOAD_START", chartLoaderRef.current.chartLoaderStart);
		chart.eventEmitter.on("LOAD_STOP", chartLoaderRef.current.chartLoaderStop);
		chart.eventEmitter.on("RESIZE", resizeArrowRepositionforLowerIndicator);
		chart.eventEmitter.on("FLAG_RENDER_XAXIS", xAxisRef.current.updateFlag);
		chart.eventEmitter.on("FLAG_HIDE_XAXIS", xAxisRef.current.hideFlag);
		chart.eventEmitter.on("CROSSHAIR_MOVE", xAxisRef.current.showFlag);
		chart.eventEmitter.on("CROSSHAIR_MOVE", addIndicatorDataToLegend);
		chart.eventEmitter.on("DATA_LOAD_COMPLETE", dataLoadComplete);
	};

	const unregisterChartEvents = () => {
		chart.eventEmitter.off("PANEL_LEGEND_UPDATE", panelLegendUpdate);
		chart.eventEmitter.off("EVENT_RENDER", eventRenderDefault);
		chart.eventEmitter.off("EVENT_REMOVE", eventRenderDefault);
		chart.eventEmitter.off("INDICATOR_HIGHLOW_RENDER", IndicatorHighLowRender);
		chart.eventEmitter.off("LOAD_START", chartLoaderRef.current.chartLoaderStart);
		chart.eventEmitter.off("LOAD_STOP", chartLoaderRef.current.chartLoaderStop);
		chart.eventEmitter.off("RESIZE", resizeArrowRepositionforLowerIndicator);
		chart.eventEmitter.off("FLAG_RENDER_XAXIS", xAxisRef.current.updateFlag);
		chart.eventEmitter.off("FLAG_HIDE_XAXIS", xAxisRef.current.hideFlag);
		chart.eventEmitter.off("CROSSHAIR_MOVE", xAxisRef.current.showFlag);
		chart.eventEmitter.off("CROSSHAIR_MOVE", addIndicatorDataToLegend);
		chart.eventEmitter.off("DATA_LOAD_COMPLETE", dataLoadComplete);
	};

	const eventRenderDefault = (params) => {
		if (params.eventType) {
			if (params.eventType === ChartEventsConstants.EVENT_TYPES.CUSTOM) {
				eventsData[ChartEventsConstants.EVENT_TYPES.RATINGSMSTAR] = [];
				eventsData[ChartEventsConstants.EVENT_TYPES.RATINGSMS] = [];
				eventsData[ChartEventsConstants.EVENT_TYPES.DIVIDENDS] = [];
				eventsData[ChartEventsConstants.EVENT_TYPES.EARNINGS] = [];
			} else eventsData[params.eventType] = [];
			setEventsData({ ...eventsData });
			return;
		}
		const coords = params.coords;
		const eventData = [];
		for (let elem of coords) {
			eventData.push(elem);
		}
		if (params.id !== ChartEventsConstants.EVENT_TYPES.CUSTOM)
			eventsData[params.id] = eventData;
		else eventsData[params.name] = eventData;

		setEventsData({ ...eventsData });
		setTimeout(() => {
			if (params.id !== ChartEventsConstants.EVENT_TYPES.CUSTOM)
				showHideEvents(params.id);
			else showHideEvents(params.name);
		}, 1);
	};

	const panelLegendUpdate = ({ legendElement, indicators, panel }) => {
		legendElement.innerHTML = "";
		let ul = panel.rootLegend.append("ul").attr("class", "advancedChartLegend");

		// handling indicators in temporary indicators variable and adding indicators those are hidden

		createLegendsForIndicator(indicators, panel, ul);

		if (panel === chart.panels[0]) createLegendsForEvents(indicators, panel, ul);
	};

	const showSelectedEvents = (eventKey, panel, indicators) => {
		if (eventKey === "highlow") {
			eventVisible[eventKey]
				? panel.addIndicator(
						Indicators.DICTIONARY.highlow.value,
						Indicators.DICTIONARY.highlow.config
				  )
				: indicators.forEach((indicator) => {
						if (indicator.params.id === "highlow") panel.removeIndicator(indicator);
				  });
			chart.render();
		} else {
			showHideEvents(eventKey);
		}
	};
	const fillLastDataDefault = (indicatorId, uid, index, symbol) => {
		if (!Indicators.PHASE1_INDICATORS.includes(indicatorId) && indicatorId !== "price")
			return;
		let data = indicatorId !== "price" ? chart.data[venueXid] : chart.data[symbol];
		let indicatorSettings = Indicators.DICTIONARY[indicatorId];
		if (!indicatorSettings && index > 1)
			indicatorSettings = { displayCardColumns: [{ name: "close" }] };

		if (indicatorSettings) {
			let element = document.querySelector(`[data-uid="${uid}"] .mouseOverValue`);
			element.innerHTML = "";
			indicatorSettings.displayCardColumns.forEach((property) => {
				let div = document.createElement("div");
				div.classList.add("cardValue");
				let lastelemIndex = data[indicatorId][uid].length;
				if (lastelemIndex === 0) return;
				let value = data[indicatorId][uid][lastelemIndex - 1][property.name];
				let activePanelIndicator = chart.getIndicatorByUid(uid);
				addStyleToIndicatorDataLegends(activePanelIndicator, property, div);
				div.innerHTML =
					getFormattedNumber(value) +
					(activePanelIndicator.params.id === "price" ? "%" : "");
				element.appendChild(div);
			});
		}
	};

	const createLegendsForEvents = (indicators, panel, ul) => {
		let li, iconText, iconString;

		Object.keys(selectedEvents)
			.filter((x) => selectedEvents[x])
			.forEach((evt) => {
				let eventKey = AdvanceChartConstant.EVENT_DICT[evt].eventKey;
				let eventName = AdvanceChartConstant.EVENT_DICT[evt].eventName;

				iconString = eventVisible[eventKey] ? VisibleHtmlString : HiddenHtmlString;
				iconText = eventVisible[eventKey] ? "Hide" : "Show";
				// legend label
				li = ul.append("li");
				li.append("div").attr("class", "label").html(eventName);
				li.append("div").attr("class", "mouseOverValue");
				li.append("button") // handling show hide of events
					.html(
						`<span class='sr-only'>${iconText} ${eventName} event</span>${iconString}`
					)
					.on("click", function () {
						iconString = eventVisible[eventKey] ? HiddenHtmlString : VisibleHtmlString;
						iconText = eventVisible[eventKey] ? "Show" : "Hide";
						this.innerHTML = `<span class='sr-only'>${iconText} ${eventName} event</span>${iconString}`;
						eventVisible[eventKey] = !eventVisible[eventKey];
						setEventVisible({ ...eventVisible });
						showSelectedEvents(eventKey, panel, indicators);
					});

				li.append("button")
					.attr("tab-index", "0")
					.html(
						`<span class='sr-only sr-only-focusable' role='button'>Remove ${eventName} event</span>${CloseHtmlString}`
					)
					.on("click", () => handleEvents(eventKey));
			});
	};

	const showHideEvents = (eventKey) => {
		var chartElem = document.getElementById("chartingCard");
		if (chartElem.nextElementSibling) {
			chartElem.nextElementSibling
				.querySelectorAll(
					`[data-eventtype=${AdvanceChartConstant.EVENT_DICT.events[eventKey]}`
				)
				.forEach((elem) => {
					elem.classList.remove("invisible", "visible");
					elem.classList.add(
						`${
							eventVisible[AdvanceChartConstant.EVENT_DICT.events[eventKey]]
								? "visible"
								: "invisible"
						}`
					);
				});
		}
	};

	const getLegendBorder = (indicator, li) => {
		let lineDash = "solid";
		switch (indicator.params.style.lineDash) {
			case "2,4":
				lineDash = "dotted";
				break;
			case "6,2":
				lineDash = "dashed";
				break;
			default:
				break;
		}
		let borderStyle = `border-top: ${
			indicator.params.style.lineWidth || 2
		}px ${lineDash} ${indicator.params.style.color}`;
		if (indicator.params.style.lineDash === "12,2") {
			let wrap = li.append("div").attr("class", "longDashWrap");
			let i = 30;
			while (i > 0) {
				let longDash = wrap.append("div").attr("class", "longDash");
				longDash.attr("style", `background-color: ${indicator.params.style.color}`);
				i--;
			}
		} else li.attr("style", borderStyle);
	};

	const createLegendsForIndicator = (indicators, panel, ul) => {
		let li, iconText, editText, iconString;
		indicators.forEach((indicator, index) => {
			// highlow is added as event so indicator legend not needed.
			if (
				indicator.params.id === "highlow" ||
				(indicator.params.id === "volume" && panel === chart.panels[0])
			)
				return;
			// legend item group
			let uid = indicator.params.uid;
			li = ul.append("li").attr("data-uid", uid);
			indicatorsVisiblitity[uid] =
				uid in indicatorsVisiblitity ? indicatorsVisiblitity[uid] : true;
			setIndicatorsVisiblity({ ...indicatorsVisiblitity });
			// legend border
			if (indicator.params && indicator.params.style) {
				getLegendBorder(indicator, li);
			}
			const isComparison = indicator.params.name === "Price";
			// Below code for showing Legend label for Comparisons
			let displaySymbol = "";
			displaySymbol = setDisplaySymbol(isComparison, indicator.params.symbol);
			// End Legend Label for Comparison
			let legendName = setLegendName(isComparison, displaySymbol, indicator.params.name);
			// legend label
			li.append("div").attr("class", "label").html(legendName);

			iconString = setIconString(indicatorsVisiblitity[indicator.params.uid]);
			iconText = indicatorsVisiblitity[indicator.params.uid] ? "Hide" : "Show";

			editText = "Edit Indicator " + legendName;

			// handle the main price indicator separately
			if (index === 0 && panel === chart.panels[0]) {
				let price = formatter.price(quoteQuery.data?.lastPrice);
				let todaysChange = quoteQuery.data?.todaysChange;

				li.attr("id", "primaryPriceLegend");
				li.attr("class", "priceLegend");
				li.append("span").html("&nbsp;" + price + "&nbsp;&nbsp;" + todaysChange);
			} else if (panel === chart.panels[0]) {
				li = addIndicatorInputs(indicator, li);
				li.append("div").attr("class", "mouseOverValue");

				li = createEditButton(indicator, editText, legendName, li, isComparison, "upper");

				li.append("button")
					.html(
						`<span class='sr-only'>${iconText} ${legendName} indicator</span>${iconString}`
					)
					.on("click", function () {
						let indicatorObj = setEyeButtonProperties(indicator);
						indicator.params.visible = indicatorObj.visible;
						chart.render();
						iconString = indicatorObj.iconString;
						iconText = indicatorObj.iconText;
						this.innerHTML = `<span class='sr-only'>${iconText} ${indicator.params.name} indicator</span>${iconString}`;
						indicatorsVisiblitity[indicator.params.uid] =
							!indicatorsVisiblitity[indicator.params.uid];
						setIndicatorsVisiblity({ ...indicatorsVisiblitity });
					});

				addRemoveIndicatorButton(li, indicator, panel, isComparison);
			} else {
				li = addIndicatorInputs(indicator, li);
				li.append("div").attr("class", "mouseOverValue");
				li = createEditButton(indicator, editText, legendName, li, isComparison, "lower");
				addRemoveIndicatorButton(li, indicator, panel, isComparison);
				lowerIndicatorRepositionArrow(li, panel.size.width, indicator.params.uid, chart);
			}
			fillLastDataDefault(
				indicator.params.id,
				indicator.params.uid,
				index,
				indicator.params.symbol
			);
		});
	};

	const setIconString = (uid) => {
		let iconString = "";
		if (uid) {
			iconString = VisibleHtmlString;
		} else {
			iconString = HiddenHtmlString;
		}
		return iconString;
	};

	const setLegendName = (isComparison, displaySymbol, name) => {
		let legendName = "";
		if (isComparison) {
			legendName = displaySymbol;
		} else {
			legendName = name;
		}
		return legendName;
	};

	const addIndicatorInputs = (indicator, li) => {
		if (Indicators.PHASE1_INDICATORS.includes(indicator.params.id)) {
			switch (indicator.params.id) {
				case "bollinger":
					li.append("div")
						.attr("class", "label")
						.html(
							"(" +
								indicator.params.inputs[0].value +
								"," +
								indicator.params.inputs[1].value +
								")"
						);
					break;
				case "mae":
					li.append("div")
						.attr("class", "label")
						.html(
							"(" +
								indicator.params.inputs[0].value +
								"," +
								Math.trunc((1 - indicator.params.inputs[1].value) * 100) +
								"," +
								indicator.params.inputs[3].value.toUpperCase() +
								")"
						);
					break;
				case "macd":
					li.append("div")
						.attr("class", "label")
						.html(
							"(" +
								indicator.params.inputs[0].value +
								"," +
								indicator.params.inputs[1].value +
								"," +
								indicator.params.inputs[2].value +
								")"
						);
					break;
				case "sma":
				case "wma":
				case "momentum":
				case "proc":
				case "vroc":
				case "rsi":
					li.append("div")
						.attr("class", "label")
						.html("(" + indicator.params.inputs[0].value + ")");
					break;
				default:
					break;
			}
		}
		return li;
	};

	const createEditButton = (indicator, editText, legendName, li, isComparison, panel) => {
		let list = li;
		if (Indicators.PHASE1_INDICATORS.includes(indicator.params.id)) {
			list
				.append("button")
				.html(
					`<span class='sr-only'>${editText} ${legendName} indicator</span>${EditHtmlString}`
				)
				.on("click", function () {
					// here indicator pop up will open for phase 1 indicators list.
					let ind = chart.getIndicatorByUid(indicator.params.uid);
					checkStylesRemove(indicator, isComparison);
					setIndicatorInputConfig({
						isOpen: true,
						isEdit: false,
						name: indicator.params.name,
						panel: panel,
						config: ind.params,
					});
				});
		}
		return list;
	};

	const setDisplaySymbol = (isComparison, symbol) => {
		if (isComparison) {
			let displaySymbol = "";
			const xref = chart.xref[symbol];
			displaySymbol = `${xref.displaySymbol}`;
			if (Comparisons.EXCHANGES.filter((x) => x.xid === symbol).length > 0) {
				displaySymbol = Comparisons.EXCHANGES.filter((x) => x.xid === symbol)[0].name;
			}
			return displaySymbol;
		}
	};

	const setEyeButtonProperties = (indicator) => {
		let indicatorObj = {};
		if (indicatorsVisiblitity[indicator.params.uid]) {
			indicatorObj.visible = false;
			indicatorObj.iconString = HiddenHtmlString;
			indicatorObj.iconText = "Show";
		} else {
			indicatorObj.visible = true;
			indicatorObj.iconString = VisibleHtmlString;
			indicatorObj.iconText = "Hide";
		}
		return indicatorObj;
	};

	const lowerIndicatorRepositionArrow = (li, width, uid, chartObj) => {
		if (chartObj.panels.length > 2) {
			//Lower Indicator reposition via UP/DOWN Arrow
			LegendMovePanel.AddArrowRepositionPanel(
				li,
				width,
				uid,
				chartObj,
				false,
				updateChartStateFromArrowRepositionPanel
			);
		}
	};

	const setPriceFormat = (isComparison) => {
		if (isComparison && selectedComparisons.length < 2) {
			setPriceFormatConfig(AdvanceChartConstant.PRICE_FORMATS.ABSOLUTE.value);
			setAbsolutePrice({ ...absolutePrice, disable: false });
		}
	};

	const addRemoveIndicatorButton = (li, indicator, panel, isComparison) => {
		li.append("button")
			.attr("aria-label", `Remove ${indicator.params.name} indicator`)
			.html(CloseHtmlString)
			.on("click", () => {
				if (isComparison) {
					setSelectedComparisons(
						selectedComparisons.filter((comp) => comp.xid !== indicator.params.symbol)
					);
				} else {
					setSelectedIndicators(
						selectedIndicators.filter((indc) => indc.uid !== indicator.params.uid)
					);
				}
				//Moved outside of the if block removal of first or seccond lower indicator is not working.

				if (panel === chart.panels[0]) {
					if (Indicators.DEFAULT_USE.includes(indicator.params.id) || isComparison)
						checkStylesRemove(indicator, isComparison);
					setPriceFormat(isComparison);
					panel.removeIndicator(indicator);
					setUpdateError({
						indicatorsCount: chart.panels[0].indicators.filter(
							(x) => x.params.id !== "highlow"
						).length,
						errorVisible: false,
					});
					setErrorMSG(false);
					updateChartingCardHeight();
				} else {
					chart.removePanel(panel);
					//Reshuffle - Reposition Arrows depending upon which indicators we are removing
					//Since state update is not predictable in React we are using timeout with a delay of 300 ms
					setTimeout(() => {
						if (chart.panels.length > 1) {
							//Lower Indicator resuffle reposition
							LegendMovePanel.ReshuffleArrowRepositionPanel(
								panel.size.width,
								chart,
								updateChartStateFromArrowRepositionPanel
							);
						}
					}, 300);
					updateChartingCardHeight();
				}
			});
	};

	const updateChartStateFromArrowRepositionPanel = (chartState) => {
		setChart(chartState);
	};

	const resizeArrowRepositionforLowerIndicator = () => {
		if (document.querySelectorAll(".advancedChartLegend").length > 2)
			document
				.querySelectorAll(".advancedChartLegend")
				.forEach((element, index, items) => {
					if (index > 0) {
						element.childNodes[0].childNodes[
							element.childNodes[0].childNodes.length - 1
						].setAttribute("style", `left: ${chart.panels[0].size.width - 63}px;`);
					}
				});
	};

	const handleTimeFrameOnSelect = (value) => {
		const timeFrame = getTimeFrameConfig(value);
		const newDatePicker = {
			dateSpan: CUSTOM_DATE_ID_VALUE,
			startDate: false,
			endDate: false,
		};
		stateStorage.set({
			datePicker: newDatePicker,
			timeFrame: value,
			interval: timeFrame.defaultPeriod,
		});
		setSelectedTimeFrame(timeFrame);
		setSelectedInterval(timeFrame.defaultPeriod);
		setDatePicker(newDatePicker);
	};

	const updateStateFromTimeFrame = (timeFrame) => {
		const newDatePicker = {
			dateSpan: CUSTOM_DATE_ID_VALUE,
			startDate: timeFrame.startDate,
			endDate: timeFrame.endDate,
		};
		stateStorage.set({
			datePicker: newDatePicker,
			timeFrame,
			interval: timeFrame.defaultPeriod,
		});
		setSelectedTimeFrame(timeFrame);
		setDatePicker(newDatePicker);
		setSelectedInterval(timeFrame.defaultPeriod);
	};

	const handleChartStyleOnSelect = (selectedLineStyleLabel) => {
		setLineStyleConfig(
			AdvanceChartConstant.LINE_STYLES.filter(
				(lineStyle) => lineStyle.label === selectedLineStyleLabel
			)[0]
		);
	};

	const handleChartIntervalOnSelect = (selectedIntervalLabel) => {
		const interval = selectedTimeFrame.periods.filter(
			(period) => period.label === selectedIntervalLabel
		)[0];
		stateStorage.set({ interval });
		setSelectedInterval(interval);
	};

	const handleResetOnClick = () => {
		const timeFrame = getTimeFrameConfig();
		stateStorage.set({}, true);
		setSelectedTimeFrame(timeFrame);
		setLineStyleConfig(AdvanceChartConstant.LINE_STYLES[0]);
		setSelectedInterval(TIME_FRAMES[DEFAULT_TIMEFRAME_INDEX].defaultPeriod);
		setDatePicker(DATEPICKERFILTERSTATE);
		setDefaultStyle(JSON.parse(JSON.stringify(DEFAULT_USE_LINE_STYLES)));
		setAvailableStyle([]);
		setPriceFormatConfig(AdvanceChartConstant.PRICE_FORMATS.ABSOLUTE.value);
		setAbsolutePrice({ ...absolutePrice, disable: false });
		setIndicatorsVisiblity({});
		setSelectedComparisons([]);
		setSelectedEvents({
			dividends: false,
			earnings: false,
			highlow: false,
			morganstanleyratingscustom: false,
			morningstarratingscustom: false,
			splits: false,
		});
		setEventsData({});
		chart?.destroy();
		setChart(null);
		initNewChart();
	};

	const canAddMoreLowerIndicators = (indicatorPanel) => {
		if (indicatorPanel === "lower") {
			if (updateError.indicatorsCount >= AdvanceChartConstant.INDICATOR_LIMITS.UPPER) {
				updateError.errorVisible = false;
				setErrorMSG(false);
				setErrorMessageForUpper(true);
			}
			const numLowerIndicators = chart.panels.length;
			if (numLowerIndicators - 1 >= AdvanceChartConstant.INDICATOR_LIMITS.LOWER) {
				setErrorMSG(true);
				setErrorMessageForUpper(false);
				updateError.errorVisible = true;
				return false;
			}
			return true;
		}
	};

	const canAddMoreUpperIndicators = () => {
		// use AdvanceChartConstant.INDICATOR_LIMITS.LOWER for upper limit
		// using 6 as threshold as pricechart indicator is added by default
		if (updateError.indicatorsCount >= AdvanceChartConstant.INDICATOR_LIMITS.UPPER) {
			setErrorMSG(true);
			setErrorMessageForUpper(true);
			updateError.errorVisible = true;
			return false;
		} else {
			setUpdateError({
				indicatorsCount:
					chart.panels[0].indicators.filter((x) => x.params.id !== "highlow").length + 1,
				errorVisible: false,
			});
			setErrorMSG(false);
			setErrorMessageForUpper(false);
		}
		return true;
	};

	const handleIndicatorOnClick = (e) => {
		const newIndicatorValue = e.target.dataset.value;
		const newIndicatorPanel = e.target.dataset.panel;
		let isIndicatorConfiguredForPopup =
			Indicators.PHASE1_INDICATORS.includes(newIndicatorValue);

		if (newIndicatorPanel === "lower" && canAddMoreLowerIndicators(newIndicatorPanel)) {
			if (isIndicatorConfiguredForPopup) {
				openPopup(newIndicatorValue, newIndicatorPanel);
				return false;
			}
			addLowerIndicator(
				newIndicatorValue,
				Indicators.DICTIONARY[newIndicatorValue].config
			);
		} else if (newIndicatorPanel === "upper" && canAddMoreUpperIndicators()) {
			if (isIndicatorConfiguredForPopup) {
				openPopup(newIndicatorValue, newIndicatorPanel);
				return false;
			}
			// add Upper Indicator here
			let newIndicatorUpper = chart.panels[0].addIndicator(
				newIndicatorValue,
				Indicators.DICTIONARY[newIndicatorValue].config
			);

			//  Indicators for which default line styles and colors are used
			setSelectedIndicators([...selectedIndicators, { ...newIndicatorUpper.params }]);
		}
	};

	const openPopup = (indicatorValue, indicatorPanel) => {
		let defaultLineStyle = {};
		if (Indicators.DEFAULT_USE.includes(indicatorValue)) {
			let index = getIndexOfAvailableStyle();
			defaultLineStyle = defaultStyle[index];
		}
		setIndicatorInputConfig({
			isOpen: true,
			isEdit: false,
			name: indicatorValue,
			panel: indicatorPanel,
			defaultLineStyle: defaultLineStyle,
		});
	};

	const handleCompareExchangeOnClick = (e) => {
		const newCompareIndex = e.currentTarget.getAttribute("data-index");
		const newExchangeCompare = Comparisons.EXCHANGES[newCompareIndex];

		// Check whether the comparison xid has already been added
		const exchangeIndexToTest = selectedComparisons.findIndex(
			(selectedComparison) => selectedComparison.xid === newExchangeCompare.xid
		);

		// If xid is new, add to the comparisons array
		// using 6 as threshold as pricechart indicator is added by default
		if (updateError.indicatorsCount >= AdvanceChartConstant.INDICATOR_LIMITS.UPPER) {
			setErrorMSG(true);
			updateError.errorVisible = true;
			if (!errorMSG) return;
		} else {
			setUpdateError({
				indicatorsCount:
					chart.panels[0].indicators.filter((x) => x.params.id !== "highlow").length + 1,
				errorVisible: false,
			});
			setErrorMSG(false);
		}
		if (exchangeIndexToTest < 0) {
			setSelectedComparisons([...selectedComparisons, newExchangeCompare]);
			checkStylesAdd();
		}
		setAbsolutePrice({ ...absolutePrice, disable: true });
		setPriceFormatConfig(AdvanceChartConstant.PRICE_FORMATS.PERCENT.value);
	};
	const handleComparePeerOnClick = (e) => {
		const newCompareIndex = e.currentTarget.getAttribute("data-index");
		const newPeerCompare = peersData[newCompareIndex];

		// Check whether the comparison xid has already been added
		const peerIndexToTest = selectedComparisons.findIndex(
			(selectedComparison) => selectedComparison.xid === newPeerCompare.xid
		);

		// If xid is new, add to the comparisons array
		// using 6 as threshold as pricechart indicator is added by default
		if (updateError.indicatorsCount >= AdvanceChartConstant.INDICATOR_LIMITS.UPPER) {
			setErrorMSG(true);
			updateError.errorVisible = true;
			if (!errorMSG) return;
		} else {
			setUpdateError({
				indicatorsCount:
					chart.panels[0].indicators.filter((x) => x.params.id !== "highlow").length + 1,
				errorVisible: false,
			});
			setErrorMSG(false);
		}
		if (peerIndexToTest < 0) {
			setSelectedComparisons([...selectedComparisons, newPeerCompare]);
			checkStylesAdd();
		}
		setAbsolutePrice({ ...absolutePrice, disable: true });
		setPriceFormatConfig(AdvanceChartConstant.PRICE_FORMATS.PERCENT.value);
	};
	const handleCompareQuoteOnClick = (e) => {
		// Check whether the comparison xid has already been added
		const quoteIndexToTest = selectedComparisons.findIndex(
			(selectedComparison) => selectedComparison.xid === e.xids.entity
		);

		// If xid is new, add to the comparisons array
		// using 6 as threshold as pricechart indicator is added by default
		if (updateError.indicatorsCount >= AdvanceChartConstant.INDICATOR_LIMITS.UPPER) {
			setErrorMSG(true);
			updateError.errorVisible = true;
			if (!errorMSG) return;
		} else {
			setUpdateError({
				indicatorsCount:
					chart.panels[0].indicators.filter((x) => x.params.id !== "highlow").length + 1,
				errorVisible: false,
			});
			setErrorMSG(false);
		}
		if (
			!isMaxLegendsRendered() &&
			quoteIndexToTest < 0 &&
			chart.params.symbol !== e.xids.venue.toString()
		) {
			setSelectedComparisons([
				...selectedComparisons,
				{
					xid: e.xids.venue.toString(),
					symbol: e.symbol,
					companyName: e.name,
					name: e.symbol,
				},
			]);
			checkStylesAdd();
		}
		setAbsolutePrice({ ...absolutePrice, disable: true });
		setPriceFormatConfig(AdvanceChartConstant.PRICE_FORMATS.PERCENT.value);
	};
	const handleEvents = (eventKey) => {
		selectedEvents[eventKey] = !selectedEvents[eventKey];
		eventVisible[eventKey] = true;
		setSelectedEvents({ ...selectedEvents });
		setEventVisible({ ...eventVisible });
	};
	const clearEvents = () => {
		setSelectedEvents({
			dividends: false,
			earnings: false,
			highlow: false,
			morganstanleyratingscustom: false,
			morningstarratingscustom: false,
			splits: false,
		});
		setEventVisible({
			dividends: true,
			earnings: true,
			highlow: true,
			morganstanleyratingscustom: true,
			morningstarratingscustom: true,
			splits: true,
		});
	};

	/*
	initial chart will have a height of 374px. 50px for the legend and 324px for the main panel.
	This initial height is important so user doesn't have to scroll to see the whole chart on initial load.
	As user adds more events/indicators/comparisons padding between panels needs to be consistent.
	*/
	const updateChartingCardHeight = () => {
		const chartingCardEl = document.getElementById("chartingCard");
		const mainPanelLegendEl = document.getElementsByClassName("modcharts-legend first");
		const chartHoverEl = document.querySelector(".modcharts-rootmouse");
		const numOfPanels = chart?.panels?.length;
		const mainPanelHeight = 324;
		// additional lower indicators will expand the chart by 283px
		const lowerPanelHeight = (numOfPanels - 1) * 283 || 0;
		const mainPanelLegendHeight = mainPanelLegendEl?.length
			? mainPanelLegendEl[0].clientHeight
			: 50;
		// adds top padding only to lower panels when the legend of the main panel wraps to a new line.
		if (numOfPanels > 1) {
			let lowerPanels = [...document.getElementsByClassName("modcharts-panel")];
			lowerPanels.forEach((el, ind) => {
				if (ind !== 0) {
					return (el.style.paddingTop = `${mainPanelLegendHeight - 50}px`);
				}
			});
		}

		// offsets the top absolute position of the hoverable area provided by modcharts by the current legend height
		chartHoverEl.style.top = `${mainPanelLegendHeight}px`;
		chartingCardEl.style.height = `${
			mainPanelHeight + lowerPanelHeight + mainPanelLegendHeight
		}px`;
	};
	const isMaxLegendsRendered = () => {
		const activeEvents = Object.keys(selectedEvents).filter(
			(key) => selectedEvents[key] === true
		);
		const activeIndicators = selectedIndicators.filter(({ panel }) => panel === "upper");
		return (
			selectedComparisons.length + activeIndicators.length + activeEvents.length >=
			AdvanceChartConstant.INDICATOR_LIMITS.UPPER
		);
	};
	const onChartingInputApply = (inputs) => {
		let inputvalue = inputs.config.id || inputs.value;
		if (Indicators.DEFAULT_USE.includes(inputvalue)) {
			addIndicatorDefaultStyle(inputs.config.style);
		}
		if (!inputs.config.uid) {
			if (inputs.panel === "upper") {
				let newIndicatorUpper = chart.panels[0].addIndicator(inputs.value, inputs.config);
				setSelectedIndicators([...selectedIndicators, { ...newIndicatorUpper.params }]);
			} else {
				addLowerIndicator(inputs.value, inputs.config);
			}
		} else {
			chart.loadData(() => {
				setChartData(chart ? chart.exportData() : {});
			});
		}
	};

	const addLowerIndicator = (indicatorValue, indicatorConfig) => {
		const numLowerIndicators = chart.panels.length;
		chart.addPanel(AdvanceChartConstant.DEFAULT_PANEL_PARAMS_LOWER);

		let newIndicatorLower = chart.panels[numLowerIndicators].addIndicator(
			indicatorValue,
			indicatorConfig
		);

		setSelectedIndicators([...selectedIndicators, { ...newIndicatorLower.params }]);

		updateChartingCardHeight();
	};
	const onCloseIndicatorPopup = (e) => {
		indicatorInputConfig.config &&
			addIndicatorDefaultStyle(indicatorInputConfig.config.style);
		setIndicatorInputConfig((config) => {
			return { ...config, isOpen: false };
		});
	};

	const addIndicatorDefaultStyle = (indicatorStyle) => {
		let index = defaultStyle.findIndex(
			(item) =>
				item.isAvailable === true &&
				item.color === indicatorStyle.color &&
				item.lineDash === indicatorStyle.lineDash
		);
		if (index >= 0) {
			defaultStyle[index].isAvailable = false;
			setDefaultStyle([...defaultStyle]);
		}
	};
	//#region PAN & ZOOM Callback & UTILITY

	const rangeSize = () => {
		const [min, max] = chart.xAxis.primaryScale.range();
		return max - min;
	};

	const pan = (panLeft) => {
		if (chart) {
			const panRangeDistance = panLeft
				? rangeSize() * AdvanceChartConstant.PANZOOM.RANGE_RATIO
				: -rangeSize() * AdvanceChartConstant.PANZOOM.RANGE_RATIO;
			chart.zoomUtil.panChart({
				chart,
				distance: panRangeDistance,
				duration: AdvanceChartConstant.PANZOOM.DURATION,
			});
		}
	};

	const resetZoom = () => {
		if (chart) {
			chart.zoomUtil.resetZoomCenter({
				chart,
				duration: AdvanceChartConstant.PANZOOM.DURATION,
			});
		}
	};

	const zoom = (distance) => {
		if (chart) {
			chart.zoomUtil.zoomChart({
				chart,
				duration: AdvanceChartConstant.PANZOOM.DURATION,
				scale: distance,
			});
		}
	};

	const getStyleForChartLegend = () => {
		if (errorMSG) {
			if (FEATURE_FLAGS.MSQR_7541_BORDER_COLOR) return styles.chartLegendMaxErr;
			else return styles.chartLegendMaxErrWithFlag;
		}
		return "";
	};

	return (
		<CollapsibleCard
			headingText="Charting"
			contentReady={true}
			hideIcon={true}
			showNavigationLink={true}
		>
			<div className={styles.dataContainer}>
				<section className={styles.componentContainer}>
					<div className={styles.chartButtonRow}>
						<div className={getStyleForChartLegend()}>
							{errorMSG && (
								<>
									<img
										className={styles.errorIcon}
										src={ErrorIcon}
										alt="Error icon"
									></img>
									<p role="alert">
										{errorMessageForUpper
											? AdvanceChartConstant.ERROR_MESSAGES.UPPER_ERROR
											: AdvanceChartConstant.ERROR_MESSAGES.LOWER_ERROR}
										{"   "}
									</p>
								</>
							)}
							<button
								className={`${styles.resetButton} btn`}
								onClick={handleResetOnClick}
							>
								{"   "}Reset Chart
							</button>
						</div>
						{!isMobile && (
							<div className={styles.priceFormatControl}>
								<TextToggle
									activeValue={selectedPriceFormat}
									option1={percentPrice}
									option2={absolutePrice}
									handleToggle={setPriceFormatConfig}
								/>
							</div>
						)}
					</div>
					<div className={styles.chartControls}>
						<div className={styles.chartControlRegion}>
							<div className={styles.advancedChartControls}>
								{!isMobile && (
									<Menus.Indicators
										onClick={handleIndicatorOnClick}
										updateError={updateError}
										errorMSG={errorMSG}
										resetErrorMSG={() => setErrorMSG(false)}
									/>
								)}
								<Menus.Comparisons
									apiInstance={apiInstance}
									onExchangeClick={handleCompareExchangeOnClick}
									onQuoteClick={handleCompareQuoteOnClick}
									onPeerClick={handleComparePeerOnClick}
									peersData={peersData}
									updateError={updateError}
									errorMSG={errorMSG}
									resetErrorMSG={() => setErrorMSG(false)}
								/>
								{!isMobile && (
									<Menus.Events
										onSelect={handleEvents}
										selectedEvents={selectedEvents}
										clearEvents={clearEvents}
									/>
								)}
							</div>
						</div>
						<div className={styles.chartControlDivider} />

						<TimeFrame
							selectedTimeFrame={selectedTimeFrame}
							onFilterChange={handleTimeFrameOnSelect}
							datePicker={datePicker}
							updateStateFromTimeFrame={updateStateFromTimeFrame}
							chart={chart}
						/>

						<Interval
							selectedTimeFrame={selectedTimeFrame}
							selectedInterval={selectedInterval}
							onSelectCallback={handleChartIntervalOnSelect}
						/>

						<div className={styles.chartControlDivider} />
						<div className={styles.chartControlRegion}>
							<div className={styles.chartStyleControl}>
								<MSDropdown
									data={AdvanceChartConstant.LINE_STYLES.map((chartStyle) => {
										return {
											option: chartStyle.label,
											icon: chartStyle.icon,
										};
									})}
									title={selectedLineStyle.label}
									showLeftIcon={true}
									dropdownIconColor="#187ABA"
									onSelectCallback={handleChartStyleOnSelect}
								/>
							</div>
						</div>
					</div>
					<div className={styles.legendContainer} aria-hidden="true">
						{selectedLineStyle.markerType === "candlestick" && (
							<>
								<div className={styles.legendPositive}>
									<CandlestickLegend />
									Positive
								</div>
								<div className={styles.legendNegative}>
									<CandlestickLegend />
									Negative
								</div>
							</>
						)}
						{selectedLineStyle.markerType === "ohlc" && (
							<>
								<div className={styles.legendPositive}>
									<div className={styles.ohlcLegend}></div>
									Positive
								</div>
								<div className={styles.legendNegative}>
									<div className={styles.ohlcLegend}></div>
									Negative
								</div>
							</>
						)}
					</div>
					<div className={styles.chartWrapper}>
						<YAxisFlag
							chart={chart}
							chartRef={chartRef}
							priceFormat={selectedPriceFormat}
						/>
						<XAxisFlag
							ref={xAxisRef}
							timeInterval={selectedInterval}
							chartData={{ ...chart }}
						/>
						{!isMobile && (
							<PanZoomToolBar chart={chart} pan={pan} resetZoom={resetZoom} zoom={zoom} />
						)}
						{indicatorInputConfig.isOpen && (
							<IndicatorInputPopUp
								onClose={onCloseIndicatorPopup}
								indicator={indicatorInputConfig}
								onApply={onChartingInputApply}
							/>
						)}
						<CrosshairTooltip chart={chart} activeValue={selectedPriceFormat} />

						<div
							data-testid="chartingCard"
							id="chartingCard"
							ref={chartRef}
							className={styles.chartContainer}
							aria-hidden="true"
						>
							{finishedLoadingData ? <></> : <SimpleSpinner />}
							<Loader ref={chartLoaderRef} chartRef={chartRef} />
						</div>
						{eventsData && Object.keys(eventsData).length > 0 && (
							<ChartEventsOverlay
								className="invisible"
								eventsData={eventsData}
								pageName="advanceCharting"
							/>
						)}
					</div>
					<IndicatorAccessibility
						chartData={chartData}
						selectedIndicators={selectedIndicators}
					/>
				</section>
			</div>
		</CollapsibleCard>
	);
};

export default React.memo(ChartingCard);
