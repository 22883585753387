import { URLS } from "utils/constants";
import Utils from "utils/utils";
import formatter from "utils/formatUtils";

// updated function without pseudoclassical implementation
export const getHistoricalDividends = async ({
	apiInstance,
	venueXid,
	numberOfResults = 16,
	offset = 1,
	sortDirection = "desc",
}) => {
	try {
		if (!apiInstance || !venueXid) return {};
		const params = {
			sortDirection,
			numberOfResults,
			offset,
		};
		const response = await apiInstance.get(
			`${URLS.DIVIDENDS_PATH}/${venueXid}/historical-events`,
			{
				params,
			}
		);
		return response?.data.data || {};
	} catch (error) {
		return {};
	}
};
// TODO: Decomission this function that behaves more like a class
/**
 * Utility for fetching API data for dividends history
 * @param {number} venueXid - the venueXid for the symbol selected
 */
const getDividendsHistory = (apiInstance, venueXid) => {
	let params = {
		sortDirection: "desc",
		numberOfResults: 16, //default
		offset: 1, //default
	};

	return {
		overrideParams: function (newParams) {
			params = newParams;
			return this;
		},

		setSortDirection: function (direction) {
			params.sortDirection = direction;
			return this;
		},

		setAnnounceDateStart: function (date) {
			params.announceDateStart = date;
			return this;
		},

		setAnnounceDateEnd: function (date) {
			params.announceDateEnd = date;
			return this;
		},

		setNumberOfResults: function (count) {
			params.numberOfResults = count;
			return this;
		},

		setOffset: function (offset) {
			params.offset = offset;
			return this;
		},

		fetch: async function () {
			try {
				if (!apiInstance || !venueXid) {
					return "error";
				}

				const response = await apiInstance.get(
					`${URLS.DIVIDENDS_PATH}/${venueXid}/historical-events`,
					{
						params,
					}
				);
				return Utils.getObjectPropertyValue(response, "data.data");
			} catch (error) {
				return {};
			}
		},
	};
};

/**
 * Utility for fetching API data for dividend details
 * @param {Object} apiInstance - api instance
 * @param {number} venueXid - the venueXid for the symbol selected
 */
const getDividendData = async (apiInstance, venueXid) => {
	if (!apiInstance || !venueXid) {
		return "error";
	}
	let dividendData = {};

	try {
		const response = await apiInstance.get(`${URLS.DIVIDENDS_PATH}/${venueXid}/current`);
		const data = response?.data?.data || null;
		if (data) {
			dividendData.venueXid = venueXid;
			dividendData.currency = Utils.getObjectPropertyValue(
				data,
				"currentDividend.currency"
			);
			dividendData.announcementDate = Utils.getObjectPropertyValue(
				data,
				"currentDividend.announcementDate"
			);
			dividendData.exDate = Utils.getObjectPropertyValue(data, "currentDividend.exDate");
			dividendData.paymentDate = Utils.getObjectPropertyValue(
				data,
				"currentDividend.paymentDate"
			);
			dividendData.gross = Utils.getObjectPropertyValue(data, "annualizedDividend.gross");
			dividendData.yieldGross = Utils.getObjectPropertyValue(
				data,
				"annualizedDividend.yieldGross"
			);
			dividendData.amount = Utils.getObjectPropertyValue(data, "currentDividend.amount");
			dividendData.frequency = Utils.getObjectPropertyValue(
				data,
				"currentDividend.frequency"
			);
			dividendData.method = Utils.getObjectPropertyValue(
				data,
				"annualizedDividend.method"
			);
			dividendData.dividendTTMYield = formatter.percent(
				Utils.getObjectPropertyValue(data, "trailingDividend.regularDividendTTMYield"),
				2
			);
			return dividendData;
		} else {
			return dividendData;
		}
	} catch (error) {
		return dividendData;
	}
};

const dividendAnnualData = async (apiInstance, venueXid) => {
	let dividendData = {};

	if (!apiInstance || !venueXid) {
		return;
	}

	try {
		const response = await apiInstance.get(
			`${URLS.DIVIDENDS_PATH}/${venueXid}/historical-events?sortDirection=desc&calculatePeriodTotal=calendarYear&numberOfResults=16&includeDividendYieldForAnnualPeriod=false&aggregatePeriodTotalMethodology=byDividendType`
		);
		const data = response?.data?.data || null;
		if (data) {
			dividendData.dividendEvents = Utils.filterOutSpinOffs(data.dividendEvents);
		}
	} catch (error) {
		return null;
	}
	return dividendData;
};

export default {
	getDividendsHistory,
	getDividendData,
	dividendAnnualData,
};
