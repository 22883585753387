const FILING_TYPE = [
	{ label: "Annual Financials", value: "10-K" },
	{ label: "Quarterly Financials", value: "10-Q" },
	{ label: "Company Current Events", value: "8-K" },
	{ label: "Foreign Issuer Annual Report", value: "20-F" },
	{ label: "Foreign Issuer Current Report", value: "6-K" },
	{ label: "Employee Stock Purchase Plan Annual Report", value: "11-K" },
	{ label: "New Insider", value: "3" },
	{ label: "Insider Buy/Sell", value: "4" },
	{ label: "Insider Year End Report", value: "5" },
	{ label: ">5% Ownership", value: "SC 13G" },
	{ label: "Notification of Late Filing", value: "NT-11K" },
	{ label: "Notification of Late Filing", value: "12b-25" },
	{ label: "Notification of Termination of Registration", value: "15-12B" },
	{ label: "Registration of Employee Benefit Plan", value: "S-8" },
	{ label: "Registration of Employee Benefit Plan", value: "S-8 POS" },
	{ label: "Definitive Proxy Statement", value: "DEF 14A" },
	{ label: "Announcement of >5% Acquisition", value: "SC 13D" },
	{ label: "Annual Financials - Small Business", value: "10KSB" },
	{ label: "Registration for New Securities", value: "S-1" },
	{ label: "Registration For Specific Situations", value: "S-3" },
	{ label: "Registration Statement", value: "8-A12B" },
	{ label: "Withdrawal of Listing On Exchange", value: "25" },
	{ label: "FWP", value: "Free Writing Prospectus" },
];

const TIME_FRAMES = [
	{
		label: "Current Year",
	},
	{
		label: "Last Month",
	},
	{
		label: "Last 3 Months",
	},
	{
		label: "Last 6 Months",
	},
	{
		label: "12 Months",
	},
	{
		label: "Last 2 Years",
	},
	{
		label: "Last 3 Years",
	},
	{
		label: "Last 4 Years",
	},
];

const SEC_FILING_REPORT_TIME = [
	{
		label: "All Filings",
		value: "",
	},
	{
		label: "Quarterly Reports (10-Q)",
		value: "10-Q",
	},
	{
		label: "Annual Reports (10-K)",
		value: "10-K",
	},
	{
		label: "Other Reports",
		value: "-10-Q,10-k", // list all the reports types that should be ignored in api call
	},
];

export default {
	FILING_TYPE,
	TIME_FRAMES,
	SEC_FILING_REPORT_TIME,
};
