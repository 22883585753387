import "./polyfills";
import React from "react";
import { Provider } from "react-redux";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import configureStore from "./store/configureStore";

// Uncomment to run axe on the rendered page using @axe-core/react
// This has a performance hit, so it is turned off by default and should not run in production
// if (process.env.NODE_ENV !== "production") {
// 	const axe = require("@axe-core/react");
// 	axe(React, ReactDOM, 1000);
// }

const store = configureStore();

ReactDOM.render(
	<Provider store={store}>
		<App />
	</Provider>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
