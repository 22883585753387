import React, { PureComponent } from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

import formatter from "utils/formatUtils";
import Utils from "utils/utils";
import HeaderValue from "components/Lib/HeaderValue/HeaderValue";
import MSSpinner from "components/Lib/MSSpinner/MSSpinner";

import styles from "./QuoteBar.module.scss";

export class QuoteBar extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			width: 0,
		};
	}

	getPriceChangeColor(data) {
		if (!data) {
			return;
		}
		//default color will be taken by default font color of the app
		let color;
		if ((data.priceChange && Number(data.percentChange)) > 0) {
			color = "success";
		} else if ((data.priceChange && Number(data.percentChange)) < 0) {
			color = "alert";
		}

		return color;
	}

	getHeaderValueItems(data) {
		if (!data) {
			return [];
		}

		// placeholder for HeaderValue elements
		const headerItems = [];
		const color = this.getPriceChangeColor(data);

		[
			{
				id: "quoteContainer_last",
				header: "Last",
				value: data.lastPrice,
				hideHeader: this.props.isMobile,
				setBold: true,
			},
			{
				id: "quoteContainer_change",
				header: "Today's change",
				value: `${formatter.price(data.priceChange)} / ${formatter.percent(
					data.percentChange,
					2,
					{
						percentModifier: 0.01,
					}
				)}`,
				hideHeader: this.props.isMobile,
				setColor: color,
				setBold: !this.props.isMobile,
			},
			{
				id: "quoteContainer_bid",
				header: "Bid",
				value: `${formatter.price(data.bid, this.props.isMobile ? 0 : 2)}`,
				hideHeader: this.props.isMobile,
				setBold: !this.props.isMobile,
			},
			{
				id: "quoteContainer_ask",
				header: "Ask",
				value: `${formatter.price(data.ask, this.props.isMobile ? 0 : 2)}`,
				hideHeader: this.props.isMobile,
				setBold: !this.props.isMobile,
			},
			{
				id: "quoteContainer_volume",
				header: "Volume",
				value: formatter.number(data.volume),
				hideHeader: this.props.isMobile,
				setBold: !this.props.isMobile,
			},
		].forEach((data) => {
			const excludeForMobile =
				this.props.isMobile &&
				(data.id === "quoteContainer_bid" || data.id === "quoteContainer_ask");

			if (!excludeForMobile) {
				headerItems.push(<HeaderValue data={data} key={data.id} />);
			}
		});

		return headerItems;
	}

	isWindowWidthExtraLarge() {
		return this.state.width > 1140;
	}

	render() {
		const quoteItems = this.getHeaderValueItems(this.props.quoteData);

		const realTimeQuoteIndicator = this.props.quoteData?.realTimeQuoteIndicator;
		const isAfterHours =
			Utils.isRealtimeNasdaqUser(this.props.userInfo) && !realTimeQuoteIndicator;

		const quoteDate = this.props.quoteData?.quoteDate;
		const asOfQuoteTime = this.props.quoteData?.quoteDelayMinutes;
		let realtimeDelayedHeader = Utils.isRealtimeNasdaqUser(this.props.userInfo)
			? "Real Time as of "
			: `Delayed by at least ${asOfQuoteTime} minutes. As of `;

		const tooltipID = "options-quote-desc";
		let symbolOverlay = (
			<OverlayTrigger
				placement="auto"
				overlay={<Tooltip id={tooltipID}>{this.props.symbol}</Tooltip>}
				trigger={Utils.toolTipTrigger()}
			>
				<span aria-describedby="options-quote-desc">
					<span>{this.props.xrefName}</span>
				</span>
			</OverlayTrigger>
		);

		return (
			<MSSpinner ready={this.props.quoteData !== null} spinnerSize={"2x"}>
				<section className={styles.quoteContainer} aria-label="quote data">
					<div id="quoteContainer_label">
						<div className={styles.quoteContainerSymbol}>
							{this.props.symbol}&nbsp;
							{symbolOverlay}
						</div>
						<div className={styles.quoteContainerTimestamp}>
							{!isAfterHours && <span>{realtimeDelayedHeader}</span>}
							{quoteDate}
						</div>
					</div>
					{quoteItems}
				</section>
			</MSSpinner>
		);
	}
}

export default QuoteBar;
