import React, { useState, useRef } from "react";
import styles from "./ComparisonQuoteSearch.module.scss";
import Form from "react-bootstrap/Form";
import { Typeahead, Menu, MenuItem, Hint } from "react-bootstrap-typeahead";
import { URLS } from "../../../../../utils/constants";
import utils from "utils/utils";

const ComparisonQuoteSearch = (props) => {
	const [xrefResult, setXrefResult] = useState([]);
	const [focus, setFocus] = useState(false);
	const typeaheadRef = useRef();
	let lastSelectEvent = null;

	const renderQuotes = (results, menuProps) => {
		let className = results.length
			? styles.comparisonSearchResultBox
			: styles.comparisonSearchResultBoxHide;

		return results.length ? (
			<Menu {...menuProps} className={className}>
				{results.map((result, index) => (
					<MenuItem
						className={`${styles.compareQuoteItem} compare-quote-row`}
						option={result}
						position={index}
						key={`${result.name}-${index}`}
						onClick={saveEvent}
					>
						<div className={styles.peerContainer}>
							<div className={styles.quoteSymbol}>{result.symbol}</div>
							{utils.buildTooltip(
								"peerComparison-companyName",
								result.name,
								"text",
								result.name
							)}
						</div>
					</MenuItem>
				))}
			</Menu>
		) : null;
	};
	const onSelected = (result) => {
		props.onQuoteClick(result[0]);
		props.closeDropdown(false, lastSelectEvent, { source: "select" });
	};
	const saveEvent = (e) => {
		lastSelectEvent = { ...e };
	};
	const onFocus = () => {
		if (
			typeaheadRef.current &&
			typeaheadRef.current.state &&
			typeaheadRef.current.state.text
		) {
			typeaheadRef.current.state.text = "";
		}
	};
	const clearText = () => {
		if (typeaheadRef.current) {
			typeaheadRef.current.clear();
		}
		setXrefResult([]);
	};

	const loadXrefData = async (symbol) => {
		if (!symbol || symbol === "") {
			setXrefResult([]);
			return;
		}

		try {
			const options = [];
			const xrefResponse = await props.apiInstance.get(
				`${URLS.XREF_PREDICTIVE_SEARCH_PATH}?term=${symbol}`
			);
			if (
				props.compSearchInput.current.value &&
				xrefResponse.data &&
				xrefResponse.data.data
			) {
				const data = xrefResponse.data.data;
				if (data) {
					options.push(...data.items);
					setXrefResult(options);
				}
			}
		} catch (error) {
			console.log("Error fetching Xref! See error below.");
			console.log(error);
		}
	};
	const onChange = (value) => {
		loadXrefData(value);
	};

	const handleFocusEvent = (event) => {
		if (event && event.type === "keyup") {
			// setting keyup event changes on tab only
			if (event.keyCode === 9 || event.keyCode === 16) {
				setFocus(true);
			} else {
				setFocus(false);
			}
		}
	};
	const customInput = ({ inputRef, referenceElementRef, ...inputProps }) => {
		const container = {
			display: "flex",
			border: "1px solid #000",
			margin: "5px 10px 5px",
		};
		const inputClass = `${focus ? "focusVisible" : "focusReset"}`;
		const inputBoxClass = `${styles.removeFormBorder + " focusReset"}`;

		return (
			<div style={container} className={inputClass}>
				<svg
					viewBox="-5 -5 24 24"
					className={`bi bi-search ${styles.svgContent}`}
					fill="currentColor"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fillRule="evenodd"
						d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"
					/>
					<path
						fillRule="evenodd"
						d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"
					/>
				</svg>
				<Hint>
					<Form.Control
						{...inputProps}
						className={inputBoxClass}
						ref={(node) => {
							props.compSearchInput.current = node;
							inputRef(node);
							referenceElementRef(node);
						}}
						onClick={() => setFocus(false)}
						onKeyUp={handleFocusEvent}
						onBlur={() => {
							setFocus(false);
							clearText();
						}}
					/>
				</Hint>
			</div>
		);
	};

	return (
		<div className={styles.quoteSearchContainer}>
			<Typeahead
				id="charting-comparison-quote-search"
				labelKey={(option) => `${option.name} ${option.symbol}`}
				onChange={onSelected}
				onInputChange={onChange}
				placeholder="Enter name or symbol"
				options={xrefResult}
				renderMenu={renderQuotes}
				renderInput={customInput}
				onFocus={onFocus}
				ref={typeaheadRef}
			/>
		</div>
	);
};

export default ComparisonQuoteSearch;
