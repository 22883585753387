import actionTypes from "./actionTypes";
import { URLS } from "../../utils/constants";

//action creator
const storeFinancialRatiosResults = (results) => {
	return {
		type: actionTypes.SAVE_FINANCIAL_RATIOS_DATA,
		financialRatiosData: results,
	};
};

const fetchFinancialRatios = (apiInstance, venueXid, includeYearEndData) => {
	//async here - dispatch here will get set by redux-thunk
	return async (dispatch, getState) => {
		if (!apiInstance || !venueXid) {
			return;
		}
		dispatch({ type: actionTypes.FINANCIAL_RATIOS_API_TRANSITION, name: "load" });

		const { optionsReducer } = getState();
		if (optionsReducer.ratiosDataStatus !== "FETCHING") {
			return;
		}

		try {
			const response = await apiInstance.get(
				`${URLS.FINANCIAL_RATIOS_DETAILS_PATH}/${venueXid}`,
				{
					params: {
						includeYearEndData: includeYearEndData,
					},
				}
			);

			const data = response.data.data || null;
			if (data) {
				data.venueXid = venueXid;
				dispatch({ type: actionTypes.FINANCIAL_RATIOS_API_TRANSITION, name: "success" });
				dispatch(storeFinancialRatiosResults(data));
			}
		} catch (error) {
			dispatch({ type: actionTypes.FINANCIAL_RATIOS_API_TRANSITION, name: "failure" });
		}
	};
};

export default {
	fetchFinancialRatios,
};
