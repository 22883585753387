import formatter from "utils/formatUtils";
import { buildDefaultFilterState } from "../../AnalystResearch/RatingsAndPriceTargetsCard/RatingsAndPriceTargetsAPIParams";
import ResearchAPIUtils from "services/APIs/Research/ResearchAPI";
import EstimatesAPIUtils from "services/APIs/Equities/Morningstar/Estimates/EstimatesAPI";
import DividendsAPIUtils from "services/APIs/CorporateActions/Dividends/DividendsAPI";
import { PERIODICITY_OPTIONS } from "utils/constants";
import { isUndefined } from "lodash";
import utils from "utils/utils";

const halfIndicatorDiameter = 4;

export const IndicatorHighLowRender = (
	highlow,
	{ minSpaceTop = 0, minSpaceBottom = 0, defaultHighTextPosition = 0 }
) => {
	// Safety check to prevent duplicate rendering in the same chart
	const { highLowCache = {} } = highlow?.indicator?.panel?.core;
	const cacheKey = JSON.stringify(highlow.data);
	if (highLowCache[cacheKey]) return;
	highLowCache[cacheKey] = true;
	const { data, ctx, boundingBox: box } = highlow;
	ctx.strokeStyle = "#FFF";
	ctx.lineWidth = 1.5;
	// high dot
	ctx.fillStyle = "#060";
	ctx.beginPath();
	data.high.y = Math.max(data.high.y, minSpaceTop);
	const maxXValue = box.right - halfIndicatorDiameter;
	data.high.x =
		data.high.x < halfIndicatorDiameter
			? halfIndicatorDiameter
			: data.high.x > maxXValue
			? maxXValue
			: data.high.x;
	ctx.arc(data.high.x, data.high.y, 4.5, 0, 2 * Math.PI);
	ctx.fill();
	ctx.stroke();
	// low dot
	ctx.fillStyle = "#A00";
	ctx.beginPath();
	data.low.y = Math.min(data.low.y, box.bottom - minSpaceBottom);
	data.low.x =
		data.low.x < halfIndicatorDiameter
			? halfIndicatorDiameter
			: data.low.x > maxXValue
			? maxXValue
			: data.low.x;
	ctx.arc(data.low.x, data.low.y, 4.5, 0, 2 * Math.PI);
	ctx.fill();
	ctx.stroke();
	// label high
	ctx.beginPath();
	ctx.font = "12px Verdana";
	const isPrice = data.high.valueFormatted.includes("$");
	const highText = `High: ${
		isPrice
			? formatter.price(data.high.value)
			: formatter.percent(data.high.value, 2, { percentModifier: 0.01 })
	}`;
	const widthHighText = ctx.measureText(highText).width + 6;
	const highPosition = data.high.x < box.left + widthHighText / 2 ? "left" : "center";
	const widthHighTextPosition =
		ctx.textAlign === "right" ? widthHighText : defaultHighTextPosition;

	ctx.textAlign = data.high.x > box.right - widthHighText / 2 ? "right" : highPosition;
	ctx.textBaseline = "bottom";
	ctx.rect(
		data.high.x -
			(ctx.textAlign === "center" ? widthHighText / 2 : widthHighTextPosition),
		data.high.y - 19,
		widthHighText,
		13
	);
	ctx.fillStyle = "#FFFF";
	ctx.stroke();
	ctx.fill();
	ctx.fillStyle = "#323232";
	ctx.fillText(highText, data.high.x, data.high.y - 6);
	// label low
	ctx.beginPath();
	ctx.font = "12px Verdana";
	const lowText = `Low: ${
		isPrice
			? formatter.price(data.low.value)
			: formatter.percent(data.low.value, 2, { percentModifier: 0.01 })
	}`;
	const widthLowText = ctx.measureText(lowText).width + 6;
	const textLowPosition = data.low.x < box.left + widthLowText / 2 ? "left" : "center";
	ctx.textAlign = data.low.x > box.right - widthLowText / 2 ? "right" : textLowPosition;
	ctx.textBaseline = "top";
	let xoffset = ctx.textAlign === "right" ? widthLowText : 0;
	let xcoord = data.low.x - (ctx.textAlign === "center" ? widthLowText / 2 : xoffset);
	ctx.rect(xcoord, data.low.y + 6, widthLowText, 13);
	ctx.fillStyle = "#FFFF";
	ctx.stroke();
	ctx.fill();
	ctx.fillStyle = "#323232";
	ctx.fillText(lowText, data.low.x, data.low.y + 6);
};

const getInfoFilters = (props) => {
	const infoFilters = { isRating: false, isFvEstimate: false, isPriceTarget: false };

	infoFilters.isRating = props.hoverInfoFilters?.find(
		(item) => item.name === "ratings" || item.name === "msratings"
	)?.value;
	infoFilters.isFvEstimate = props.hoverInfoFilters?.find(
		(item) => item.name === "fvEstimate"
	)?.value;
	infoFilters.isPriceTarget = props.hoverInfoFilters?.find(
		(item) => item.name === "priceTarget"
	)?.value;
	return infoFilters;
};
// Equities API Requests for Events
export const eventCustomData = async (
	props,
	tab,
	[setDividendsData, setEarningsData, setResearchMSData, setResearchMstarData]
) => {
	if (!props.venueXid) {
		return;
	}

	if (["overview", "charting"].includes(tab)) {
		await fetchDividendsData(props, setDividendsData);
	}

	if (["overview", "charting"].includes(tab)) {
		await fetchEarningsData(props, setEarningsData);
	}

	if (["research", "charting"].includes(tab)) {
		let infoFilters;
		if (props?.hoverInfoFilters) {
			// InfoFilters passed by Analyst Research card
			infoFilters = getInfoFilters(props);
		} else {
			// infofilters set for charting tab chart.  it should return ratings only
			infoFilters = {
				isRating: true,
				isFvEstimate: false,
				isPriceTarget: false,
				dateSpan: "10Y",
			};
		}
		await fetchResearchDetails(
			props,
			[setResearchMSData, setResearchMstarData],
			infoFilters
		);
	}
};

const fetchDividendsData = async (props, setDividendsData) => {
	// Return 20 results as the chart can go back 5 years with 1 result per quarter
	const dividendsEventData = DividendsAPIUtils.getDividendsHistory(
		props.apiInstance,
		props.venueXid
	)
		.setNumberOfResults(20)
		.fetch();
	Promise.resolve(dividendsEventData)
		.then(({ dividendEvents }) => {
			if (dividendEvents) {
				const dividendData = getDividendsData(dividendEvents);
				localStorage.setItem("dividendsData", JSON.stringify(dividendData));
				setDividendsData(dividendData);
			} else {
				setDividendsData(null);
				localStorage.setItem("dividendsData", JSON.stringify([]));
			}
		})
		.catch(() => {
			setDividendsData(null);
		});
};

const fetchEarningsData = async (props, setEarningsData) => {
	// Return the maximum number of historical periods - 20 quarters -
	// as the chart can go back 5 years
	EstimatesAPIUtils.getEstimatesTrends(
		props.apiInstance,
		props.venueXid,
		PERIODICITY_OPTIONS.QUARTERLY,
		4,
		20
	)
		.then(({ historical, forward }) => {
			const currency = forward?.[0]?.currencyCode;
			const earningsData = getEarningsData(historical, currency);
			localStorage.setItem("earningsData", JSON.stringify(earningsData));
			setEarningsData(earningsData);
		})
		.catch(() => {
			localStorage.setItem("earningsData", JSON.stringify([]));
			setEarningsData(null);
		});
};

const fetchResearchDetails = async (
	props,
	[setResearchMSData, setResearchMstarData],
	infoFilters = { isRating: true, isPriceTarget: true, dateSpan: "1Y" }
) => {
	// build default filters
	let filters = buildDefaultFilterState({
		ratings: infoFilters.isRating,
		priceTargets: infoFilters.isPriceTarget,
		dateSpan: infoFilters.dateSpan,
	});

	if (props?.filters?.provider === "Morgan Stanley" || isUndefined(props.filters)) {
		// Undefined check because no props filters are passed by Charting tab
		// Return all of Research Ratings data
		await ResearchAPIUtils.getResearchDetails(
			props.apiInstance,
			props.venueXid,
			filters,
			100,
			0,
			(response) => {
				localStorage.setItem(
					"researchMSData",
					JSON.stringify(getResearchMSData(response.data.data.items[0]))
				);
				setResearchMSData(JSON.parse(localStorage.getItem("researchMSData")));
			}
		);
	}
	if (props?.filters?.provider === "Morningstar" || isUndefined(props.filters)) {
		filters = {
			...filters,
			priceTargets: infoFilters.isFvEstimate,
			provider: "Morningstar",
		};
		await ResearchAPIUtils.getResearchDetails(
			props.apiInstance,
			props.venueXid,
			filters,
			100,
			0,
			(response) => {
				localStorage.setItem(
					"researchMstarData",
					JSON.stringify(getResearchMstarData(response.data.data.items[0]))
				);
				setResearchMstarData(JSON.parse(localStorage.getItem("researchMstarData")));
			}
		);
	}
};

//Method to format the Earnings data
const getEarningsData = (data, currency) => {
	let earningsFinal = [];
	if (data && data.length > 0) {
		data.forEach((element) => {
			earningsFinal.push({
				actual: element.actual,
				date: element.announceDate,
				consensusEstimate: element.adjustedEpsEstimate?.mean,
				estimateRangeLow: element.adjustedEpsEstimate?.low,
				estimateRangeHigh: element.adjustedEpsEstimate?.high,
				CurrentCurrency: currency,
			});
		});
	}
	return earningsFinal;
};

//Method to format the dividend data
const getDividendsData = (data) => {
	let dividendsFinal = [];
	if (data.length > 0) {
		data.forEach((element) => {
			dividendsFinal.push({
				date: element.exDate,
				paymentDate: element.paymentDate,
				typeDescription: element.typeDescription,
				dividends: element.amount,
				cashEquivalent: element.cashEquivalent,
				currency: element.returnedInCurrency,
			});
		});
	}
	return dividendsFinal;
};
//End Method to format the dividend data

// Method to format Research Ratings Data
const getResearchMSData = (data) => {
	let researchMSsFinal = [];

	if (data.length > 0) {
		data.forEach((element) => {
			const elementLabel =
				element.Label === "RatingChange" ? "Ratings Changed" : element.Label;
			researchMSsFinal.push({
				date: getResearchJSDate(element),
				ProviderName: element.ProviderName,
				Label: element.Label === "TargetPrice" ? "Price Target" : elementLabel,
				Rating: utils.ratingString(element.RatingValue),
				ChangeType: element.ChangeType,
				TargetPrice: element.TargetPriceValue,
				PriorTargetPrice: element.PriorTargetPriceValue,
				Currency: element.Currency,
				PriorRating: utils.ratingString(element.PriorRatingValue),
			});
		});
	}
	return researchMSsFinal;
};
const getResearchMstarData = (data) => {
	let researchMstarsFinal = [];
	if (data.length > 0) {
		data.forEach((element) => {
			const elementLabel =
				element.Label === "RatingChange" ? "Ratings Changed" : element.Label;
			researchMstarsFinal.push({
				date: getResearchJSDate(element),
				ProviderName: element.ProviderName,
				Label: element.Label === "TargetPrice" ? "Price Target" : elementLabel,
				Rating: element.RatingValue,
				PriorRating: element.PriorRatingValue,
				ChangeType: element.ChangeType,
				TargetPrice: element.TargetPriceValue,
				PriorTargetPrice: element.PriorTargetPriceValue,
			});
		});
	}
	return researchMstarsFinal;
};

// Converts Excel date format to JS date
const getResearchJSDate = (element) => {
	return new Date(Math.round((element.ChangeDate - 25569) * 86400 * 1000));
};

export const removeFocusfromMenu = (element) => {
	setTimeout(() => {
		element.firstChild.blur();
	}, 1);
};
