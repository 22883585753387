import { URLS, RESEARCH_IDS } from "utils/constants";
import formatter from "utils/formatUtils";
import { find } from "lodash";

import { mapFiltersToParams } from "components/MarketsAndResearch/AnalystResearch/RatingsAndPriceTargetsCard/RatingsAndPriceTargetsAPIParams";
import * as ResearchAPIParams from "../../../components/MarketsAndResearch/AnalystResearch/AllResearchCard/ResearchAPIParams";

const EMPTY_RESEARCH_OBJ = { items: [] };

/**
 * Utility for fetching API data for research ratings and price targets
 * @param {Object} apiInstance - api instance
 * @param {Number} venueXid - the Xid for the symbol selected
 * @param {Number} limit - limit the number of news results returned
 * @param {Number} page - the page the user is on
 * @param {String} dateSelect - the time period the news articles come from
 * @param {String} sortBy - how to order the results
 * @param {String Array} providers - array of news providers
 */
const getResearchDetails = async (
	apiInstance,
	venueXid,
	filterData,
	PAGE_LIMIT,
	researchOffset,
	callback
) => {
	if (!apiInstance || !venueXid) {
		return "error";
	}

	try {
		const postPayload = mapFiltersToParams(
			venueXid,
			filterData,
			PAGE_LIMIT,
			researchOffset
		);

		const researchRequest = await apiInstance.post(
			`${URLS.RESEARCH_PATH}/${RESEARCH_IDS.RATINGS_AND_PRICE_TARGETS_ID}/details`,
			postPayload
		);
		Promise.resolve(researchRequest).then(callback);
	} catch (error) {
		return EMPTY_RESEARCH_OBJ;
	}
};

/**
 * Utility for fetching API data for financial research details
 * @param {Object} apiInstance - api instance
 * @param {number} venueXid - the Xid for the symbol selected
 * @param {Function} successCb - callback function to execute when the api fetch is successful
 * @param {Function} failureCb - callback function to execute when the api fetch fails
 */
const getAndParseResearchDetails = async (
	apiInstance,
	venueXid,
	successCB,
	failureCB
) => {
	if (!apiInstance || !venueXid) {
		return "error";
	}

	const equity = {};

	const postPayload = {
		arguments: [
			{
				field: "WSODIssue",
				type: "string",
				values: [`${venueXid}`],
			},
		],
		sortArgument: {
			direction: "desc",
			field: "XID",
		},
		limit: 20,
		offset: 0,
	};

	try {
		const response = await apiInstance.post(
			`${URLS.RESEARCH_PATH}/${RESEARCH_IDS.FINANCIAL_DETAILS_ID}/details`,
			postPayload
		);
		const data = response.data.data || null;
		if (data && data.items && data.items.length) {
			const financialsData = data.items[0];
			const ratingsData =
				financialsData && financialsData.length > 0 ? financialsData[0] : null;
			const morganStanley = financialsData.find(
				(f) => f.ProviderName === "Morgan Stanley"
			);
			const morningstar = financialsData.find((f) => f.ProviderName === "Morningstar");
			if (morganStanley || morningstar) {
				equity.morganStanleyRatingNumeric = morganStanley
					? morganStanley.ProviderRatingNumeric
					: null;
				equity.morganStanleyRatingValue = morganStanley
					? morganStanley.ProviderRatingValue
					: null;
				equity.morningstarRatingNumeric = morningstar
					? morningstar.ProviderRatingNumeric
					: null;
				equity.morningstarRatingValue = morningstar
					? morningstar.ProviderRatingValue
					: null;
				equity.targetPrice = formatter.price(
					ratingsData.TargetPrice,
					undefined,
					undefined,
					ratingsData.TargetPriceCurrency
				);
			} else {
				return;
			}
			if (successCB) {
				successCB();
			}
			return equity;
		} else {
			if (failureCB) {
				failureCB();
			}
			return "Error";
		}
	} catch (error) {
		if (failureCB) {
			failureCB();
		}
		return error;
	}
};

/**
 * Utility for fetching API data for research documents
 * @param {Object} apiInstance - api instance
 * @param {Number} venueXid - the Xid for the symbol selected
 * @param {Object} filterData - filter data
 * @param {Number} pageLimit - number of articles per page
 * @param {Number} offset - the first row returned
 * @param {Number} legacyCompany - the unique legacy company number
 */
const getResearchDocuments = async (
	apiInstance,
	venueXid,
	filterData,
	pageLimit,
	offset,
	legacyCompany,
	showAllResearch
) => {
	if (!apiInstance || (!showAllResearch && !venueXid)) {
		return "error";
	}

	const researchSortArgument = {
		direction: "desc",
		field: "DocumentDate",
	};
	const researchParams = ResearchAPIParams.mapFiltersToParams(
		venueXid,
		filterData,
		researchSortArgument,
		pageLimit,
		offset,
		legacyCompany
	);

	try {
		const postPayload = {
			argsOperator: "AND",
			arguments: researchParams.arguments,
			limit: pageLimit,
			offset: offset,
			sortArgument: researchSortArgument,
		};
		const response = await apiInstance.post(
			`${URLS.RESEARCH_PATH}/${RESEARCH_IDS.RESEARCH}/documents`,
			postPayload
		);
		if (response?.data?.data?.items?.length) {
			return response.data.data;
		}
		return EMPTY_RESEARCH_OBJ;
	} catch (error) {
		return EMPTY_RESEARCH_OBJ;
	}
};

const getProviderDocumentDetails = async (apiInstance, venueXid, reportType) => {
	if (!apiInstance || !venueXid) {
		return "Error: No VenueXid";
	}

	try {
		const postMessage = {
			argsOperator: "AND",
			arguments: [
				{
					field: "Module",
					conditions: [
						{
							operator: "EqualTo",
							values: [reportType],
						},
					],
				},
			],
			limit: 5,
			offset: 0,
			sortArgument: {
				direction: "desc",
				field: "DocumentDate",
			},
		};
		if (reportType === "MorganStanleyResearch") {
			postMessage.arguments.push(
				{
					field: "PrimaryWSODIssue",
					conditions: [
						{
							operator: "EqualTo",
							values: [`${venueXid}`],
						},
					],
				},
				{
					field: "Module",
					conditions: [
						{
							operator: "EqualTo",
							values: ["CompanySpecific"],
						},
					],
				}
			);
		} else if (reportType === "MorningstarResearch") {
			postMessage.arguments.push(
				{
					field: "WSODIssue",
					conditions: [
						{
							operator: "EqualTo",
							values: [`${venueXid}`],
						},
					],
				},
				{
					field: "Module",
					conditions: [
						{
							operator: "EqualTo",
							values: ["CompanySpecific"],
						},
					],
				}
			);
		}
		const response = await apiInstance.post(
			`${URLS.RESEARCH_PATH}/${RESEARCH_IDS.RESEARCH}/documents`,
			postMessage
		);
		const documentData = response.data || {};
		if (documentData && documentData.data && documentData.data.items.length) {
			return documentData.data;
		}
	} catch (error) {
		return EMPTY_RESEARCH_OBJ;
	}
};

/**
 * Utility for fetching API data for ratings and price target updates
 * @param {Object} apiInstance - api instance
 * @param {number} venueXid - the Xid for the symbol selected
 */
const getRatingAndPriceTargetDetails = async (apiInstance, venueXid) => {
	if (!apiInstance || !venueXid) {
		return "error";
	}

	try {
		const postPayload = {
			arguments: [
				{
					field: "WSODIssue",
					type: "string",
					values: [`${venueXid}`],
				},
				{
					field: "ProviderID",
					type: "string",
					values: ["809"],
				},
				{
					field: "StartDate",
					type: "string",
					values: ["2021-01-12"],
				},
				{
					field: "IncludeRatings",
					type: "string",
					values: ["0"],
				},
				{
					field: "IncludeTargetPrice",
					type: "string",
					values: ["1"],
				},
			],
			sortArgument: {
				direction: "desc",
				field: "DocumentDate",
			},
			limit: 1,
			offset: 0,
		};

		const response = await apiInstance.post(
			`${URLS.RESEARCH_PATH}/${RESEARCH_IDS.RATINGS_AND_PRICE_TARGETS_ID}/details`,
			postPayload
		);

		if (response?.data?.data?.items[0][0]) {
			return response.data.data.items[0][0];
		}

		return {};
	} catch (error) {
		return {};
	}
};

/**
 * Utility for fetching API data for financial research details
 * @param {Object} apiInstance - api instance
 * @param {number} venueXid - the Xid for the symbol selected
 */
const getResearchFinancialDetails = async (apiInstance, venueXid) => {
	if (!apiInstance || !venueXid) {
		return {};
	}

	const postPayload = {
		arguments: [
			{
				field: "WSODIssue",
				type: "string",
				values: [`${venueXid}`],
			},
		],
		sortArgument: {
			direction: "desc",
			field: "XID",
		},
		limit: 20,
		offset: 0,
	};

	const researchFinancialDetails = {};

	try {
		const response = await apiInstance.post(
			`${URLS.RESEARCH_PATH}/${RESEARCH_IDS.FINANCIAL_DETAILS_ID}/details`,
			postPayload
		);

		const data = response.data.data || null;
		if (data?.items?.length) {
			// Only need ratings data at the moment, which is the first array item
			data.items[0].forEach((ratingData) => {
				if (ratingData.ProviderName === "Morgan Stanley") {
					researchFinancialDetails.morganStanley = {
						ratingDate: ratingData.RatingDate,
						ratingNumeric: ratingData.ProviderRatingNumeric,
						ratingChangeDate: ratingData.RatingChangeDate,
						ratingChangeType: ratingData.RatingChangeType,
						targetPrice: ratingData.TargetPrice,
						targetCurrency: ratingData.TargetPriceCurrency,
					};
				}

				if (ratingData.ProviderName === "Morningstar") {
					researchFinancialDetails.morningstar = {
						ratingDate: ratingData.RatingDate,
						ratingNumeric: ratingData.ProviderRatingNumeric,
						ratingChangeDate: ratingData.RatingChangeDate,
						ratingChangeType: ratingData.RatingChangeType,
						targetPrice: ratingData.TargetPrice,
						economicMoat: ratingData.EconomicMoat,
						economicMoatDate: ratingData.EconomicMoatDate,
					};
				}
			});
			let financialLabel = {};
			if (data.items && data.items.length && data.items[1]) {
				financialLabel = data.items[1];
			}
			const bear = find(financialLabel, {
				ProviderFinancialLabel: "BearValuePerShare",
			});
			const base = find(financialLabel, {
				ProviderFinancialLabel: "BaseValuePerShare",
			});
			const bull = find(financialLabel, {
				ProviderFinancialLabel: "BullValuePerShare",
			});
			bear
				? (researchFinancialDetails.morganStanley.bear = bear)
				: (researchFinancialDetails.morganStanley.bear = undefined);
			base
				? (researchFinancialDetails.morganStanley.base = base)
				: (researchFinancialDetails.morganStanley.base = undefined);
			bull
				? (researchFinancialDetails.morganStanley.bull = bull)
				: (researchFinancialDetails.morganStanley.bull = undefined);
		}

		return researchFinancialDetails;
	} catch (error) {
		return researchFinancialDetails;
	}
};

const getEquitiesMorningStarPeerResults = async (apiInstance, venueXid) => {
	if (!apiInstance || !venueXid) {
		return "error";
	}

	try {
		const response = await apiInstance.get(`${URLS.PEERS_PATH}/${venueXid}`);
		const data = response.data.data || null;
		let peersData = [];
		if (data) {
			data.forEach((element) => {
				peersData.push({ wsodIssue: element.wsodIssue, peerRank: element.PeerRank });
			});
			return peersData;
		}
	} catch (error) {
		if (error && error.response && error.response.status === 404) {
			return [];
		} else {
			return null;
		}
	}
};

export default {
	getResearchDetails,
	getAndParseResearchDetails,
	getResearchDocuments,
	getProviderDocumentDetails,
	getRatingAndPriceTargetDetails,
	getResearchFinancialDetails,
	getEquitiesMorningStarPeerResults,
};
