import React, { Component } from "react";
import { connect } from "react-redux";

import actionTypes from "../../../store/actions/actionTypes";

import styles from "./MonthlyExpirationMenu.module.scss";

class MonthlyExpirationMenu extends Component {
	handleMonthlyExpirationOnChange = (e) => {
		const showMonthlyExpiration = e.target.value === "onlyMonthly";
		this.props.dispatchUpdateMonthlyExpiration(showMonthlyExpiration);

		this.props.handleOnChange();
	};

	render() {
		return (
			<div id="expirationsMenu">
				<div className={styles.expirationRow}>
					<input
						id="onlyMonthlyExpirations"
						type="radio"
						name="monthlyExpirations"
						value="onlyMonthly"
						checked={this.props.showMonthlyExpiration}
						onChange={this.handleMonthlyExpirationOnChange}
					/>
					<label htmlFor="onlyMonthlyExpirations">
						<span></span>
						Only show monthly expirations
					</label>
				</div>
				<div className={styles.expirationRow}>
					<input
						id="allMonthlyExpirations"
						type="radio"
						name="monthlyExpirations"
						value="all"
						checked={!this.props.showMonthlyExpiration}
						onChange={this.handleMonthlyExpirationOnChange}
					/>
					<label htmlFor="allMonthlyExpirations">
						<span></span>
						Show all expirations
					</label>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		showMonthlyExpiration: state.optionsReducer.showMonthlyExpiration,
	};
};

// dispatch events from container to redux by  creating a mapping function
const mapDispatchToProps = (dispatch) => {
	return {
		dispatchUpdateMonthlyExpiration: (showMonthlyExpiration) =>
			dispatch(
				{ type: actionTypes.UPDATE_SHOW_MONTHLY_EXPIRATION },
				showMonthlyExpiration
			),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MonthlyExpirationMenu);
