/* eslint no-restricted-globals:off */

import React, { Component } from "react";
import { Button } from "react-bootstrap";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import StrikesFilterItem from "./StrikesFilterItem";
import styles from "./StrikesFilter.module.scss";

// id key will be prefixed with 'strikes_'
const items = [
	{
		id: "10",
		value: 10,
		label: "Show 10 Strikes",
	},
	{
		id: "20",
		value: 20,
		label: "Show 20 Strikes",
	},
	{
		id: "40",
		value: 40,
		label: "Show 40 Strikes",
	},
	{
		id: "all",
		value: "all",
		label: "Show all Strikes",
	},
	{
		id: "custom",
		value: "custom",
		label: "Custom Strike price",
	},
];

// currency formatting settings
const defaultMaskOptions = {
	prefix: "$",
	suffix: "",
	includeThousandsSeparator: true,
	thousandsSeparatorSymbol: ",",
	allowDecimal: true,
	decimalSymbol: ".",
	decimalLimit: 2,
	integerLimit: 7,
	allowNegative: false,
	allowLeadingZeroes: false,
};

const currencyMask = createNumberMask(defaultMaskOptions);

class StrikesFilter extends Component {
	constructor(props) {
		super(props);
		this.regex = new RegExp("[$,]", "g");
		this.state = {
			strikes: "10",
			selectedStrikes: "",
			customLow: "",
			customHigh: "",
			error: "",
		};
	}

	onSubmit = (e) => {
		this.setState({ strikes: this.state.selectedStrikes });
		this.props.onSubmit(this.state);
		e.preventDefault();
	};

	onValueChange = async (e) => {
		e.preventDefault();
		if (
			this.state.selectedStrikes === "custom" &&
			this.state.customLow === "" &&
			this.state.customHigh === ""
		) {
			this.setState({ error: "Please enter valid input" });
		} else if (
			this.state.selectedStrikes === "custom" &&
			parseFloat(this.state.customLow.replace(this.regex, "")) >
				parseFloat(this.state.customHigh.replace(this.regex, ""))
		) {
			this.setState({ error: "Please check low and high values" });
		} else {
			await this.setState({
				strikes: this.state.selectedStrikes,
			});
			this.props.onValueChange(this.state);
		}
	};

	onCancelClick = (e) => {
		e.preventDefault();
		this.setState({
			customLow: this.state.customLow !== "" ? this.state.customLow : "",
			customHigh: this.state.customHigh !== "" ? this.state.customHigh : "",
			strikes: this.state.strikes,
			selectedStrikes: this.state.strikes,
		});
	};

	onChange = (key) => (e) => {
		if (key === "selectedStrikes" && e.target.value !== "custom") {
			this.setState({
				customLow: "",
				customHigh: "",
				error: "",
			});
		}
		const state = {};
		state[key] = e.target.value;
		this.setState(state);
	};

	formatOnBlur = (key) => (e) => {
		if (e.target.value) {
			// value ends with period
			if (/\.$/.test(e.target.value)) {
				e.target.value += "00";
			}
			// value ends with period and single digit
			else if (/\.\d{1}$/.test(e.target.value)) {
				e.target.value += "0";
			}
			// value has no decimal
			else if (!/\.\d{2}/.test(e.target.value)) {
				e.target.value += ".00";
			}
		}
		if (key) {
			const state = {};
			state[key] = e.target.value;
			this.setState(state);
		}
	};

	render() {
		let buttons;

		const isMobileClass = this.props.data.isMobile ? styles.strikesFilterMobile : "";

		if (this.props.data.isMobile) {
			buttons = (
				<div className={styles.strikesFilterApply}>
					<Button onClick={this.onValueChange} type="submit">
						Apply
					</Button>
				</div>
			);
		} else {
			buttons = (
				<div className={[styles.strikesFilterApply, "mt-0"].join(" ")}>
					<button
						id="strikesCancel"
						className={[styles.desktopButtons, "ml-auto"].join(" ")}
						onClick={this.onCancelClick}
						type="reset"
					>
						Cancel
					</button>
					<button
						id="strikesApply"
						className={styles.desktopButtons}
						onClick={this.onValueChange}
						type="submit"
					>
						Apply
					</button>
				</div>
			);
		}

		return (
			<form onSubmit={this.onSubmit}>
				<fieldset
					id="strikesFieldset"
					className={[styles.fieldset, isMobileClass].join(" ")}
					aria-live="polite"
				>
					<legend className="sr-only">Strikes Filter</legend>
					{items.map((item, index) => (
						<StrikesFilterItem
							key={index}
							data={item}
							current={
								this.state.selectedStrikes === ""
									? this.state.strikes
									: this.state.selectedStrikes
							}
							onChange={this.onChange("selectedStrikes")}
						/>
					))}
					<StrikesFilterCustom
						visible={
							this.state.strikes === "custom" || this.state.selectedStrikes === "custom"
						}
						parent={this}
					/>
					{this.state.error !== "" && <p className="text-danger">{this.state.error}</p>}
				</fieldset>
				<div>{buttons}</div>
			</form>
		);
	}
}

function StrikesFilterCustom({ visible, parent }) {
	if (!visible) return null;

	return (
		<div className={styles.strikesFilterCustom}>
			<MaskedInput
				required
				className="form-control"
				placeholder="$0.00"
				mask={currencyMask}
				onBlur={parent.formatOnBlur("customLow")}
				onChange={parent.onChange("customLow")}
				value={parent.state.customLow}
				aria-label="Custom Strike Price Low"
			/>
			<span className={styles.strikesFilterSeparator}>to</span>
			<MaskedInput
				required
				className="form-control"
				placeholder="$0.00"
				mask={currencyMask}
				onBlur={parent.formatOnBlur("customHigh")}
				onChange={parent.onChange("customHigh")}
				value={parent.state.customHigh}
				aria-label="Custom Strike Price High"
			/>
		</div>
	);
}

StrikesFilter.defaultProps = {
	data: {},
};

export default StrikesFilter;
