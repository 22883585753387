/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import Tabs from "../../../Lib/Tabs/Tabs";
import {
	FINANCIAL_STATEMENT_TYPE,
	MARKETS_AND_RESEARCH_ROUTE_NAMES,
} from "../../../../utils/constants";
import { Link } from "react-router-dom";
import { FINANCIAL_PERIOD_TYPE, FINANCIAL_TABS } from "./FinancialsConstants";
import CollapsibleCard from "../../../Lib/CollapsibleCard/CollapsibleCard";
import StatementDetailsTable from "../StatementDetailsTable/StatementDetailsTable";
import SecFilingTable from "../SecFiling/SecFilingTable";
import StatementOverview from "../StatementOverview/StatementOverview";
import FinancialsChartContainer from "../FinancialsChartContainer";
import TextToggle from "../../../Lib/TextToggle/TextToggle";
import styles from "./FinancialsCard.module.scss";

import FinancialStatementsAPIUtils from "services/APIs/Equities/Morningstar/FinancialStatements/FinancialStatementsAPI";
import FinancialRatiosAPIUtils from "services/APIs/Equities/Morningstar/FinancialRatios/FinancialRatiosAPI";
import SimpleSpinner from "components/Lib/SimpleSpinner/SimpleSpinner";
import NoData from "components/Lib/NoData/NoData";
import utils from "utils/utils";

const FinancialsCard = (props) => {
	const isMobile = props.isMobile;
	const [periodType, setPeriodType] = useState(FINANCIAL_PERIOD_TYPE[1]);
	const [activeSelectedTab, setActiveSelectedTab] = useState(
		props.activeTab || "Income Statement"
	);
	const [isContentReady, setIsContentReady] = useState(false);
	const [isContentValidated, setIsContentValidated] = useState(false);
	const [validatedData, setValidatedData] = useState(null);
	const [financialKeyData, setFinancialKeyData] = useState(null);
	const [financialStatementsData, setFinancialStatementsData] = useState(null);
	const [financialRatiosData, setFinancialRatiosData] = useState(null);
	const [financialKeyCurrency, setFinancialKeyCurrency] = useState([]);
	const [financialStatementsCurrency, setFinancialStatementsCurrency] = useState([]);
	const [financialKeyCurrencyLabel, setFinancialKeyCurrencyLabel] = useState([]);
	const [financialStatementsCurrencyLabel, setFinancialStatementsCurrencyLabel] =
		useState([]);

	const pointLabelOptions = { showPointLabel: false };

	useEffect(() => {
		fetchNewData(activeSelectedTab, periodType);
	}, [props.isMobile]);

	useEffect(() => {
		setFinancialKeyData(props.financialStatementsKeyData);
	}, [props.financialStatementsKeyData]);

	useEffect(() => {
		setFinancialStatementsData(props.financialStatementsData);
	}, [props.financialStatementsData]);

	useEffect(() => {
		setFinancialRatiosData(props.financialRatiosHistoryData);
	}, [props.financialRatiosHistoryData]);

	useEffect(() => {
		if (financialKeyData && financialStatementsData && financialRatiosData) {
			setIsContentReady(true);
			setFinancialKeyCurrency(utils.getUniqueCurrencies(financialKeyData?.currencies));
			setFinancialStatementsCurrency(
				utils.getUniqueCurrencies(financialStatementsData?.currencies)
			);
			setFinancialKeyCurrencyLabel(
				utils.fetchUniqueCurrency(financialKeyData?.currencies)
			);
			setFinancialStatementsCurrencyLabel(
				utils.fetchUniqueCurrency(financialStatementsData?.currencies)
			);
			validateData();
		}
	}, [financialKeyData, financialStatementsData, financialRatiosData]);

	useEffect(() => {
		if (isContentReady) {
			validateData();
		}
	}, [isContentReady]);

	const fetchNewData = async (tabLabel, newPeriodType) => {
		if (
			tabLabel === FINANCIAL_TABS.IncomeStatement ||
			tabLabel === FINANCIAL_TABS.BalanceSheet ||
			tabLabel === FINANCIAL_TABS.CashFlow
		) {
			setFinancialKeyData(null);
			setFinancialRatiosData(null);
			setFinancialStatementsData(null);
			setIsContentReady(false);
			setIsContentValidated(false);
			setValidatedData(null);

			const newFinancialKeyData = await FinancialStatementsAPIUtils.getKeyData(
				props.apiInstance,
				props.entityXid,
				tabLabel,
				newPeriodType.value,
				props.numPeriods
			);

			if (newFinancialKeyData && newFinancialKeyData.rows) {
				setFinancialKeyData(newFinancialKeyData);
			} else {
				setFinancialKeyData({});
			}

			const newFinancialRatiosData =
				await FinancialRatiosAPIUtils.getFinancialRatiosHistory(
					props.apiInstance,
					props.venueXid,
					true,
					props.numPeriods
				);

			if (newFinancialRatiosData && newFinancialRatiosData.operations) {
				setFinancialRatiosData(newFinancialRatiosData.operations);
			} else {
				setFinancialRatiosData([]);
			}

			const newFinancialStatementsData =
				await FinancialStatementsAPIUtils.getFinancialStatements(
					props.apiInstance,
					props.entityXid,
					tabLabel,
					newPeriodType.value,
					props.numPeriods
				);

			if (newFinancialStatementsData?.rows) {
				setFinancialStatementsData(newFinancialStatementsData);
			} else {
				setFinancialStatementsData({});
			}
		}
	};

	const onTabClick = (tabLabel) => {
		// Do nothing if the active tab was clicked
		if (tabLabel === activeSelectedTab) return;
		// Otherwise, fetch new data and set the new active tab
		fetchNewData(tabLabel, periodType);
		window.dataLayer.push({
			event: "financialTabClick",
			eventProps: {
				tabName: tabLabel,
			},
		});
		setActiveSelectedTab(tabLabel);
	};
	const handleToggle = (newToggleText) => {
		const newPeriodType = FINANCIAL_PERIOD_TYPE.filter(
			(x) => x.value === newToggleText
		)[0];

		if (periodType === newPeriodType) {
			return;
		}

		fetchNewData(activeSelectedTab, newPeriodType);
		setPeriodType(newPeriodType);
	};

	const chartOptions = { height: 250 };
	const displayNoActivitySection = (statementType) => {
		return (
			<NoData>{`There is no ${statementType} data available for ${props.symbol}`}</NoData>
		);
	};
	const getFinancialChartContainer = (statementType) => {
		return (
			<div className={styles.rightComponent}>
				<FinancialsChartContainer
					statementType={statementType}
					periodType={periodType.label}
					pointLabelOptions={
						statementType === FINANCIAL_STATEMENT_TYPE.CASH_FLOW
							? pointLabelOptions
							: null
					}
					symbol={props.symbol}
					lineChartOptions={chartOptions}
					barChartOptions={chartOptions}
					financialStatementsKeyData={financialKeyData}
					financialRatiosHistoryData={financialRatiosData}
					financialStatementsData={financialStatementsData}
					isMobile={isMobile}
				/>
			</div>
		);
	};
	const getIncomeStatementOverviewContainer = (statementType) => {
		return (
			<div className={styles.leftComponent}>
				<StatementOverview
					statementType={statementType}
					periodType={periodType.value}
					financialStatementsKeyData={financialKeyData}
					financialRatiosHistoryData={financialRatiosData}
					financialStatementsData={financialStatementsData}
				/>
			</div>
		);
	};
	const getIncomeStatementTableComponent = (statementType) => {
		return (
			<React.Fragment>
				<div className={styles.currencyLabel}>{financialStatementsCurrencyLabel}</div>
				<div className={styles.bottomComponent}>
					<StatementDetailsTable
						statementType={statementType}
						periodType={periodType.value}
						isMobile={isMobile}
						financialStatementsData={financialStatementsData}
						rowHeaderAsTooltip={true}
					/>
				</div>
			</React.Fragment>
		);
	};
	const getTextToggleComponent = () => {
		return (
			<div className={styles.textToggleMobileContainer}>
				<TextToggle
					handleToggle={handleToggle}
					activeValue={periodType.value}
					option1={FINANCIAL_PERIOD_TYPE[1]}
					option2={FINANCIAL_PERIOD_TYPE[0]}
				/>
			</div>
		);
	};

	const validateData = useCallback(() => {
		const validatedData =
			financialStatementsData &&
			Object.keys(financialStatementsData).length &&
			financialKeyData &&
			Object.keys(financialKeyData).length &&
			financialRatiosData &&
			Object.keys(financialRatiosData).length
				? true
				: false;
		setIsContentValidated(true);
		setValidatedData(validatedData);
	}, [financialKeyData, financialRatiosData, financialStatementsData]);

	return (
		<CollapsibleCard
			headingText="Financials"
			componentName={MARKETS_AND_RESEARCH_ROUTE_NAMES.FINANCIALS}
			contentReady={true}
			hideIcon={true}
		>
			<div className={styles.dataContainer}>
				<Tabs
					activeTab={activeSelectedTab}
					onClick={(k) => onTabClick(k)}
					shouldUseLinks={true}
					navName={"/ms-research/markets-and-research/Stocks/Financials"}
				>
					<div key="IncomeStatement" label={FINANCIAL_TABS.IncomeStatement}>
						{getTextToggleComponent()}
						{isContentValidated ? (
							validatedData ? (
								<div>
									{financialKeyCurrency.length === 1 && (
										<React.Fragment>
											{financialKeyCurrencyLabel}
											<div className={styles.tabContainer}>
												{isMobile &&
													getFinancialChartContainer(
														FINANCIAL_STATEMENT_TYPE.INCOME_STATEMENT
													)}
												{getIncomeStatementOverviewContainer(
													FINANCIAL_STATEMENT_TYPE.INCOME_STATEMENT
												)}
												{!isMobile &&
													getFinancialChartContainer(
														FINANCIAL_STATEMENT_TYPE.INCOME_STATEMENT
													)}
											</div>
										</React.Fragment>
									)}
									{getIncomeStatementTableComponent(
										FINANCIAL_STATEMENT_TYPE.INCOME_STATEMENT
									)}
								</div>
							) : (
								displayNoActivitySection(FINANCIAL_STATEMENT_TYPE.INCOME_STATEMENT)
							)
						) : (
							<div className={styles.dataContainer}>
								<SimpleSpinner />
							</div>
						)}
					</div>
					<div key="BalanceSheet" label={FINANCIAL_TABS.BalanceSheet}>
						{getTextToggleComponent()}
						{isContentValidated ? (
							validatedData ? (
								<div>
									{financialKeyCurrency.length === 1 && (
										<React.Fragment>
											{financialKeyCurrencyLabel}
											<div className={styles.tabContainer}>
												{isMobile &&
													getFinancialChartContainer(
														FINANCIAL_STATEMENT_TYPE.BALANCE_SHEET
													)}
												{getIncomeStatementOverviewContainer(
													FINANCIAL_STATEMENT_TYPE.BALANCE_SHEET
												)}
												{!isMobile &&
													getFinancialChartContainer(
														FINANCIAL_STATEMENT_TYPE.BALANCE_SHEET
													)}
											</div>
										</React.Fragment>
									)}
									{getIncomeStatementTableComponent(
										FINANCIAL_STATEMENT_TYPE.BALANCE_SHEET
									)}
								</div>
							) : (
								displayNoActivitySection(FINANCIAL_STATEMENT_TYPE.BALANCE_SHEET)
							)
						) : (
							<div className={styles.dataContainer}>
								<SimpleSpinner />
							</div>
						)}
					</div>
					<div key="CashFlow" label={FINANCIAL_TABS.CashFlow}>
						{getTextToggleComponent()}
						{isContentValidated ? (
							validatedData ? (
								<div>
									{financialStatementsCurrency.length === 1 && (
										<React.Fragment>
											{financialStatementsCurrencyLabel}
											<div className={styles.tabContainer}>
												{isMobile &&
													getFinancialChartContainer(FINANCIAL_STATEMENT_TYPE.CASH_FLOW)}
												{getIncomeStatementOverviewContainer(
													FINANCIAL_STATEMENT_TYPE.CASH_FLOW
												)}
												{!isMobile &&
													getFinancialChartContainer(FINANCIAL_STATEMENT_TYPE.CASH_FLOW)}
											</div>
										</React.Fragment>
									)}
									{getIncomeStatementTableComponent(FINANCIAL_STATEMENT_TYPE.CASH_FLOW)}
								</div>
							) : (
								displayNoActivitySection(FINANCIAL_STATEMENT_TYPE.CASH_FLOW)
							)
						) : (
							<div className={styles.dataContainer}>
								<SimpleSpinner />
							</div>
						)}
					</div>
					<div key="SECFilings" label={FINANCIAL_TABS.SECFillings}>
						<SecFilingTable
							apiInstance={props.apiInstance}
							cik={props.cik}
							symbol={props.symbol}
						></SecFilingTable>
					</div>
				</Tabs>
			</div>
		</CollapsibleCard>
	);
};

export default FinancialsCard;
