import React, { PureComponent } from "react";
import { Dropdown } from "react-bootstrap";
import styles from "./MSDropdown.module.scss";
import Chevron from "../Chevron/Chevron";
//MSQR-6748
const OPEN_HIGH_LOW_CLOSE = "Open / High / Low / Close";
const OHLC = "OHLC";

class MSDropdown extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			selectedItem: props.data && props.data.length ? props.title : "",
			selectedIndex: 0,
		};
		this.handleClick = this.handleClick.bind(this);
	}

	componentDidMount() {
		if (
			this.props &&
			this.props.defaultSelectedItem &&
			this.props.defaultSelectedItem.option
		) {
			this.setState({
				selectedItem: this.props.defaultSelectedItem.option,
				selectedIndex: this.props.defaultSelectedItem.index || 0,
			});
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.title !== this.props.title) {
			let newSelectedIndex = this.props.data.findIndex(
				(item) => item.option === this.props.title
			);
			if (this.props.title === "Custom Date" && !this.props.titleOverride) {
				newSelectedIndex = this.props.data.findIndex(
					(item) => item.option === prevProps.title
				);
			}
			if (newSelectedIndex > -1 && newSelectedIndex < this.props.data.length) {
				this.setState({
					selectedItem: this.props.data[newSelectedIndex].option,
					selectedIndex: newSelectedIndex,
				});
			}
		}
		if (prevProps.titleOverride !== this.props.titleOverride) {
			const newSelectedIndex = this.props.data.findIndex(
				(item) => item.option === this.props.title
			);
			if (this.props.titleOverride) {
				this.setState({
					selectedItem: this.props.titleOverride,
					selectedIndex: newSelectedIndex,
				});
			}
		} else if (
			this.state.selectedItem === "Custom Date" &&
			this.state.selectedItem !== this.props.titleOverride
		) {
			const newSelectedIndex = this.props.data.findIndex(
				(item) => item.option === this.props.title
			);

			this.setState({
				selectedItem: this.props.titleOverride || this.props.title,
				selectedIndex: newSelectedIndex,
			});
		}

		if (this.props.title === OPEN_HIGH_LOW_CLOSE && OHLC !== this.state.selectedItem) {
			const newSelectedIndex = this.props.data.findIndex(
				(item) => item.option === this.props.title
			);
			this.setState({
				selectedItem: OHLC,
				selectedIndex: newSelectedIndex,
			});
		}
	}
	handleClick = (event) => {
		event.preventDefault();
		const selectedItem = event.target.closest("[data-value]").getAttribute("data-value");

		if (
			(this.props.skipTitleReset && this.props.skipTitleReset.toLowerCase() !== "y") ||
			this.props.skipTitleReset == null
		) {
			const selectedIndex = event.target
				.closest("[data-index]")
				.getAttribute("data-index");
			this.setState({
				selectedItem: selectedItem,
				selectedIndex: selectedIndex,
			});
		}

		if (this.props.onSelectCallback) {
			this.props.onSelectCallback(selectedItem);
		}
	};

	render() {
		return (
			<Dropdown className={this.props.className}>
				<Dropdown.Toggle
					className={styles.dropdownToggle}
					title="Dropdown"
					aria-labelledby={this.props["aria-labelledby"]}
				>
					{this.props.showLeftIcon || this.props.data[this.state.selectedIndex]?.icon}
					<span data-testid="mr-dropdown-title">
						{this.props.titlePrefix
							? `${this.props.titlePrefix}: ${this.state.selectedItem}`
							: this.state.selectedItem}
					</span>
					{!this.props.hideDefaultDropdownIcon && (
						<Chevron
							direction="down"
							dimension={16}
							color={this.props.dropdownIconColor || "black"}
						/>
					)}
				</Dropdown.Toggle>
				<Dropdown.Menu className={styles.dropdownMenu}>
					{this.props.data.map((item, index) => {
						let title = item.option;
						let action = item.action;
						return (
							<Dropdown.Item
								key={index}
								data-index={index}
								data-value={title}
								href={`#/action-${action}`}
								className={styles.dropdownItem}
								onClick={this.handleClick}
							>
								{item.icon || null}
								{title}
							</Dropdown.Item>
						);
					})}
				</Dropdown.Menu>
			</Dropdown>
		);
	}
}

export default MSDropdown;
