import React, { useState, useEffect } from "react";
import FinancialChart from "../../Lib/Chart/FinancialChart";
import formatter from "../../../utils/formatUtils";
import {
	FINANCIAL_STATEMENT_TYPE,
	RESPONSIVE_BREAKPOINTS,
	PERIOD_TYPE,
} from "../../../utils/constants";
import { CHART_LEGEND_NAMES, CASHFLOW_ROW } from "./FinancialsCard/FinancialsConstants";
import MSSpinner from "../../Lib/MSSpinner/MSSpinner";
import NoData from "components/Lib/NoData/NoData";

const FinancialsChartContainer = ({
	statementType,
	barChartOptions,
	financialStatementsData,
	financialStatementsKeyData,
	financialRatiosHistoryData,
	periodType,
	isMobile,
	pointLabelOptions,
	lineChartOptions,
	symbol,
	isOverview,
}) => {
	const [chartData, setChartData] = useState();
	const [combinebarChartOptions, setCombinedBarChartOptions] = useState(
		barChartOptions || {}
	);
	const [isCashFlow] = useState(statementType === FINANCIAL_STATEMENT_TYPE.CASH_FLOW);
	const [barSeriesCurrency, setBarSeriesCurrency] = useState();
	useEffect(() => {
		if (isCashFlow && financialStatementsData) {
			loadChartData(financialStatementsData);
		} else if (!isCashFlow && financialStatementsKeyData)
			loadChartData(financialStatementsKeyData);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [financialStatementsData, financialStatementsKeyData, financialRatiosHistoryData]);

	const loadChartData = (financialOverviewData) => {
		setCombinedBarChartOptions(barChartOptions || {});
		let financialOverviewDataT = Object.assign({}, financialOverviewData, {
			rows: financialOverviewData.rows
				? financialOverviewData.rows.map((x) => {
						let newValues = [];
						if (x.values) {
							newValues =
								!isOverview || isCashFlow ? [...x.values].reverse() : [...x.values];
						}
						// MSQR-8283 spread so newValues doesn't affect source data
						return { ...x, values: newValues };
				  })
				: [],
		});
		let lineChartData = [];
		let barChartData = [];
		let barChartMultiReturn = {};
		let filterData;
		if (isCashFlow) {
			//Not a nice check but for cashflow we need to consider financialOverviewData for point and bar chart
			filterData = getFixedRecords(financialOverviewDataT.rows);
			financialOverviewDataT.fiscalYears = financialOverviewDataT.fiscalYears?.reverse();
			financialOverviewDataT.fiscalQuarters =
				financialOverviewDataT.fiscalQuarters?.reverse();
			barChartMultiReturn = createBarSeriesData(filterData);
			barChartData = barChartMultiReturn.barChartData;
		} else {
			barChartMultiReturn = createBarSeriesData(financialOverviewDataT.rows);
			barChartData = barChartMultiReturn.barChartData;
		}
		if (financialRatiosHistoryData && !isCashFlow) {
			let filterValue = periodType === PERIOD_TYPE.ANNUAL ? "1Y" : "3M";
			let ratioData = financialRatiosHistoryData.filter(
				({ period }) => period === filterValue
			);
			lineChartData = createLineSeriesData(
				ratioData,
				barChartMultiReturn.millionOrBillion
			);
		}

		if (
			barChartData?.length !== 0 &&
			barChartData[0].length !== 0 &&
			(isCashFlow || lineChartData?.length !== 0)
		) {
			let drawLineChart = lineChartData.some((el) => !!el);
			let newChartData = {
				labels: createLabels(
					financialOverviewDataT.fiscalYears,
					financialOverviewDataT.fiscalQuarters,
					periodType
				),
				barChartSeries: barChartData,
				lineChartSeries: [drawLineChart ? lineChartData : []],
				legendNames: createLegendNames(
					isCashFlow ? filterData : financialOverviewDataT.rows,
					statementType,
					drawLineChart
				),
			};
			setChartData(newChartData);
		} else setChartData();
	};

	const getFixedRecords = (rowsArr) => {
		let returnArray = [];
		rowsArr.forEach(function (item) {
			let cellVal = item && item.dataPointLabel;
			if (cellVal === CASHFLOW_ROW.OCF.description) {
				returnArray[0] = { ...item, dataPointLabel: CASHFLOW_ROW.OCF.shortName };
			} else if (cellVal === CASHFLOW_ROW.ICF.description) {
				returnArray[1] = { ...item, dataPointLabel: CASHFLOW_ROW.ICF.shortName };
			} else if (cellVal === CASHFLOW_ROW.FCF.description) {
				returnArray[2] = { ...item, dataPointLabel: CASHFLOW_ROW.FCF.shortName };
			}
			if (returnArray.length === 3) return;
		});
		return returnArray;
	};

	const createLabels = (fiscalYears, fiscalQuarters, periodType) => {
		let labels = [];
		for (let index = 0; index < fiscalYears.length; index++) {
			labels.push(
				(periodType === PERIOD_TYPE.ANNUAL ? "" : "Q" + fiscalQuarters[index] + " ") +
					fiscalYears[index]
			);
		}
		return labels;
	};

	const createBarSeriesData = (rowsData) => {
		let rows = Object.assign([], rowsData);

		if (isCashFlow) {
			rows = rows.filter((e) => e);
		}

		if (rows.length > 0 && !rows[0].values) return [];

		let maxChars = [];
		const charCount = { T: 0, B: 0, M: 0, K: 0 };
		let formatNumberString = rows.map((row) => {
			let max = 0; // variable to store the number of appearances of the max char so far
			let tempFormatArray = [];
			// initialize a fixed sized array and fill it with zeros
			if (row.values) {
				setBarSeriesCurrency(row.currency);
				for (const value of row.values) {
					let formatPrice = formatter.currencyMagnitude(
						value,
						1,
						row.currency === "USD" ? "USD" : " "
					);
					const ch = formatPrice.charAt(formatPrice.length - 1);
					charCount[ch]++;
					if (charCount[ch] > max) {
						maxChars = [ch];
						max = charCount[ch];
					} else if (charCount[ch] === max) maxChars.push(ch);
					tempFormatArray.push(formatPrice);
				}
			}
			return tempFormatArray;
		});

		let newOffset = window.matchMedia(
			`(max-width: ${RESPONSIVE_BREAKPOINTS.MEDIA_BREAKPOINTS_MAX.MEDIA_BREAKPOINT_MAX_MD}px)`
		).matches
			? 40
			: 55;
		let newResultArray = formatNumberString.map((arr) => {
			let tempResult = [];
			arr.forEach((y) => {
				let formattedNumber = parseFloat(y.replace("$", ""));
				let currency = "";
				if (y.indexOf("$") > -1) {
					currency = "$";
				}

				switch (maxChars[0]) {
					case "K":
					case "M":
						if (y.includes("K")) {
							formattedNumber = formattedNumber / 1000;
						}
						if (y.includes("B")) {
							formattedNumber = formattedNumber * 1000;
						}
						if (Math.abs(formattedNumber) >= 1000) {
							newOffset = window.matchMedia(
								`(max-width: ${RESPONSIVE_BREAKPOINTS.MEDIA_BREAKPOINTS_MAX.MEDIA_BREAKPOINT_MAX_MD}px)`
							).matches
								? 50
								: 65;
						}
						setCombinedBarChartOptions(
							Object.assign({}, combinebarChartOptions, {
								axisY: {
									position: "left",
									showGrid: true,
									offset: newOffset,
									labelInterpolationFnc: function (value, index) {
										return value >= 0
											? `${currency}` + value + "M"
											: `-${currency}` + Math.abs(value) + "M";
									},
								},
							})
						);
						break;
					case "T":
						if (y.includes("B")) {
							formattedNumber = formattedNumber / 1000;
						}
						setCombinedBarChartOptions(
							Object.assign({}, combinebarChartOptions, {
								axisY: {
									position: "left",
									showGrid: true,
									offset: newOffset,
									labelInterpolationFnc: function (value, index) {
										return value >= 0
											? `${currency}` + value + "T"
											: `-${currency}` + Math.abs(value) + "T";
									},
								},
							})
						);
						break;
					default:
						if (y.includes("M")) {
							formattedNumber = formattedNumber / 1000;
						}
						if (y.includes("K")) {
							formattedNumber = formattedNumber / (1000 * 1000);
						}
						break;
				}
				tempResult.push(formattedNumber);
			});
			return tempResult;
		});

		return { barChartData: newResultArray, millionOrBillion: maxChars };
	};
	const createLineSeriesData = (rows, millionOrBillion) => {
		let singleSeries = [];
		for (let row of rows) {
			let ratio;
			if (statementType === FINANCIAL_STATEMENT_TYPE.INCOME_STATEMENT) {
				ratio = row.margin.netProfitNormalized ? row.margin.netProfitNormalized * 100 : 0;
			} else if (statementType === FINANCIAL_STATEMENT_TYPE.BALANCE_SHEET) {
				ratio =
					row.leverage.debtToAssets !== undefined ? row.leverage.debtToAssets * 100 : "-";
			} else {
				let formatPrice = formatter.currencyMagnitude(row, 1);
				let formattedNumber = parseFloat(formatPrice.replace("$", ""));
				switch (millionOrBillion[0]) {
					case "K":
					case "M":
						if (formatPrice.includes("K")) {
							formattedNumber = formattedNumber / 1000;
						}
						if (formatPrice.includes("B")) {
							formattedNumber = formattedNumber * 1000;
						}
						break;
					default:
						if (formatPrice.includes("M")) {
							formattedNumber = formattedNumber / 1000;
						}
						if (formatPrice.includes("K")) {
							formattedNumber = formattedNumber / (1000 * 1000);
						}
						break;
				}

				ratio = formattedNumber;
			}
			singleSeries.push(Math.round(ratio * 100) / 100);
		}
		return singleSeries;
	};

	const createLegendNames = (rows, statementType, drawLineChart) => {
		let legendNames = [];
		if (statementType === FINANCIAL_STATEMENT_TYPE.BALANCE_SHEET) {
			legendNames = Object.assign([], CHART_LEGEND_NAMES.Balance_Sheet);
		} else {
			legendNames = rows.map((x) => x.dataPointLabel);
		}

		if (statementType === FINANCIAL_STATEMENT_TYPE.INCOME_STATEMENT) {
			legendNames.push("Profit Margin");
		}

		if (!drawLineChart && !isCashFlow) {
			legendNames.pop();
		}

		return legendNames;
	};
	const isContentReady = () => {
		return (
			((!isCashFlow && financialRatiosHistoryData) ||
				(isCashFlow && financialStatementsData)) &&
			chartData
		);
	};
	return (
		<MSSpinner
			ready={isContentReady()}
			spinnerSize={"2x"}
			spinnerStyle={{
				minHeight: "50px",
			}}
		>
			<div>
				{chartData ? (
					<FinancialChart
						{...chartData}
						pointLabelOptions={pointLabelOptions}
						lineChartOptions={lineChartOptions}
						barChartOptions={combinebarChartOptions}
						statementType={statementType}
						financialStatementsData={financialStatementsData}
						isMobile={isMobile}
						isCashFlow={isCashFlow}
						barSeriesCurrency={barSeriesCurrency}
					/>
				) : (
					<NoData>{`There is no ${statementType} chart available for ${symbol}`}</NoData>
				)}
			</div>
		</MSSpinner>
	);
};
export default FinancialsChartContainer;
