import React from "react";
import { ReactComponent as ActiveArrow } from "assets/icons/UpArrowActive.svg";
import { ReactComponent as InactiveArrow } from "assets/icons/UpArrowInactive.svg";
const UpAndDownArrowActive = [
	<ActiveArrow order="1" isarrowdown="true" isarrowup="false" isActive="true" />,
	<ActiveArrow order="2" isarrowup="true" isarrowdown="false" isActive="true" />,
];

const DownActiveUpInactiveArrow = [
	<ActiveArrow order="1" isarrowdown="true" isarrowup="false" isActive="true" />,
	<InactiveArrow order="2" isarrowup="false" isarrowdown="false" isActive="false" />,
];

const UpActiveDownInactiveArrow = [
	<InactiveArrow order="1" isarrowup="false" isarrowdown="true" isActive="false" />,
	<ActiveArrow order="2" isarrowup="true" isarrowdown="false" isActive="true" />,
];

export default {
	UpAndDownArrowActive,
	DownActiveUpInactiveArrow,
	UpActiveDownInactiveArrow,
};
