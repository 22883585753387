import React from "react";
import LinesEllipsis from "react-lines-ellipsis";
import responsiveHOC from "react-lines-ellipsis/lib/responsiveHOC";
import formatter from "utils/formatUtils";
import { RESEARCH_PROVIDERS, CLIENTS } from "utils/constants";
import utils from "utils/utils";
import styles from "../../AllResearchCard/AllResearchCard.module.scss";
import SimpleSpinner from "components/Lib/SimpleSpinner/SimpleSpinner";
import English from "assets/language/en";
import NoData from "components/Lib/NoData/NoData";

const ResearchItemsTable = React.memo((props) => {
	const { items, dateSpan, headlineOnlyVisibility, ...others } = props;
	const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);
	const renderArticleLink = (article, index) => {
		if (article.DataProviderName) {
			if (
				article.DataProviderName === RESEARCH_PROVIDERS.MORGAN_STANLEY &&
				others.userInfo &&
				others.userInfo.researchRedesignFlag === "Y" &&
				article.MorganStanleyProductID
			) {
				return (
					<button
						className={styles.msHeadline}
						rel="noopener noreferrer"
						title={"Research Article: " + article.Headline}
						tabIndex="0"
						aria-label={article.Headline}
						key={`Research_doc_${article.documentId}${index}`}
						text={article.Headline}
						onClick={(e) =>
							utils.checkResearchDocumentFormat(
								e,
								article.MorganStanleyProductID,
								article.documentId,
								others.userInfo
							)
						}
					>
						<ResponsiveEllipsis
							text={article.Headline}
							maxLine="2"
							ellipsis="..."
							trimRight
							basedOn="letters"
						/>
					</button>
				);
			} else {
				let baseUrl = "";

				if (window.MD) {
					baseUrl =
						window.MD.CLIENT === CLIENTS.CANACCORD_GENUITY
							? `${window.MD.CG_API_DOMAIN}/cg-research`
							: `${window.MD.MSO_DOMAIN}/ms-research/markets-and-research`;
				}

				return (
					<a
						className={styles.headline}
						href={`${baseUrl}/ResearchPdf?documentId=${article.documentId}`}
						target="_blank"
						rel="noopener noreferrer"
						title={"Research Article: " + article.Headline}
						aria-label={article.Headline}
						key={`Research_doc_${article.documentId}${index}`}
						tabIndex="0"
					>
						<ResponsiveEllipsis
							text={article.Headline}
							maxLine="2"
							ellipsis="..."
							trimRight
							basedOn="letters"
						/>
					</a>
				);
			}
		}
	};

	const language = props.language ? props.language : English;

	const getAuthorNames = (authors) => {
		if (!authors || !authors.Author || !authors.Author.length) return "";
		// Find the first author in the array with a name specified
		const author = authors.Author.find((a) => !!a.AuthorName);
		return author ? author.AuthorName : "";
	};

	if (!items || items === null || items === undefined) {
		return <SimpleSpinner />;
	} else if (Object.keys(items).length === 0) {
		const symbolText = others.symbol ? `${language.FOR} ${others.symbol} ` : String.empty;
		return (
			<NoData>
				{`${language.THERE_ARE_NO_RESEARCH_REPORTS_AVAILABLE} ${symbolText}`}
				{language.THERE_ARE_NO_REPORTS_TIMEFRAME}
			</NoData>
		);
	}

	return (
		<div>
			<ol className={styles.researchItems}>
				{items.map((item, index) => {
					if (!item) return null;

					const text = item.Teaser || item.Abstract;

					return (
						<li key={item.documentId} className={styles.item}>
							<div className={styles.reportType}></div>
							<div className={styles.reportData}>
								<div className={styles.author}>
									{`${getAuthorNames(item.Authors)} for ${item.DataProviderName}`}
								</div>
								<div className={styles.date}>
									{formatter.date(item.DocumentDate, "MMM DD YYYY hh:MM A", {
										outputTimezone: "America/New_York",
									})}
								</div>
							</div>
							{renderArticleLink(item, index)}
							{!headlineOnlyVisibility && text && (
								<div className={styles.teaser}>
									<ResponsiveEllipsis
										text={text}
										maxLine="2"
										ellipsis="..."
										trimRight
										basedOn="letters"
									/>
								</div>
							)}
						</li>
					);
				})}
			</ol>
		</div>
	);
});

export default ResearchItemsTable;
