import InputConfig from "./IndicatorInputsConfig";
function rgbToHex(color) {
	var bg = color.match(
		/rgba?\((\d{1,3}), ?(\d{1,3}), ?(\d{1,3})\)?(?:, ?(\d(?:\.\d?))\))?/
	);
	function hex(x) {
		return ("0" + parseInt(x).toString(16)).toUpperCase().slice(-2);
	}
	return "#" + hex(bg[1]) + hex(bg[2]) + hex(bg[3]);
}
export const MergedIndicatorConfig = {
	bollinger: function (current) {
		let config = JSON.parse(
			JSON.stringify(InputConfig.INDICATOR_INPUT_CONFIG["bollinger"])
		);
		config.inputs.number0.default = current.inputs.find(
			(inp) => inp.name === "period"
		).value;
		config.inputs.number1.default = current.inputs.find(
			(inp) => inp.name === "standardDeviations"
		).value;

		config.inputs.linestyle0.line.default = {
			width: current.style.width,
			linedash: current.style.lineDash,
		};
		config.inputs.linestyle0.color.default = current.style.color;

		config.inputs.linestyle1.line.default = {
			width: current.style.sma.width,
			linedash: current.style.sma.lineDash,
		};
		config.inputs.linestyle1.color.default = current.style.sma.color;

		config.inputs.color1.default = rgbToHex(current.style.fillColor);
		return config;
	},
	mae: function (current) {
		let config = JSON.parse(JSON.stringify(InputConfig.INDICATOR_INPUT_CONFIG["mae"]));
		config.inputs.number0.default = current.inputs.find(
			(inp) => inp.name === "period"
		).value;
		config.inputs.number1.default = Math.trunc(
			(1 - current.inputs.find((inp) => inp.name === "lowfactor").value) * 100
		);

		config.inputs.dropdown.selected =
			InputConfig.AverageType[
				current.inputs.find((inp) => inp.name === "averagetype").value
			];

		config.inputs.linestyle.line.default = {
			width: current.style.width,
			linedash: current.style.lineDash,
		};
		config.inputs.linestyle.color.default = current.style.color;
		config.inputs.color.default = rgbToHex(current.style.fillColor);
		return config;
	},
	sma: function (current) {
		let config = JSON.parse(JSON.stringify(InputConfig.INDICATOR_INPUT_CONFIG["sma"]));
		config.inputs.number.default = current.inputs.find(
			(inp) => inp.name === "period"
		).value;

		config.inputs.linestyle.line.default = {
			width: current.style.width,
			linedash: current.style.lineDash,
		};
		config.inputs.linestyle.color.default = current.style.color;
		return config;
	},
	volume: function (current) {
		let config = JSON.parse(JSON.stringify(InputConfig.INDICATOR_INPUT_CONFIG["volume"]));
		config.inputs.color0.default = current.style.positive.color;
		config.inputs.color1.default = current.style.negative.color;
		return config;
	},
	wma: function (current) {
		let config = JSON.parse(JSON.stringify(InputConfig.INDICATOR_INPUT_CONFIG["wma"]));
		config.inputs.number.default = current.inputs.find(
			(inp) => inp.name === "period"
		).value;

		config.inputs.linestyle.line.default = {
			width: current.style.width,
			linedash: current.style.lineDash,
		};
		config.inputs.linestyle.color.default = current.style.color;
		return config;
	},
	//Lower Indicatror
	dividendyield: function (current) {
		let config = JSON.parse(
			JSON.stringify(InputConfig.INDICATOR_INPUT_CONFIG["dividendyield"])
		);

		config.inputs.linestyle.line.default = {
			width: current.style.width,
			linedash: current.style.lineDash,
		};
		config.inputs.linestyle.color.default = current.style.color;
		return config;
	},
	onbalancevolume: function (current) {
		let config = JSON.parse(
			JSON.stringify(InputConfig.INDICATOR_INPUT_CONFIG["onbalancevolume"])
		);

		config.inputs.linestyle.line.default = {
			width: current.style.width,
			linedash: current.style.lineDash,
		};
		config.inputs.linestyle.color.default = current.style.color;
		return config;
	},

	peratio: function (current) {
		let config = JSON.parse(
			JSON.stringify(InputConfig.INDICATOR_INPUT_CONFIG["peratio"])
		);

		config.inputs.linestyle.line.default = {
			width: current.style.width,
			linedash: current.style.lineDash,
		};
		config.inputs.linestyle.color.default = current.style.color;
		return config;
	},
	revenues: function (current) {
		let config = JSON.parse(
			JSON.stringify(InputConfig.INDICATOR_INPUT_CONFIG["revenues"])
		);

		config.inputs.linestyle.line.default = {
			width: current.style.width,
			linedash: current.style.lineDash,
		};
		config.inputs.linestyle.color.default = current.style.color;
		return config;
	},
	rollingeps: function (current) {
		let config = JSON.parse(
			JSON.stringify(InputConfig.INDICATOR_INPUT_CONFIG["rollingeps"])
		);

		config.inputs.linestyle.line.default = {
			width: current.style.width,
			linedash: current.style.lineDash,
		};
		config.inputs.linestyle.color.default = current.style.color;
		return config;
	},
	macd: function (current) {
		let config = JSON.parse(JSON.stringify(InputConfig.INDICATOR_INPUT_CONFIG["macd"]));
		config.inputs.number0.default = current.inputs.find(
			(inp) => inp.name === "fastperiod"
		).value;
		config.inputs.number1.default = current.inputs.find(
			(inp) => inp.name === "slowperiod"
		).value;
		config.inputs.number2.default = current.inputs.find(
			(inp) => inp.name === "smoothing"
		).value;

		config.inputs.linestyle0.line.default = {
			width: current.style.width,
			linedash: current.style.lineDash,
		};
		config.inputs.linestyle0.color.default = current.style.color;

		config.inputs.linestyle1.line.default = {
			width: current.style.ema.width,
			linedash: current.style.ema.lineDash,
		};
		config.inputs.linestyle1.color.default = current.style.ema.color;

		config.inputs.linestyle2.line.default = {
			width: current.style.positive.width,
			linedash: current.style.positive.lineDash,
		};
		config.inputs.linestyle2.color.default = current.style.positive.color;

		config.inputs.linestyle3.line.default = {
			width: current.style.negative.width,
			linedash: current.style.negative.lineDash,
		};
		config.inputs.linestyle3.color.default = current.style.negative.color;

		return config;
	},
	momentum: function (current) {
		let config = JSON.parse(
			JSON.stringify(InputConfig.INDICATOR_INPUT_CONFIG["momentum"])
		);
		config.inputs.number.default = current.inputs.find(
			(inp) => inp.name === "period"
		).value;

		config.inputs.linestyle.line.default = {
			width: current.style.width,
			linedash: current.style.lineDash,
		};
		config.inputs.linestyle.color.default = current.style.color;
		config.inputs.color0.default = rgbToHex(current.style.fillColor);
		return config;
	},
	proc: function (current) {
		let config = JSON.parse(JSON.stringify(InputConfig.INDICATOR_INPUT_CONFIG["proc"]));
		config.inputs.number.default = current.inputs.find(
			(inp) => inp.name === "period"
		).value;

		config.inputs.linestyle.line.default = {
			width: current.style.width,
			linedash: current.style.lineDash,
		};
		config.inputs.linestyle.color.default = current.style.color;
		return config;
	},
	vroc: function (current) {
		let config = JSON.parse(JSON.stringify(InputConfig.INDICATOR_INPUT_CONFIG["vroc"]));
		config.inputs.number.default = current.inputs.find(
			(inp) => inp.name === "period"
		).value;

		config.inputs.linestyle.line.default = {
			width: current.style.width,
			linedash: current.style.lineDash,
		};
		config.inputs.linestyle.color.default = current.style.color;
		return config;
	},
	rsi: function (current) {
		let config = JSON.parse(JSON.stringify(InputConfig.INDICATOR_INPUT_CONFIG["rsi"]));
		config.inputs.number.default = current.inputs.find(
			(inp) => inp.name === "period"
		).value;

		config.inputs.linestyle.line.default = {
			width: current.style.width,
			linedash: current.style.lineDash,
		};
		config.inputs.linestyle.color.default = current.style.color;
		config.inputs.color0.default = current.style.overbought.color.background;
		config.inputs.color1.default = current.style.oversold.color.background;
		return config;
	},
};
