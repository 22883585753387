import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useRouteMatch } from "react-router-dom";

import { URLS } from "utils/constants";
import Utils from "utils/utils";

import classNames from "classnames";

import styles from "./SubNavigation.module.scss";

const menuItems = [
	"Overview",
	"Charting",
	"News",
	"Analyst Research",
	"Earnings",
	"Dividends",
	"Financials",
	"Options",
];

const SubNavigation = ({ queryClient }) => {
	let selected = useRouteMatch()?.params.name;
	//selected item based on store route name
	const routeName = useSelector((state) => state.marketsAndResearchAppReducer.routeName);

	useEffect(() => {
		if (routeName) {
			Utils.adobeEventTracking("", routeName.toLowerCase(), "page");
		}
	}, [routeName]);

	const onLinkClick = (selected) => {
		// TODO: check if updating link with tab will change adobe analytics tracking
		Utils.adobeEventTracking("", selected.toLowerCase(), "page");
		queryClient.invalidateQueries(URLS.QUOTE_PATH);
	};

	return (
		<div className={styles.mainContainer}>
			<nav className={styles.navContainer}>
				<ul>
					{menuItems.map((item, index) => {
						const modItem = item.replace(/\s/g, "");
						return (
							<li
								key={index}
								className={classNames(styles.menuItem, { active: modItem === selected })}
							>
								<Link
									className={styles.noTextDecoration}
									to={`/ms-research/markets-and-research/Stocks/${modItem}${window.location.search}`}
									onClick={(e) => {
										onLinkClick(modItem);
										e.currentTarget.blur();
									}}
								>
									{item}
								</Link>
							</li>
						);
					})}
				</ul>
			</nav>
		</div>
	);
};

export default SubNavigation;
