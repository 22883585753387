import rowTemplateThresholds from "./defaultRowTemplateThresholds";
export default {
	decimal: ".",
	thousands: ",",
	grouping: [3],
	currency: ["$", ""],
	dateTime: "%a %b %e %X %Y", // %c
	time: "%-I:%M%p", // 12-hour format
	periods: ["am", "pm"], // %p
	date: "%-m/%-d/%Y", // %x
	days: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"], // %A
	shortDays: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"], // %a
	months: [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December",
	], // %B
	shortMonths: [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec",
	], // %b
	translations: {
		band: {
			overbought: "Overbought",
			oversold: "Oversold",
		},
		chart: {
			unavailable: "Chart not available",
			unavailableIndicator: "No data available for {0}",
		},
		indicator: {
			previousclose: {
				label: "Previous Close {0}",
			},
		},
	},
	labelTemplates: {
		xAxis: {
			doubleRow: {
				minutes: {
					default: "%X",
					first: "%x %A",
				},
				hours: {
					default: "%X",
					first: "%x %A",
				},
				days: {
					default: "%e",
					first: "%b %Y",
					firstAlt: "%x %A",
					intraday: "%x %A",
				},
				weeks: {
					default: "%-m/%-d", // Short date: 1/7
					first: "%b %Y",
					firstAlt: "%b %Y",
				},
				months: {
					default: "%b",
					first: "%Y",
					firstAlt: "%b %Y",
				},
				years: {
					default: "%Y",
					first: "%Y",
				},
			},
		},
	},
	rowTemplateThresholds,
};
