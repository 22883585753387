import React, { useEffect, useState, useCallback } from "react";
import MSSpinner from "../../../Lib/MSSpinner/MSSpinner";
import SecFilingsAPIUtils from "services/APIs/SecFilings/SecFilingsAPI";

const SecFilingsPdf = (props) => {
	const [secFilingPdf, setSecFilingsPdf] = useState(null);
	let feedId, documentTag;
	const urlParams = new URLSearchParams(window.location.search);
	for (const [key, value] of urlParams) {
		if (key === "feedId") {
			feedId = value;
		}
		if (key === "documentTag") {
			documentTag = value;
		}
	}

	const loadSecFilingsPdfData = async (feedId, documentTag) => {
		let secFilingsResult = await SecFilingsAPIUtils.getDocument(
			props.apiInstance,
			feedId,
			documentTag
		);
		setSecFilingsPdf(secFilingsResult);
	};

	useEffect(() => {
		loadSecFilingsPdfData(feedId, documentTag);
	}, [loadSecFilingsPdfData, feedId, documentTag]);

	const isContentReady = () => {
		return secFilingPdf !== null;
	};

	return <MSSpinner ready={isContentReady()} spinnerSize={"2x"}></MSSpinner>;
};

export default SecFilingsPdf;
