import { URLS } from "utils/constants";

/**
 * Utility for fetching a list of Sec Filings documents from the Sec Filings API
 * @param {number} cik - central index key (SEC ID)
 * @param {number} startDate - date from which to get filings
 * @param {number} endDate - date up to which to get filings
 * @param {number} formType - filing form type (10-Q, 11-k, etc)
 * @param {number} limit - pagination limit
 * @param {number} offset - pagination offset
 */
const getDocumentList = async (
	apiInstance,
	{ cik, startDate, endDate, formType = "", limit = 20, offset = 0 }
) => {
	if (!apiInstance || (!startDate && !endDate)) {
		return;
	}

	try {
		const response = await apiInstance.get(
			`${URLS.SEC_FILING_PATH}/${cik}?offset=${offset}&limit=${limit}&sort=-filingDate`,
			{
				params: {
					startDate: startDate,
					endDate: endDate,
					formType: formType,
				},
			}
		);

		return response?.data?.data || {};
	} catch (error) {
		return {};
	}
};

/**
 * Utility for fetching an Sec Filing document from the Sec Filings API
 * @param {Object} feedId - a single stream of similarly formatted data from a single source
 * @param {number} documentTag - an identifier that uniquely identifies a document within a feed
 */
const getDocument = async (apiInstance, feedId, documentTag) => {
	if (!apiInstance || (!feedId && !documentTag)) {
		return;
	}

	try {
		const response = await apiInstance.get(
			`${URLS.SEC_FILINGS_PDF_PATH}/${feedId}-${documentTag}`,
			{
				headers: {
					Accept: "application/pdf",
				},
				responseType: "blob",
			}
		);

		if (response?.data) {
			//Create a Blob from the PDF Stream
			const file = new Blob([response.data], { type: "application/pdf" });

			var fileUrl = URL.createObjectURL(file);

			var a = document.createElement("a");
			document.body.appendChild(a);
			a.style = "display: none";
			a.href = fileUrl;
			a.click();
			a.remove();

			setTimeout(function () {
				window.URL.revokeObjectURL(fileUrl);
			}, 100);
		}
	} catch (error) {
		return {};
	}
};

export default {
	getDocumentList,
	getDocument,
};
