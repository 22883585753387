import React, { Component } from "react";
import Card from "components/Lib/Card/Card";
import PaginationFooter from "components/Lib/PaginationFooter/PaginationFooter";
import paginationhelper from "utils/paginationHelper";
import CGSearchBar from "../../../Lib/CGSearch/CGSearchBar";
import ResearchItemsTable from "./ResearchItemsTable/ResearchItemsTable";
import { debounce } from "lodash";
import ResearchFilters from "./ResearchFilters/ResearchFilters";
import { buildDefaultFilterState, DateSpan } from "./ResearchAPIParams";
import ResearchAPIUtils from "services/APIs/Research/ResearchAPI";
import Utils from "utils/utils";

import styles from "./AllResearchCard.module.scss";
import languageEn from "assets/language/en";

class AllResearchCard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			filterData: buildDefaultFilterState(),
			headlineOnlyVisibility: false,
			researchOffset: 0,
			researchData: null,
			allResearchCardHeightChanged: false,
			allResearchCardHeight: 0,
		};

		this.PAGE_LIMIT = 10;
		this.EMPTY_RESEARCH_OBJ = { items: [] };
		this.setResearchOffset = this.setResearchOffset.bind(this);
		this.onFilterChange = this.onFilterChange.bind(this);
		this.onDatePickerDateChange = this.onDatePickerDateChange.bind(this);
		this.onHeadlineOnlyChange = this.onHeadlineOnlyChange.bind(this);
	}

	componentDidMount() {
		this.fetchResearchDocuments();
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			this.isPropUpdated(prevProps, "venueXid") ||
			this.isPropUpdated(prevProps, "apiInstance")
		) {
			this.fetchResearchDocuments();
		}
		if (this.isPropUpdated(prevProps, "researchDocumentsData")) {
			this.setState({ researchData: this.props.researchDocumentsData });
		}
		if (this.isPropUpdated(prevProps, "filterData")) {
			this.setState({ filterData: this.props.filterData });
		}

		// Fetch data when filters are modified by the user
		if (
			this.props?.venueXid &&
			(this.isStateUpdated(prevState, "filterData") ||
				this.isStateUpdated(prevState, "headlineOnlyVisibility") ||
				this.isStateUpdated(prevState, "researchOffset"))
		) {
			this.fetchResearchDocuments();
		}

		if (this.isStateUpdated(prevState, "filterData")) {
			if (this.props.setFilterDataCallback) {
				this.props.setFilterDataCallback(this.state.filterData);
			}
		}

		// Sets a new card height when there is new research data. Mobile requires a delay to get correct height.
		if (this.isStateUpdated(prevState, "researchData")) {
			if (this.props.isMobile) {
				setTimeout(() => {
					this.setState({
						allResearchCardHeight: document.getElementById("allResearchCardHeight")
							?.clientHeight,
					});
				}, 100);
			} else {
				this.setState({
					allResearchCardHeight: document.getElementById("allResearchCardHeight")
						?.clientHeight,
				});
			}
		}

		/*
		When user clicks on pagination it sets allResearchCardHeightChanged to true.
		That then allows the page to scroll up or down based on the difference between
		the All Research Card height that was loss or gained.
		*/
		if (
			this.isStateUpdated(prevState, "allResearchCardHeight") &&
			this.state.allResearchCardHeightChanged
		) {
			let prev = prevState.allResearchCardHeight;
			let curr = this.state.allResearchCardHeight;
			if (prev > curr) {
				let heightLoss = -(prev - curr);
				Utils.postScrollBy(0, heightLoss);
			} else {
				let heightGrowth = curr - prev;
				Utils.postScrollBy(0, heightGrowth);
			}
			this.setState({ allResearchCardHeightChanged: false });
		}
	}

	isPropUpdated(prevProps, verificationProp) {
		if (!prevProps) {
			return false;
		}

		return prevProps[verificationProp] !== this.props[verificationProp];
	}

	isStateUpdated(prevState, verificationState) {
		if (!prevState) {
			return false;
		}
		return prevState[verificationState] !== this.state[verificationState];
	}

	headerTitle() {
		if (this.props.symbol) {
			return this.props.language?.MARKET_RESEARCH_WITH_SYMBOL
				? `${this.props.language.MARKET_RESEARCH_WITH_SYMBOL}${this.props.symbol}`
				: `${languageEn.MARKET_RESEARCH_WITH_SYMBOL}${this.props.symbol}`;
		}

		return this.props.language?.MARKET_RESEARCH
			? this.props.language.MARKET_RESEARCH
			: languageEn.MARKET_RESEARCH;
	}

	onFilterChange(fieldOrObject, value) {
		let updatedFields = fieldOrObject;
		if (typeof fieldOrObject === "string" || fieldOrObject instanceof String) {
			updatedFields = { ...this.state.filterData, [fieldOrObject]: value };
		}
		// Update state of filter data and reset the offset back to 0 on filter changes
		this.setState({ filterData: updatedFields, researchOffset: 0 });
	}

	onDatePickerDateChange = (startDate, endDate) => {
		this.setState({
			filterData: {
				...this.state.filterData,
				dateSpan: DateSpan.CUSTOM_DATE_ID,
				startDate: startDate,
				endDate: endDate,
			},
		});
	};

	onHeadlineOnlyChange() {
		this.setState({ headlineOnlyVisibility: !this.state.headlineOnlyVisibility });
	}

	trackGA = debounce((totalItems) => {
		try {
			window.dataLayer.push({
				event: "analystResearchSearch",
				eventProps: {
					symbol: this.props.symbol,
					keyword: this.state.filterData.keyword,
					source: this.state.filterData.provider,
					reportType: this.state.filterData.reportTypes,
					dateSelection: this.state.filterData.dateSpan,
					companySpecific: this.state.filterData.companyOnly, //Dimension
					headlineOnly: this.state.headlineOnlyVisibility, //Dimension
					searchResultCount: totalItems, //Dimension
				},
			});
		} catch (ex) {}
	}, 300);

	async fetchResearchDocuments() {
		if (this.props.venueXid) {
			const researchData = await ResearchAPIUtils.getResearchDocuments(
				this.props.apiInstance,
				this.props.venueXid,
				this.state.filterData,
				this.PAGE_LIMIT,
				this.state.researchOffset,
				this.props.legacyCompany
			);
			this.trackGA(researchData?.pagination?.totalItems);
			this.setState({ researchData });
		}
	}

	renderPagination() {
		const navigatePagination = (next) => {
			this.setState({ researchOffset: next });
			this.setState({ allResearchCardHeightChanged: true });
		};
		const setResearchOffset = this.props.venueXid
			? (next) => navigatePagination(next)
			: this.props.setResearchOffsetCallback;
		const researchOffset = this.props.venueXid
			? this.state.researchOffset
			: this.props.researchOffset;

		if (this.state.researchData?.pagination?.totalItems) {
			return (
				<div className={styles.paginationWrapper}>
					<PaginationFooter
						ariaLabel="Research Article Pagination"
						className={styles.researchPaginationFooter}
						language={this.props.language ? this.props.language : languageEn}
						{...paginationhelper(
							setResearchOffset,
							this.PAGE_LIMIT,
							researchOffset,
							this.state.researchData?.pagination
								? this.state.researchData.pagination.totalItems
								: 0
						)}
					/>
				</div>
			);
		} else {
			return null;
		}
	}

	setResearchOffset(researchOffset) {
		this.setState({ researchOffset });
	}

	render() {
		const onFilterChange = this.props.venueXid
			? this.onFilterChange
			: this.props.onFilterChange;
		const onDatePickerDateChange = this.props.venueXid
			? this.onDatePickerDateChange
			: this.props.onDatePickerDateChange;
		const setResearchOffset = this.props.venueXid
			? (next) => this.setState({ researchOffset: next })
			: this.props.setResearchOffsetCallback;
		const containerClass = this.props.client === "CG" ? styles.cgContainer : "mt-2 mb-2";
		return (
			<div className={containerClass}>
				<Card>
					<div className={styles.header}>
						<h2 className={styles.title} id="allResearchCard-title">
							{this.headerTitle()}
						</h2>
						{this.props.client === "CG" ? (
							<div className={styles.quoteSearch}>
								<CGSearchBar
									apiInstance={this.props.apiInstance}
									setStateCallback={this.props.setStateCallback}
									setResearchOffset={setResearchOffset}
									language={this.props.language}
								/>
							</div>
						) : null}
					</div>
					<ResearchFilters
						filterData={this.state.filterData}
						headlineOnlyVisibilityState={this.state.headlineOnlyVisibility}
						onHeadlineOnlyChange={this.onHeadlineOnlyChange}
						onFilterChange={onFilterChange}
						onDatePickerDateChange={onDatePickerDateChange}
						language={this.props.language ? this.props.language : languageEn}
						locale={this.props.locale}
						clearFilterCallback={this.props.clearFilterCallback}
						client={this.props.client || null}
					/>
					<div className={styles.researchContainer} id="allResearchCardHeight">
						<ResearchItemsTable
							items={this.state.researchData ? this.state.researchData.items : null}
							dateSpan={this.state.filterData.dateSpan}
							headlineOnlyVisibility={this.state.headlineOnlyVisibility}
							{...this.props}
						></ResearchItemsTable>
					</div>
					{this.renderPagination()}
				</Card>
			</div>
		);
	}
}

export default AllResearchCard;
