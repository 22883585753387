import { URLS } from "utils/constants";

export const getUserInfo = async (apiInstance) => {
	if (!apiInstance) return;

	const CancelToken = apiInstance.config.CancelToken;
	const source = CancelToken.source();
	const response = await apiInstance.get(`${URLS.USER_INFO_PATH}`, {
		cancelToken: source.token,
	});

	return response?.data?.data;
};

export default { getUserInfo };
