import { URLS } from "utils/constants";

const apiUrl = `${URLS.USER_MANAGEMENT}/preferences`;

const setRecentSearch = async (apiInstance, recentSearch) => {
	if (!apiInstance) {
		return false;
	}

	let result;
	try {
		const postPayload = {
			value: recentSearch,
		};
		const response = await apiInstance.post(`${apiUrl}/recentSearch`, postPayload);

		if (response === "rejected") {
			result = false;
		} else {
			result = true;
		}
	} catch (e) {
		result = false;
	}
	return result;
};

const getRecentSearch = async (apiInstance) => {
	if (!apiInstance) {
		return [];
	}

	let result = [];
	try {
		const response = await apiInstance.get(apiUrl);
		const recentData = response?.data?.data?.items || null;

		if (recentData) {
			const recentSearchesItems = recentData.find(
				({ key }) => key === "recentSearch"
			)?.value;
			if (recentSearchesItems) {
				const data = JSON.parse(recentSearchesItems);
				result = data.value;
			}
		}
	} catch (e) {
		result = [];
	}
	return result;
};

export default {
	getRecentSearch,
	setRecentSearch,
};
