import Indicators from "components/MarketsAndResearch/Charting/Indicators";
import Comparisons from "components/MarketsAndResearch/Charting/Comparisons";
import utils from "utils/utils";

const showDataOnLegendCard = (
	args,
	activePanelIndicator,
	activePanelIndex,
	index,
	indicatorSettings
) => {
	let isNoClipIndicatorExists = getNoClipIndicator(args);
	let comparisonXid = getComparisonXid(activePanelIndicator);

	var element = getLegendDataCardElement(
		args,
		activePanelIndex,
		index,
		isNoClipIndicatorExists,
		comparisonXid,
		activePanelIndicator
	);
	if (element) {
		let indicator = configureDataOnIndicator(
			args,
			comparisonXid,
			isNoClipIndicatorExists,
			index,
			activePanelIndex,
			activePanelIndicator
		);

		createDataSpanforLegendCard(
			indicatorSettings,
			indicator,
			activePanelIndicator,
			element
		);
	}
};

const getNoClipIndicator = (args) => {
	return args.activePanel.indicators.some((x) =>
		Indicators.NO_CLIP_INDICATOR.includes(x.params.id)
	);
};

const getComparisonXid = (activePanelIndicator) => {
	return Comparisons.EXCHANGES.filter(
		(x) => x.xid === activePanelIndicator.params.symbol
	);
};

const configureDataOnIndicator = (
	args,
	comparisonXid,
	isNoClipIndicatorExists,
	index,
	activePanelIndex,
	activePanelIndicator
) => {
	let getComparisonIndex;
	if (comparisonXid.length > 0) {
		getComparisonIndex = args.panels[activePanelIndex].indicators.findIndex(
			(x) => x.params.symbol === comparisonXid[0].xid
		);
	}

	let getIndicatorIndex;
	if (activePanelIndicator.params.id) {
		getIndicatorIndex = args.panels[activePanelIndex].indicators.findIndex(
			(x) => x.params.uid === activePanelIndicator.params.uid
		);
	}

	if (isNoClipIndicatorExists && getComparisonIndex > -1)
		return args.panels[activePanelIndex].indicators[getComparisonIndex];
	else return args.panels[activePanelIndex].indicators[getIndicatorIndex];
};

const getLegendDataCardElement = (
	args,
	activePanelIndex,
	index,
	isNoClipIndicatorExists,
	comparisonXid,
	activePanelIndicator
) => {
	let element;
	let activeIndicator = configureDataOnIndicator(
		args,
		comparisonXid,
		isNoClipIndicatorExists,
		index,
		activePanelIndex,
		activePanelIndicator
	);

	if (activeIndicator) {
		element = document.querySelector(
			`[data-uid="${activeIndicator.params.uid}"] .mouseOverValue`
		);
		element.innerHTML = "";
	}

	return element;
};

const createDataSpanforLegendCard = (
	indicatorSettings,
	indicator,
	activePanelIndicator,
	element
) => {
	indicatorSettings.displayCardColumns.forEach((property) => {
		let span = document.createElement("div");
		span.classList.add("cardValue");

		let value = indicator.data[property.name];
		addStyleToIndicatorDataLegends(activePanelIndicator, property, span);

		span.innerHTML =
			utils.getFormattedNumber(value) +
			(activePanelIndicator.params.id === "price" ? "%" : "");
		element.appendChild(span);
	});
};
export const addIndicatorDataToLegend = (args) => {
	let activePanelIndex = args.activePanel.index;
	args.activePanel.indicators.forEach((activePanelIndicator, index) => {
		if (
			activePanelIndicator.params.id === "highlow" ||
			(activePanelIndicator.params.id === "volume" && activePanelIndex === 0)
		)
			return;
		if (Indicators.NO_CLIP_INDICATOR.includes(activePanelIndicator.params.id))
			return false;
		var indicatorSettings = Indicators.DICTIONARY[activePanelIndicator.params.id];
		if (index !== 0 && !indicatorSettings && activePanelIndicator.params.id === "price") {
			indicatorSettings = { displayCardColumns: [{ name: "close" }] };
		}
		if (indicatorSettings && indicatorSettings.displayCardColumns) {
			showDataOnLegendCard(
				args,
				activePanelIndicator,
				activePanelIndex,
				index,
				indicatorSettings
			);
		}
	});
};
export const addStyleToIndicatorDataLegends = (indicator, property, span) => {
	var indicatorStyle = indicator.params.style;
	let currentColor = indicatorStyle[property.styleMarker];
	if (currentColor) {
		if (currentColor === Object(currentColor)) {
			span.style.color = currentColor.color;
		} else {
			span.style.color = currentColor;
		}
	}
};
