//Please note that the news url path does not include the version number in order to accomodate the pattern that document links are returned. Please do not add a version without updating news API calls in the project
export const URLS = {
	ALPHA_BETA_STATS: "/equities-morningstar-alphabetastatistics/v1/alpha-beta-statistics",
	CHARTWORKS_SERIES_PATH: "/apiman-gateway/MOD/chartworks-data/1.0/",
	DIVIDENDS_PATH: "/corporateactions-dividends/v1",
	EARNINGS_DETAILS_PATH: "/equities-morningstar-earnings/v1/details",
	EARNINGS_ESTIMATES_CURRENT_PATH: "/equities-morningstar-estimates/v1/current",
	EARNINGS_ESTIMATES_TRENDS_PATH: "/equities-morningstar-estimates/v1/trends",
	EARNINGS_PATH: "/equities-ibes-estimates/v1/current",
	EQ_MSTAR_CLASSIFICATION_DETAILS_PATH:
		"/equities-morningstar-assetclassification/v1/details",
	EQUITIES_MORNING_STAR_GECS: "/equities-morningstar-gecs/v1/",
	EQUITIES_MORNING_STAR_GENERAL_INFO:
		"/equities-morningstar-generalinformation/v1/details/",
	EQUITIES_MORNING_STAR_MANAGEMENT: "/equities-morningstar-management/v1/details",
	FINANCIAL_RATIOS_DETAILS_PATH: "/equities-morningstar-financialratios/v1/details",
	FINANCIAL_RATIOS_HISTORY_PATH: "/equities-morningstar-financialratios/v1/history",
	FINANCIAL_STATEMENTS_CHAIN_PATH: "/equities-morningstar-financialstatements/v1",
	MERGERS_ACQUISITIONS_PATH:
		"/equities-morningstar-mergersandacquisitions/v1/mergers-and-acquisitions",
	MIND_INTERESTING_EVENTS_PATH: "/mind/v1/interesting-events",
	MSO_API: "/MSOnline/api",
	MSPI_PATH: "/ms-research/mspi",
	NEWS_PATH: "/news",
	OPTIONS_CHAIN_PATH: "/optionchainsgreeks/v1/options/chainwithgreeks",
	OPTIONS_PATH: "/optionchains/v1/options",
	PEERS_PATH: "/apiman-gateway/MorganStanley/morganstanley-api/1.0/peers",
	PRICING_PATH: "/pricing-current-endofdaystats/v1/details",
	QUOTE_PATH: "/quote/v1/details",
	RESEARCH_PATH: "/research/v1",
	SEC_FILING_PATH: "/sec-filings/v1/documentList",
	SEC_FILINGS_PDF_PATH: "/sec-filings/v1/document",
	SECTOR_AVERAGE_PATH:
		"/equities-morningstar-gecs/v1/ratiosAggregate?countryOfExchange=US",
	SECTOR_EQUITY_CLASSIFICATION: "/equities-morningstar-gecs/v1/equityClassification",
	SPLITS_PATH: "/corporateactions-splits/v1",
	USER_INFO_PATH: "/userinfo/v1/userInfo",
	USER_MANAGEMENT: "/user-management/v1",
	XREF_PREDICTIVE_SEARCH_PATH: "/xref/v1/venues",
	XREF_SYMBOL_PATH: "/xref/v1/symbols",
	XREF_XID_PATH: "/xref/v1/venue-xids",
};
export const QUERY_KEYS = {
	QUOTE: "quote",
	XREF_SYMBOL: "xref/symbol",
	XREF_XID: "xref/xid",
	PEERS: "peers",
	OPTIONS_EXPIRATION_DATES: "options/expirationDates",
	OPTIONS: "options",
};
export const OPTION_VIEW_TYPE_CALL = "call";
export const OPTION_VIEW_TYPE_PUT = "put";
export const OPTION_VIEW_TYPE_CALL_AND_PUT = "callandput";
export const OPTION_PAGE_VIEW_TYPE_MODAL = "modal";
export const OPTION_PAGE_VIEW_TYPE_DESKTOP = "desktop";
export const OPTION_PAGE_VIEW_TYPE_MR_REDESIGN_DESKTOP = "mr_redesign_desktop";
export const OPTION_PAGE_VIEW_TYPE_MOBILE = "mobile";
export const TREASURY_YIELD_VENUE_XID = 11523678;
export const DIVIDEND_YIELD_DEFAULT = 0;
export const RISK_FREE_RATE_DEFAULT = 0.01;
export const QUOTE_MODULE_OPIONS = "options";
export const QUOTE_MODULE_MARKETS_RESEARCH = "marketsandresearch";
export const MARKETS_AND_RESEARCH_PAGES = {
	OVERVIEW: "overview",
	CHARTING: "charting",
	NEWS: "news",
	ANALYST_RESEARCH: "analystresearch",
	EARNINGS: "earnings",
	DIVIDENDS: "dividends",
	FINANCIALS: "financials",
	SECFILING: "SECFilings",
	OPTIONS: "options",
};
export const RESEARCH_IDS = {
	FINANCIAL_DETAILS_ID: 471,
	RATINGS_AND_PRICE_TARGETS_ID: 472,
	RESEARCH: 343,
};
export const SEC_FILING_CIK = "0000040545";
export const MARKETS_AND_RESEARCH_ROUTE_NAMES = {
	OVERVIEW: "Overview",
	CHARTING: "Charting",
	NEWS: "News",
	ANALYST_RESEARCH: "AnalystResearch",
	EARNINGS: "Earnings",
	DIVIDENDS: "Dividends",
	FINANCIALS: "Financials",
	OPTIONS: "Options",
	SECFILING: "SECFilings",
};
export const PERIODICITY_OPTIONS = {
	ANNUAL: "annual",
	QUARTERLY: "quarterly",
	TTM: "ttm",
};
export const DEFAULT_EARNINGS_CHART = "overview";
export const FINANCIAL_STATEMENT_TYPE = {
	INCOME_STATEMENT: "Income Statement",
	CASH_FLOW: "Cash Flow",
	BALANCE_SHEET: "Balance Sheet",
};
export const PERIOD_TYPE = {
	INTERIM: "Interim",
	ANNUAL: "Annual",
};
export const EARNINGS_TYPE = {
	FORWARD: "forward",
	HISTORICAL: "historical",
};
export const RATING_DICTIONARY = {
	1: "Underweight",
	2: "Equal-weight",
	3: "Overweight",
};
/* responsive breakpoints should align with bootstrap breakpoints as defined here
		C:\Projects\morganstanley-research\src\styles\partials\_variables.scss
	*/
export const RESPONSIVE_BREAKPOINTS = {
	MEDIA_BREAKPOINTS_MIN: {
		MEDIA_BREAKPOINT_MIN_XS: 361,
		MEDIA_BREAKPOINT_MIN_SM: 376,
		MEDIA_BREAKPOINT_MIN_MD: 578,
		MEDIA_BREAKPOINT_MIN_LG: 770,
		MEDIA_BREAKPOINT_MIN_XL: 994,
		MEDIA_BREAKPOINT_MIN_XXL: 1120,
	},
	MEDIA_BREAKPOINTS_MAX: {
		MEDIA_BREAKPOINT_MAX_XXS: 360,
		MEDIA_BREAKPOINT_MAX_XS: 375,
		MEDIA_BREAKPOINT_MAX_SM: 577,
		MEDIA_BREAKPOINT_MAX_MD: 769,
		MEDIA_BREAKPOINT_MAX_LG: 993,
		MEDIA_BREAKPOINT_MAX_XL: 1119,
		MEDIA_BREAKPOINT_MAX_XXL: 1140,
	},
};
export const FRAME_INCREMENT_SIZE = {
	OVERVIEW: 250,
	ALL_RESEARCH_CARD: 50,
	ALL_RESEARCH_CARD_MIN_HEIGHT: 3050,
};
export const USER_TIERS = {
	NASDAQ_REALTIME: [5846, 5847, 4327],
	OPTIONS_REALTIME: [5846, 4327],
};
export const RESEARCH_PROVIDERS = {
	MORNINGSTAR: "Morningstar",
	MORGAN_STANLEY: "Morgan Stanley",
};
export const CLIENTS = {
	MORGAN_STANLEY: "MorganStanley",
	CANACCORD_GENUITY: "CanaccordGenuity",
};
// TODO: can this be moved to scss variable
export const CANACCORD_GENUITY_BACKGROUND = "#ffffff";
export const PEER_VALUATION = {
	SECTOR_AVERAGE: "Sector Average",
};
export const SPIN_OFF = "Spin-off";
