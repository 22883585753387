import React from "react";
import "./NoData.module.scss";
import { ReactComponent as NoActivity } from "assets/icons/no-activity.svg";

const NoData = ({ children = "Data not available" }) => (
	<div className="noData">
		<NoActivity aria-hidden></NoActivity>
		<div className="mt-4 mb-4">{children}</div>
	</div>
);

export default NoData;
