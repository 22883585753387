import React from "react";

const MountainChart = () => {
	return (
		<svg
			aria-hidden="true"
			focusable="false"
			className="mountainChart"
			width="17px"
			height="9px"
			viewBox="0 0 17 9"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g
				id="Desktop---Interactive-States"
				stroke="none"
				strokeWidth="1"
				fill="none"
				fillRule="evenodd"
			>
				<g
					id="07_MSO-Company-Overview-1440-Charting-Module-States"
					transform="translate(-989.000000, -842.000000)"
					stroke="#187ABA"
					strokeWidth="2"
				>
					<g id="Mountain" transform="translate(32.000000, 666.000000)">
						<polyline points="973 177 966.604901 183 962.589486 180.308655 958 184.047321"></polyline>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default MountainChart;
