import React from "react";
import styles from "./StrikesFilter.module.scss";

const StrikesFilterItem = (props) => {
	return (
		<div className={styles.strikesFilter}>
			<input
				id={`strikes_${props.data.id}`}
				type="radio"
				name="strikes"
				checked={props.current === `${props.data.value}`}
				value={props.data.value}
				onChange={props.onChange}
			/>
			<label htmlFor={`strikes_${props.data.id}`}>
				<span></span> {props.data.label}
			</label>
		</div>
	);
};

export default StrikesFilterItem;
