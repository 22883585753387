/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect, useState } from "react";
import { Popper } from "react-popper";
import styles from "./ColorPallete.module.scss";
import Chevron from "../Chevron/Chevron";

const colorList = [
	{ value: "#EF8989", name: "Light Coral" },
	{ value: "#FDCC72", name: "Topaz" },
	{ value: "#E9E07D", name: "Flax" },
	{ value: "#90DE90", name: "Light Green" },
	{ value: "#8FD3FF", name: "Pale Cyan" },
	{ value: "#B9A7DA", name: "Light Pastel Purple" },
	{ value: "#F5F5F5", name: "Whitesmoke" },
	{ value: "#CA3030", name: "Persian Red" },
	{ value: "#D6A241", name: "Satin Sheen Gold" },
	{ value: "#DACC37", name: "Sandstorm" },
	{ value: "#4BB44B", name: "Apple" },
	{ value: "#187ABA", name: "Cyan Cornflower Blue" },
	{ value: "#9788B2", name: "Lavender Purple" },
	{ value: "#B7B7B7", name: "Philippine Silver" },
	{ value: "#AA0000", name: "Dark Candy Apple Red" },
	{ value: "#BB831B", name: "University Of California Gold" },
	{ value: "#B9AB15", name: "Gold Foil" },
	{ value: "#006600", name: "Green" },
	{ value: "#005C90", name: "Sea Blue" },
	{ value: "#675882", name: "Dark Lavender" },
	{ value: "#767676", name: "Sonic Silver" },
	{ value: "#750707", name: "Barn Red" },
	{ value: "#8B6216", name: "Golden Brown" },
	{ value: "#8B8113", name: "Dark Yellow" },
	{ value: "#004400", name: "Dark Green" },
	{ value: "#002B51", name: "Prussian Blue" },
	{ value: "#583D65", name: "English Violet" },
	{ value: "#333333", name: "Dark Charcoal" },
];

const getColorName = (code) => colorList.find((x) => x.value === code).name;

const ColorPallete = (props) => {
	const btnRef = useRef(null);
	const buttonId = `btn_colorpalette_${props.id}`;
	const palleteId = `colorpalette_${props.id}`;
	const [selectedColor, setSelectedColor] = useState({
		color: props.defaultValue,
		name: getColorName(props.defaultValue),
	});
	let popperNode = null;
	const [toggle, setToggle] = useState(false);

	const togglePopOver = () => {
		setToggle(!toggle);
	};

	const onColorSelection = (e) => {
		if (e.type === "click" || (e.type === "keydown" && e.key === "Enter")) {
			let color = e.target.dataset.color;
			let name = e.target.dataset.name;
			setSelectedColor({ color: color, name: name });
			if (e.type === "click") closePopover();
			else btnRef.current.focus();
			props.onSelected({ name: props.name, color: color });
		}
	};

	let colordiv = colorList.map((color, i, data) => (
		<div
			key={color.key || color.value}
			className={`${styles.colorbox} ${
				color.value === selectedColor.color ? "active" : ""
			}`}
			style={{ backgroundColor: color.value }}
			data-color={color.value}
			data-name={color.name}
			aria-label={props.name + " " + color.name}
			data-isfirst={i === 0}
			tabIndex="0"
			onClick={onColorSelection}
			onKeyDown={onColorSelection}
		></div>
	));

	useEffect(() => {
		toggle && popperNode.firstChild.focus({ preventScroll: true });
	}, [toggle]);

	const onBlur = (e) => {
		e && e.stopPropagation();

		if (
			e.relatedTarget &&
			!e.currentTarget.contains(e.relatedTarget) &&
			e.relatedTarget.id !== buttonId
		) {
			closePopover();
		}
	};
	const onKeyHandler = (e) => {
		if (e.key === "Escape") {
			closePopover();
			btnRef.current.focus();
		} else if (e.target.dataset.isfirst === "true" && e.shiftKey && e.keyCode === 9) {
			closePopover();
		}
	};
	const closePopover = () => {
		setToggle(false);
	};

	return (
		<div className={styles.colorPallete}>
			<button
				ref={btnRef}
				onClick={togglePopOver}
				id={buttonId}
				className={styles.button}
			>
				<span className="sr-only">{props.name + " " + selectedColor.name}</span>
				<span
					className={styles.colorbox}
					style={{ backgroundColor: selectedColor.color }}
				></span>
				<Chevron direction={toggle ? "up" : "down"} dimension="20" color="black" />
			</button>
			{toggle && (
				<Popper
					referenceElement={btnRef.current}
					placement={"bottom"}
					modifiers={{ offset: { offset: "10px, 10px" } }}
					innerRef={(node) => (popperNode = node)}
				>
					{({ ref, style, placement, arrowProps }) => (
						<div
							className={styles.popOver}
							id={palleteId}
							ref={ref}
							onKeyDown={onKeyHandler}
							data-placement={placement}
							onBlur={(e) => onBlur(e)}
						>
							{colordiv}
						</div>
					)}
				</Popper>
			)}
		</div>
	);
};

export default ColorPallete;
