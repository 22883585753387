import React from "react";
import formatter from "../../../utils/formatUtils";
import styles from "./Disclosures.module.scss";

const overviewDisclosures = () => {
	return (
		<>
			<p className={styles.legalBold}>
				Investors should carefully consider the investment objectives and risks, as well
				as charges and expenses, of a mutual fund/exchange traded fund (ETF) before
				investing. To obtain a prospectus, contact your Financial Advisor or visit the
				fund company’s website. The prospectus contains this and other information about
				the mutual fund/ETF. Read the prospectus carefully before investing.
			</p>
			<p className={styles.legal}>
				The information and data contained herein does not provide individually tailored
				investment advice. It has been prepared without regard to the individual financial
				circumstances and objectives of persons who receive it. Morgan Stanley Smith
				Barney LLC (“Morgan Stanley” and “Morgan Stanley Wealth Management”) recommends
				that investors independently evaluate particular investments and strategies, and
				encourages investors to seek the advice of a Morgan Stanley Smith Barney LLC
				Financial Advisor. The appropriateness of a particular investment or strategy will
				depend on an investor’s individual circumstances and objectives.
			</p>
			<p className={styles.legal}>
				By providing a link to a third party article, Morgan Stanley is not implying an
				affiliation, sponsorship, endorsement, etc. with the third party. Morgan Stanley
				is not responsible for the information contained in the third party article. The
				opinions expressed by the author are solely their own and do not necessarily
				reflect those of Morgan Stanley. Securities, investments, strategies or products
				mentioned or discussed in the third party article are neither an endorsement nor
				solicitation by Morgan Stanley. The information and data provided by the third
				party web site or publication is as of the date of the article when it was
				published on Morgan Stanley Online and is subject to change without notice. Past
				performance is not a guarantee of future results.
			</p>
			<p className={styles.legal}>
				Copyright © {formatter.moment().year()} Thomson Reuters. All Rights Reserved. Use,
				duplication, or sale of this service, or data contained herein is strictly
				prohibited.
			</p>
			<p className={styles.legal}>
				The research reports and the information and data derived from such reports that
				are included on this website are provided for information purposes only. This
				content is not a solicitation of any offer to buy or sell any security or other
				financial instrument or to participate in any trading strategy. It is not
				individually tailored investment advice and is prepared and made available on this
				website without regard to your individual financial circumstances and objectives.
				The securities and strategies discussed or referred to in this content may not be
				appropriate for you. Your decision to buy, hold or sell a security should depend
				on your individual circumstances (such as your investment objectives and
				experience, time horizon, liquidity needs, financial situation and existing
				holdings) and other considerations. We recommend that you independently evaluate
				particular investments and strategies, and encourage you to seek the advice of
				your Financial Advisor. Please see the relevant research report for important
				disclosures. Morgan Stanley is an affiliate of Morgan Stanley Smith Barney LLC.
			</p>
			<p className={styles.legal}>
				Past performance is not a guarantee of future results. Indexes are unmanaged and
				not available for direct investment. For illustrative purposes only and does not
				reflect any specific product.
			</p>
			<p className={styles.legal}>
				Any real-time market data is for informational purposes only. The Real-Time Quotes
				data feed provided by NASDAQ Basic is displayed only on the non-trading pages and
				only for US exchange-listed equities. Delayed NASDAQ market data is delayed for at
				least 15 minutes; all other market data is delayed for at least 20 minutes. This
				data should be used for informational purposes only. Please be advised that on
				weekdays, excepting holidays, current day NASDAQ market data is available after
				9:45 AM ET, data from all other exchanges is available after 9:50 AM ET. The
				accuracy of any market data displayed is not guaranteed.
			</p>
		</>
	);
};
const subTabDisclosures = () => {
	return (
		<>
			<p className={styles.legal}>
				Past performance is not a guarantee of future results. Indexes are unmanaged and
				not available for direct investment. For illustrative purposes only and does not
				reflect any specific product.
			</p>
			<p className={styles.legal}>
				© Thomson Reuters {formatter.moment().year()}. All rights reserved.
			</p>
			<p className={styles.legal}>
				Thomson Reuters content is the intellectual property of Thomson Reuters or its
				third party content providers. Republication or redistribution of Thomson Reuters
				content, including by framing or similar means, is prohibited without the prior
				written consent of Thomson Reuters. Thomson Reuters is not liable for any errors
				or delays in Thomson Reuters content, or for any actions taken in reliance on such
				content. 'Thomson Reuters' and the Thomson Reuters logo are trademarks of Thomson
				Reuters and its affiliated companies.
			</p>
		</>
	);
};
const optionsDisclosures = () => {
	return (
		<>
			<p className={styles.legal}>
				Options may not be suitable for particular investors.{"\n"}
				Before engaging in the purchase or sale of options, potential clients should
				understand the nature and extent of their rights and obligations and be aware of
				the risks involved, including, without limitation, the risks pertaining to the
				business and financial condition of the issuer of the underlying
				security/instrument. Options investing, like other forms of investing, involves
				tax considerations, transaction costs and margin requirements that can
				significantly affect clients’ potential profits and losses. The transaction costs
				of options investing consist primarily of commissions (which are imposed in
				opening, closing, exercise and assignment transactions) but may also include
				margin and interest costs in particular transactions. Transaction costs are
				especially significant in options strategies calling for multiple purchases and
				sales of options, such as multiple leg strategies, including spreads, straddles
				and collars. If a client is considering engaging in options trading as part of
				their investment plan, the Financial Advisor or Private Wealth Advisor is required
				to provide the client with the "Characteristics and Risks of Standardized Options"
				(ODD) booklet published by the Options Clearing Corporation. Clients should not
				enter into options transactions until they have read and understood the ODD
				Disclosure Document, as options are not suitable for everyone, and discussed
				transaction costs with the Financial Advisor or Private Wealth Advisor. A copy of
				the ODD is also available online at:
				http://www.theocc.com/about/publications/publication-listing.jsp.
			</p>
			<p className={styles.legal}>
				Any real-time market data is for informational purposes only. The Real-Time Quotes
				data feed provided by NASDAQ Basic is displayed only on the non-trading pages and
				only for US exchange-listed equities. Delayed NASDAQ market data is delayed for at
				least 15 minutes; all other market data is delayed for at least 20 minutes. This
				data should be used for informational purposes only. Please be advised that on
				weekdays, excepting holidays, current day NASDAQ market data is available after
				9:45 AM ET, data from all other exchanges is available after 9:50 AM ET. The
				accuracy of any market data displayed is not guaranteed.
			</p>
		</>
	);
};
const chartingDisclosures = () => {
	return (
		<>
			<p className={styles.legal}>
				Any real-time market data is for informational purposes only. The Real-Time Quotes
				data feed provided by NASDAQ Basic is displayed only on the non-trading pages and
				only for US exchange-listed equities. Delayed NASDAQ market data is delayed for at
				least 15 minutes; all other market data is delayed for at least 20 minutes. This
				data should be used for informational purposes only. Please be advised that on
				weekdays, excepting holidays, current day NASDAQ market data is available after
				9:45 AM ET, data from all other exchanges is available after 9:50 AM ET. The
				accuracy of any market data displayed is not guaranteed.
			</p>
		</>
	);
};
const canacordResearchDisclosures = (language) => {
	return (
		<>
			<p className={styles.legalBold}>{language.DISCLOSURES_P1}</p>
			<p className={styles.legalBold}>{language.DISCLOSURES_P2}</p>
			<p className={styles.legalBold}>{language.DISCLOSURES_P3}</p>
			<p className={styles.legal}>{language.DISCLOSURES_P4}</p>
			<p className={styles.legal}>{language.DISCLOSURES_P5}</p>
			<p className={styles.legal}>{language.DISCLOSURES_P6}</p>
			<p className={styles.legal}>{language.DISCLOSURES_P7}</p>
		</>
	);
};

const newsAndResearchDisclosures = () => {
	return (
		<>
			<p className={styles.legal}>
				The information and data contained herein does not provide individually tailored
				investment advice. It has been prepared without regard to the individual financial
				circumstances and objectives of persons who receive it. Morgan Stanley Smith
				Barney LLC (“Morgan Stanley” and “Morgan Stanley Wealth Management”) recommends
				that investors independently evaluate particular investments and strategies, and
				encourages investors to seek the advice of a Morgan Stanley Smith Barney LLC
				Financial Advisor. The appropriateness of a particular investment or strategy will
				depend on an investor’s individual circumstances and objectives.
			</p>
			<p className={styles.legal}>
				By providing a link to a third party article, Morgan Stanley is not implying an
				affiliation, sponsorship, endorsement, etc. with the third party. Morgan Stanley
				is not responsible for the information contained in the third party article. The
				opinions expressed by the author are solely their own and do not necessarily
				reflect those of Morgan Stanley. Securities, investments, strategies or products
				mentioned or discussed in the third party article are neither an endorsement nor
				solicitation by Morgan Stanley. The information and data provided by the third
				party web site or publication is as of the date of the article when it was
				published on Morgan Stanley Online and is subject to change without notice. Past
				performance is not a guarantee of future results.
			</p>
			<p className={styles.legal}>
				© Thomson Reuters {formatter.moment().year()}. All rights reserved.
			</p>
			<p className={styles.legal}>
				Thomson Reuters content is the intellectual property of Thomson Reuters or its
				third party content providers. Republication or redistribution of Thomson Reuters
				content, including by framing or similar means, is prohibited without the prior
				written consent of Thomson Reuters. Thomson Reuters is not liable for any errors
				or delays in Thomson Reuters content, or for any actions taken in reliance on such
				content. 'Thomson Reuters' and the Thomson Reuters logo are trademarks of Thomson
				Reuters and its affiliated companies.
			</p>
			<p className={styles.legal}>
				The research reports and the information and data derived from such reports that
				are included on this website are provided for information purposes only. This
				content is not a solicitation of any offer to buy or sell any security or other
				financial instrument or to participate in any trading strategy. It is not
				individually tailored investment advice and is prepared and made available on this
				website without regard to your individual financial circumstances and objectives.
				The securities and strategies discussed or referred to in this content may not be
				appropriate for you. Your decision to buy, hold or sell a security should depend
				on your individual circumstances (such as your investment objectives and
				experience, time horizon, liquidity needs, financial situation and existing
				holdings) and other considerations. We recommend that you independently evaluate
				particular investments and strategies, and encourage you to seek the advice of
				your Financial Advisor. Please see the relevant research report for important
				disclosures. Morgan Stanley is an affiliate of Morgan Stanley Smith Barney LLC.
			</p>
			<p className={styles.legal}>
				Past performance is not a guarantee of future results. Indexes are unmanaged and
				not available for direct investment. For illustrative purposes only and does not
				reflect any specific product.
			</p>
		</>
	);
};
const secFilingDisclosures = () => (
	<p className={styles.legal}>
		SEC filings are sourced from the U.S. Securities and Exchange Commission.
	</p>
);

const Disclosures = ({ disclosures, language, tab }) => {
	return (
		<footer aria-label="Footer" className={styles.disclosureFooter}>
			{disclosures === "overview" ? overviewDisclosures() : null}
			{disclosures === "analystresearch" || disclosures === "news"
				? newsAndResearchDisclosures()
				: null}
			{disclosures === "canacordAnalystResearch"
				? canacordResearchDisclosures(language)
				: null}
			{disclosures !== "overview" &&
			disclosures !== "analystresearch" &&
			disclosures !== "news" &&
			disclosures !== "canacordAnalystResearch"
				? subTabDisclosures()
				: null}
			{disclosures === "options" ? optionsDisclosures() : null}
			{disclosures === "charting" ? chartingDisclosures() : null}
			{disclosures === "overview" || tab === "SECFilings" ? secFilingDisclosures() : null}
		</footer>
	);
};

export default Disclosures;
