import { combineReducers } from "redux";
import optionsReducer from "./optionsReducer";
import quoteReducer from "./quoteReducer";
import equitiesReducer from "./equitiesReducer";
import researchReducer from "./researchReducer";
import marketsAndResearchAppReducer from "./marketsAndResearchAppReducer";
import xrefReducer from "./xrefReducer";
import corporateActionsReducer from "./corporateActionsReducer";
import dividendGrowthRateReducer from "./dividendsGrowthRateReducer";

// create and combined reducers into on central one
const mainReducer = combineReducers({
	optionsReducer,
	quoteReducer,
	equitiesReducer,
	researchReducer,
	marketsAndResearchAppReducer,
	xrefReducer,
	corporateActionsReducer,
	dividendGrowthRateReducer,
});

// create root reducer
const rootReducer = (state, action) => {
	return mainReducer(state, action);
};

export default rootReducer;
