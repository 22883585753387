import React from "react";
import { useDispatch } from "react-redux";
import actionTypes from "../../../store/actions/actionTypes";
import MSToggle from "../../Lib/MSToggle/MSToggle";

const MonthlyExpirationToggle = () => {
	const dispatch = useDispatch();

	const onClick = () => {
		dispatch({ type: actionTypes.UPDATE_SHOW_MONTHLY_EXPIRATION });
	};
	return (
		<MSToggle id="onlyMonthly" label="Only show monthly expiration" onClick={onClick} />
	);
};

export default MonthlyExpirationToggle;
