/*
	Hard-code Exchange xids from MSO for now.  When posible, use xref calls to get xid dynamically.
*/

const EXCHANGES = [
	{
		label: "Dow",
		name: "Dow",
		xid: "599362",
	},
	{
		label: "NASDAQ",
		name: "NASDAQ",
		xid: "579435",
	},
	{
		label: "S&P 500",
		name: "S&P 500",
		xid: "593933",
	},
];

// Add more colors (up to the limit of comparisons) once the user can add more than 6 comparisons
const COLORS = [
	"#F79121",
	"#B0C30C",
	"#5EB6E4",
	"#666666",
	"#036868",
	"#582557",
	"#CD1D8C",
];

export default {
	EXCHANGES,
	COLORS,
};
