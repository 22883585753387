import actionTypes from "./actionTypes";
import { URLS } from "../../utils/constants";

import QuoteActionCreator from "./quoteActions";
import DividendsActionCreator from "./dividendsActions";
import FinancialRatiosActionCreator from "./financialRatiosActions";
import EarningsEstimatesActionCreator from "./earningsEstimatesActions";
import ResearchActionCreator from "./researchActions";
import PeerComparisonActionCreator from "./peerComparisonActions";
import XrefActionCreator from "./xrefActions";
import DividendsGrowthRateActionCreator from "./dividendsGrowthRateActions";

//action creators
const storeResultsExpirationDates = (results) => {
	return {
		type: actionTypes.SAVE_EXP_FILTER_DATES,
		dates: results,
	};
};

const storeEarningsDetailsData = (results) => {
	return {
		type: actionTypes.SAVE_EARNINGS_DETAILS_DATA,
		earningsDetails: results,
	};
};

const fetchExpirationDates = (apiInstance, venueXid, contractTerm) => {
	//async here - dispatch here will get set by redux-thunk
	return async (dispatch, getState) => {
		if (!apiInstance || !venueXid) {
			return;
		}
		dispatch({ type: actionTypes.EXP_DATES_API_TRANSITION, name: "load" });

		const tempContractTerm = contractTerm || "all"; //possible values: all, weekly, monthly, quarterly
		try {
			const expirationDates = await apiInstance.get(
				`${URLS.OPTIONS_PATH}/expirationdates/${venueXid}`,
				{
					params: {
						contractTerm: tempContractTerm,
					},
				}
			);
			if (expirationDates.data.data) {
				const { dates } = expirationDates.data.data;
				if (Object.keys(dates).length === 0) {
					dispatch({
						type: actionTypes.EXP_DATES_API_TRANSITION,
						name: "failure",
					});
				} else {
					dispatch({
						type: actionTypes.EXP_DATES_API_TRANSITION,
						name: "success",
					});
					dispatch(storeResultsExpirationDates(dates));
				}
			}
		} catch (error) {
			dispatch({ type: actionTypes.EXP_DATES_API_TRANSITION, name: "failure" });
		}
	};
};

const fetchEarningsDetailsData = (apiInstance, venueXid) => {
	return async (dispatch, getState) => {
		if (!venueXid) {
			return "error";
		}

		dispatch({
			type: actionTypes.EARNINGS_DETAILS_API_TRANSITION,
			name: "load",
		});

		const { earningsReducer } = getState();
		if (earningsReducer.earningsDataStatus !== "FETCHING") {
			return;
		}

		try {
			const earningsData = await apiInstance.get(
				`${URLS.EARNINGS_DETAILS_PATH}/${venueXid}`,
				{
					params: {
						periodicity: "ttm",
						yearsOfHistory: 5,
					},
				}
			);

			dispatch({
				type: actionTypes.EARNINGS_DETAILS_API_TRANSITION,
				name: "success",
			});
			dispatch(storeEarningsDetailsData(earningsData));
		} catch (error) {
			dispatch({
				type: actionTypes.EARNINGS_DETAILS_API_TRANSITION,
				name: "failure",
			});
		}
	};
};

const storeEarningsData = (results) => {
	return {
		type: actionTypes.SAVE_EARNINGS_DATA,
		earnings: results,
	};
};

const fetchEarningsData = (apiInstance, venueXid) => {
	//async here - dispatch here will get set by redux-thunk
	return async (dispatch, getState) => {
		if (!venueXid) {
			return "error";
		}
		dispatch({ type: actionTypes.EARNINGS_API_TRANSITION, name: "load" });

		const { optionsReducer } = getState();
		if (optionsReducer.earningsDataStatus !== "FETCHING") {
			return;
		}

		try {
			const earningsData = await apiInstance.get(`${URLS.EARNINGS_PATH}/${venueXid}`, {});

			if (earningsData.data.data) {
				const { estimate } = earningsData.data.data;
				if (estimate && estimate.eps && estimate.nextReport) {
					const results = {
						reportDate: estimate.nextReport.reportDate,
						estimate: estimate.eps.currentQuarter.mean,
					};

					dispatch({ type: actionTypes.EARNINGS_API_TRANSITION, name: "success" });
					dispatch(storeEarningsData(results));
				}
			}
		} catch (error) {
			dispatch({ type: actionTypes.EARNINGS_API_TRANSITION, name: "failure" });
		}
	};
};

export default {
	fetchExpirationDates,
	fetchEarningsData,
	fetchEarningsDetailsData,
	fetchQuoteData: QuoteActionCreator.fetchQuote,
	fetchDividendsData: DividendsActionCreator.fetchDividends,
	fetchHistoricalDividendsData: DividendsActionCreator.fetchHistoricalDividends,
	fetchFinancialRatiosData: FinancialRatiosActionCreator.fetchFinancialRatios,
	fetchFinancialRatiosHistoryData:
		FinancialRatiosActionCreator.fetchFinancialRatiosHistory,
	fetchEarningsEstimatesCurrentData:
		EarningsEstimatesActionCreator.fetchEarningsEstimatesCurrent,
	fetchEarningsEstimatesTrendsData:
		EarningsEstimatesActionCreator.fetchEarningsEstimatesTrends,
	fetchAllAnnualEarningsEstimatesTrendsData:
		EarningsEstimatesActionCreator.fetchAllAnnualEarningsEstimatesTrends,
	fetchDocumentDetailsData: ResearchActionCreator.fetchDocumentDetails,
	fetchResearchPdfData: ResearchActionCreator.fetchResearchPDF,
	fetchEquitiesMorningStarPeer: PeerComparisonActionCreator.fetchEquitiesMorningStarPeer,
	setEarningsChartPeriodicity:
		EarningsEstimatesActionCreator.storeEarningsChartPeriodicity,
	fetchXrefSymbolsData: XrefActionCreator.fetchXrefSymbols,
	fetchDividendsGrowthRateData: DividendsGrowthRateActionCreator.fetchDividendsGrowthRate,
};
