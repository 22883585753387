import React, { Component } from "react";
import PropTypes from "prop-types";
import Chevron from "../Chevron/Chevron";
import "./Carousel.scss";

const { node } = PropTypes;

const arrowWidth = 22;

class Carousel extends Component {
	static propTypes = {
		children: node.isRequired,
	};

	constructor(props) {
		super(props);

		this.setSliderRef = (element) => {
			this.slider = element;
		};

		this.setSliderContentRef = (element) => {
			this.sliderContent = element;
		};

		this.state = {
			offsetLeft: 0,
			scrollStart: 0,
			isScrolling: false,
			transition: false,
			remainigWidth: 0,
		};
	}

	componentDidMount() {
		window.addEventListener("resize", () => this.handleResize());
		this.resetMargin();

		// Use the initial size of the carousel content to recalculate the remaining width
		// This determines if the right arrow should render
		const currentRemainingWidth = this.calculateRemainingWidth();

		this.setState({
			remainingWidth: currentRemainingWidth,
		});
	}

	componentWillUnmount() {
		window.removeEventListener("resize", () => this.handleResize());
	}

	componentDidUpdate(nextProps, nextState) {
		if (this.state.isScrolling && !nextState.isScrolling) {
			document.addEventListener("mousemove", this.handleMouseMove);
			document.addEventListener("mouseup", this.handleMouseUp);
		} else if (!this.state.isScrolling && nextState.isScrolling) {
			document.removeEventListener("mousemove", this.handleMouseMove);
			document.removeEventListener("mouseup", this.handleMouseUp);
		}

		// If the size of the carousel content has changed, recalculate the remaining width
		// This determines if the right arrow should render
		const currentRemainingWidth = this.calculateRemainingWidth();

		if (currentRemainingWidth !== nextState.remainingWidth) {
			this.setState({
				remainingWidth: currentRemainingWidth,
			});
		}
	}

	calculateRemainingWidth() {
		const currentoffsetLeft = this.state.offsetLeft;
		const sliderWidth = this.slider ? this.slider.offsetWidth : 0;
		const contentWidth = this.sliderContent ? this.sliderContent.offsetWidth : 0;

		return contentWidth - (sliderWidth - arrowWidth) - currentoffsetLeft;
	}

	//   slider width
	//        |  content width
	//        v        |
	// ~~~~~~~~~~~~~~  v
	// ~~~~~~~~~~~~~~~~~~~~
	// ______________
	// |             |----|
	// |             |

	// if we are currently paginated to the right less than / equal the width of
	// the slider container, then we can paginate back left all the way;
	// otherwise there is not enough space, so we only shift left the amount of
	// the width of the slider container

	handleLeftClicked = () => {
		const currentoffsetLeft = this.state.offsetLeft;
		const sliderWidth = this.slider.offsetWidth;
		let offsetLeft;

		if (currentoffsetLeft > sliderWidth) {
			offsetLeft = currentoffsetLeft - sliderWidth;
		} else {
			offsetLeft = 0;
		}

		this.setState({ offsetLeft, transition: true });
	};

	// if the amount of width left is less than / equal the width of the slider
	// container, we can shift left enough to show the entire remaining width;
	// otherwise we shift the length of the slider container; note the extra
	// 20px to account for margins for the arrows

	handleRightClicked = () => {
		const currentoffsetLeft = this.state.offsetLeft;
		const sliderWidth = this.slider.offsetWidth;
		const remainingWidth = this.state.remainingWidth;

		let offsetLeft;

		if (remainingWidth > 0) {
			if (remainingWidth <= sliderWidth) {
				offsetLeft = currentoffsetLeft + remainingWidth;
			} else {
				offsetLeft = currentoffsetLeft + sliderWidth;
			}
		} else {
			offsetLeft = currentoffsetLeft;
		}

		this.setState({ offsetLeft, transition: true });
	};

	handleResize = () => {
		this.updateFn = this.updateFn || setTimeout(() => this.resetMargin(), 200);
		return this.updateFn;
	};

	handleMouseUp = (e) => {
		this.setState({
			isScrolling: false,
			scrollStart: 0,
			transition: false,
		});
		e.stopPropagation();
		e.preventDefault();
	};

	handleMouseDown = (e) => {
		// only left mouse button
		if (e.button !== 0) return;
		this.setState({
			isScrolling: true,
			scrollStart: e.clientX,
			transition: false,
		});
		e.stopPropagation();
		e.preventDefault();
	};

	handleMouseMove = (e) => {
		const { offsetLeft, scrollStart, isScrolling } = this.state;
		if (!isScrolling) return;

		const scrollDirection = e.clientX > scrollStart ? "left" : "right";

		let offset, step;

		if (scrollDirection === "left") {
			step = Math.abs(e.clientX - scrollStart);
			offset = offsetLeft - step;
			this.setState({
				offsetLeft: offset > 0 ? offset : 0,
				scrollStart: e.clientX,
			});
		} else if (scrollDirection === "right") {
			step = Math.abs(scrollStart - e.clientX);
			offset = offsetLeft + step;
			this.setState({
				offsetLeft: this.state.remainingWidth > 0 ? offset : offsetLeft,
				scrollStart: e.clientX,
			});
		}

		e.stopPropagation();
		e.preventDefault();
	};

	resetMargin = () => {
		if (this.slider && this.sliderContent) {
			this.setState({ offsetLeft: 0 });
		}
	};

	// Notes on when the carousel control arrows render:
	//--------------------------------------------------
	// - Show left arrow if we are tabbed over at all (margin left not 0)
	// - Show the right arrow When the 'content' of a slider exceeds the width of the slider itself
	// (remaining width is the current width of the content minus the width of the slider container)

	render = () => {
		const styles = { transform: `translateX(-${this.state.offsetLeft}px)` };
		if (this.state.transition) {
			styles.transition = `transform 250ms ease-in`;
		} else {
			delete styles.transition;
		}

		return (
			<div className="carousel" ref={this.setSliderRef}>
				{this.state.offsetLeft !== 0 && (
					<button
						className="carousel-nav carousel-nav-left"
						onClick={this.handleLeftClicked}
					>
						<span className="sr-only">Previous</span>
						<Chevron direction="left" dimension={arrowWidth} color="black" />
					</button>
				)}
				<div className="carousel-wrapper">
					<div
						className="carousel-content"
						ref={this.setSliderContentRef}
						style={styles}
						onMouseDown={this.handleMouseDown}
					>
						{this.props.children}
					</div>
				</div>
				{this.state.remainingWidth > 0 && (
					<button
						className="carousel-nav carousel-nav-right"
						onClick={this.handleRightClicked}
					>
						<span className="sr-only">Next</span>
						<Chevron direction="right" dimension={arrowWidth} color="black" />
					</button>
				)}
			</div>
		);
	};
}

export default Carousel;
