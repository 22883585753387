import formatter from "utils/formatUtils";

/**
 * Convenience method for constructing an equal to parameter
 *
 * @param {string} field Field name
 * @param {string[]} values Array of values
 * @param {string} type The field data type
 */
const equalTo = (field, values, type = "string") => {
	return {
		field,
		type,
		values,
	};
};

/**
 * Common functionality for the discrete options
 */
const commonMethods = {
	findByLabel(label) {
		return this.values.find((rs) => rs.label === label);
	},
	hasType(arr, field) {
		return arr && arr.some((rt) => rt === field);
	},
	toggleValue(arr, field) {
		if (arr.some((x) => x === field)) {
			return arr.filter((x) => x !== field);
		} else {
			return [...arr, field];
		}
	},
};

export const ProviderIds = {
	MORGAN_STANLEY: "809",
	MORNINGSTAR: "22",
};
export const ProviderLabels = {
	MORGAN_STANLEY: "Morgan Stanley",
	MORNINGSTAR: "Morningstar",
};

export const ProviderInfo = {
	[ProviderLabels.MORGAN_STANLEY]: [
		{
			label: "Ratings",
			name: "msratings",
			value: true,
			propertyName: "ratings",
		},
		{
			label: "Price Targets",
			name: "priceTarget",
			value: true,
			propertyName: "priceTargets",
		},
	],
	[ProviderLabels.MORNINGSTAR]: [
		{
			label: "Ratings",
			name: "ratings",
			value: true,
			propertyName: "ratings",
		},

		{
			label: "Fair Value Estimates",
			name: "fvEstimate",
			value: true,
			propertyName: "priceTargets",
		},
	],
};

export const ResearchSource = {
	...commonMethods,
	values: [
		{
			label: ProviderLabels.MORGAN_STANLEY,
			providerId: [ProviderIds.MORGAN_STANLEY],
		},
		{
			label: ProviderLabels.MORNINGSTAR,
			providerId: [ProviderIds.MORNINGSTAR],
		},
	],
	DEFAULT_RESEARCH_SOURCE: ProviderLabels.MORGAN_STANLEY,

	buildArgs(provider) {
		const providerSearch = this.findByLabel(provider);
		if (!providerSearch || !providerSearch.providerId) {
			return undefined;
		}

		return equalTo("ProviderID", providerSearch.providerId);
	},
};

export const DateSpan = {
	...commonMethods,
	values: [
		{
			label: "1M",
			period: "years",
			interval: 10,
		},
		{
			label: "3M",
			period: "years",
			interval: 10,
		},
		{
			label: "6M",
			period: "years",
			interval: 10,
		},
		{
			label: "1Y",
			period: "years",
			interval: 10,
		},
		{
			label: "3Y",
			period: "years",
			interval: 10,
		},
	],

	findInAllLabels(label) {
		let additional10Y = {
			label: "10Y",
			period: "years",
			interval: 10,
		};
		const dateValues = [...DateSpan.values, additional10Y];
		return dateValues.find((rs) => rs.label === label);
	},
	DEFAULT_DATE_SPAN: "1Y",

	buildArgs(dateSelection) {
		const obj = this.findInAllLabels(dateSelection);
		if (!obj) {
			console.warn(`Couldn't find specified date key: ${dateSelection}`);
			return undefined;
		}

		const startDate = formatter
			.moment()
			.tz("America/New_York")
			.subtract(obj.interval, obj.period)
			.startOf("day")
			.format("YYYY-MM-DD");
		return equalTo("StartDate", [startDate]);
	},
};

export const buildDefaultHoverInfo = () => {
	return [
		...ProviderInfo[ProviderLabels.MORGAN_STANLEY],
		...ProviderInfo[ProviderLabels.MORNINGSTAR],
	];
};
export const buildDefaultFilterState = (overrides = {}) => {
	let ratings = ProviderInfo[ResearchSource.DEFAULT_RESEARCH_SOURCE].find(
		(info) => info.propertyName === "ratings"
	).value;
	let priceTargets = ProviderInfo[ResearchSource.DEFAULT_RESEARCH_SOURCE].find(
		(info) => info.propertyName === "priceTargets"
	).value;
	let dateRange = overrides?.dateSpan ? overrides.dateSpan : DateSpan.DEFAULT_DATE_SPAN;

	return {
		provider: ResearchSource.DEFAULT_RESEARCH_SOURCE,
		dateRange,
		ratings,
		priceTargets,
		...overrides,
	};
};

export const buildFilterState = (provider = ResearchSource.DEFAULT_RESEARCH_SOURCE) => {
	return {
		provider,
		dateRange: DateSpan.DEFAULT_DATE_SPAN,
		ratings: true,
		priceTargets: true,
	};
};

export const mapFiltersToParams = (venueXid, filters, limit = 10, offset = 0) => {
	let { provider, dateRange, ratings, priceTargets } = filters;
	let includeRatings = ratings ? ["1"] : ["0"];
	let includePrice = priceTargets ? ["1"] : ["0"];

	const args = [
		equalTo("WSODIssue", [`${venueXid}`]),
		ResearchSource.buildArgs(provider),
		DateSpan.buildArgs(dateRange),
		equalTo("IncludeRatings", includeRatings),
		equalTo("IncludeTargetPrice", includePrice),
	].filter((x) => x !== undefined);

	return {
		arguments: args,
		sortArgument: {
			direction: "desc",
			field: "DocumentDate",
		},
		limit,
		offset,
	};
};
