import React, { useState, useRef } from "react";
import classNames from "classnames/bind";
import HelpDrawer from "components/Lib/HelpDrawer/HelpDrawer";
import { ReactComponent as QuestionIcon } from "assets/icons/question.svg";
import styles from "./LearnMoreTooltip.module.scss";
export const LearnMoreTooltipData = {
	overlay: "drawer",
	drawerDescription:
		"Right pane helper drawer opened with Morgan Stanley stock rating explanation",
	tooltipTextDescription: "Learn more",
};

const LearnMoreTooltip = () => {
	const [showDrawer, setShowDrawer] = useState(false);

	const drawerButton = useRef(null);
	const handleDrawer = () => {
		drawerButton.current.focus();
		setShowDrawer(!showDrawer);
	};

	return (
		<span className={classNames(styles.header)} aria-hidden={false}>
			<button ref={drawerButton} className="btn btn-link p-0" onClick={handleDrawer}>
				<QuestionIcon aria-hidden="true" className="pl-1" />
				<span className={styles.questionIconText}>
					{LearnMoreTooltipData.tooltipTextDescription}
				</span>
			</button>
			<span id="ms-rating-desc" aria-live="polite" className="sr-only">
				{showDrawer
					? "Right pane helper drawer closed"
					: LearnMoreTooltipData.drawerDescription}
			</span>

			<HelpDrawer show={showDrawer} showHandle={handleDrawer} />
		</span>
	);
};

export default LearnMoreTooltip;
