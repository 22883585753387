import React, { useState, useEffect, useRef } from "react";
import { useQuery } from "react-query";
import styles from "./QuoteSearch.module.scss";
import { URLS } from "../../../utils/constants";
import Form from "react-bootstrap/Form";
import { Typeahead, Menu, MenuItem, Hint } from "react-bootstrap-typeahead";

import XRefAPIUtils from "services/APIs/Xref/XrefAPI";
import UserManagementAPIUtils from "services/APIs/UserManagement/UserManagementAPI";

import Utils from "utils/utils";
import stripUtilityQueryParams from "utils/stripUtilityQueryParams";

const QuoteSearch = React.memo((props) => {
	const typeaheadRef = useRef(null);

	const [inputTerm, setInputTerm] = useState("");
	const [xrefResult, setXrefResult] = useState([]);
	const [recentSearches, setRecentSearches] = useState([]);
	const [selectedItems, setSelectedItems] = useState([]);
	const [focus, setFocus] = useState(false);

	// displayRecentSearch should be changed to false if you are searching for a new symbol.
	const [displayRecentSearch, setDisplayRecentSearch] = useState(true);

	const predictiveSearchQuery = useQuery(
		[URLS.XREF_PREDICTIVE_SEARCH_PATH, props.apiInstance?.internalAuthToken, inputTerm],
		() => XRefAPIUtils.getPredictiveSearchResults(props.apiInstance, inputTerm)
	);

	const fetchRecentSearches = async () => {
		const recentSearches = await UserManagementAPIUtils.getRecentSearch(
			props.apiInstance
		);
		if (recentSearches) setRecentSearches(recentSearches);
	};

	const handleRecentSearches = async (recentSearches) => {
		const response = await UserManagementAPIUtils.setRecentSearch(
			props.apiInstance,
			recentSearches
		);
		if (response) {
			setRecentSearches(recentSearches);
		}
	};

	useEffect(() => {
		fetchRecentSearches();
	}, [props.venueXid, props.apiInstance]);

	const onInputChange = (input) => {
		if (input) {
			setInputTerm(input);
		}
	};

	useEffect(() => {
		const options = [];
		if (selectedItems && selectedItems.length > 0) {
			options.push(...selectedItems);
		}

		if (predictiveSearchQuery.data) {
			options.push(...predictiveSearchQuery.data?.items);
		}

		setXrefResult(options);
		setDisplayRecentSearch(false);
	}, [predictiveSearchQuery.data]);

	const onSelected = (selectedItem) => {
		const tempSelected = [...recentSearches];
		let foundItem = false;
		let itemIndex = 0;
		for (let i = 0; i <= tempSelected.length; i++) {
			//ensure we are not tracking the same symbol selected again.
			if (tempSelected[i]?.symbol === selectedItem[0].symbol) {
				foundItem = true;
				itemIndex = i;
				break;
			}
		}
		if (!foundItem) {
			tempSelected.unshift(selectedItem[0]);
			//recent search list should not exceed 5 symbols
			if (tempSelected.length > 5) {
				tempSelected.pop();
			}
		} else {
			tempSelected.splice(itemIndex, 1);
			tempSelected.unshift(selectedItem[0]);
		}
		setSelectedItems(selectedItem);
		handleRecentSearches(tempSelected);

		const encodedSymbol = encodeURIComponent(selectedItem[0].symbol);
		const queryParams = Utils.replaceQueryParam("symbol", encodedSymbol);
		window.location.href = stripUtilityQueryParams(
			Utils.getQuoteURLBasedOnIssueType(selectedItem[0].classification.name, queryParams)
		);
	};
	/**
	 *  clear input and selection once user selectes search box
	 **/
	const onFocus = () => {
		if (typeaheadRef?.current?.state?.text) {
			typeaheadRef.current.state.text = "";
		}

		setXrefResult(recentSearches);
		setSelectedItems([]);
	};

	const onKeyDown = (event) => {
		if (event.key === "Enter" && xrefResult.length) onSelected(xrefResult);
	};

	const handleFocusEvent = (event) => {
		if (event && event.type === "keyup") {
			// setting keyup event changes on tab only
			if (event.keyCode === 9 || event.keyCode === 16) {
				setFocus(true);
			} else {
				setFocus(false);
			}
		}
	};

	const handleBlurInput = () => {
		setDisplayRecentSearch(true);
		setFocus(false);
	};
	const customInput = ({ inputRef, referenceElementRef, ...inputProps }) => {
		const inputClass = `${focus ? "focusVisible" : "focusReset"}`;
		return (
			<div className={inputClass}>
				<Hint>
					<Form.Control
						{...inputProps}
						ref={(node) => {
							inputRef(node);
							referenceElementRef(node);
						}}
						onClick={() => setFocus(false)}
						onKeyUp={handleFocusEvent}
						onBlur={handleBlurInput}
					/>
				</Hint>
				<svg
					viewBox="-5 -5 24 24"
					className={`bi bi-search ${styles.svgContent}`}
					fill="currentColor"
					xmlns="http://www.w3.org/2000/svg"
					onClick={() => {
						typeaheadRef.current.toggleMenu();
						typeaheadRef.current.focus();
					}}
				>
					<path
						fillRule="evenodd"
						d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"
					/>
					<path
						fillRule="evenodd"
						d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"
					/>
				</svg>
			</div>
		);
	};

	const customMenu = (results, menuProps) => {
		return (
			<Menu {...menuProps}>
				{/* Need to have this conditionaly render only if there is recent search history. Match legacy site behavor*/}
				{<Menu.Header>{displayRecentSearch ? "Recent Searches" : "Symbol"}</Menu.Header>}
				{results.map((result, index) => (
					<MenuItem option={result} position={index} key={index}>
						<div className={styles.menuItems}>
							<div className={styles.symbol}>{result.symbol}</div>
							<div className={styles.wrapContent}>
								{Utils.buildTooltip(
									"quoteSearch-resultName",
									result.name,
									"text",
									result.name
								)}
							</div>
						</div>
					</MenuItem>
				))}
			</Menu>
		);
	};

	return (
		<div className={styles.quoteSearchContainer}>
			<Typeahead
				id="quote-search"
				labelKey={(option) => `${option.name} ${option.symbol}`}
				onChange={onSelected}
				onInputChange={onInputChange}
				onKeyDown={onKeyDown}
				placeholder="Quote Search"
				selected={selectedItems}
				options={xrefResult}
				renderMenu={customMenu}
				renderInput={customInput}
				onFocus={onFocus}
				ref={typeaheadRef}
			/>
		</div>
	);
});

export default QuoteSearch;
