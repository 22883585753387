import React from "react";

import styles from "./MSCheckbox.module.scss";

export default ({ id, checked, onChange, label, className, inputClassName, inline }) => {
	return (
		<div
			className={`${styles.msCheckbox} ${className ? className : ""} ${
				inline ? styles.inline : ""
			}`}
		>
			<input
				id={id}
				type="checkbox"
				checked={checked}
				onChange={onChange}
				className={inputClassName}
			/>
			<label htmlFor={id}>
				<span /> {label}
			</label>
		</div>
	);
};
