import React from "react";
import ChartistGraph from "react-chartist";
import ChartPointLabels from "./Plugin/ChartPointLabels";
import ChartStripeBar from "./Plugin/ChartStripeBar";
import ChartResizeLineChart from "./Plugin/ChartResizeLineChart";
import PropTypes from "prop-types";
import "./FinancialChart.scss";
import filterEmptyDataWithoutHeader from "utils/financialsEmptyColumns";

export default class FinancialChart extends React.Component {
	_mounted = false;
	static propTypes = {
		labels: PropTypes.array.isRequired,
		barChartSeries: PropTypes.array.isRequired,
		lineChartSeries: PropTypes.array.isRequired,
		legendNames: PropTypes.array,
	};
	constructor(props) {
		super(props);
		this.state = {
			lineChartPadding: false,
			chartGuid: (+new Date() * Math.random()).toString(36).substring(0, 5),
		};
	}
	getMinMaxOf2DIndex = (arr) => {
		return {
			max: Math.max.apply(
				null,
				arr.map((row) => {
					return Math.max.apply(Math, row);
				})
			),
			min: Math.min.apply(
				null,
				arr.map((row) => {
					return Math.min.apply(Math, row);
				})
			),
		};
	};

	getSeriesManipulativeHighLow = () => {
		let maxMinSeries = this.getMinMaxOf2DIndex(this.props.barChartSeries);
		let yAxisMaxValue = document.getElementById(this.state.chartGuid);
		if (yAxisMaxValue) {
			yAxisMaxValue = yAxisMaxValue.getAttribute("yaxismaxvalue");
			maxMinSeries.max = parseInt(yAxisMaxValue);
		}
		return maxMinSeries;
	};
	getBarChartXAxisLeft = () => {
		let xAxis = document.getElementById(this.state.chartGuid);
		if (xAxis) {
			xAxis = xAxis.getAttribute("startPoint");
			return parseInt(xAxis) + 10; // offset in order to bring in between both bars
		}
		return null;
	};

	componentDidMount() {
		this._mounted = true;
		window.addEventListener("resize", () => this.updateLineChartPadding());
		setTimeout(
			() =>
				this._mounted &&
				this.setState({ lineChartPadding: !this.state.lineChartPadding }),
			50
		);
	}

	componentWillUnmount() {
		this._mounted = false;
		var element = document.getElementById(this.state.chartGuid);
		if (!element) {
			return;
		}
		element.parentNode.removeChild(element);
		window.removeEventListener("resize", () => this.updateLineChartPadding());
	}

	updateLineChartPadding = () => {
		setTimeout(
			() =>
				this._mounted &&
				this.setState({ lineChartPadding: !this.state.lineChartPadding }),
			50
		);
	};
	render() {
		let obj = filterEmptyDataWithoutHeader.filterEmptyDataWithoutHeader(
			[...this.props.barChartSeries, ...this.props.lineChartSeries],
			this.props.labels.length,
			this.props.labels
		);
		let barChartData = {
			labels: obj.headersData,
			series: obj.rowsData.slice(0, this.props.barChartSeries.length),
		};
		let lineChartData = {
			labels: obj.headersData,
			series: !this.props.isCashFlow ? [obj.rowsData[2]] : [],
		};

		//get max min of barChartSeries
		let optionsBarChart = {
			height: "250px",
			seriesBarDistance: 20,
			divisor: 5,
			chartPadding: {
				top: 10,
				right: 0,
				bottom: 5,
				left: 0,
			},
			axisX: {
				showGrid: false,
				position: "end",
				labelInterpolationFnc: function (value, index) {
					return value;
				},
				labelOffset: {
					x: 0,
					y: 0,
				},
			},
			axisY: {
				position: "left",
				showGrid: true,
				offset: this.props.isMobile ? 40 : 55,
				labelInterpolationFnc: (value, index) => {
					if (this?.props?.barSeriesCurrency === "USD") {
						return value >= 0 ? "$" + value + "B" : "-$" + Math.abs(value) + "B";
					} else {
						return value >= 0 ? value + "B" : Math.abs(value) + "B";
					}
				},
				labelOffset: {
					x: -10,
					y: 0,
				},
			},
			plugins: [
				ChartStripeBar({
					tooltip: {
						legendNames: this.props.legendNames,
						chartGuid: this.state.chartGuid,
					},
				}),
				ChartResizeLineChart({ chartGuid: this.state.chartGuid }),
			],
		};
		let maxMinLabelSeries = this.getMinMaxOf2DIndex(this.props.lineChartSeries);

		let lineChartMin = 0;
		let lineChartMax = 100;
		if (!this.props.isCashFlow) {
			lineChartMax = Math.ceil((maxMinLabelSeries.max + 1) / 10) * 10;
			if (maxMinLabelSeries.min < 0 && maxMinLabelSeries.max < 0) {
				lineChartMin = maxMinLabelSeries.min;
				lineChartMax = maxMinLabelSeries.max;
			} else if (maxMinLabelSeries.min > 0 && maxMinLabelSeries.min < 15) {
				lineChartMin = 0;
			} else {
				lineChartMin = parseInt(maxMinLabelSeries.min);
			}
		} else {
			maxMinLabelSeries = this.getMinMaxOf2DIndex(
				this.props.lineChartSeries.concat(this.props.barChartSeries)
			);
			lineChartMin = maxMinLabelSeries.min;
			lineChartMax = maxMinLabelSeries.max;
			optionsBarChart.high = maxMinLabelSeries.max;
			optionsBarChart.low = maxMinLabelSeries.min;
		}
		let optionsLineChart = {
			high: lineChartMax,
			low: lineChartMin,
			axisX: {
				showGrid: false,
				showLabel: false,
				labelInterpolationFnc: function (value, index) {
					return value;
				},
			},
			axisY: {
				position: "left",
				showGrid: false,
				showLabel: false,
				labelInterpolationFnc: function (value, index) {
					return "$" + value + "B";
				},
			},
			fullWidth: true,
			chartPadding: {
				top: 20,
				right:
					this.getBarChartXAxisLeft() +
						(this.props.isMobile
							? this.props.barChartOptions &&
							  this.props.barChartOptions.axisY &&
							  this.props.barChartOptions.axisY.offset === 50
								? 5
								: -5
							: this.props.barChartOptions &&
							  this.props.barChartOptions.axisY &&
							  this.props.barChartOptions.axisY.offset === 65
							? 20
							: 10) || 60,
				bottom:
					maxMinLabelSeries.min < 0 && !this.state.isMobile && !this.props.isCashFlow
						? 20
						: 30,
				left: this.getBarChartXAxisLeft() || 60,
			},
			lineSmooth: false,
			plugins: [
				ChartPointLabels(
					Object.assign(
						{},
						{
							textAnchor: "middle",
							labelOffset: {
								x: -3,
								y: -10,
							},
							labelInterpolationFnc: function (value) {
								return value.toFixed(2) + "%";
							},
							showPointLabel: true,
							chartGuid: this.state.chartGuid,
							labelCount: this.props.labels.length,
						},
						this.props.pointLabelOptions || {}
					)
				),
			],
		};
		if (this.props.barChartOptions)
			optionsBarChart = Object.assign(
				{},
				optionsBarChart,
				this.props.barChartOptions || {}
			);
		if (this.props.lineChartOptions)
			optionsLineChart = Object.assign(
				{},
				optionsLineChart,
				this.props.lineChartOptions || {}
			);

		return (
			<div className="financial-chart-container">
				<div className="ct-legend">
					{this.props.legendNames.map((x, index) => {
						if (index === 2 && !this.props.isCashFlow) {
							return (
								<div
									className={"ct-series-point-" + index}
									key={"ct-series-point-" + index}
								>
									<span className="ct-series-point-legend"></span>
									{x}
								</div>
							);
						}
						return (
							<div className={"ct-series-" + index} key={"ct-series-" + index}>
								{x}
							</div>
						);
					})}
				</div>
				<div className="financial-chart">
					<ChartistGraph
						data={barChartData}
						options={optionsBarChart}
						type={"Bar"}
						className={`financial-bar-chart ${this.props.statementType}`}
					/>
					{!this.props.isCashFlow && (
						<ChartistGraph
							data={lineChartData}
							options={optionsLineChart}
							type={"Line"}
							className={`financial-line-chart ${this.props.statementType}`}
						/>
					)}
				</div>
			</div>
		);
	}
}
