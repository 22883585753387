import React, { useState, useRef } from "react";
import { Dropdown } from "react-bootstrap";
import CirclePlus from "components/Lib/Icons/CirclePlus/CirclePlus";
import INDICATOR_CONSTANTS from "../../Indicators";
import AutoCompleteIndicatorSearch from "../AutoCompleteIndicatorSearch/AutoCompleteIndicatorSearch";
import styles from "../../ChartingCard/ChartingCard.module.scss";
import AdvanceChartConstant from "components/MarketsAndResearch/ChartingCommon/Constants/Chart/AdvanceChart";
import AvailableIndicatorFilter from "../AvailableIndicatorFilterExtension";
import { removeFocusfromMenu } from "../../../ChartingCommon/Utility/CommonChartUtility";

const IndicatorMenu = (props) => {
	const [indicatorsView, setIndicatorsView] = useState("UPPER_LOWER");
	const [indicators, setIndicators] = useState(INDICATOR_CONSTANTS.UPPER_LOWER);
	const [isSearched, setIsSearched] = useState(false);
	const [onFocus, callOnFocus] = useState(null);
	const [isShown, setIsShown] = useState(false);
	const dropdownRef = useRef();

	const handleIndicatorsView = () => {
		let newView = "UPPER_LOWER";
		if (indicatorsView === "UPPER_LOWER") {
			newView = "STUDIES";
			setIndicators(INDICATOR_CONSTANTS.STUDIES);
		} else {
			setIndicators(INDICATOR_CONSTANTS.UPPER_LOWER);
		}

		setIndicatorsView(newView);
		callOnFocus(() => () => 0);
	};

	const onToggleCallback = (isOpen, e, metadata) => {
		// Do not close the dropdown when a non-selectable item is selected
		// (i.e. symbol search, UI button)
		// use AdvanceChartConstant.INDICATOR_LIMITS.UPPER for upper limit
		// using 6 as threshold as pricechart indicator is added by default
		if (
			props.updateError.indicatorsCount >= AdvanceChartConstant.INDICATOR_LIMITS.UPPER &&
			props.updateError.errorVisible
		) {
			setIsShown(props.updateError.errorVisible);
			if (!props.errorMSG) {
				if (metadata?.source === "rootClose") closeDropdown();
				return;
			}
		}
		checkError(isOpen, e, metadata);

		if (e && e.detail === 1 && metadata?.source === "select")
			removeFocusfromMenu(dropdownRef.current);
	};
	const checkError = (isOpen, e, metadata) => {
		if (metadata?.source === "rootClose") closeDropdown();
		else if (
			metadata?.source !== "select" ||
			!e?.currentTarget?.classList?.contains("nonselectable")
		) {
			if (!isOpen && props.updateError.errorVisible) setIsShown(true);
			else setIsShown(isOpen);
		}
	};
	const closeDropdown = () => {
		setIsShown(false);
		props.resetErrorMSG();
	};

	const onSelectCallback = (eventKey, event) => {
		// If a button group is selected, click on the active button
		if (event?.currentTarget?.classList?.contains("buttongroup")) {
			event.currentTarget.querySelector("button:not([disabled])").click();
			// If a search box is selected, focus into it so the user can start searching
		} else if (event?.currentTarget?.classList?.contains("search")) {
			event.currentTarget.querySelector("input").focus();
		}
	};

	const renderDropdownItem = (
		index,
		groupLabel,
		indicatorLabel,
		value,
		panel,
		clickHandler
	) => {
		return (
			<Dropdown.Item
				key={`${groupLabel}-${indicatorLabel}-${index}`}
				data-value={value}
				data-panel={panel}
				onClick={clickHandler}
			>
				{indicatorLabel}
			</Dropdown.Item>
		);
	};

	return (
		<Dropdown
			drop="down"
			show={isShown}
			ref={dropdownRef}
			onToggle={onToggleCallback}
			onSelect={onSelectCallback}
			id={"indicators-dropdown"}
		>
			<Dropdown.Toggle id="indicatorsDropdownToggle" className={styles.dropdownToggle}>
				<CirclePlus /> Indicators
			</Dropdown.Toggle>
			<Dropdown.Menu className={`${styles.indicatorsDropdown} ${styles.dropdown}`}>
				<Dropdown.ItemText
					className={`${styles.nonSelectableItem} ${styles.autoCompleteItem} nonselectable search`}
					aria-describedby={"indicatorSearchDescription"}
				>
					<p id="indicatorSearchDescription" className="sr-only">
						{"Select this item to filter the available indicators."}
					</p>
					<AutoCompleteIndicatorSearch
						onFocus={onFocus}
						isSearched={isSearched}
						isSearchOnChange={setIsSearched}
						indicators={indicators}
						closeDropdown={onToggleCallback}
						{...props}
					/>
				</Dropdown.ItemText>
				{!isSearched && (
					<Dropdown.ItemText
						className={`${styles.indicatorsView} ${styles.nonSelectableItem} nonselectable buttongroup`}
						aria-describedby={"indicatorButtonGroupDescription"}
					>
						<p id="indicatorButtonGroupDescription" className="sr-only">
							{
								"Select this item to toggle how the available indicators are grouped below.  Switches between Upper/Lower and Studies, where Upper/Lower is the default view."
							}
						</p>
						View By:
						<button
							disabled={indicatorsView === "UPPER_LOWER"}
							onClick={handleIndicatorsView}
							className={`btn btn-link ${
								indicatorsView === "UPPER_LOWER" ? "disabled" : ""
							}`}
						>
							Upper/Lower
						</button>{" "}
						|
						<button
							disabled={indicatorsView === "STUDIES"}
							onClick={handleIndicatorsView}
							className={`btn btn-link ${indicatorsView === "STUDIES" ? "disabled" : ""}`}
						>
							Type
						</button>
					</Dropdown.ItemText>
				)}

				{!isSearched &&
					indicators &&
					Object.keys(indicators).length > 0 &&
					Object.keys(indicators).map((key, indexOuter) => {
						const group = indicators[key];

						return (
							<React.Fragment key={`${group.label}-${indexOuter}`}>
								{AvailableIndicatorFilter() &&
									group.items.some((f) => {
										return f.isProductionReady === true;
									}) && (
										<Dropdown.ItemText
											className={`${styles.dropdownText} ${
												indexOuter === 0 ? "border-top-0 mt-0" : ""
											}`}
										>
											{group.label}
										</Dropdown.ItemText>
									)}
								{!AvailableIndicatorFilter() && (
									<Dropdown.ItemText
										className={`${styles.dropdownText} ${
											indexOuter === 0 ? "border-top-0 mt-0" : ""
										}`}
									>
										{group.label}
									</Dropdown.ItemText>
								)}
								{AvailableIndicatorFilter() &&
									group.items
										.filter((f) => {
											return f.isProductionReady === true;
										})
										.map((prod_indicator, indexInner) =>
											renderDropdownItem(
												indexInner,
												group.label,
												prod_indicator.label,
												prod_indicator.value,
												prod_indicator.panel,
												props.onClick
											)
										)}
								{!AvailableIndicatorFilter() &&
									group.items.map((indicator, indexInner) =>
										renderDropdownItem(
											indexInner,
											group.label,
											indicator.label,
											indicator.value,
											indicator.panel,
											props.onClick
										)
									)}
							</React.Fragment>
						);
					})}
			</Dropdown.Menu>
		</Dropdown>
	);
};

export default IndicatorMenu;
