import React from "react";

const DotChart = () => {
	return (
		<svg
			aria-hidden="true"
			focusable="false"
			className="dotChart"
			width="14px"
			height="15px"
			viewBox="0 0 14 15"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g
				id="Desktop---Interactive-States"
				stroke="none"
				strokeWidth="1"
				fillRule="evenodd"
			>
				<g
					id="07_MSO-Company-Overview-1440-Charting-Module-States"
					transform="translate(-989.000000, -3348.000000)"
				>
					<g id="Dot" transform="translate(32.000000, 3015.000000)">
						<g id="Group-19" transform="translate(958.896440, 334.158100)">
							<circle id="Oval-Copy-16" cx="1" cy="7" r="1"></circle>
							<circle id="Oval-Copy-17" cx="4" cy="11" r="1"></circle>
							<circle id="Oval-Copy-18" cx="7" cy="5" r="1"></circle>
							<circle id="Oval-Copy-19" cx="10" cy="1" r="1"></circle>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default DotChart;
