import React from "react";

import { default as NextPage } from "components/Lib/Icons/Right/Right";
import { default as PreviousPage } from "components/Lib/Icons/Left/Left";
import { default as SkipToBeginning } from "components/Lib/Icons/SkipToEndLeft/SkipToEndLeft";
import { default as SkipToEnd } from "components/Lib/Icons/SkipToEndRight/SkipToEndRight";
import formatter from "utils/formatUtils";

import styles from "./PaginationFooter.module.scss";
import en from "../../../assets/language/en";

const PaginationFooter = React.memo((props) => {
	const handleClick = (event, behavior) => {
		event.preventDefault();
		if (!behavior) {
			behavior = event.target.getAttribute("data-value");
		}
		if (props.arrowClickCallback) {
			props.arrowClickCallback(behavior);
		}
	};

	const handleEnter = (event) => {
		if (event.charCode === 13) {
			event.preventDefault();
			const behavior = event.target.getAttribute("data-value");

			if (props.arrowClickCallback) {
				props.arrowClickCallback(behavior);
			}
		}
	};

	const language = props.language ? props.language : en;
	const isFirstPage = parseInt(props.page) === 1;
	const isLastPage = parseInt(props.page) === parseInt(props.totalPages);

	return (
		<nav
			aria-label={props.ariaLabel}
			className={`${styles.paginationFooter} ${props.className ? props.className : ""}`}
		>
			<div className={styles.itemCounter}>
				{`${props.startingItem} ${language.TO} ${props.endingItem} ${language.OF} ${props.totalItems}`}
			</div>
			{!props.disableSkipToBeginning && (
				<div
					className={`${styles.paginationNav} ${isFirstPage ? "" : styles.cursorPointer}`}
					data-value="skipToBeginning"
					aria-label={language.SKIP_TO_BEGINNING}
					tabIndex="0"
					onClick={(e) => handleClick(e, "skipToBeginning")}
					onKeyPress={handleEnter}
				>
					<SkipToBeginning
						onClick={(e) => handleClick(e, "skipToBeginning")}
						dataValue="skipToBeginning"
						ariaLabel={language.SKIP_TO_BEGINNING}
						color={isFirstPage && "#ccc"}
					/>
				</div>
			)}
			<div
				className={`${styles.paginationNav} ${isFirstPage ? "" : styles.cursorPointer}`}
				data-value="previousPage"
				aria-label={language.PREVIOUS_PAGE}
				tabIndex="0"
				onClick={(e) => handleClick(e, "previousPage")}
				onKeyPress={handleEnter}
			>
				<PreviousPage
					onClick={(e) => handleClick(e, "previousPage")}
					tabIndex="0"
					dataValue="previousPage"
					ariaLabel={language.PREVIOUS_PAGE}
					color={isFirstPage && "#ccc"}
				/>
			</div>
			<div className={styles.pageCounter} aria-current={props.page} aria-live="polite">
				{`${formatter.number(props.page, 0)} ${language.OF} ${formatter.number(
					props.totalPages,
					0
				)}`}
			</div>
			<div
				className={`${styles.paginationNav} ${isLastPage ? "" : styles.cursorPointer}`}
				data-value="nextPage"
				aria-label={language.NEXT_PAGE}
				tabIndex="0"
				onClick={(e) => handleClick(e, "nextPage")}
				onKeyPress={handleEnter}
			>
				<NextPage
					onClick={(e) => handleClick(e, "nextPage")}
					tabIndex="0"
					dataValue="nextPage"
					ariaLabel={language.NEXT_PAGE}
					color={isLastPage && "#ccc"}
				/>
			</div>
			{!props.disableSkipToEnd && (
				<div
					className={`${styles.paginationNav} ${isLastPage ? "" : styles.cursorPointer}`}
					data-value="skipToEnd"
					aria-label={language.SKIP_TO_END}
					tabIndex="0"
					onClick={(e) => handleClick(e, "skipToEnd")}
					onKeyPress={handleEnter}
				>
					<SkipToEnd
						onClick={(e) => handleClick(e, "skipToEnd")}
						tabIndex="0"
						dataValue="skipToEnd"
						ariaLabel={language.SKIP_TO_END}
						color={isLastPage && "#ccc"}
					/>
				</div>
			)}
		</nav>
	);
});

export default PaginationFooter;
