import * as React from "react";
import ChartistGraph from "react-chartist";
import "./TrendChart.style.scss";
export default class Cell extends React.Component {
	render() {
		let optionsBarChart = {
			seriesBarDistance: 5,
			chartPadding: {
				top: 0,
				right: 0,
				bottom: 0,
				left: 0,
			},
			showLabel: false,
			axisX: {
				showGrid: false,
				showLabel: false,
				offset: 0,
			},
			axisY: {
				showLabel: false,
				showGrid: false,
				offset: 0,
			},
		};

		const { content, header, className, IsChart } = this.props;
		return header ? (
			<th className={className}>
				<div dangerouslySetInnerHTML={{ __html: content }} />
			</th>
		) : (
			<td className={className}>
				{IsChart ? (
					<div className="graph_container">
						<ChartistGraph
							data={{ series: [content] }}
							options={optionsBarChart}
							type={"Bar"}
							className={"data_table_chart"}
						/>
					</div>
				) : (
					content
				)}
			</td>
		);
	}
}
