import Indicators from "components/MarketsAndResearch/Charting/Indicators";
import React from "react";
const IndicatorAccessibility = (props) => {
	const chartData = props.chartData;
	let indArr = props.selectedIndicators.map((ind) => ind.id);
	function isSubArray(A, B) {
		B.forEach((elem) => {
			if (!A.includes(elem)) return false;
		});
		return true;
	}
	return (
		<div>
			{Indicators.PRICE.column &&
				chartData.series &&
				chartData.columns.includes("diff") && (
					<table className="sr-only">
						<caption>Price chart data</caption>
						<thead>
							<tr>
								<th scope="col">date</th>
								{Indicators.PRICE.column.map((column, index) => (
									<th scope="col" key={index}>
										{column}
									</th>
								))}
							</tr>
						</thead>
						<tbody>
							{Object.keys(chartData.series).map((keyName, index) => {
								const data = chartData.series[keyName];
								return (
									<tr key={index}>
										<td>{keyName}</td>
										<td>{data.close}</td>
										<td>{data.diff}</td>
										<td>{data.high}</td>
										<td>{data.hollow}</td>
										<td>{data.low}</td>
										<td>{data.open}</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				)}
			{chartData.series &&
				indArr.map((indKey, index) => {
					if (
						Indicators.INDICATOR_ACCESSIBILITY.includes(indKey) &&
						isSubArray(chartData.columns, Indicators.DICTIONARY[indKey].column)
					) {
						return (
							<table className="sr-only" key={index}>
								<caption>{Indicators.DICTIONARY[indKey].label}</caption>
								<thead>
									<tr>
										<th scope="col">date</th>
										{Indicators.DICTIONARY[indKey].column.map((column, index) => (
											<th scope="col" key={index}>
												{column}
											</th>
										))}
									</tr>
								</thead>
								<tbody>
									{Object.keys(chartData.series).map((keyName, index) => (
										<tr key={index}>
											<td>{keyName}</td>
											{Indicators.DICTIONARY[indKey].column.map((column, index) => (
												<td key={index}>{chartData.series[keyName][column]}</td>
											))}
										</tr>
									))}
								</tbody>
							</table>
						);
					} else {
						return <></>;
					}
				})}
		</div>
	);
};
export default IndicatorAccessibility;
