import React, { Component } from "react";

import ResearchAPIUtils from "services/APIs/Research/ResearchAPI";

import AllResearchCard from "../../../components/MarketsAndResearch/AnalystResearch/AllResearchCard/AllResearchCard";
import Disclosures from "components/Lib/Disclosures/Disclosures";
import {
	buildDefaultFilterState,
	DateSpan,
} from "../../MarketsAndResearch/AnalystResearch/AllResearchCard/ResearchAPIParams";
import ResearchAppUtils from "./ResearchApp.utils";
import Utils from "utils/utils";

import languageEn from "assets/language/en";

class ResearchApp extends Component {
	constructor(props) {
		super(props);
		this.state = {
			symbol: null,
			venueXid: null,
			userInfo: null,
			language: null,
			locale: null,
			filterData: buildDefaultFilterState(),
			legacyCompany: null,
			researchDocumentsData: null,
			hasAllResearchDocumentsData: false,
			researchOffset: 0,
			allResearchCardHeightChanged: false,
			allResearchCardHeight: 0,
		};
		this.PAGE_LIMIT = 10;
		this.setResearchOffsetCallback = this.setResearchOffsetCallback.bind(this);
		this.onFilterChange = this.onFilterChange.bind(this);
		this.onDatePickerDateChange = this.onDatePickerDateChange.bind(this);
	}

	componentDidMount() {
		this.setLanguage();
		this.fetchResearchDocuments();
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.apiInstance?.internalAuthToken !==
			this.props.apiInstance?.internalAuthToken
		) {
			this.fetchResearchDocuments();
		}

		if (this.isStateUpdated(prevState, "filterData") && !this.state.venueXid) {
			this.fetchResearchDocuments();
		}
		if (this.isStateUpdated(prevState, "researchOffset") && !this.state.venueXid) {
			this.fetchResearchDocuments();
		}
		if (this.isStateUpdated(prevState, "symbol")) {
			this.setState({ researchOffset: 0 });
		}
		// Sets a new card height when there is new research data. Requires a delay to get correct height.
		if (
			this.state.allResearchCardHeight !==
			document.getElementById("allResearchCardHeight")?.clientHeight
		) {
			setTimeout(() => {
				this.setState({
					allResearchCardHeight: document.getElementById("allResearchCardHeight")
						?.clientHeight,
				});
			}, 250);
		}

		/*
		When user clicks on pagination it sets allResearchCardHeightChanged to true.
		That then allows the page to scroll up or down based on the difference between
		the All Research Card height that was loss or gained.
		*/
		if (
			this.isStateUpdated(prevState, "allResearchCardHeight") &&
			this.state.allResearchCardHeightChanged
		) {
			let prev = prevState.allResearchCardHeight;
			let curr = this.state.allResearchCardHeight;
			if (prev > curr) {
				let heightLoss = -(prev - curr);
				Utils.postScrollBy(0, heightLoss);
			} else {
				let heightGrowth = curr - prev;
				Utils.postScrollBy(0, heightGrowth);
			}
			this.setState({ allResearchCardHeightChanged: false });
		}
	}

	isPropUpdated(prevProps, verificationProp) {
		if (!prevProps) {
			return false;
		}

		return prevProps[verificationProp] !== this.props[verificationProp];
	}

	isStateUpdated(prevState, verificationState) {
		if (!prevState) {
			return false;
		}
		return prevState[verificationState] !== this.state[verificationState];
	}

	// obtain url query paramater for language translation (assumption is there will always be a lang parameter passed to the application)
	setLanguage() {
		let lang = ResearchAppUtils.getQueryParam(this.props.location.search, "lang");
		switch (lang) {
			case "fr":
				import("../../../assets/language/fr").then((data) =>
					this.setState({ language: data.default })
				);
				import("date-fns/locale/fr").then((data) =>
					this.setState({ locale: data.default })
				);
				break;
			case "en":
			default:
				import("../../../assets/language/en").then((data) =>
					this.setState({ language: data.default })
				);
				import("date-fns/locale/en-US").then((data) =>
					this.setState({ locale: data.default })
				);
		}
	}

	setStateCallback = (symbol, venueXid, legacyCompany) => {
		this.setState({
			symbol,
			venueXid,
			legacyCompany,
		});
	};

	setFilterDataCallback = (filterData) => {
		this.setState({ filterData });
	};

	onDatePickerDateChange = (startDate, endDate) => {
		this.setState({
			filterData: {
				...this.state.filterData,
				dateSpan: DateSpan.CUSTOM_DATE_ID,
				startDate: startDate,
				endDate: endDate,
			},
		});
	};

	setResearchOffsetCallback = (nextOffset) => {
		this.setState({ researchOffset: nextOffset });
		this.setState({ allResearchCardHeightChanged: true });
	};

	clearFilterCallback = () => {
		this.setState({
			symbol: null,
			venueXid: null,
			filterData: buildDefaultFilterState(),
			legacyCompany: null,
			researchDocumentsData: null,
			hasAllResearchDocumentsData: false,
			researchOffset: 0,
		});
	};

	onFilterChange(fieldOrObject, value) {
		let updatedFields = fieldOrObject;
		if (typeof fieldOrObject === "string" || fieldOrObject instanceof String) {
			updatedFields = { ...this.state.filterData, [fieldOrObject]: value };
		}
		// Update state of filter data and reset the offset back to 0 on filter changes
		this.setState({ filterData: updatedFields, researchOffset: 0 });
	}

	async fetchResearchDocuments() {
		const {
			venueXid,
			legacyCompany,
			filterData,
			hasAllResearchDocumentsData,
			researchOffset,
		} = this.state;

		const researchDocumentsData = await ResearchAPIUtils.getResearchDocuments(
			this.props.apiInstance,
			venueXid,
			filterData,
			this.PAGE_LIMIT,
			researchOffset,
			legacyCompany,
			venueXid ? false : true
		);
		if (!hasAllResearchDocumentsData) {
			this.setState({
				hasAllResearchDocumentsData: !hasAllResearchDocumentsData,
				researchDocumentsData,
			});
		} else {
			this.setState({ researchDocumentsData });
		}
	}

	render() {
		return (
			<div className="canaccord-research">
				<div>
					<AllResearchCard
						apiInstance={this.props.apiInstance}
						symbol={this.state.symbol}
						venueXid={this.state.venueXid}
						legacyCompany={this.state.legacyCompany}
						userInfo={this.state.userInfo}
						title={"Market Research"}
						client={"CG"}
						setStateCallback={this.setStateCallback}
						setFilterDataCallback={this.setFilterDataCallback}
						setResearchOffsetCallback={this.setResearchOffsetCallback}
						clearFilterCallback={this.clearFilterCallback}
						onFilterChange={this.onFilterChange}
						onDatePickerDateChange={this.onDatePickerDateChange}
						language={this.state.language}
						locale={this.state.locale}
						filterData={this.state.filterData}
						researchDocumentsData={this.state.researchDocumentsData}
						hasAllResearchDocumentsData={this.state.hasAllResearchDocumentsData}
						researchOffset={this.state.researchOffset}
					/>
					<Disclosures
						disclosures={"canacordAnalystResearch"}
						language={this.state.language ? this.state.language : languageEn}
					/>
				</div>
			</div>
		);
	}
}

export default ResearchApp;
