import Chartist from "chartist";

function ChartStripeBar(options) {
	let defaultOptions = {
		maskStripe: {
			rect: {
				width: "100%",
				height: "100%",
			},
		},
		patternStripe: {
			width: "5",
			height: "5",
			patternTransform: "rotate(135)",
			line: {
				height: "5",
			},
		},
	};

	function createMaskWithBorder(data, options) {
		// Select the defs element within the chart or create a new one
		var defs = data.svg.querySelector("defs") || data.svg.elem("defs");

		// Create pattern for stripe
		defs
			.elem("pattern", {
				patternUnits: "userSpaceOnUse",
				patternTransform: options.patternStripe.patternTransform,
				width: options.patternStripe.width,
				height: options.patternStripe.height,
				id: "pattern-stripe",
			})
			.elem("line", {
				class: "pattern-stripe-line",
				y2: options.patternStripe.line.height,
			});
		return defs;
	}
	return function ctStripeBar(chart) {
		options = Chartist.extend({}, defaultOptions, options);

		if (chart instanceof Chartist.Bar) {
			chart.on("draw", function (data) {
				//not a nice check as second series will be converted to stripe bar
				if (data.type === "bar" && data.seriesIndex === 1) {
					let yAxisPoint = data.series[data.index] < 0 ? data.y1 : data.y2;
					const whiteBar = new Chartist.Svg(
						"line",
						{
							x1: data.x1 + 2,
							x2: data.x1 + 2,
							y1: data.y1,
							y2: data.y2,
						},
						"white-bar"
					);
					data.element.replace(whiteBar);
					let rect = new Chartist.Svg(
						"rect",
						{
							x: [data.x1 - 5],
							y: [yAxisPoint],
							height: [Math.abs(data.y1 - data.y2)],
							width: [15],
							"ct:value": data.series[data.index],
						},
						"hbar"
					);
					data.group.append(rect);
				} else if (data.type === "bar" && data.seriesIndex === 2) {
					let yAxisPoint = data.series[data.index] < 0 ? data.y1 : data.y2;
					const whiteBar = new Chartist.Svg(
						"line",
						{
							x1: data.x1 + 2,
							x2: data.x1 + 2,
							y1: data.y1,
							y2: data.y2,
						},
						"white-bar"
					);
					data.element.replace(whiteBar);
					let rect = new Chartist.Svg(
						"rect",
						{
							x: [data.x1 - 5],
							y: [yAxisPoint],
							height: [Math.abs(data.y1 - data.y2)],
							width: [15],
							"ct:value": data.series[data.index],
						},
						"wbar"
					);
					data.group.append(rect);
				}
			});
			// On the created event, create the mask definition used to mask the bar graphs
			chart.on("created", function (data) {
				createMaskWithBorder(data, options);
			});
		}
	};
}

export default ChartStripeBar;
