import Utils from "utils/utils";
import { URLS } from "utils/constants";
import formatter from "utils/formatUtils";

/**
 * Utility for fetching API data for quote for a single xid
 * Uses the same API as multiquote, but is structured to only
 * pass one xid and process the results for a single result
 * @param {number} venueXid - the Xid for the symbol selected
 */
export const getSingleQuoteDetails = async ({
	apiInstance,
	venueXid,
	marketHoursTradeType = "normal",
	isRealTimeQuote = false,
}) => {
	if (!apiInstance || !venueXid || !marketHoursTradeType) {
		return {};
	}

	const quoteData = {};
	try {
		const response = await apiInstance.get(
			`${URLS.QUOTE_PATH}?venueXids=${venueXid}&marketHoursTradeType=${marketHoursTradeType}`,
			{
				headers: {
					realtime: isRealTimeQuote,
				},
			}
		);

		const firstQuoteResponse = response?.data?.data?.quotes?.length
			? response.data.data.quotes[0]?.data
			: null;
		// Construct output object
		if (firstQuoteResponse) {
			// Price
			quoteData.lastPrice = formatter.price(
				Utils.getObjectPropertyValue(firstQuoteResponse, "lastTrade.last")
			);

			quoteData.lastTradeSize = formatter.number(
				Utils.getObjectPropertyValue(firstQuoteResponse, "lastTrade.size"),
				0
			);

			quoteData.open = formatter.price(
				Utils.getObjectPropertyValue(firstQuoteResponse, "lastTrade.open")
			);
			quoteData.priceChange = Utils.getObjectPropertyValue(
				firstQuoteResponse,
				"lastTrade.change"
			);
			quoteData.oneYearPriceChange = formatter.price(
				Utils.getObjectPropertyValue(firstQuoteResponse, "changeAmount.oneYear"),
				2,
				true
			);
			quoteData.percentChange = Utils.getObjectPropertyValue(
				firstQuoteResponse,
				"changePercent.today"
			);
			quoteData.oneYearPercentChange = formatter.percent(
				Utils.getObjectPropertyValue(firstQuoteResponse, "changePercent.oneYear"),
				2,
				{ percentModifier: 0.01, showSign: true }
			);
			quoteData.close = formatter.number(
				Utils.getObjectPropertyValue(firstQuoteResponse, "lastTrade.close"),
				2
			);
			quoteData.dayLow = formatter.price(
				Utils.getObjectPropertyValue(firstQuoteResponse, "lastTrade.low")
			);
			quoteData.dayHigh = formatter.number(
				Utils.getObjectPropertyValue(firstQuoteResponse, "lastTrade.high"),
				2
			);
			quoteData.yearLow = formatter.price(
				Utils.getObjectPropertyValue(firstQuoteResponse, "price52Week.low")
			);
			quoteData.yearHigh = formatter.number(
				Utils.getObjectPropertyValue(firstQuoteResponse, "price52Week.high"),
				2
			);

			/* as specified in Quote API docs
			 *  Number of shares reported outstanding for security, provided in thousands
			 */
			quoteData.sharesOutstanding = formatter.numberMagnitude(
				Utils.getObjectPropertyValue(firstQuoteResponse, "sharesOutstanding") * 1000,
				3
			);

			quoteData.todaysChange = `${formatter.price(
				quoteData.priceChange
			)} (${formatter.percent(quoteData.percentChange, 2, {
				percentModifier: 0.01,
			})})`;

			// quoteData.
			quoteData.vwap = formatter.price(
				Utils.getObjectPropertyValue(firstQuoteResponse, "vwap")
			);

			// Options
			quoteData.bid = formatter.price(
				Utils.getObjectPropertyValue(firstQuoteResponse, "bid.price")
			);
			quoteData.ask = formatter.price(
				Utils.getObjectPropertyValue(firstQuoteResponse, "ask.price")
			);
			quoteData.bidSize = Utils.getObjectPropertyValue(firstQuoteResponse, "bid.size");
			quoteData.askSize = Utils.getObjectPropertyValue(firstQuoteResponse, "ask.size");

			// Volume
			quoteData.volume = formatter.number(
				Utils.getObjectPropertyValue(firstQuoteResponse, "volume.last")
			);
			quoteData.blockTradeCount = formatter.number(
				Utils.getObjectPropertyValue(firstQuoteResponse, "volume.blockCount"),
				0
			);

			// Date and Time
			quoteData.quoteDate = formatter.quoteTimeStamp(firstQuoteResponse.lastTrade?.date);
			quoteData.quoteDelayMinutes = firstQuoteResponse.quoteDelayMinutes;
			quoteData.realTimeQuoteIndicator = firstQuoteResponse.realTimeQuoteIndicator;

			// Exchange and Market Cap
			quoteData.exchangeName = firstQuoteResponse.exchange?.name;
			quoteData.marketCap = Utils.getQuoteMarketCapValue(firstQuoteResponse.marketCap);

			return quoteData;
		}
		return quoteData;
	} catch (error) {
		return quoteData;
	}
};

export default {
	getSingleQuoteDetails,
};
