const paramsToStrip = ["refresh", "source"];

const stripUtilityQueryParams = (href) => {
	const [url, queryString] = href.split("?");
	const queryParams = new URLSearchParams(queryString);
	paramsToStrip.forEach((param) => {
		queryParams.delete(param);
	});
	const updatedQueryString = queryParams.toString();
	return `${url}${updatedQueryString ? `?${updatedQueryString}` : ""}`;
};

export default stripUtilityQueryParams;
