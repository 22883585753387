import { URLS, FINANCIAL_STATEMENT_TYPE } from "utils/constants";
import {
	ALLOWED_BALANCESHEEET_COLUMNS,
	FINANCIAL_DATA_FOR_HOME_PAGE,
} from "components/MarketsAndResearch/Financials/FinancialsCard/FinancialsConstants";

/**
 * Utility for fetching API data for equities-morningstar-financialstatements/key-data
 * @param {number} venueXid - the Xid for the symbol selected
 * @param {number} statementType - the financial statement type to return
 * @param {number} periodType - the period type of the financial statement to return
 * @param {number} numberOfPeriods - the number of periods of data to return
 */
const getKeyData = async (
	apiInstance,
	entityXid,
	statementType,
	periodType,
	numberOfPeriods
) => {
	if (!apiInstance || !entityXid || !statementType) {
		return;
	}

	let statementsData = {};

	try {
		const financialStatementResponse = await apiInstance.get(
			`${URLS.FINANCIAL_STATEMENTS_CHAIN_PATH}/key-data/${entityXid}`,
			{
				params: {
					statementType: statementType,
					periodType: periodType,
					numberOfPeriods: numberOfPeriods,
				},
			}
		);

		if (financialStatementResponse.data?.data?.rows?.length) {
			statementsData = financialStatementResponse.data.data;
			if (
				statementType === FINANCIAL_STATEMENT_TYPE.BALANCE_SHEET ||
				statementType ===
					FINANCIAL_STATEMENT_TYPE.BALANCE_SHEET + FINANCIAL_DATA_FOR_HOME_PAGE
			) {
				//No special property in API to filter data
				statementsData.rows = statementsData.rows.filter(
					(x) => ALLOWED_BALANCESHEEET_COLUMNS.indexOf(x.dataPointLabel) !== -1
				);
			}

			return statementsData;
		} else {
			return {};
		}
	} catch (error) {
		return {};
	}
};

const getFinancialStatements = async (
	apiInstance,
	entityXid,
	statementType,
	periodType,
	numberOfPeriods
) => {
	if (!apiInstance || !entityXid || !statementType) {
		return;
	}

	try {
		const financialStatementResponse = await apiInstance.get(
			`${URLS.FINANCIAL_STATEMENTS_CHAIN_PATH}/financial-statements/${entityXid}`,
			{
				params: {
					statementType: statementType,
					periodType: periodType,
					numberOfPeriods: numberOfPeriods,
				},
			}
		);

		return financialStatementResponse.data?.data?.rows?.length
			? financialStatementResponse.data.data
			: {};
	} catch (error) {
		return {};
	}
};

export default {
	getKeyData,
	getFinancialStatements,
};
