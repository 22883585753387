/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import { Typeahead, Menu, MenuItem, Hint } from "react-bootstrap-typeahead";
import Form from "react-bootstrap/Form";
import styles from "../../ChartingCard/ChartingCard.module.scss";
import AvailableIndicatorFilter from "../AvailableIndicatorFilterExtension";

const AutoCompleteIndicatorSearch = (props) => {
	const typeaheadRef = useRef(null);
	const [result, setResult] = useState([]);
	const [selectedItems, setSelectedItems] = useState([]);
	const [recentSearches, setRecentSearches] = useState([]);
	const [focus, setFocus] = useState(false);
	var lastSelectEvent = null;

	const onInputChange = (input) => {
		loadSearchedIndicator(input);
	};

	const handleFocusEvent = (event) => {
		if (event && event.type === "keyup") {
			// setting keyup event changes on tab only
			if (event.keyCode === 9 || event.keyCode === 16) {
				setFocus(true);
			} else {
				setFocus(false);
			}
		}
	};

	//set focus and cleanup earlier text of autocomplete search box while changing from upper/lower to studies or vice versa.
	useEffect(() => {
		clearText();
	}, []);

	//#region Auto Complete search Indicator
	const loadSearchedIndicator = (input) => {
		if (!input) {
			props.isSearchOnChange(false);
			return;
		}
		try {
			const options = [];
			if (selectedItems && selectedItems.length > 0) {
				options.push(...selectedItems);
			}
			let indicatorList = [];
			Object.keys(props.indicators).forEach((key, index) => {
				if (!AvailableIndicatorFilter()) {
					let indicator = props.indicators[key].items.filter((x) =>
						x.label.toLowerCase().includes(input.toLowerCase())
					);
					addFilteredIndicatorToIndicatorList(indicatorList, key, indicator);
				} else if (AvailableIndicatorFilter()) {
					let indicator = props.indicators[key].items.filter(
						(x) =>
							x.isProductionReady === true &&
							x.label.toLowerCase().includes(input.toLowerCase())
					);
					addFilteredIndicatorToIndicatorList(indicatorList, key, indicator);
				}
			});

			if (indicatorList) {
				options.push(...indicatorList);
				setResult(options);
				props.isSearchOnChange(true);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const addFilteredIndicatorToIndicatorList = (indicatorList, key, indicator) => {
		indicatorList.push(
			...indicator.map((x) => ({ ...x, labelName: props.indicators[key].label }))
		);
	};

	const onSelected = (selectedItem) => {
		if (selectedItem.length === 0) return;
		const tempSelected = [...recentSearches];
		let foundItem = false;
		for (let item of tempSelected) {
			if (item.label === selectedItem[0].label) {
				foundItem = true;
				break;
			}
		}
		if (!foundItem) {
			tempSelected.push(selectedItem[0]);
		}

		setRecentSearches(tempSelected);
		props.isSearchOnChange(false);
		let ob = {
			target: {
				dataset: { value: selectedItem[0].value, panel: selectedItem[0].panel },
			},
		};

		props.onClick(ob);

		props.closeDropdown(false, lastSelectEvent, { source: "select" });
	};
	const saveEvent = (e) => {
		lastSelectEvent = { ...e };
	};

	/**
	 *  clear input and selection once user selectes search box
	 **/
	const clearText = () => {
		if (typeaheadRef.current) {
			typeaheadRef.current.clear();
		}
		setResult(recentSearches);
		setSelectedItems([]);
		props.isSearchOnChange(false);
	};

	const renderMenu = (...results) => {
		return props.isSearched && results.length > 0
			? results.map((indicator, index, data) => {
					return (
						(index === 0 && (
							<React.Fragment>
								<span className={styles.autoCompleteSearchItemTextWithoutBorder}>
									{indicator.labelName}
								</span>
								<MenuItem
									option={indicator}
									position={index}
									key={`${indicator.label}-${index}`}
									onClick={saveEvent}
								>
									<div className={styles.autoCompleteSearchItemMenu}>
										{indicator.label}
									</div>
								</MenuItem>
							</React.Fragment>
						)) ||
						(index - 1 >= 0 && indicator.labelName !== data[index - 1].labelName && (
							<React.Fragment>
								<span className={styles.autoCompleteSearchItemText}>
									{indicator.labelName}
								</span>
								<MenuItem
									option={indicator}
									position={index}
									key={`${indicator.label}-${index}`}
									onClick={saveEvent}
								>
									<div className={styles.autoCompleteSearchItemMenu}>
										{indicator.label}
									</div>
								</MenuItem>
							</React.Fragment>
						)) || (
							<MenuItem
								option={indicator}
								position={index}
								key={`${indicator.label}-${index}`}
								onClick={saveEvent}
							>
								<div className={styles.autoCompleteSearchItemMenu}>{indicator.label}</div>
							</MenuItem>
						)
					);
			  })
			: props.isSearched && <div className={styles.nomatch}>No indicator matches</div>;
	};

	const customMenu = (results, menuProps) => {
		return (
			<Menu
				className={
					results
						? styles.autoCompleteSearchResultBox
						: styles.autoCompleteSearchResultBoxHide
				}
				{...menuProps}
			>
				{renderMenu(...results)}
			</Menu>
		);
	};

	const customInput = ({ inputRef, referenceElementRef, ...inputProps }) => {
		const container = {
			display: "flex",
		};
		const inputClass = `${focus ? "focusVisible" : "focusReset"}`;

		return (
			<div style={container} className={inputClass}>
				<svg
					viewBox="-5 -5 24 24"
					className={`bi bi-search ${styles.svgContent}`}
					fill="currentColor"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fillRule="evenodd"
						d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"
					/>
					<path
						fillRule="evenodd"
						d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"
					/>
				</svg>
				<Hint>
					<Form.Control
						{...inputProps}
						ref={(node) => {
							inputRef(node);
							referenceElementRef(node);
						}}
						onClick={() => setFocus(false)}
						onKeyUp={handleFocusEvent}
						onBlur={() => {
							setFocus(false);
							clearText();
						}}
					/>
				</Hint>
			</div>
		);
	};

	//#endregion

	return (
		<div className={styles.indicatorSearchContainer}>
			<Typeahead
				id="indicator-search"
				labelKey={(option) => `${option.label}`}
				onInputChange={onInputChange}
				placeholder="Search indicators"
				options={result}
				renderMenu={customMenu}
				renderInput={customInput}
				onFocus={clearText}
				ref={typeaheadRef}
				onChange={onSelected}
				selected={selectedItems}
			></Typeahead>
		</div>
	);
};

export default AutoCompleteIndicatorSearch;
