import Indicators from "components/MarketsAndResearch/Charting/Indicators";
import InputConfig from "./IndicatorInputsConfig";
// to change hex color code into rgb with opacity
const HexToRGB = (hex, opacity) => {
	hex = hex.replace(/[^0-9A-F]/gi, "");
	var bigint = parseInt(hex, 16);
	var r = (bigint >> 16) & 255;
	var g = (bigint >> 8) & 255;
	var b = bigint & 255;

	return "rgb(" + r + "," + g + "," + b + "," + opacity + ")";
};

const updateIndicatorStyle = (ind, inputs, colorDict, lineDict) => {
	ind.config.style = {
		...ind.config.style,
		width: lineDict[inputs.linestyle.line.name]
			? lineDict[inputs.linestyle.line.name].width
			: inputs.linestyle.line.default.width,
		lineDash: lineDict[inputs.linestyle.line.name]
			? lineDict[inputs.linestyle.line.name].linedash
			: inputs.linestyle.line.default.linedash,
		color: colorDict[inputs.linestyle.color.name]
			? colorDict[inputs.linestyle.color.name]
			: inputs.linestyle.color.default,
	};
	return ind;
};
export const IndicatorFormatter = {
	bollinger: function ({ textDict, lineDict, colorDict, inputs, indicator }) {
		let ind = indicator.config
			? indicator
			: JSON.parse(JSON.stringify(Indicators.DICTIONARY["bollinger"]));
		if (textDict[inputs.number0.name])
			ind.config.inputs[0] = { name: "period", value: textDict[inputs.number0.name] };
		if (textDict[inputs.number1.name])
			ind.config.inputs[1] = {
				name: "standardDeviations",
				value: textDict[inputs.number1.name],
			};

		ind.config.style = {
			...ind.config.style,
			width: lineDict[inputs.linestyle0.line.name]
				? lineDict[inputs.linestyle0.line.name].width
				: inputs.linestyle0.line.default.width,
			lineDash: lineDict[inputs.linestyle0.line.name]
				? lineDict[inputs.linestyle0.line.name].linedash
				: inputs.linestyle0.line.default.linedash,
			color: colorDict[inputs.linestyle0.color.name]
				? colorDict[inputs.linestyle0.color.name]
				: inputs.linestyle0.color.default,
			fillColor: colorDict[inputs.color1.name]
				? HexToRGB(colorDict[inputs.color1.name], 0.2)
				: HexToRGB(inputs.color1.default, 0.2),
			sma: {
				color: colorDict[inputs.linestyle1.color.name]
					? colorDict[inputs.linestyle1.color.name]
					: inputs.linestyle1.color.default,
				width: lineDict[inputs.linestyle1.line.name]
					? lineDict[inputs.linestyle1.line.name].width
					: inputs.linestyle1.line.default.width,
				lineDash: lineDict[inputs.linestyle1.line.name]
					? lineDict[inputs.linestyle1.line.name].linedash
					: inputs.linestyle1.line.default.linedash,
			},
		};
		return ind;
	},
	mae: function ({ dropDownDict, textDict, lineDict, colorDict, inputs, indicator }) {
		let ind = indicator.config
			? indicator
			: JSON.parse(JSON.stringify(Indicators.DICTIONARY["mae"]));
		if (textDict[inputs.number0.name])
			ind.config.inputs[0] = { name: "period", value: textDict[inputs.number0.name] };
		if (textDict[inputs.number1.name]) {
			ind.config.inputs[1] = {
				name: "lowfactor",
				value: 1 - textDict[inputs.number1.name] / 100,
			};
			ind.config.inputs[2] = {
				name: "highfactor",
				value: 1 + textDict[inputs.number1.name] / 100,
			};
		}
		if (dropDownDict[inputs.dropdown.name]) {
			ind.config.inputs[3] = {
				name: "averagetype",
				value: InputConfig.AverageType[dropDownDict[inputs.dropdown.name]],
			};
		}
		ind.config.style = {
			...ind.config.style,
			width: lineDict[inputs.linestyle.line.name]
				? lineDict[inputs.linestyle.line.name].width
				: inputs.linestyle.line.default.width,
			lineDash: lineDict[inputs.linestyle.line.name]
				? lineDict[inputs.linestyle.line.name].linedash
				: inputs.linestyle.line.default.linedash,
			color: colorDict[inputs.linestyle.color.name]
				? colorDict[inputs.linestyle.color.name]
				: inputs.linestyle.color.default,
			fillColor: colorDict[inputs.color.name]
				? HexToRGB(colorDict[inputs.color.name], 0.25)
				: HexToRGB(inputs.color.default, 0.25),
		};
		return ind;
	},
	sma: function ({ textDict, lineDict, colorDict, inputs, indicator }) {
		let ind = indicator.config
			? indicator
			: JSON.parse(JSON.stringify(Indicators.DICTIONARY["sma"]));
		if (textDict[inputs.number.name])
			ind.config.inputs[0] = { name: "period", value: textDict[inputs.number.name] };
		ind.config.style = {
			...ind.config.style,
			width: lineDict[inputs.linestyle.line.name]
				? lineDict[inputs.linestyle.line.name].width
				: inputs.linestyle.line.default.width,
			lineDash: lineDict[inputs.linestyle.line.name]
				? lineDict[inputs.linestyle.line.name].linedash
				: inputs.linestyle.line.default.linedash,
			color: colorDict[inputs.linestyle.color.name]
				? colorDict[inputs.linestyle.color.name]
				: inputs.linestyle.color.default,
		};
		return ind;
	},
	volume: function ({ colorDict, inputs, indicator }) {
		let ind = indicator.config
			? indicator
			: JSON.parse(JSON.stringify(Indicators.DICTIONARY["volume"]));
		ind.config.style = {
			...ind.config.style,
			positive: {
				color: colorDict[inputs.color0.name]
					? colorDict[inputs.color0.name]
					: inputs.color0.default,
			},
			negative: {
				color: colorDict[inputs.color1.name]
					? colorDict[inputs.color1.name]
					: inputs.color1.default,
			},
		};
		return ind;
	},
	wma: function ({ textDict, lineDict, colorDict, inputs, indicator }) {
		let ind = indicator.config
			? indicator
			: JSON.parse(JSON.stringify(Indicators.DICTIONARY["wma"]));
		if (textDict[inputs.number.name])
			ind.config.inputs[0] = { name: "period", value: textDict[inputs.number.name] };
		ind.config.style = {
			...ind.config.style,
			width: lineDict[inputs.linestyle.line.name]
				? lineDict[inputs.linestyle.line.name].width
				: inputs.linestyle.line.default.width,
			lineDash: lineDict[inputs.linestyle.line.name]
				? lineDict[inputs.linestyle.line.name].linedash
				: inputs.linestyle.line.default.linedash,
			color: colorDict[inputs.linestyle.color.name]
				? colorDict[inputs.linestyle.color.name]
				: inputs.linestyle.color.default,
		};
		return ind;
	},
	dividendyield: function ({ textDict, lineDict, colorDict, inputs, indicator }) {
		let ind = indicator.config
			? indicator
			: JSON.parse(JSON.stringify(Indicators.DICTIONARY["dividendyield"]));
		return updateIndicatorStyle(ind, inputs, colorDict, lineDict);
	},
	onbalancevolume: function ({ textDict, lineDict, colorDict, inputs, indicator }) {
		let ind = indicator.config
			? indicator
			: JSON.parse(JSON.stringify(Indicators.DICTIONARY["onbalancevolume"]));
		return updateIndicatorStyle(ind, inputs, colorDict, lineDict);
	},
	peratio: function ({ textDict, lineDict, colorDict, inputs, indicator }) {
		let ind = indicator.config
			? indicator
			: JSON.parse(JSON.stringify(Indicators.DICTIONARY["peratio"]));
		return updateIndicatorStyle(ind, inputs, colorDict, lineDict);
	},
	revenues: function ({ textDict, lineDict, colorDict, inputs, indicator }) {
		let ind = indicator.config
			? indicator
			: JSON.parse(JSON.stringify(Indicators.DICTIONARY["revenues"]));
		return updateIndicatorStyle(ind, inputs, colorDict, lineDict);
	},
	rollingeps: function ({ textDict, lineDict, colorDict, inputs, indicator }) {
		let ind = indicator.config
			? indicator
			: JSON.parse(JSON.stringify(Indicators.DICTIONARY["rollingeps"]));
		return updateIndicatorStyle(ind, inputs, colorDict, lineDict);
	},
	macd: function ({ textDict, lineDict, colorDict, inputs, indicator }) {
		let ind = indicator.config
			? indicator
			: JSON.parse(JSON.stringify(Indicators.DICTIONARY["macd"]));
		if (textDict[inputs.number0.name])
			ind.config.inputs[0] = { name: "fastperiod", value: textDict[inputs.number0.name] };
		if (textDict[inputs.number1.name])
			ind.config.inputs[1] = { name: "slowperiod", value: textDict[inputs.number1.name] };
		if (textDict[inputs.number2.name])
			ind.config.inputs[2] = { name: "smoothing", value: textDict[inputs.number2.name] };

		ind.config.style = {
			...ind.config.style,
			color: colorDict[inputs.linestyle0.color.name]
				? colorDict[inputs.linestyle0.color.name]
				: inputs.linestyle0.color.default,
			width: lineDict[inputs.linestyle0.line.name]
				? lineDict[inputs.linestyle0.line.name].width
				: inputs.linestyle0.line.default.width,
			lineDash: lineDict[inputs.linestyle0.line.name]
				? lineDict[inputs.linestyle0.line.name].linedash
				: inputs.linestyle0.line.default.linedash,

			ema: {
				color: colorDict[inputs.linestyle1.color.name]
					? colorDict[inputs.linestyle1.color.name]
					: inputs.linestyle1.color.default,
				width: lineDict[inputs.linestyle1.line.name]
					? lineDict[inputs.linestyle1.line.name].width
					: inputs.linestyle1.line.default.width,
				lineDash: lineDict[inputs.linestyle1.line.name]
					? lineDict[inputs.linestyle1.line.name].linedash
					: inputs.linestyle1.line.default.linedash,
			},
			positive: {
				color: colorDict[inputs.linestyle2.color.name]
					? colorDict[inputs.linestyle2.color.name]
					: inputs.linestyle2.color.default,
				width: lineDict[inputs.linestyle2.line.name]
					? lineDict[inputs.linestyle2.line.name].width
					: inputs.linestyle2.line.default.width,
				lineDash: lineDict[inputs.linestyle2.line.name]
					? lineDict[inputs.linestyle2.line.name].linedash
					: inputs.linestyle2.line.default.linedash,
			},
			negative: {
				color: colorDict[inputs.linestyle3.color.name]
					? colorDict[inputs.linestyle3.color.name]
					: inputs.linestyle3.color.default,
				width: lineDict[inputs.linestyle3.line.name]
					? lineDict[inputs.linestyle3.line.name].width
					: inputs.linestyle3.line.default.width,
				lineDash: lineDict[inputs.linestyle3.line.name]
					? lineDict[inputs.linestyle3.line.name].linedash
					: inputs.linestyle3.line.default.linedash,
			},
		};
		return ind;
	},
	momentum: function ({ textDict, lineDict, colorDict, inputs, indicator }) {
		let ind = indicator.config
			? indicator
			: JSON.parse(JSON.stringify(Indicators.DICTIONARY["momentum"]));
		if (textDict[inputs.number.name])
			ind.config.inputs[0] = { name: "period", value: textDict[inputs.number.name] };

		ind = updateIndicatorStyle(ind, inputs, colorDict, lineDict);
		ind.config.style.fillColor = colorDict[inputs.color0.name]
			? HexToRGB(colorDict[inputs.color0.name], 0.2)
			: HexToRGB(inputs.color0.default, 0.2);

		return ind;
	},
	proc: function ({ textDict, lineDict, colorDict, inputs, indicator }) {
		let ind = indicator.config
			? indicator
			: JSON.parse(JSON.stringify(Indicators.DICTIONARY["proc"]));
		if (textDict[inputs.number.name])
			ind.config.inputs[0] = { name: "period", value: textDict[inputs.number.name] };

		return updateIndicatorStyle(ind, inputs, colorDict, lineDict);
	},
	vroc: function ({ textDict, lineDict, colorDict, inputs, indicator }) {
		let ind = indicator.config
			? indicator
			: JSON.parse(JSON.stringify(Indicators.DICTIONARY["vroc"]));
		if (textDict[inputs.number.name])
			ind.config.inputs[0] = { name: "period", value: textDict[inputs.number.name] };

		return updateIndicatorStyle(ind, inputs, colorDict, lineDict);
	},
	rsi: function ({ textDict, lineDict, colorDict, inputs, indicator }) {
		let ind = indicator.config
			? indicator
			: JSON.parse(JSON.stringify(Indicators.DICTIONARY["rsi"]));
		if (textDict[inputs.number.name])
			ind.config.inputs[0] = { name: "period", value: textDict[inputs.number.name] };

		ind = updateIndicatorStyle(ind, inputs, colorDict, lineDict);

		ind.config.style = {
			...ind.config.style,
			overbought: {
				...ind.config.style.overbought,
				color: {
					background: colorDict[inputs.color0.name]
						? colorDict[inputs.color0.name]
						: inputs.color0.default,
				},
			},
			oversold: {
				...ind.config.style.oversold,
				color: {
					background: colorDict[inputs.color1.name]
						? colorDict[inputs.color1.name]
						: inputs.color1.default,
				},
			},
		};

		return ind;
	},
};
