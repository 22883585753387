import React from "react";
import LineChart from "components/Lib/Icons/LineChart/LineChart";
import MountainChart from "components/Lib/Icons/MountainChart/MountainChart";
import ChartEventsConstants from "components/Lib/ChartEventsOverlay/ChartEventsConstants";

const CHART_COLORS = {
	LINE: "#002B51",
	MOUNTAIN_FILL: "rgba(5, 173, 251, 0.28)",
	HISTOGRAM: "#005C90",
	DOT: "#005C90",
};

const LINE_STYLES = [
	{
		label: "Line",
		markerType: "line",
		icon: <LineChart />,
		lineColor: CHART_COLORS.LINE,
	},
	{
		label: "Mountain",
		markerType: "fill",
		icon: <MountainChart />,
		lineColor: CHART_COLORS.LINE,
	},
];

const CHART_PANEL_PARAMS = {
	primaryOhlcType: "posneg",
	style: {
		panel: {
			margin: {
				bottom: 0,
			},
			padding: { right: 70, bottom: 40 },
			border: {
				color: {
					default: "transparent",
				},
			},
			grid: {
				color: "transparent",
				horizontal: {
					color: "#767676",
					lineDash: "",
					width: "1",
				},
				vertical: {
					alt: { color: "transparent" },
				},
			},
			xAxis: {
				tick: {
					alt: {
						color: "transparent",
					},
					color: "transparent",
				},
				font: {
					size: 14,
					color: "#000",
					family: "Karla",
				},
			},
			yAxis: {
				font: {
					size: 14,
					color: "#000",
					family: "Karla",
				},
				tick: {
					alt: {
						color: "transparent",
					},
					color: "transparent",
				},
				padding: {},
				label: {
					format: {
						default: {
							format: "$0,.0f",
							formatPercent: "0,.0%",
						},
						large: {
							format: "$0,.2s",
							formatPercent: "0,.2%",
						},
						small: {
							format: "$0.2f",
							formatPercent: "0.2%",
							threshold: 1,
						},
						micro: {
							format: "$0.4f",
							formatPercent: "0.2%",
						},
						medium: {
							format: "$0,.2f",
							formatPercent: "0,.1%",
						},
					},
				},
			},
		},
		indicator: {
			price: {
				bar: {
					color: CHART_COLORS.HISTOGRAM,
					fillColor: CHART_COLORS.HISTOGRAM,
				},
				candlestick: {
					positive: {
						color: "forestgreen",
					},
					negative: {
						color: "firebrick",
					},
					neutral: {
						color: "#999999",
					},
				},
				color: "steelblue",
				fillColor: CHART_COLORS.MOUNTAIN_FILL,
				fillColorStop: CHART_COLORS.MOUNTAIN_FILL,
				lineDash: null,
				ohlc: {
					positive: {
						color: "green",
					},
					negative: {
						color: "darkred",
					},
					neutral: {
						color: "#999999",
					},
				},
				posneg: {
					lineDash: null,
					width: 1.5,
					positive: {
						color: "forestgreen",
						fillColor: "rgba(17, 182, 0, 0.1)",
						fillColorStop: null,
					},
					negative: {
						color: "firebrick",
						fillColor: "rgba(178, 0, 0, 0.1)",
						fillColorStop: null,
					},
				},
				sessionBreak: {
					color: "steelblue",
					fillColor: "rgba(0, 0, 0, 0.04)",
					lineDash: "2,2",
					width: 1.5,
				},
				shape: {
					color: CHART_COLORS.DOT,
					fillColor: CHART_COLORS.DOT,
					length: 2.5,
					lineWidth: 1,
					offset: 1,
					type: "circle",
				},
				width: 1.5,
			},
		},
	},
};

const DEFAULT_PANEL_PARAMS = {
	primaryOhlcType: "posneg",
	style: {
		panel: JSON.parse(JSON.stringify(CHART_PANEL_PARAMS.style.panel)),
		indicator: {
			price: {
				bar: {
					color: CHART_COLORS.HISTOGRAM,
					fillColor: CHART_COLORS.HISTOGRAM,
				},
				candlestick: {
					positive: {
						color: "forestgreen",
					},
					negative: {
						color: "firebrick",
					},
					neutral: {
						color: "#999999",
					},
				},
				color: "steelblue",
				fillColor: CHART_COLORS.MOUNTAIN_FILL,
				fillColorStop: CHART_COLORS.MOUNTAIN_FILL,
				lineDash: null,
				ohlc: {
					positive: {
						color: "green",
					},
					negative: {
						color: "darkred",
					},
					neutral: {
						color: "#999999",
					},
				},
				posneg: {
					lineDash: null,
					width: 1.5,
					positive: {
						color: "forestgreen",
						fillColor: "rgba(17, 182, 0, 0.1)",
						fillColorStop: null,
					},
					negative: {
						color: "firebrick",
						fillColor: "rgba(178, 0, 0, 0.1)",
						fillColorStop: null,
					},
				},
				sessionBreak: {
					color: "steelblue",
					fillColor: "rgba(0, 0, 0, 0.04)",
					lineDash: "2,2",
					width: 1.5,
				},
				shape: {
					color: CHART_COLORS.DOT,
					fillColor: CHART_COLORS.DOT,
					length: 2.5,
					lineWidth: 1,
					offset: 1,
					type: "circle",
				},
				width: 1.5,
			},
		},
	},
};

const CHART_CONFIG = {
	params: {
		dataPeriod: "Week",
		days: "365",
		showFlags: true,
		xAxisShortMonths: true,
		returnPreviousPoint: true,
		zoomEnabled: false,
		dataInterface: ChartEventsConstants.DATA_INTERFACE,
		style: JSON.parse(JSON.stringify(DEFAULT_PANEL_PARAMS.style)),
	},
	panels: [
		{
			indicators: [
				{
					id: "price",
					markerType: "line",
					style: {
						width: 2,
						color: "#002B51",
						fillColor: CHART_COLORS.MOUNTAIN_FILL,
						lineColorDot: CHART_COLORS.DOT,
						fillColorDot: CHART_COLORS.DOT,
						diameterDot: 3,
					},
				},
			],
		},
	],
};

export default {
	CHART_PANEL_PARAMS,
	DEFAULT_PANEL_PARAMS,
	CHART_COLORS,
	LINE_STYLES,
	CHART_CONFIG,
};
