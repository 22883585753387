/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import styles from "./TextToggle.module.scss";

export const TextToggle = React.memo(
	({ activeValue: activeValueProp, option1, option2, handleToggle }) => {
		const [activeValue, setActiveValue] = useState(activeValueProp || option1.value);
		const [, setDisable] = useState(false);
		const option1Ref = useRef(null);
		const option2Ref = useRef(null);

		// set toggle button focus to active value
		useEffect(() => {
			if (option2.disable) {
				setDisable(true);
			} else setDisable(false);
			const buttonRef = activeValue === option1.value ? option1Ref : option2Ref;
			buttonRef.current.ariaLabel = `Showing ${activeValue} earnings`;
		}, [activeValue, option2]);

		const onToggle = (e) => {
			const newValue = e.target.value;
			if (newValue !== activeValue) {
				if (handleToggle) {
					handleToggle(newValue);
					setActiveValue(newValue);
					e.target.focus();
				}
			}
		};
		return (
			<div
				role="status"
				className={styles.buttonGroup}
				aria-label="Chart controls"
				aria-live="polite"
			>
				{[option1, option2].map((item, key) => (
					<div className={styles.buttonContainer} key={key}>
						<button
							value={item.value}
							className={activeValue === item.value ? styles.active : styles.inactive}
							onClick={onToggle}
							ref={key === 0 ? option1Ref : option2Ref}
							aria-label={
								activeValue === item.value ? `showing ${item.value} earnings` : item.value
							}
							aria-current={activeValue === item.value}
						>
							{item.label}
						</button>
					</div>
				))}
			</div>
		);
	}
);

export default TextToggle;

TextToggle.propTypes = {
	option1: PropTypes.object,
	option2: PropTypes.object,
	activeFilter: PropTypes.string,
	handleSwitch: PropTypes.func,
};
