import React, { Component } from "react";
import { connect, useDispatch } from "react-redux";
import {
	OPTION_PAGE_VIEW_TYPE_MODAL,
	URLS,
	OPTION_PAGE_VIEW_TYPE_DESKTOP,
	OPTION_VIEW_TYPE_CALL_AND_PUT,
	OPTION_VIEW_TYPE_CALL,
	OPTION_VIEW_TYPE_PUT,
} from "../../utils/constants";
import Utils from "../../utils/utils";
import actionTypes from "../../store/actions/actionTypes";
import ActionCreators from "../../store/actions/actions";
import "url-search-params-polyfill";

import XrefAPI from "services/APIs/Xref/XrefAPI";
import QuoteAPIUtils from "services/APIs/Quote/QuoteAPI";

import Drawer from "../Lib/Drawer/Drawer";
import OptionsTradeButton from "./OptionsTradeButton/OptionsTradeButton";

import styles from "./Options.module.scss";
import QuoteBar from "./QuoteBar/QuoteBar";
import OptionsStrikesFilter from "./OptionsStrikesFilter/OptionsStrikesFilter";
import ExpirationDatesFilter from "./ExpirationDateFilter/ExpirationDatesFilter";
import OptionsTable from "./OptionsTable/OptionsTable";
import MonthlyExpirationToggle from "./MonthlyExpirationToggle/MonthlyExpirationToggle";
import MonthlyExpirationButton from "./MonthlyExpirationButton/MonthlyExpirationButton";
import MonthlyExpirationMenu from "./MonthlyExpirationMenu/MonthlyExpirationMenu";
import RealTimeBanner from "../Lib/RealTimeBanner/RealTimeBanner";
import MSSpinner from "components/Lib/MSSpinner/MSSpinner";
import MSDropdown from "components/Lib/MSDropdown/MSDropdown";

export class Options extends Component {
	constructor(props) {
		super(props);
		this.timer = null;
		this.state = {
			xrefData: null,
			quoteData: null,
			noScroll: false, // scroll lock flag
			drawerMonthly: false, // drawer flags
			drawerStrikes: false, // drawer flags
		};
		this.toggleDrawerStrikes = this.toggleDrawerStrikes.bind(this);
		this.toggleDrawerMonthly = this.toggleDrawerMonthly.bind(this);
		this.toggleDrawerTrade = this.toggleDrawerTrade.bind(this);
		this.loadBanner = false;
	}

	async componentDidMount() {
		const query = new URLSearchParams(this.props.location.search);
		const symbol = query.get("symbol");

		/*
			MS is not setting viewType to "modal" for the Trade Modal integration, so
			we need to default to "modal".

			Possible values are: modal, desktop, mobile
		*/
		let viewType = query.get("viewType").toLowerCase() || OPTION_PAGE_VIEW_TYPE_MODAL;

		const optionViewType = query.get("optionType")
			? query.get("optionType").toLowerCase()
			: null; // call, put, callandput
		const queryMspi = query.get("mspi");

		if (window?.MD?.ENV !== "localhost") {
			await this.checkForSession(queryMspi);
		}

		this.props.dispatchUpdateOptionsPageParams({ symbol, viewType, optionViewType });
	}

	async componentDidUpdate(prevProps, prevState) {
		if (this.shouldFetchXref(prevProps)) {
			await this.fetchXrefData();
		}

		if (
			prevState.xrefData !== this.state.xrefData ||
			prevProps.apiInstance !== this.props.apiInstance
		) {
			this.fetchQuoteData();

			this.loadOptionsExpirations(this.state.xrefData?.xids?.venue);
			this.props.dispatchLoadEarningsEstimatesCurrentData(
				this.props.apiInstance,
				this.state.xrefData?.xids?.venue
			);
			this.props.dispatchLoadEarningsEstimatesTrendsData(
				this.props.apiInstance,
				this.state.xrefData?.xids?.venue
			);
		}

		const query = new URLSearchParams(this.props.location.search);
		const symbol = query.get("symbol");

		/*
			MS is not setting viewType to "modal" for the Trade Modal integration, so
			we need to default to "modal".

			Possible values are: modal, desktop, mobile
		*/
		let viewType = query.get("viewType").toLowerCase() || OPTION_PAGE_VIEW_TYPE_MODAL;

		const optionViewType = query.get("optionType")
			? query.get("optionType").toLowerCase()
			: null; // call, put, callandput
		const queryMspi = query.get("mspi");

		if (window?.MD?.ENV !== "localhost") {
			await this.checkForSession(queryMspi);
		}

		this.props.dispatchUpdateOptionsPageParams({ symbol, viewType, optionViewType });

		if (
			this.shouldFetchExpirationDates(prevProps) ||
			prevProps.apiInstance !== this.props.apiInstance
		) {
			this.loadOptionsExpirations(this.state.xrefData?.xids?.venue);
		}

		// Refactor trade enabled state once we get this from an API endpoint instead of the parent frame
		if (
			prevProps.optionsData.isOptionsTradeEnabled !==
			this.props.optionsData.isOptionsTradeEnabled
		) {
			this.props.dispatchUpdateIsOptionsTradeEnabled(
				this.props.optionsData.isOptionsTradeEnabled
			);
		}

		this.loadBanner =
			prevProps.xrefDataStatus === "FETCHING" && this.props.xrefDataStatus === "IDLE";
	}

	async fetchQuoteData() {
		if (this.state.xrefData?.xids?.venue) {
			const isRealTimeQuote = Utils.isRealtimeNasdaqUser(this.props.userInfo);

			const quoteData = await QuoteAPIUtils.getSingleQuoteDetails({
				apiInstance: this.props.apiInstance,
				venueXid: this.state.xrefData?.xids?.venue,
				isRealTimeQuote,
			});

			this.setState({ quoteData: quoteData });
		}
	}

	onIdle = () => {
		const status = [
			this.props.expirationDatesStatus,
			this.props.dividendsDataStatus,
			this.props.earningsDataStatus,
		];
		if (status.indexOf("ERROR") === -1 && status.indexOf("FETCHING") === -1) {
			this.fetchQuoteData();
			this.loadOptionsExpirations(this.state.xrefData?.xids?.venue);
			this.props.dispatchLoadEarningsEstimatesCurrentData(
				this.props.apiInstance,
				this.state.xrefData?.xids?.venue
			);
			this.props.dispatchLoadEarningsEstimatesTrendsData(
				this.props.apiInstance,
				this.state.xrefData?.xids?.venue
			);
		}
		this.timer.reset();
	};

	async checkForSession(queryMspi) {
		try {
			const mspiResponse = await this.props.apiInstance.instance({
				method: "get",
				url: URLS.MSPI_PATH,
				baseURL: window.location.origin,
			});
			if (mspiResponse) {
				if (
					mspiResponse.headers &&
					mspiResponse.headers["x-mspi"] &&
					mspiResponse.headers["x-mspi"] === "1" &&
					!queryMspi
				) {
					Utils.fetchToken();
				}
			}
		} catch (error) {
			return false;
		}
		return true;
	}

	shouldFetchXref(prevProps) {
		if (!prevProps) {
			return false;
		}

		if (
			prevProps.optionsData.authToken !== this.props.optionsData.authToken ||
			prevProps.apiInstance?.internalAuthToken !==
				this.props.apiInstance?.internalAuthToken
		) {
			return true;
		}

		if (prevProps.symbol !== this.props.symbol) {
			return true;
		}

		return false;
	}

	shouldFetchExpirationDates(prevProps) {
		if (!prevProps) {
			return false;
		}
		return prevProps.showMonthlyExpiration !== this.props.showMonthlyExpiration;
	}

	async fetchXrefData() {
		const xrefData = await XrefAPI.getXrefBySymbol(
			this.props.apiInstance,
			this.props.symbol,
			() => null,
			() => null
		);

		if (xrefData) {
			this.setState({ xrefData: xrefData });
		}
	}

	loadOptionsExpirations(venueXid) {
		if (this.props.showMonthlyExpiration) {
			this.props.dispatchFetchExpirationDates(
				this.props.apiInstance,
				venueXid,
				"monthly"
			);
		} else {
			this.props.dispatchFetchExpirationDates(this.props.apiInstance, venueXid);
		}
	}

	closeDrawers = () => (e) => {
		if (e && e.hasOwnProperty("preventDefault")) {
			e.preventDefault();
		}
		// close all drawer flags
		this.setState({
			noScroll: false,
			drawerMonthly: false,
			drawerStrikes: false,
			drawerTrade: false,
		});
	};

	toggleDrawer = (drawer) => (e) => {
		if (this.state.hasOwnProperty(drawer)) {
			if (e && e.hasOwnProperty("preventDefault")) {
				e.preventDefault();
			}
			// toggle scroll lock
			const update = {
				noScroll: !this.state.noScroll,
			};
			// toggle specific drawer
			update[drawer] = !this.state[drawer];
			// push state update
			this.setState(update);
		}
	};

	toggleDrawerTrade() {
		this.toggleDrawer("drawerTrade")();
	}

	toggleDrawerStrikes() {
		this.toggleDrawer("drawerStrikes")();
	}

	toggleDrawerMonthly() {
		const monthlyExpirationsButton = document.getElementById("monthlyExpirationButton");
		if (monthlyExpirationsButton) {
			monthlyExpirationsButton.focus();
		}

		this.toggleDrawer("drawerMonthly")();
	}

	optionsTableRowClickCallback = () => {
		this.toggleDrawerTrade();
		const tradeDrawerButton = document.getElementById("tradeDrawerButton");
		if (tradeDrawerButton) {
			tradeDrawerButton.focus();
		}
	};

	handleMonthlyExpirationsButtonClick = () => {
		this.toggleDrawerMonthly();
		const selectedRadioButton = document.querySelector("#expirationsMenu input:checked");
		if (selectedRadioButton) {
			selectedRadioButton.focus();
		}
	};

	handleMobileStrikesClick = () => {
		this.toggleDrawerStrikes();
		const mobileStrikesFieldset = document.getElementById("strikesFieldset");
		if (mobileStrikesFieldset) {
			const firstButton = mobileStrikesFieldset.querySelector("input:checked");
			if (firstButton) {
				firstButton.focus();
			}
		}
	};

	quoteDisplay() {
		if (this.state.quoteData === {}) {
			return (
				<p className="text-danger text-center">
					This information is temporarily unavailable.
				</p>
			);
		}
		return (
			<QuoteBar
				apiInstance={this.props.apiInstance}
				moduleName="options"
				symbol={this.state.xrefData?.symbol}
				venueXid={this.state.xrefData?.xids?.venue}
				xrefName={this.state.xrefData?.name}
				quoteData={this.state.quoteData}
				isMobile={this.props.isMobile}
				userInfo={this.props.userInfo}
			/>
		);
	}

	getOptionType() {
		if (!this.props || !this.props.viewType) {
			return;
		}
		if (
			this.props.viewType === OPTION_PAGE_VIEW_TYPE_DESKTOP &&
			this.props.optionViewType == null
		) {
			return OPTION_VIEW_TYPE_CALL_AND_PUT;
		}

		return this.props.optionViewType;
	}

	isDesktopView() {
		return this.props && this.props.viewType === OPTION_PAGE_VIEW_TYPE_DESKTOP;
	}

	optionPageDisplay() {
		const spinnerStyle = {
			minHeight: "200px",
		};

		const optionType = this.getOptionType();

		const contentReady =
			this.props.xrefDataStatus && this.props.xrefDataStatus === "IDLE";
		if (this.props.xrefDataStatus === "ERROR") {
			return (
				<p className="text-danger text-center">
					Something went wrong. Please Check Symbol or Try Again
				</p>
			);
		} else {
			return (
				<MSSpinner
					style={spinnerStyle}
					spinnerStyle={{ minHeight: "30px" }}
					ready={contentReady}
					spinnerSize={"lg"}
				>
					<section
						aria-label="options content"
						className={[
							styles.layout,
							this.state.noScroll ? styles.layoutNoScroll : "",
						].join(" ")}
					>
						<header>{!this.isDesktopView() && this.quoteDisplay()}</header>
						{/* MOBILE FILTERS */}
						{(this.props.optionsDataStatus === "ERROR" ||
							this.props.expirationDatesStatus === "ERROR") && (
							<p className="text-danger text-center">
								No Options Available For This Symbol.
							</p>
						)}
						{this.props.isMobile &&
							this.props.optionsDataStatus !== "ERROR" &&
							this.props.expirationDatesStatus !== "ERROR" && (
								<section aria-label="data filters">
									<div className={styles.layoutMobileFilters}>
										<MonthlyExpirationButton
											handleOnClick={this.handleMonthlyExpirationsButtonClick}
										/>
										<OptionsStrikesFilter
											useDrawer={true}
											onDrawerOpen={this.state.drawerStrikes}
											toggleDrawer={this.toggleDrawerStrikes}
										/>
									</div>
									<div className={styles.layoutFiltersExpirations}>
										<ExpirationDatesFilter
											isMobile={this.props.isMobile}
											applySingleSelection={this.props.isMobile}
										/>
									</div>
								</section>
							)}
						{/* DESKTOP FILTERS */}
						{!this.props.isMobile &&
							this.props.optionsDataStatus !== "ERROR" &&
							this.props.expirationDatesStatus !== "ERROR" && (
								<section className={styles.layoutFilters} aria-label="data filters">
									<div className={styles.layoutFiltersStrikes}>
										{this.isDesktopView() && <OptionTypeDropdown />}
										<OptionsStrikesFilter isMobile={this.props.isMobile} />
									</div>
									<div className={styles.layoutFiltersLegend}>In the money</div>
									<div className={styles.layoutFiltersToggles}>
										<MonthlyExpirationToggle
											id="onlyMonthly"
											label="Only show monthly expiration"
										/>
									</div>
									<div className={styles.layoutFiltersExpirations}>
										<p className={styles.layoutFiltersExpirationsLabel}>
											Expiration dates
										</p>
										<ExpirationDatesFilter
											isMobile={this.props.isMobile}
											applySingleSelection={this.props.isMobile}
										/>
									</div>
								</section>
							)}
						<RealTimeBanner />
						{/* OPTIONS TABLES */}
						{this.props.optionsDataStatus !== "ERROR" &&
							this.props.expirationDatesStatus !== "ERROR" && (
								<article>
									<OptionsTable
										apiInstance={this.props.apiInstance}
										componentName={this.props.componentName}
										viewType={this.props.optionsData.viewType}
										optionType={optionType}
										symbol={this.props.symbol}
										venueXid={this.state.xrefData?.xids?.venue}
										rowClickCallback={this.optionsTableRowClickCallback}
										userInfo={this.props.userInfo}
									/>
								</article>
							)}
						{/* MOBILE DRAWERS */}

						{/* TRADE DRAWER */}
						{this.props.isMobile &&
							this.props.optionsDataStatus !== "ERROR" &&
							this.props.expirationDatesStatus !== "ERROR" &&
							this.props.optionsData.isOptionsTradeEnabled && (
								<Drawer
									open={this.state.drawerTrade}
									title={"Select an action"}
									onClose={this.toggleDrawerTrade}
								>
									<OptionsTradeButton
										apiInstance={this.props.apiInstance}
										id="tradeDrawerButton"
										text={`Trade ${this.props.selectedOption.optionText}`}
										tradeMessageData={this.props.selectedOption}
										customStyles={styles.tradeButton}
										onClickCallback={this.toggleDrawerTrade}
									></OptionsTradeButton>
								</Drawer>
							)}

						{/* MONTHLY EXPIRATIONS DRAWER */}
						{this.props.isMobile &&
							this.props.optionsDataStatus !== "ERROR" &&
							this.props.expirationDatesStatus !== "ERROR" && (
								<Drawer
									open={this.state.drawerMonthly}
									title={"Select an action"}
									onClose={this.toggleDrawerMonthly}
								>
									<MonthlyExpirationMenu handleOnChange={this.toggleDrawerMonthly} />
								</Drawer>
							)}
					</section>
				</MSSpinner>
			);
		}
	}

	render() {
		return this.optionPageDisplay();
	}
}

export const OptionTypeDropdown = () => {
	const dispatch = useDispatch();
	const data = [
		{ option: "Calls + Puts", action: OPTION_VIEW_TYPE_CALL_AND_PUT },
		{ option: "Calls", action: OPTION_VIEW_TYPE_CALL },
		{ option: "Puts", action: OPTION_VIEW_TYPE_PUT },
	];

	const selectCallback = (item) => {
		if (!item) return;
		for (const entry of data) {
			if (entry.option === item) {
				dispatch({ type: actionTypes.UPDATE_OPTIONS_VIEW_TYPE, view: entry.action });
				break;
			}
		}
	};

	return (
		<div style={{ marginRight: "24px", width: "125px" }}>
			<MSDropdown
				data={data}
				defaultSelectedItem={{ option: data[0].option, index: 0 }}
				onSelectCallback={selectCallback}
			/>
		</div>
	);
};

// listen to redux state changes
const mapStateToProps = (state) => {
	return {
		xrefDataStatus: state.optionsReducer.xrefDataStatus,
		expirationDatesStatus: state.optionsReducer.expirationDatesStatus,
		dividendsDataStatus: state.optionsReducer.dividendsDataStatus,
		earningsDataStatus: state.optionsReducer.earningsDataStatus,
		showMonthlyExpiration: state.optionsReducer.showMonthlyExpiration,
		selectedOption: state.optionsReducer.selectedOption,
		symbol: state.optionsReducer.optionsPageParams.symbol,
		optionViewType: state.optionsReducer.optionsPageParams.optionViewType,
		viewType: state.optionsReducer.optionsPageParams.viewType,
		isMobile: state.optionsReducer.isMobile,
		quoteData: state.quoteReducer.quoteData,
	};
};

// dispatch events from container to redux by  creating a mapping function
const mapDispatchToProps = (dispatch) => {
	return {
		dispatchLoadExpirationDates: () =>
			dispatch({ type: actionTypes.EXP_DATES_API_TRANSITION, name: "load" }),
		dispatchFetchExpirationDates: (apiInstance, venueXid, contractTerm) =>
			dispatch(ActionCreators.fetchExpirationDates(apiInstance, venueXid, contractTerm)),
		dispatchUpdateIsOptionsTradeEnabled: (isOptionsTradeEnabled) =>
			dispatch({
				type: actionTypes.SAVE_IS_OPTIONS_TRADE_ENABLED,
				isOptionsTradeEnabled: isOptionsTradeEnabled,
			}),
		dispatchUpdateOptionsPageParams: (params) =>
			dispatch({ type: actionTypes.UPDATE_OPTIONS_PAGE_PARAMS, params }),
		dispatchLoadEarningsEstimatesCurrentData: (apiInstance, venueXid) =>
			dispatch(ActionCreators.fetchEarningsEstimatesCurrentData(apiInstance, venueXid)),
		dispatchLoadEarningsEstimatesTrendsData: (apiInstance, venueXid) =>
			dispatch(ActionCreators.fetchEarningsEstimatesTrendsData(apiInstance, venueXid)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Options);
