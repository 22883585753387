import React, { Component } from "react";
import { connect } from "react-redux";
import actionTypes from "store/actions/actionTypes";
import Drawer from "components/Lib/Drawer/Drawer";

import { Dropdown } from "react-bootstrap";
import Styles from "./OptionsStrikesFilter.module.scss";
import Chevron from "components/Lib/Chevron/Chevron";
import StrikesFilter from "components/Options/StrikesFilter/StrikesFilter";

export class OptionsStrikesFilter extends Component {
	constructor(props) {
		super(props);
		this.state = {
			strikeDisplay: "Show 10 Strikes",
			chevronDirection: "down",
			showDropdownMenu: false,
		};
	}

	onValueChange = (selectedValue) => {
		const valueMap = {
			10: "Show 10 Strikes",
			20: "Show 20 Strikes",
			40: "Show 40 Strikes",
			all: "Show all Strikes",
		};

		let setValue;
		if (selectedValue.strikes === "custom") {
			setValue = `Strikes: ${selectedValue.customLow} - ${selectedValue.customHigh}`;
		} else {
			setValue = valueMap[selectedValue.strikes]
				? valueMap[selectedValue.strikes]
				: valueMap[10];
		}

		this.setState({ strikeDisplay: setValue });
		this.props.dispatchStrikesFilterCountUpdate(selectedValue.strikes);

		if (selectedValue.strikes === "custom") {
			this.props.dispatchStrikesCustomLowHigh(
				selectedValue.customLow,
				selectedValue.customHigh
			);
		} else {
			this.props.dispatchStrikesCustomLowHigh("", "");
		}

		if (this.props.useDrawer) {
			this.props.toggleDrawer();
		}
	};

	toggleChevronDirection = (event) => {
		if (event.target.id === "strikesCancel" || event.target.id === "strikesApply") {
			this.setState({ chevronDirection: "down", showDropdownMenu: false });
		} else if (event.target.id === "strikesDropdown") {
			this.state.chevronDirection === "up"
				? this.setState({ chevronDirection: "down" })
				: this.setState({ chevronDirection: "up" });
			this.setState({ showDropdownMenu: !this.state.showDropdownMenu });
		}
	};

	handleMenuOnToggle = (isOpen, event, metadata) => {
		// make sure the dropdown menu stays open when items are clicked or selected with the keyboard,
		// except tabbing forward from an element with closeaftertab="true",
		// which is meant to be set on the last element in the menu's tab order.
		const isShiftTabOnDropdownButton =
			event.shiftKey && event.target && event.target.id === "strikesDropdown";
		const isLastElement = event.target && event.target.id === "strikesApply";

		const isIgnoreKeydownEvent =
			metadata.source === "keydown" &&
			!isShiftTabOnDropdownButton &&
			(!isLastElement || (isLastElement && event.shiftKey));

		const shouldMenuStayOpen = metadata.source === "select" || isIgnoreKeydownEvent;

		if (this.state.showDropdownMenu && shouldMenuStayOpen) {
			this.setState({ showDropdownMenu: true });
		} else {
			this.setState({ showDropdownMenu: false });
			this.setState({ chevronDirection: isOpen ? "up" : "down" });

			// reset the strikes filter by programmatically clicking Cancel if the modal is closed by
			// anything that isn't the user selecting Apply (by click or Spacebar/Enter)
			const isSpacebarOrEnter = event.keyCode === 32 || event.keyCode === 13;
			const isApplySelected =
				isSpacebarOrEnter && event.target && event.target.id !== "strikesApply";
			if (!isOpen && !isApplySelected) {
				const cancelButton = document.getElementById("strikesCancel");
				if (cancelButton) {
					cancelButton.click();
				}
			}
		}
	};

	handleDropdownToggleKeyDown = (e) => {
		// only focus on the selected radio button when down arrow is pressed (or tab, by default)
		if (e.keyCode === 40) {
			const selectedMenuInput = e.target.parentElement.querySelector("input:checked");
			if (selectedMenuInput) {
				selectedMenuInput.focus();
			}
		}
	};

	render() {
		if (this.props.useDrawer) {
			return (
				<>
					<button
						className={`${Styles.mobileLayoutButton} btn`}
						onClick={this.props.toggleDrawer}
					>
						{this.state.strikeDisplay}{" "}
						<Chevron direction="down" dimension={16} color="dodgerblue" />
					</button>
					<Drawer
						full={true}
						open={this.props.onDrawerOpen}
						onClose={this.props.toggleDrawer}
					>
						<StrikesFilter onValueChange={this.onValueChange} data={{ isMobile: true }} />
					</Drawer>
				</>
			);
		}

		return (
			<Dropdown
				show={this.state.showDropdownMenu}
				onToggle={this.handleMenuOnToggle}
				onClick={this.toggleChevronDirection}
				className={Styles.OptionsStrikesFilter}
			>
				<Dropdown.Toggle
					id="strikesDropdown"
					className={Styles.dropdownToggle}
					onKeyDown={this.handleDropdownToggleKeyDown}
				>
					{this.state.strikeDisplay}
					<Chevron direction={this.state.chevronDirection} dimension={14} color="black" />
				</Dropdown.Toggle>
				<Dropdown.Menu>
					<StrikesFilter onValueChange={this.onValueChange} />
				</Dropdown.Menu>
			</Dropdown>
		);
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		dispatchStrikesFilterCountUpdate: (strikes) =>
			dispatch({ type: actionTypes.STRIKES_FILTER_COUNT_UPDATE, strikeCount: strikes }),
		dispatchStrikesCustomLowHigh: (customLow, customHigh) =>
			dispatch({
				type: actionTypes.STRIKES_CUSTOM_LOW_HIGH_UPDATE,
				customPrice: { low: customLow, high: customHigh },
			}),
	};
};

export default connect(null, mapDispatchToProps)(OptionsStrikesFilter);
