import React from "react";
import { URLS } from "../../../utils/constants";
import actionTypes from "../../../store/actions/actionTypes";

import { useDispatch } from "react-redux";

const OptionsTradeButton = (props) => {
	const dispatch = useDispatch();

	const onTradeClick = async (e) => {
		e.preventDefault();
		dispatch({ type: actionTypes.XREF_OPTION_API_TRANSITION, name: "load" });

		try {
			const xrefResponse = await props.apiInstance.get(
				`${URLS.XREF_XID_PATH}/${props.tradeMessageData.optionXid}`
			);

			if (
				xrefResponse.data &&
				xrefResponse.data.data &&
				xrefResponse.data.data.supplementalData &&
				xrefResponse.data.data.supplementalData.length
			) {
				dispatch({ type: actionTypes.XREF_OPTION_API_TRANSITION, name: "success" });

				const postMessage = JSON.stringify({
					name: "tradeEvent",
					data: {
						optionSymbol: xrefResponse.data.data.supplementalData[0].value,
						underlyingSymbol: props.tradeMessageData.underlyingSymbol,
						strikePrice: props.tradeMessageData.strikePrice,
						expirationDate: props.tradeMessageData.expirationDate,
						optionType: props.tradeMessageData.optionType,
					},
				});

				window.parent.postMessage(postMessage, window.MD.PARENT_DOMAIN); // NOSONAR
			}
		} catch (error) {
			dispatch({ type: actionTypes.XREF_OPTION_API_TRANSITION, name: "failure" });
		}

		if (props.onClickCallback) {
			props.onClickCallback();
		}
	};

	return (
		<button
			id={props.id || null}
			className={`${props.customStyles} btn btn-link tradeButton`}
			onClick={onTradeClick}
		>
			{props.text}
		</button>
	);
};

export default OptionsTradeButton;
