import React from "react";

const FlagInfoIcon = () => {
	return (
		<svg
			width="15px"
			height="20px"
			viewBox="0 0 15 20"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
		>
			<title>Flag</title>
			<g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g
					id="Icons-Alerts-Flag"
					transform="translate(-4.500000, -2.000000)"
					fill="#187ABA"
				>
					<path
						d="M5.75,2 C6.16421356,2 6.5,2.33578644 6.5,2.75 L6.5,20.75 C6.5,21.1642136 6.16421356,21.5 5.75,21.5 C5.33578644,21.5 5,21.1642136 5,20.75 L5,2.75 C5,2.33578644 5.33578644,2 5.75,2 Z M19.2045455,3.38636364 L19.2045455,11.3863636 L8.20454545,11.3863636 L8.20454545,3.38636364 L19.2045455,3.38636364 Z"
						id="Icon"
					></path>
				</g>
			</g>
		</svg>
	);
};

export default FlagInfoIcon;
