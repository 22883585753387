import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import styles from "../../Lib/SimpleSpinner/SimpleSpinner.module.scss";

const SimpleSpinner = (props) => {
	return (
		<div className={styles.simpleSpinnerContainer}>
			<FontAwesomeIcon
				className={styles.spinner}
				icon={faSpinner}
				pulse
				size="lg"
				style={{
					color: "lightblue",
					width: "35px",
				}}
			/>
		</div>
	);
};

export default SimpleSpinner;
