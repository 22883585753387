import actionTypes from "./actionTypes";
import { URLS } from "../../utils/constants";

//action creator
const storeQuoteResults = (results) => {
	return {
		type: actionTypes.SAVE_QUOTE_DATA,
		quoteData: results,
	};
};

const fetchQuote = (
	apiInstance,
	venueXid,
	marketHoursTradeType,
	isMultiQuote = false,
	realtime = false
) => {
	//async here - dispatch here will get set by redux-thunk
	return async (dispatch, getState) => {
		const venueXids = Array.isArray(venueXid) ? venueXid : [venueXid];
		if (
			!apiInstance ||
			!venueXids ||
			!venueXids.length ||
			!venueXids[0] ||
			!marketHoursTradeType
		) {
			return;
		}
		dispatch({ type: actionTypes.QUOTE_API_TRANSITION, name: "load" });

		const { quoteReducer } = getState();
		if (quoteReducer.quoteDataStatus !== "FETCHING") {
			return;
		}

		try {
			const quoteResponse = await apiInstance.get(`${URLS.QUOTE_PATH}`, {
				headers: {
					realtime: realtime,
				},
				params: {
					venueXids: venueXids.join(","),
					marketHoursTradeType: marketHoursTradeType,
				},
			});

			const quote = quoteResponse.data.data || {};
			if (quote.quotes && quote.quotes.length > 0 && quote.quotes[0].data) {
				let quoteData = quote.quotes[0].data;
				if (isMultiQuote) {
					quoteData = quote.quotes;
				}
				dispatch({ type: actionTypes.QUOTE_API_TRANSITION, name: "success" });
				dispatch(storeQuoteResults(quoteData));
			}
		} catch (error) {
			dispatch({ type: actionTypes.QUOTE_API_TRANSITION, name: "failure" });
		}
	};
};

export default { fetchQuote };
