import formatter from "../../utils/formatUtils";
const today = formatter.moment().year();

export default {
	ALL_PROVIDERS: "All Providers",
	ALL_REPORTS: "All Reports",
	APPLY: "Apply",
	AUDIO: "Audio",
	CANCEL: "Cancel",
	COMPANY_SPECIFIC: "Company Specific",
	CUSTOM_DATE: "Custom Date",
	DATE_SELECTION: "Date Selection",
	DISCLOSURES_P1:
		"The information and data contained herein does not provide individually tailored investment advice. It has been prepared without regard to the individual financial circumstances and objectives of persons who receive it. Morgan Stanley	Wealth Management Canada Inc. (MSWC) recommends that investors independently evaluate particular investments and strategies, and encourages investors to seek	the advice of a financial advisor. The appropriateness of a particular investment or strategy will depend on an investor’s individual circumstances and objectives.",
	DISCLOSURES_P2:
		"The research reports that appear on this website are provided by third parties for information purposes only. These research reports do not provide individually tailored investment advice and are prepared without regard to your individual financial circumstances and objectives. The securities and strategies discussed in any report may not be appropriate for you. Your decision to buy,	hold or sell a security should depend on your individual circumstances (such as	your investment objectives and experience, time horizon, liquidity needs,	financial situation and existing holdings) and other considerations. We	recommend that you independently evaluate particular investments and strategies, and encourage you to seek the advice of a financial adviser. The research reports presented are not prepared subject to Canadian disclosure requirements.",
	DISCLOSURES_P3:
		"By providing a link to a third party article, MSWC is not implying an affiliation, sponsorship, endorsement, etc. with the third party. MSWC is not responsible for the information contained in the third party article. The opinions expressed by the author are solely their own and do not necessarily reflect those of MSWC. Securities, investments, strategies or products mentioned or discussed in the third party article are neither an endorsement nor solicitation by MSWC. The information and data provided by the third party web site or publication is as of the date of the article when it was written and is	subject to change without notice. Past performance is not a guarantee of future results.",
	DISCLOSURES_P4: `© Thomson Reuters ${today}. All Rights Reserved.`,
	DISCLOSURES_P5:
		"Thomson Reuters content is the intellectual property of Thomson Reuters or its	third party content providers. Republication or redistribution of Thomson Reuters content, including by framing or similar means, is prohibited without the prior written consent of Thomson Reuters. Thomson Reuters is not liable for any errors or delays in Thomson Reuters content, or for any actions taken in reliance on such content. 'Thomson Reuters' and the Thomson Reuters logo are trademarks of Thomson Reuters and its affiliated companies.",
	DISCLOSURES_P6:
		"The research reports and the information and data derived from such reports that are included on this website are provided for information purposes only. This content is not a solicitation of any offer to buy or sell any security or other financial instrument or to participate in any trading strategy. It is not individually tailored investment advice and is prepared and made available on this website without regard to your individual financial circumstances and objectives. The securities and strategies discussed or referred to in this content may not be appropriate for you. Your decision to buy, hold or sell a security should depend on your individual circumstances (such as your investment objectives and experience, time horizon, liquidity needs, financial situation and existing holdings) and other considerations. We recommend that you independently evaluate particular investments and strategies, and encourage you to seek the advice of your Financial Advisor. Please see the relevant research report for important disclosures. Morgan Stanley is an affiliate of Morgan Stanley Smith Barney LLC.",
	DISCLOSURES_P7:
		"Past performance is not a guarantee of future results. Indexes are unmanaged and not available for direct investment. For illustrative purposes only and does not reflect any specific product",
	ENTER_KEYWORDS: "Enter keywords",
	ENTER_SEARCH_SYMBOL: "Enter search symbol",
	FILTERS: "Filters",
	FOR: "for",
	FROM: "From",
	KEYWORD_SEARCH: "Keyword Search",
	LAST_7_DAYS: "Last 7 Days",
	LAST_30_DAYS: "Last 30 Days",
	LAST_3_MONTHS: "Last 3 Months",
	LAST_6_MONTHS: "Last 6 Months",
	LAST_12_MONTHS: "Last 12 Months",
	LATEST_RESEARCH: "Latest Research",
	MARKET_RESEARCH: "Market Research",
	MARKET_RESEARCH_WITH_SYMBOL: "All Research for ",
	NEXT_PAGE: "Next Page",
	OF: "of",
	PREVIOUS_PAGE: "Previous Page",
	PROVIDER: "Provider",
	RECENT_SEARCHES: "Recent Searches",
	REPORT_TYPE: "Report Type",
	RESEARCH: "Research",
	RESET: "Reset",
	RESET_FILTERS: "Reset Filters",
	SELECT_CUSTOM_DATE_RANGE: "Select Custom Date Range",
	SHOW_HEADLINE_ONLY: "Show Headline Only",
	SKIP_TO_BEGINNING: "Skip to Beginning",
	SKIP_TO_END: "Skip to End",
	SOURCE: "Source",
	SYMBOL_SEARCH: "Symbol Search",
	THERE_ARE_NO_RESEARCH_REPORTS_AVAILABLE: "There are no research reports available",
	THERE_ARE_NO_REPORTS_TIMEFRAME: "within the selected timeframe",
	TO: "To",
	TODAY: "Today",
	VIDEO: "Video",
	VIEW_FILTERS: "View Filters",
	YESTERDAY: "Yesterday",
};
