import React from "react";

const CandlestickLegend = () => {
	return (
		<svg
			aria-hidden="true"
			focusable="false"
			className="candlestickLegend"
			width="7px"
			height="18px"
			viewBox="0 0 7 18"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g
				id="Desktop---Interactive-States"
				stroke="none"
				strokeWidth="1"
				fill="none"
				fillRule="evenodd"
			>
				<g
					id="07_MSO-Company-Overview-1440-Charting-Module-States"
					transform="translate(-49.000000, -1439.000000)"
				>
					<g id="Positive-Candle-Symbol" transform="translate(49.000000, 1439.000000)">
						<line x1="3.5" y1="17.5" x2="3.5" y2="0.5" id="Path" stroke="#767676"></line>
						<path d="M6.5,5 L6.5,13 L0.5,13 L0.5,5 L6.5,5 Z" id="Path"></path>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default CandlestickLegend;
