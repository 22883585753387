import React, { forwardRef, useImperativeHandle, useRef } from "react";
import styles from "components/MarketsAndResearch/ChartingCommon/Loader/Loader.module.scss";

const Loader = forwardRef((props, ref) => {
	const divRef = useRef(null);
	const chartRef = props.chartRef;
	useImperativeHandle(ref, () => ({
		chartLoaderStart,
		chartLoaderStop,
	}));
	const chartLoaderStart = () => {
		if (divRef && divRef.current) {
			if (!document.getElementById("alertLoader")) {
				let chartloader = document.createElement("div");
				chartloader.setAttribute("id", "alertLoader");
				chartloader.setAttribute("aria-label", "Loading Chart");
				chartloader.setAttribute("role", "alert");
				document.body.appendChild(chartloader);
			}
			chartRef.current.style.opacity = 0.6;
			divRef.current.style.display = "block";
		}
	};
	const chartLoaderStop = () => {
		if (divRef && divRef.current) {
			let chartLoader = document.getElementById("alertLoader");
			chartLoader && chartLoader.remove();
			chartRef.current.style.opacity = 1;
			divRef.current.style.display = "none";
		}
	};

	return (
		<div ref={divRef}>
			<div className={styles.modchartsLoading}></div>
		</div>
	);
});
export default Loader;
