import { URLS } from "utils/constants";

export const getPeersDetails = async (apiInstance, venueXid) => {
	if (!apiInstance || !venueXid) {
		return "error";
	}

	let peersData = [];

	try {
		const response = await apiInstance.get(`${URLS.PEERS_PATH}/${venueXid}`);

		const data = response.data.data || null;
		if (data) {
			data.forEach((element) => {
				peersData.push({ wsodIssue: element.wsodIssue, peerRank: element.PeerRank });
			});
		}
		return peersData;
	} catch (error) {
		return peersData;
	}
};

export default { getPeersDetails };
