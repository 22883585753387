import React from "react";
import { useDispatch } from "react-redux";
import styles from "./RedesignBanner.module.scss";
import classNames from "classnames";

import { URLS } from "utils/constants";
import Utils from "utils/utils";
import actionTypes from "../../../store/actions/actionTypes";

import FlagInfoIcon from "assets/icons/FlagInfoIcon";

const RedesignBanner = (props) => {
	const dispatch = useDispatch();

	const handleReturnToSiteClick = async (e) => {
		e.preventDefault();
		await updateUserPreference();
	};

	const handleFeedbackClickAdobeTracking = () => {
		Utils.adobeEventTracking(
			"stock quote pilot give feedback",
			"stock quote pilot new",
			"event"
		);
	};

	const updateUserPreference = async () => {
		try {
			const response = await props.apiInstance.instance({
				method: "POST",
				url: `${URLS.MSO_API}/redesign/preference`,
				baseURL: window.MD.MSO_DOMAIN
					? window.MD.MSO_DOMAIN
					: "https://markets-dev.morganstanleyclientserv.com",
				data: {
					optedInFlagV3: "n",
					closedCounter: 0,
				},
			});
			if (response.status !== 200) {
				dispatch({ type: actionTypes.USER_PREFERENCE_API_TRANSITION, name: "failure" });
				return;
			}
			//redirect
			dispatch({ type: actionTypes.USER_PREFERENCE_API_TRANSITION, name: "success" });
			const queryStringParams = new URLSearchParams(window.location.search);
			queryStringParams.set("source", "return to existing view");
			window.location.href = `${window.location.origin}/MSOnline/Stocks/Overview?${queryStringParams}`;
		} catch (error) {
			dispatch({ type: actionTypes.USER_PREFERENCE_API_TRANSITION, name: "failure" });
		}
	};

	const renderGiveFeedbackLink = () => {
		let href =
			props.userInfo && props.userInfo.uuid
				? `https://mswm.ca1.qualtrics.com/jfe/form/SV_blbgeZwDKepdVaZ?UUID=${props.userInfo.uuid}`
				: "https://mswm.ca1.qualtrics.com/jfe/form/SV_blbgeZwDKepdVaZ";

		return (
			<a
				data-testid="redesign-banner-feedback"
				target="_blank"
				rel="noreferrer noopener"
				href={href}
				onClick={handleFeedbackClickAdobeTracking}
			>
				Give Feedback
			</a>
		);
	};

	return (
		<section
			id={styles.redesignBannerContainer}
			aria-label="new quote experience banner."
		>
			<FlagInfoIcon aria-hidden />
			<div className={styles.message}>
				<span className={styles.messageText}>
					In a few weeks, the classic view will no longer be available.
				</span>
				<div className={styles.controls}>
					<button
						className={classNames(styles.actionBtn, "btn", "btn-link")}
						onClick={handleReturnToSiteClick}
					>
						Return To Classic View
					</button>
					{renderGiveFeedbackLink()}
				</div>
			</div>
		</section>
	);
};

export default RedesignBanner;
