import FEATURE_FLAGS from "utils/featureFlags";
const OverrideIndicatorsFilterAll = "all";
const OverrideIndicatorsFilterLive = "live";

const AvailableIndicatorFilterExtension = () => {
	let chartIndicator = getchartIndicatorFlagFromQueryStringParams();
	let overrideFilterUsingQueryString =
		chartIndicator === null ? "" : chartIndicator.toLowerCase();
	if (
		(FEATURE_FLAGS.LIST_ALL_INDICATORS &&
			overrideFilterUsingQueryString !== OverrideIndicatorsFilterLive) ||
		(!FEATURE_FLAGS.LIST_ALL_INDICATORS &&
			overrideFilterUsingQueryString === OverrideIndicatorsFilterAll)
	) {
		return false;
	} else if (
		!FEATURE_FLAGS.LIST_ALL_INDICATORS ||
		(FEATURE_FLAGS.LIST_ALL_INDICATORS &&
			overrideFilterUsingQueryString === OverrideIndicatorsFilterLive)
	) {
		return true;
	}
};

const getchartIndicatorFlagFromQueryStringParams = () => {
	const params = new URLSearchParams(window.location.search);
	const newParams = new URLSearchParams();
	for (const [name, value] of params) {
		newParams.append(name.toLowerCase(), value);
	}
	return newParams.get("chartindicator");
};

export default AvailableIndicatorFilterExtension;
