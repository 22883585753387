import actionTypes from "./actionTypes";
import { URLS } from "../../utils/constants";

//action creator
const storeXrefSymbolsResults = (results) => {
	return {
		type: actionTypes.SAVE_XREF_SYMBOLS_DATA,
		xrefSymbolsData: results,
	};
};

const fetchXrefSymbols = (apiInstance, venueXids) => {
	//async here - dispatch here will get set by redux-thunk
	return async (dispatch, getState) => {
		if (!apiInstance || !venueXids || !venueXids.length) {
			return;
		}
		dispatch({ type: actionTypes.XREF_SYMBOLS_API_TRANSITION, name: "load" });

		const { xrefReducer } = getState();
		if (xrefReducer.xrefSymbolsDataStatus !== "FETCHING") {
			return;
		}

		try {
			const response = await apiInstance.get(
				// Use the /venue-xids path to get symbol data from the given venueXids
				URLS.XREF_XID_PATH,
				{
					params: {
						venueXids: venueXids.join(","),
					},
				}
			);

			const data = response.data.data.items || null;
			if (data) {
				dispatch({ type: actionTypes.XREF_SYMBOLS_API_TRANSITION, name: "success" });
				dispatch(storeXrefSymbolsResults(data));
			}
		} catch (error) {
			dispatch({ type: actionTypes.XREF_SYMBOLS_API_TRANSITION, name: "failure" });
			dispatch(storeXrefSymbolsResults(null));
		}
	};
};

export default {
	fetchXrefSymbols,
};
