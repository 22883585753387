import React, { useState, useRef, useEffect } from "react";
import { useQuery } from "react-query";
import styles from "./CGSearchBar.module.scss";
import { URLS } from "../../../utils/constants";
import Form from "react-bootstrap/Form";
import { Typeahead, Menu, MenuItem, Hint } from "react-bootstrap-typeahead";
import { ReactComponent as AmericanFlag } from "../../../assets/icons/AmericaFlag.svg";
import { ReactComponent as CanadianFlag } from "../../../assets/icons/CanadaFlag.svg";

import XRefAPIUtils from "services/APIs/Xref/XrefAPI";
import languageEn from "assets/language/en";

const CGSearchBar = React.memo((props) => {
	const typeaheadRef = useRef(null);
	const [inputTerm, setInputTerm] = useState("");
	const [xrefResult, setXrefResult] = useState([]);
	const [selectedItems, setSelectedItems] = useState([]);
	const [recentSearches, setRecentSearches] = useState([]);
	const [focus, setFocus] = useState(false);
	const [displayMenu, setDisplayMenu] = useState(true);
	// displayRecentSearch should be changed to false if you are searching for a new symbol.
	const [displayRecentSearch, setDisplayRecentSearch] = useState(true);

	const onInputChange = (input) => {
		if (input !== "") setDisplayRecentSearch(false);
		if (input) setInputTerm(input);
	};
	const predictiveSearchQuery = useQuery(
		[URLS.XREF_PREDICTIVE_SEARCH_PATH, props.apiInstance?.internalAuthToken, inputTerm],
		() => XRefAPIUtils.getPredictiveSearchResults(props.apiInstance, inputTerm)
	);

	useEffect(() => {
		const options = [];
		if (selectedItems && selectedItems.length > 0) {
			options.push(...selectedItems);
		}

		if (predictiveSearchQuery.data) {
			options.push(...predictiveSearchQuery.data?.items);
		}
		setXrefResult(options);
		setDisplayRecentSearch(false);
	}, [predictiveSearchQuery.data]);

	const onSelected = (selectedItem) => {
		const tempSelected = [...recentSearches];
		let foundItem = false;
		props.setResearchOffset(0);

		if (selectedItem[0]) {
			for (let item of tempSelected) {
				//ensure we are not tracking the same symbol selected again.
				if (item && item.symbol && item.symbol === selectedItem[0].symbol) {
					foundItem = true;
					break;
				}
			}
			if (!foundItem) tempSelected.push(selectedItem[0]);

			props.setStateCallback(
				selectedItem[0].symbol,
				selectedItem[0]?.xids?.venue,
				selectedItem[0]?.xids?.legacyCompany
			);
		}
		setSelectedItems(selectedItem);
		setRecentSearches(tempSelected);
	};
	/**
	 *  clear input and selection once user selectes search box
	 **/
	const onFocus = () => {
		setDisplayMenu(true);
		if (typeaheadRef?.current?.state?.text) {
			typeaheadRef.current.state.text = "";
		}
		setXrefResult(recentSearches);
		setSelectedItems([]);
	};

	const handleFocusEvent = (event) => {
		if (event && event.type === "keyup") {
			// setting keyup event changes on tab only
			if (event.keyCode === 9) {
				setFocus(true);
			} else {
				setFocus(false);
			}
		}
	};

	const handleBlur = () => {
		setDisplayRecentSearch(true);
		setFocus(false);
		setDisplayMenu(false);
	};

	const customInput = ({ inputRef, referenceElementRef, ...inputProps }) => {
		const container = {
			display: "flex",
		};
		const inputClass = `${focus ? "focusVisible" : "focusReset"}`;
		return (
			<div style={container} className={inputClass}>
				<svg
					viewBox="-5 -5 24 24"
					className={`bi bi-search ${styles.svgContent}`}
					fill="currentColor"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fillRule="evenodd"
						d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"
					/>
					<path
						fillRule="evenodd"
						d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"
					/>
				</svg>
				<Hint>
					<Form.Control
						{...inputProps}
						ref={(node) => {
							inputRef(node);
							referenceElementRef(node);
						}}
						onClick={() => setFocus(false)}
						onKeyUp={handleFocusEvent}
						onBlur={handleBlur}
					/>
				</Hint>
			</div>
		);
	};

	const customMenu = (results, menuProps) => {
		const isLanguageRecentSearch = props.language && props.language.RECENT_SEARCHES;
		const languageRecentSearch = isLanguageRecentSearch
			? props.language.RECENT_SEARCHES
			: languageEn.RECENT_SEARCHES;
		// If user clicks outside the our iframe this will make the dropdown menu disapear.
		const menuClassName = displayMenu ? "d-block" : "d-none";
		return (
			<Menu {...menuProps} className={menuClassName}>
				{/* Need to have this conditionally render only if there is recent search history. Match legacy site behavior*/}
				{displayRecentSearch ? <Menu.Header>{languageRecentSearch}</Menu.Header> : <></>}
				{results.map((result, index) => (
					<MenuItem option={result} position={index} key={index}>
						<div className={styles.menuItems}>
							<div className={styles.mainRow}>
								<div className={styles.wrapContent}>{result.name}</div>
								{result.exchange.countryIso === "US" ? (
									<AmericanFlag />
								) : (
									<CanadianFlag />
								)}
							</div>
							<div className={styles.symbol}>{result.symbol}</div>
						</div>
					</MenuItem>
				))}
			</Menu>
		);
	};

	return (
		<div className={styles.cgSearchContainer}>
			<Typeahead
				id="quote-search"
				labelKey={(option) => `${option.name} ${option.symbol}`}
				onChange={onSelected}
				onInputChange={onInputChange}
				placeholder={
					props.language && props.language.SYMBOL_SEARCH
						? props.language.SYMBOL_SEARCH
						: languageEn.SYMBOL_SEARCH
				}
				selected={selectedItems}
				options={xrefResult}
				renderMenu={customMenu}
				renderInput={customInput}
				onFocus={onFocus}
				ref={typeaheadRef}
			/>
		</div>
	);
});

export default CGSearchBar;
