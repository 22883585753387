import React from "react";
import MSDropdown from "components/Lib/MSDropdown/MSDropdown";
import styles from "components/MarketsAndResearch/Charting/ChartingCard/ChartingCard.module.scss";

const Interval = React.memo(
	({ selectedInterval, selectedTimeFrame, onSelectCallback }) => {
		return (
			<>
				<div className={styles.chartControlDivider} />
				<div className={styles.chartControlRegion}>
					<div className={styles.chartIntervalControl}>
						<MSDropdown
							data={selectedTimeFrame.periods.map((period) => {
								return {
									option: period.label,
								};
							})}
							titlePrefix="Interval"
							title={selectedInterval.label}
							dropdownIconColor="#187ABA"
							onSelectCallback={onSelectCallback}
						></MSDropdown>
					</div>
				</div>
			</>
		);
	}
);

export default Interval;
