import formatter from "utils/formatUtils";
import { URLS } from "utils/constants";

/**
 * Utility for fetching API data for Equities Morningstar Financial Ratios Details Data
 * @param {Object} apiInstance - api instance
 * @param {Number} venueXid - the Xid for the symbol selected
 * @param {Boolean} includeYearEndData - If true, available data from 3M, 1Y, 3Y, 5Y, and 10Y periods will be returned. Otherwise available data from 3M and 5Y periods will be returned
 */
export const getFinancialRatiosDetails = async (
	apiInstance,
	venueXid,
	includeYearEndData = true
) => {
	if (!apiInstance || !venueXid) return "error";

	try {
		const response = await apiInstance.get(
			`${URLS.FINANCIAL_RATIOS_DETAILS_PATH}/${venueXid}`,
			{
				params: {
					includeYearEndData,
				},
			}
		);
		const { data } = response.data;
		if (!data) return {};

		const {
			threeMonthOperations = {},
			oneYearOperations = {},
			ttmOperations = {},
		} = data.operations?.reduce((acc, operation) => {
			switch (operation.period) {
				case "3M":
					if (acc.threeMonthOperations) break;
					acc.threeMonthOperations = operation;
					break;
				case "1Y":
					if (acc.oneYearOperations) break;
					acc.oneYearOperations = operation;
					break;
				case "TTM":
					if (acc.ttmOperations) break;
					acc.ttmOperations = operation;
					break;
				default:
					break;
			}
			return acc;
		}, {}) || {};
		const thing = {
			venueXid,
			threeMonthOperations,
			oneYearOperations,
			ttmOperations,
			operations: data.operations,
			valuation: data.valuation,
			earningsGrowths: data.earningsGrowths,
			priceEarningsRatio: formatter.number(data.valuation?.peRatio?.ttm, 2),
			priceEarningsRatioFinancials: formatter.numberWithSuffix(
				data.valuation?.peRatio?.ttm,
				2,
				"x"
			),
			priceSalesRatio: formatter.numberWithSuffix(data.valuation?.psRatio?.ttm, 2, "x"),
			priceBookRatio: formatter.numberWithSuffix(
				data.valuation?.pbRatio?.current,
				2,
				"x"
			),
			dividendYield: formatter.percent(data.valuation?.dividend?.trailingYield, 2),
			forwardYield: formatter.percent(data.valuation?.dividend?.forwardYield, 2),
			payoutRatio: formatter.percent(data.valuation?.dividend?.payoutRatio, 2, {
				showSign: false,
			}),
			buyBackYield: formatter.percent(data.valuation?.dividend?.buyBackYield, 2),
			earningsGrowth: formatter.percent(data.earningsGrowths?.dps, 2),
			currencyId: data.operations?.[0]?.financialsTtm.currencyId,
			freeCashFlow: formatter.numberWithSuffix(data.valuation?.fcf?.perShare, 2),
			peRatioPegForward: formatter.number(data.valuation?.peRatio?.peg?.forward, 2),
			grossProfitMargin: formatter.percent(threeMonthOperations.margin?.gross, 2),
			operatingProfitMargin: formatter.percent(threeMonthOperations.margin?.operation, 2),
			cashFlowMargin: formatter.percent(threeMonthOperations.margin?.ebitda, 2),
			netProfitMarginTTM: formatter.percent(ttmOperations.margin?.netProfitNormalized, 2),
			returnOnEquityTTM: formatter.percent(ttmOperations.roe, 2),
			grossProfitMarginTTM: formatter.percent(ttmOperations.margin?.gross, 2),
			operatingProfitMarginTTM: formatter.percent(ttmOperations.margin?.operation, 2),
			cashFlowMarginTTM: formatter.percent(ttmOperations.margin?.ebitda, 2),
			cashFlowPerShare: formatter.price(
				data.valuation?.cashFlow?.perShare,
				undefined,
				undefined,
				""
			),
			cashPerShare: formatter.price(
				data.valuation?.cash?.perShare,
				undefined,
				undefined,
				""
			),
			netProfitMargin: formatter.percent(
				oneYearOperations.margin?.netProfitNormalized,
				2
			),
			returnOnEquity: formatter.percent(oneYearOperations.roe, 2),
			revenue: formatter.currencyMagnitude(
				oneYearOperations.financialsTtm?.totalRevenueTtm,
				2,
				oneYearOperations.financialsTtm?.currencyId
			),
			valuationPerShareSales: formatter.number(data.valuation?.perShare?.sales, 2),
			longTermDebtEquityRatio: formatter.percent(
				threeMonthOperations.leverage?.longTermDebtEquityRatio,
				2
			),
		};
		return thing;
	} catch (error) {
		return {};
	}
};

/**
 * Utility for fetching API data for Equities Morningstar Financial Ratios History Data
 * @param {Object} apiInstance - api instance
 * @param {Number} venueXid - the Xid for the symbol selected
 * @param {Boolean} includeYearEndData - If true, available data from 3M, 1Y, 3Y, 5Y, and 10Y periods will be returned. Otherwise available data from 3M and 5Y periods will be returned
 * @param {Number} numberOfPeriods -The number of periods being requested (range: 1 ≤ x ≤ 10, default: 5)
 */
export const getFinancialRatiosHistory = async (
	apiInstance,
	venueXid,
	includeYearEndData,
	numberOfPeriods = 5
) => {
	if (!apiInstance || !venueXid) return;

	try {
		const response = await apiInstance.get(
			`${URLS.FINANCIAL_RATIOS_HISTORY_PATH}/${venueXid}`,
			{
				params: {
					includeYearEndData,
					numberOfPeriods,
				},
			}
		);
		const { data = {} } = response.data;

		return data;
	} catch (error) {
		return {};
	}
};

export default { getFinancialRatiosDetails, getFinancialRatiosHistory };
