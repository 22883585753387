import React from "react";

import ChartingCard from "./ChartingCard/ChartingCard";
import styles from "./Charting.module.scss";

const Charting = (props) => {
	return (
		<section className={styles.mainContainer}>
			<ChartingCard
				apiInstance={props.apiInstance}
				contentReady={props.contentReady}
				symbol={props.symbol}
				userInfo={props.userInfo}
				venueXid={props.venueXid}
			/>
		</section>
	);
};

export default Charting;
