import React, { useState, useRef } from "react";
import MSDropdown from "components/Lib/MSDropdown/MSDropdown";
import styles from "components/MarketsAndResearch/Charting/ChartingCard/ChartingCard.module.scss";
import languageEn from "assets/language/en";
import formatUtils from "utils/formatUtils";
import AdvanceTimeFrameConstant from "components/MarketsAndResearch/ChartingCommon/Constants/TimeFrame/AdvanceTimeFrame";
import DateRangePickerModal from "components/MarketsAndResearch/AnalystResearch/AllResearchCard/ResearchFilters/DateRangePickerModal/DateRangePickerModal";

const TimeFrame = React.memo(
	({
		selectedTimeFrame,
		onFilterChange,
		datePicker,
		updateStateFromTimeFrame,
		chart,
	}) => {
		let customDateRange;
		let maxNumberOfYears = 25;
		if (datePicker.startDate && datePicker.endDate) {
			customDateRange = `${formatUtils.date(
				datePicker.startDate,
				"MM/DD/YY"
			)} - ${formatUtils.date(datePicker.endDate, "MM/DD/YY")}`;
		} else {
			customDateRange = false;
		}

		const [modalVisibility, setModalVisibility] = useState(false);
		const datepickerRef = useRef(null);

		const minStartDate = () => {
			return formatUtils.moment().subtract(maxNumberOfYears, "year");
		};

		const onModalClose = () => {
			setModalVisibility(false);
		};

		const interceptDateFilterSelection = (value) => {
			if (
				AdvanceTimeFrameConstant.UTILITY.findByLabel(value).key ===
				AdvanceTimeFrameConstant.CUSTOM_DATE_ID
			) {
				setModalVisibility(true);
			} else {
				onFilterChange(AdvanceTimeFrameConstant.UTILITY.findByLabel(value).key);
			}
		};

		const onDatePickerDateChange = (startDate, endDate) => {
			let getTimeFrame;
			if (startDate && endDate) {
				getTimeFrame = AdvanceTimeFrameConstant.TIME_FRAMES.filter(
					(timeFrame) => timeFrame.key === AdvanceTimeFrameConstant.CUSTOM_DATE_ID
				)[0];
			} else {
				getTimeFrame = AdvanceTimeFrameConstant.TIME_FRAMES.filter(
					(timeFrame) => timeFrame.key === datePicker.dateSpan
				)[0];
			}

			getIntervalForDatePicker(getTimeFrame, startDate, endDate);

			//update state at Parent Level.
			updateStateFromTimeFrame(getTimeFrame);
		};

		const getIntervalForDatePicker = (getTimeFrame, startDate, endDate) => {
			let getTradingDaysData = chart.getTradingDays(startDate, endDate);

			const intradayLimit = new Date();
			intradayLimit.setDate(intradayLimit.getDate() - 30);

			const diffTime = Math.abs(endDate - startDate);
			let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
			diffDays = diffDays === 0 ? 1 : diffDays;

			let timeFrameAndInterval = { periods: {}, defaultPeriod: {} };

			if (startDate >= intradayLimit) {
				if (diffDays <= 5 || getTradingDaysData <= 5) {
					timeFrameAndInterval = AdvanceTimeFrameConstant.UTILITY.findByKey(1);
				} else if (diffDays < 30 && diffDays >= 6) {
					timeFrameAndInterval = AdvanceTimeFrameConstant.UTILITY.findByKey(10);
				}
			} else {
				if (diffDays <= 5 || getTradingDaysData + 1 <= 5) {
					timeFrameAndInterval = AdvanceTimeFrameConstant.UTILITY.findByKey(11);
				} else if (diffDays < 30 && diffDays >= 6) {
					timeFrameAndInterval = AdvanceTimeFrameConstant.UTILITY.findByKey(3);
				} else if (diffDays < 365 && diffDays >= 30) {
					timeFrameAndInterval = AdvanceTimeFrameConstant.UTILITY.findByKey(3);
				} else if (diffDays >= 365 && diffDays < 1825) {
					timeFrameAndInterval = AdvanceTimeFrameConstant.UTILITY.findByKey(8);
				} else if (diffDays >= 1825) {
					timeFrameAndInterval = AdvanceTimeFrameConstant.UTILITY.findByKey(9);
				}
			}
			getTimeFrame.periods = timeFrameAndInterval.periods;
			getTimeFrame.defaultPeriod = timeFrameAndInterval.defaultPeriod;
			getTimeFrame.numDays = diffDays;
			getTimeFrame.startDate = startDate;
			getTimeFrame.endDate = endDate;
		};

		return (
			<>
				<div className={styles.chartControlDivider} />
				<div ref={datepickerRef} className={styles.chartControlRegion}>
					<div className={styles.chartTimeFrameControl}>
						<MSDropdown
							data={AdvanceTimeFrameConstant.TIME_FRAMES.filter((x) => x.enabled).map(
								(timeFrame) => {
									return {
										option: timeFrame.label,
										action: timeFrame.key,
									};
								}
							)}
							titlePrefix="Timeframe"
							title={selectedTimeFrame.label}
							dropdownIconColor="#187ABA"
							onSelectCallback={(value) => interceptDateFilterSelection(value)}
							controlled={true}
							aria-labelledby="desktop_date_range"
							titleOverride={customDateRange}
						/>
						<DateRangePickerModal
							onDatePickerDateChange={onDatePickerDateChange}
							modalVisibility={modalVisibility}
							onModalClose={onModalClose}
							language={languageEn}
							minStartDate={minStartDate}
							maxNumberOfYears={maxNumberOfYears}
						/>
					</div>
				</div>
			</>
		);
	}
);
export default TimeFrame;
