import format from "@markit/format";

export const options = {
	errorString: "\u2014",
};

format.extend({
	daysFromCurrentDate(date) {
		const currentDate = this.moment(new Date()).startOf("day");
		const futureDate = this.moment(date).startOf("day");

		return Math.abs(futureDate.diff(currentDate, "days"));
	},
	ratio(val, precision) {
		const formattedNum = format(options).number(val, precision);

		return formattedNum && formattedNum !== options.errorString
			? `${formattedNum}x`
			: formattedNum;
	},
	removeProtocol(val) {
		if (!val) {
			return val;
		}

		const regex = /^https?:\/\//;
		return val.replace(regex, "");
	},
	removeDomainPrefix(val) {
		if (!val) {
			return val;
		}

		const regex = /^(www\.)/;
		return val.replace(regex, "");
	},
	price(val, precision = 2, showSign = false, currency = "USD") {
		if (!val && val !== 0) {
			return options.errorString;
		}

		const priceFormatter = precision
			? format(Object.assign(options, { precision: { number: precision } }))
			: format(options);
		let formattedVal = priceFormatter.number(val);
		let currencyFormat = "$";
		let percentFormat = "";
		if (currency !== "USD") {
			currencyFormat = currency + ` `;
		}
		if (currency === "PCT") {
			currencyFormat = "";
			percentFormat = `%`;
		}
		if (currency === "") {
			return `${formattedVal}`;
		}

		return formattedVal.charAt(0) === "-"
			? `-${currencyFormat}${formattedVal.substring(1)}${percentFormat}`
			: showSign
			? `+${currencyFormat}${formattedVal}${percentFormat}`
			: `${currencyFormat}${formattedVal}${percentFormat}`;
	},
	numberWithSuffix(val, precision, suffix) {
		const formattedNum = formatter.number(val, precision);
		return formattedNum !== options.errorString
			? `${formattedNum}${suffix ?? ""}`
			: formattedNum;
	},
	numberMagnitude(val, precision) {
		const formattedNum = formatter.number(val, precision, {
			showMagnitude: true,
		});

		return formattedNum.toUpperCase().replace(" ", "");
	},
	currencyMagnitude(val, precision = 2, currency = "USD") {
		let currencyFormat = "$";
		if (currency !== "USD") {
			currencyFormat = currency + ` `;
		}

		return val || val === 0
			? `${currencyFormat}${formatter.numberMagnitude(val, precision)}`
			: options.errorString;
	},
	text(val) {
		if (typeof val !== "undefined" && val !== null && val !== "") {
			return val.toString();
		}

		return options.errorString;
	},
	/**
	 * Show minimum to minPrecision, significant digits allowed upto maxPrecision and remove any trailing zeros beyond maxPrecision
	 * @param {string} val input value
	 * @param {number} minPrecision minimum precision
	 * @param {number} maxPrecision max. significant digits allowed
	 * @returns {string} Formatted price string
	 * priceWithoutTrailingZero(12345, 2, 2) // $12,345.00
	 * priceWithoutTrailingZero(12345.67895000, 2, 4) // $12,345.6789
	 * priceWithoutTrailingZero(12345.6780000, 2, 4) // $12,345.678
	 * priceWithoutTrailingZero(12345.6700000, 2, 4) // $12,345.67
	 * priceWithoutTrailingZero(12345.6000000, 2, 4) // $12,345.60
	 * priceWithoutTrailingZero(12345.6, 2, 4) // $12,345.60
	 */
	priceWithoutTrailingZero(val, minPrecision, maxPrecision, currency = "USD") {
		// removes trailing zeros
		if (val === null || typeof val === "undefined") return options.errorString;
		val = val.toString();
		const decimalIndex = val.indexOf(".");
		if (decimalIndex > -1) {
			const decimalPlaces = val.substring(decimalIndex + 1);
			if (decimalPlaces.length > maxPrecision) {
				val = val.substring(0, decimalIndex + maxPrecision + 1);
			} else if (decimalPlaces.length < minPrecision) {
				maxPrecision = minPrecision;
			} else {
				maxPrecision = decimalPlaces.length;
			}
			return formatter.price(val, maxPrecision, undefined, currency);
		} else {
			return formatter.price(val, minPrecision, undefined, currency);
		}
	},

	marketCapText(marketCap, styleBoxCode) {
		const styleBoxMap = {
			1: "Large",
			2: "Large",
			3: "Large",
			4: "Mid",
			5: "Mid",
			6: "Mid",
			7: "Small",
			8: "Small",
			9: "Small",
		};

		const capSizeText = styleBoxMap[styleBoxCode]
			? `${styleBoxMap[styleBoxCode]} Cap`
			: this.options.errorString;
		const marketCapValue = marketCap || this.options.errorString;
		return `${capSizeText} (${marketCapValue})`;
	},
	quoteTimeStamp(isoDate) {
		// ISO dates are in UTC, so convert to ET when formatting the time stamp
		let quoteTimestamp = isoDate
			? `${this.moment(isoDate).tz("America/New_York").calendar().replace(" at", "")}`
			: this.options.errorString;

		if (quoteTimestamp !== this.options.errorString && !quoteTimestamp?.includes("/")) {
			quoteTimestamp = `${quoteTimestamp} ET`;
		}

		return quoteTimestamp;
	},
	isValid(value) {
		return (!!value && value !== options.errorString) || value === 0;
	},
});

const formatter = format(options);

export default formatter;
