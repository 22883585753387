import PeerComparisonProfitabilityCard from "components/MarketsAndResearch/Overview/PeerComparisonCard/PeerComparisonProfitabilityCard/PeerComparisonProfitabilityCard";
import { URLS } from "utils/constants";

const EMPTY_XREFS = [];
/**
 * Utility for fetching XREF API data for list of venueXids
 * @param {Object} apiInstance - api instance
 * @param {Object[]} venueXids - an array of Xids
 */
const getXrefs = async (apiInstance, venueXids) => {
	if (!apiInstance || !venueXids || !venueXids.length) {
		return EMPTY_XREFS;
	}

	try {
		const response = await apiInstance.get(
			// Use the /venue-xids path to get symbol data from the given venueXids
			URLS.XREF_XID_PATH,
			{
				params: {
					venueXids: venueXids.join(","),
				},
			}
		);

		if (response?.data?.data?.items?.length) {
			return response.data.data.items;
		}

		return EMPTY_XREFS;
	} catch (error) {
		return EMPTY_XREFS;
	}
};

const getXrefBySymbol = async (apiInstance, symbol, successCb, failureCb) => {
	if (!apiInstance || !symbol) {
		return;
	}

	try {
		const { data } = await apiInstance.get(`${URLS.XREF_SYMBOL_PATH}/${symbol}`);
		const xrefResults = data?.data;
		if (xrefResults) {
			const { xids = {} } = xrefResults;
			xids.entity = xids.warrantUnderlyingEntity || xids.entity;
			xrefResults.xids = xids;
			successCb();
			return xrefResults;
		}
	} catch (error) {
		failureCb(error);
	}
};

const getPredictiveSearchResults = async (apiInstance, searchTerm) => {
	if (!apiInstance || !searchTerm) {
		return;
	}

	try {
		const xrefResponse = await apiInstance.get(
			`${URLS.XREF_PREDICTIVE_SEARCH_PATH}?term=${searchTerm}`
		);

		if (xrefResponse.data?.data) {
			return xrefResponse.data.data;
		}
	} catch (error) {
		return {};
	}
};

export default { getXrefs, getXrefBySymbol, getPredictiveSearchResults };
