import actionTypes from "./actionTypes";
import { URLS, PERIODICITY_OPTIONS } from "../../utils/constants";

//action creator
const storeEarningsChartPeriodicity = (results) => {
	return {
		type: actionTypes.SAVE_EARNINGS_CHART_PERIODICITY,
		earningsChartPeriodicity: results,
	};
};

const storeEarningsEstimatesCurrentResults = (results) => {
	return {
		type: actionTypes.SAVE_EARNINGS_ESTIMATES_CURRENT_DATA,
		earningsEstimatesCurrentData: results,
	};
};

const storeEarningsEstimatesTrendsResults = (results) => {
	return {
		type: actionTypes.SAVE_EARNINGS_ESTIMATES_TRENDS_DATA,
		earningsEstimatesTrendsData: results,
	};
};

const storeAllAnnualEarningsEstimatesTrendsResults = (results) => {
	return {
		type: actionTypes.SAVE_EARNINGS_ESTIMATES_TRENDS_ANNUAL_DATA,
		earningsEstimatesTrendsAnnualData: results,
	};
};

const fetchEarningsEstimatesCurrent = (apiInstance, venueXid) => {
	//async here - dispatch here will get set by redux-thunk
	return async (dispatch, getState) => {
		if (!apiInstance || !venueXid) {
			return;
		}
		dispatch({
			type: actionTypes.EARNINGS_ESTIMATES_CURRENT_API_TRANSITION,
			name: "load",
		});

		try {
			const response = await apiInstance.get(
				`${URLS.EARNINGS_ESTIMATES_CURRENT_PATH}/${venueXid}`
			);
			const data = response.data.data || null;
			if (data) {
				dispatch({
					type: actionTypes.EARNINGS_ESTIMATES_CURRENT_API_TRANSITION,
					name: "success",
				});
				dispatch(storeEarningsEstimatesCurrentResults(data));
			}
		} catch (error) {
			dispatch({
				type: actionTypes.EARNINGS_ESTIMATES_CURRENT_API_TRANSITION,
				name: "failure",
			});
			dispatch(storeEarningsEstimatesCurrentResults(null));
		}
	};
};

const fetchEarningsEstimatesTrends = (
	apiInstance,
	venueXid,
	periodicity,
	numEstimatePeriods,
	numHistoricalPeriods
) => {
	//async here - dispatch here will get set by redux-thunk
	return async (dispatch, getState) => {
		if (!apiInstance || !venueXid) {
			return "error";
		}
		dispatch({
			type: actionTypes.EARNINGS_ESTIMATES_TRENDS_API_TRANSITION,
			name: "load",
		});

		try {
			const response = await apiInstance.get(
				`${URLS.EARNINGS_ESTIMATES_TRENDS_PATH}/${venueXid}`,
				{
					params: {
						periodicity: periodicity
							? periodicity.toLowerCase()
							: PERIODICITY_OPTIONS.QUARTERLY,
						numberOfEstimatePeriods: numEstimatePeriods,
						includeHistory: true,
						numberOfHistoricalPeriods: numHistoricalPeriods,
					},
				}
			);

			const data = response.data.data || null;
			if (data) {
				dispatch({
					type: actionTypes.EARNINGS_ESTIMATES_TRENDS_API_TRANSITION,
					name: "success",
				});
				dispatch(storeEarningsEstimatesTrendsResults(data));
			}
		} catch (error) {
			dispatch({
				type: actionTypes.EARNINGS_ESTIMATES_TRENDS_API_TRANSITION,
				name: "failure",
			});
			dispatch(storeEarningsEstimatesTrendsResults(null));
		}
	};
};

const fetchAllAnnualEarningsEstimatesTrends = (apiInstance, venueXid) => {
	//async here - dispatch here will get set by redux-thunk
	return async (dispatch, getState) => {
		if (!apiInstance || !venueXid) {
			return;
		}
		dispatch({
			type: actionTypes.EARNINGS_ESTIMATES_TRENDS_ANNUAL_API_TRANSITION,
			name: "load",
		});

		const { equitiesReducer } = getState();
		if (equitiesReducer.earningsEstimatesTrendsAnnualDataStatus !== "FETCHING") {
			return;
		}

		try {
			const response = await apiInstance.get(
				`${URLS.EARNINGS_ESTIMATES_TRENDS_PATH}/${venueXid}`,
				{
					params: {
						periodicity: PERIODICITY_OPTIONS.ANNUAL,
						numberOfEstimatePeriods: 8,
						includeHistory: true,
						numberOfHistoricalPeriods: 5,
					},
				}
			);

			const data = response.data.data || null;
			if (data) {
				dispatch({
					type: actionTypes.EARNINGS_ESTIMATES_TRENDS_ANNUAL_API_TRANSITION,
					name: "success",
				});
				dispatch(storeAllAnnualEarningsEstimatesTrendsResults(data));
			}
		} catch (error) {
			dispatch({
				type: actionTypes.EARNINGS_ESTIMATES_TRENDS_ANNUAL_API_TRANSITION,
				name: "failure",
			});
			dispatch(storeAllAnnualEarningsEstimatesTrendsResults(null));
		}
	};
};

export default {
	fetchEarningsEstimatesCurrent,
	fetchEarningsEstimatesTrends,
	fetchAllAnnualEarningsEstimatesTrends,
	storeEarningsChartPeriodicity,
};
