import React from "react";

const Close = () => {
	return (
		<svg
			width="12px"
			height="12px"
			viewBox="0 0 16 16"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
		>
			<title>Close</title>
			<g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Icon" transform="translate(-4.000000, -4.000000)" fill="#333333">
					<path d="M5.69207527,4.29031422 L12,10.598 L18.3079247,4.29031422 C18.6652345,3.93300441 19.227471,3.90551904 19.616308,4.20785811 L19.7096858,4.29031422 C20.0967714,4.67739984 20.0967714,5.30498964 19.7096858,5.69207527 L19.7096858,5.69207527 L13.402,12 L19.7096858,18.3079247 C20.0967714,18.6950104 20.0967714,19.3226002 19.7096858,19.7096858 C19.3226002,20.0967714 18.6950104,20.0967714 18.3079247,19.7096858 L12,13.402 L5.69207527,19.7096858 C5.33476546,20.0669956 4.77252902,20.094481 4.38369204,19.7921419 L4.29031422,19.7096858 C3.90322859,19.3226002 3.90322859,18.6950104 4.29031422,18.3079247 L4.29031422,18.3079247 L10.598,12 L4.29031422,5.69207527 C3.90322859,5.30498964 3.90322859,4.67739984 4.29031422,4.29031422 C4.67739984,3.90322859 5.30498964,3.90322859 5.69207527,4.29031422 Z"></path>
				</g>
			</g>
		</svg>
	);
};

// Used to append to chart legend via jquery
const CloseHtmlString = [
	'<svg width="12px" height="12px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">',
	"<title>Close</title>",
	'<g id="Icons" stroke="none" strokeWidth="1" fill="none" fill-rule="evenodd">',
	'<g id="Icon" transform="translate(-4.000000, -4.000000)" fill="#333333">',
	'<path d="M5.69207527,4.29031422 L12,10.598 L18.3079247,4.29031422 C18.6652345,3.93300441 19.227471,3.90551904 19.616308,4.20785811 L19.7096858,4.29031422 C20.0967714,4.67739984 20.0967714,5.30498964 19.7096858,5.69207527 L19.7096858,5.69207527 L13.402,12 L19.7096858,18.3079247 C20.0967714,18.6950104 20.0967714,19.3226002 19.7096858,19.7096858 C19.3226002,20.0967714 18.6950104,20.0967714 18.3079247,19.7096858 L12,13.402 L5.69207527,19.7096858 C5.33476546,20.0669956 4.77252902,20.094481 4.38369204,19.7921419 L4.29031422,19.7096858 C3.90322859,19.3226002 3.90322859,18.6950104 4.29031422,18.3079247 L4.29031422,18.3079247 L10.598,12 L4.29031422,5.69207527 C3.90322859,5.30498964 3.90322859,4.67739984 4.29031422,4.29031422 C4.67739984,3.90322859 5.30498964,3.90322859 5.69207527,4.29031422 Z"></path>',
	"</g>",
	"</g>",
	"</svg>",
].join("");

export default Close;

export { CloseHtmlString };
