import React, { useEffect, useState, useRef } from "react";
import styles from "../ChartingCard.module.scss";
import formatter from "utils/formatUtils";
import Utils from "utils/utils";
import AdvanceChartConstant from "components/MarketsAndResearch/ChartingCommon/Constants/Chart/AdvanceChart";

const CrosshairTooltip = React.memo((props) => {
	const chart = props.chart;
	let activeState = props.activeValue;
	let divRef = useRef(null);
	let [isEventHover, setisEventHover] = useState({
		isHover: false,
	});
	let [hoverValues, setHoverValues] = useState({
		high: null,
		low: null,
		open: null,
		close: null,
		volume: null,
		change: null,
	});
	let [position, setPosition] = useState({ topVal: null, leftVal: null });

	useEffect(() => {
		if (chart) {
			chart.eventEmitter.on("ZOOM", hideTooltip);
			chart.eventEmitter.on("CROSSHAIR_MOVE", updateTooltip);
			chart.eventEmitter.on("CROSSHAIR_HIDE", hideTooltip);
			chart.eventEmitter.on("EVENT_MOUSE_ENTER", eventEnter);
			chart.eventEmitter.on("EVENT_MOUSE_LEAVE", eventLeave);
		}
		return () => unregisterChartEvents();
	});

	useEffect(() => {
		activeState = props.activeValue;
	}, [props.activeValue]);

	const unregisterChartEvents = () => {
		if (chart) {
			chart.eventEmitter.off("ZOOM", hideTooltip);
			chart.eventEmitter.off("CROSSHAIR_MOVE", updateTooltip);
			chart.eventEmitter.off("CROSSHAIR_HIDE", hideTooltip);
			chart.eventEmitter.off("EVENT_MOUSE_ENTER", eventEnter);
			chart.eventEmitter.off("EVENT_MOUSE_LEAVE", eventLeave);
		}
	};

	const updateTooltip = (args) => {
		const len = args.panels[0].indicators.length;
		for (let i = 1; i < len; i++) {
			if (args.panels[0].indicators[i].params.id === "price") {
				activeState = AdvanceChartConstant.PRICE_FORMATS.PERCENT.value;
			}
		}
		if (!args.activePanel.isUpper) {
			hideTooltip();
		} else {
			setHoverValues({
				open:
					activeState === AdvanceChartConstant.PRICE_FORMATS.ABSOLUTE.value
						? formatter.price(args.open)
						: formatValue(args.open),
				high:
					activeState === AdvanceChartConstant.PRICE_FORMATS.ABSOLUTE.value
						? formatter.price(args.high)
						: formatValue(args.high),
				low:
					activeState === AdvanceChartConstant.PRICE_FORMATS.ABSOLUTE.value
						? formatter.price(args.low)
						: formatValue(args.low),
				close:
					activeState === AdvanceChartConstant.PRICE_FORMATS.ABSOLUTE.value
						? formatter.price(args.close)
						: formatValue(args.close),
				volume: args.volume,
				change:
					activeState === AdvanceChartConstant.PRICE_FORMATS.ABSOLUTE.value
						? formatter.percent(
								(args.close - chart.dataFrame[0].close) / chart.dataFrame[0].close,
								2
						  )
						: formatter.percent(args.close / 100, 2),
			});
			positionTooltip(args);
		}
	};

	const formatValue = (val) => {
		if (chart.panels[0].isNormalized) {
			return formatter.price(chart.getActualFromNormalized(val));
		} else {
			return formatter.price(val);
		}
	};

	const hideTooltip = () => {
		const el = divRef.current;
		if (el) {
			el.style.visibility = "hidden";
		}
	};

	const showTooltip = () => {
		const el = divRef.current;
		if (el) {
			el.style.visibility = "visible";
		}
	};

	const eventEnter = () => {
		setisEventHover({ isHover: true });
	};

	const eventLeave = () => {
		setisEventHover({ isHover: false });
	};

	const positionTooltip = (args) => {
		const el = divRef.current;
		if (!el) {
			return;
		}
		const rect = chart.rootMouse.node().getBoundingClientRect();
		const margin = 10;
		const width = el.offsetWidth;
		const height = el.offsetHeight;
		let left = rect.left + margin + args.coords.x - 50;
		let top = margin + args.coords.y;
		// bump left if right collision
		if (
			left + width >
			rect.left + chart.size.width - chart.panels[0].size.padding.right - 50
		) {
			left = rect.left + args.coords.x - width - margin - 50;
		}
		// bump up if bottom collision
		if (top + height > chart.panels[0].size.height + margin) {
			top = args.coords.y - height - margin;
		}
		setPosition({ topVal: top, leftVal: left });
		showTooltip();
	};

	return (
		!isEventHover.isHover && (
			<div
				tabIndex="0"
				className={styles.crosshairTooltip}
				ref={divRef}
				id="tooltip"
				style={{
					left: position.leftVal,
					top: position.topVal,
				}}
			>
				<table className="">
					<tbody>
						<tr>
							<td align="left">Open:</td>
							<td align="right">{hoverValues.open}</td>
						</tr>
						<tr>
							<td align="left">High:</td>
							<td align="right">{hoverValues.high}</td>
						</tr>
						<tr>
							<td align="left">Low:</td>
							<td align="right">{hoverValues.low}</td>
						</tr>
						<tr>
							<td align="left">Close:</td>
							<td align="right">{hoverValues.close}</td>
						</tr>
						<tr>
							<td align="left">Volume:</td>
							<td align="right">{Utils.getVolumeHoverValue(hoverValues.volume)}</td>
						</tr>
						<tr>
							<td align="left">% Change:</td>
							<td align="right">{hoverValues.change}</td>
						</tr>
					</tbody>
				</table>
			</div>
		)
	);
});

export default CrosshairTooltip;
