import { createReducer } from "reduxsauce";
import actionTypes from "../actions/actionTypes";
import ApiStates from "../apiStates";
import { API_TRANSITIONS, getApiTransitionAction } from "../apiTransitionUtils";

const INITIAL_STATE = {
	xrefSymbolsDataStatus: ApiStates.IDLE,
	xrefSymbolsData: {},
};

export const updateXrefSymbolsData = (state = INITIAL_STATE, { xrefSymbolsData }) => {
	// NOSONAR
	if (!xrefSymbolsData) {
		return { ...state };
	}

	return {
		...state,
		xrefSymbolsData: xrefSymbolsData,
	};
};

export const xrefSymbolsTransition = (state = INITIAL_STATE, { name }) => {
	// NOSONAR
	const action = getApiTransitionAction(state.xrefSymbolsDataStatus, name);
	if (action) {
		// execute action
		return action.apply(API_TRANSITIONS, [state, { key: "xrefSymbolsDataStatus" }]);
	}
	// return existing state if no action found
	return { ...state };
};

export const HANDLERS = {
	[actionTypes.XREF_SYMBOLS_API_TRANSITION]: xrefSymbolsTransition,
	[actionTypes.SAVE_XREF_SYMBOLS_DATA]: updateXrefSymbolsData,
};

export default createReducer(INITIAL_STATE, HANDLERS);
