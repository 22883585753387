import React from "react";

import styles from "./Chevron.module.scss";

const Chevron = React.memo((props) => {
	const { color, direction, dimension } = props;

	function chevronPath(direction) {
		switch (direction) {
			case "up":
				return "M9.646 6.646a.5.5 0 01.708 0l6 6a.5.5 0 01-.708.708L10 7.707l-5.646 5.647a.5.5 0 01-.708-.708l6-6z";
			case "down":
				return "M3.646 6.646a.5.5 0 01.708 0L10 12.293l5.646-5.647a.5.5 0 01.708.708l-6 6a.5.5 0 01-.708 0l-6-6a.5.5 0 010-.708z";
			case "left":
				return "M13.354 3.646a.5.5 0 010 .708L7.707 10l5.647 5.646a.5.5 0 01-.708.708l-6-6a.5.5 0 010-.708l6-6a.5.5 0 01.708 0z";
			case "right":
			default:
				return "M6.646 3.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L12.293 10 6.646 4.354a.5.5 0 010-.708z";
		}
	}

	return (
		<svg
			className={`${styles.chevron} chevronIcon`}
			width={dimension}
			height={dimension}
			viewBox="0 0 20 20"
			fill={color}
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				stroke={color}
				fillRule="evenodd"
				clipRule="evenodd"
				d={chevronPath(direction)}
			/>
		</svg>
	);
});

export default Chevron;
