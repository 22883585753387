import React from "react";
import styles from "./MSToggle.module.scss";

const MSToggle = (props) => {
	return (
		<div className={styles.toggle}>
			<input
				id={props.id}
				type="checkbox"
				onClick={props.onClick}
				name="expiration"
				defaultChecked={props.isDefaultChecked}
			/>
			<label htmlFor={props.id}>
				{!props.isLabelLast && props.label}
				<span
					className={props.isLabelLast ? styles.leftControl : styles.rightControl}
				></span>
				{props.isLabelLast && props.label}
			</label>
		</div>
	);
};

export default MSToggle;
