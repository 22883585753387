import React from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import formatter from "../../../../utils/formatUtils";
import styles from "./EarningsItem.module.scss";
import utils from "utils/utils";

const EarningsTooltip = (data) => {
	return (
		<Tooltip className={styles.earningsItemTooltip}>
			<p>
				<span>Expected report date:</span> {formatter.date(data.reportDate)}
			</p>
			<p>
				<span>Estimated Earnings per share:</span> {formatter.price(data.estimate)}
			</p>
		</Tooltip>
	);
};

const EarningsItem = (props) => {
	return (
		<OverlayTrigger
			placement="auto"
			trigger={utils.toolTipTrigger()}
			overlay={EarningsTooltip(props.data)}
		>
			<div className={styles.earningsItem} tabIndex={0}>
				E
				<span className={"sr-only"}>
					arnings Indicator:
					<p>
						<span>Expected report date:</span> {formatter.date(props.data.reportDate)}
					</p>
					<p>
						<span>Estimated Earnings per share:</span>{" "}
						{formatter.price(props.data.estimate)}
					</p>
				</span>
			</div>
		</OverlayTrigger>
	);
};

export default EarningsItem;
