import { createReducer } from "reduxsauce";
import actionTypes from "../actions/actionTypes";
import ApiStates from "../apiStates";
import { API_TRANSITIONS, getApiTransitionAction } from "../apiTransitionUtils";

const INITIAL_STATE = {
	quoteDataStatus: ApiStates.IDLE,
	mstarClassificatioStatus: ApiStates.IDLE,
	quoteData: null,
};

export const updateQuoteData = (state = INITIAL_STATE, { quoteData }) => {
	// NOSONAR
	if (!quoteData) {
		return {
			...state,
		};
	}

	return {
		...state,
		quoteData: quoteData,
	};
};

export const quoteTransition = (state = INITIAL_STATE, { name }) => {
	// NOSONAR
	const action = getApiTransitionAction(state.quoteDataStatus, name);
	if (action) {
		// execute action
		return action.apply(API_TRANSITIONS, [state, { key: "quoteDataStatus" }]);
	}
	// return existing state if no action found
	return { ...state };
};

export const mstarClassificationTransition = (state = INITIAL_STATE, { name }) => {
	// NOSONAR
	const action = getApiTransitionAction(state.quoteDataStatus, name);
	if (action) {
		// execute action
		return action.apply(API_TRANSITIONS, [state, { key: "quoteDataStatus" }]);
	}
	// return existing state if no action found
	return { ...state };
};

export const HANDLERS = {
	[actionTypes.QUOTE_API_TRANSITION]: quoteTransition,
	[actionTypes.MSTAR_CLASSIFICATION_TRANSITION]: mstarClassificationTransition,
	[actionTypes.SAVE_QUOTE_DATA]: updateQuoteData,
};

export default createReducer(INITIAL_STATE, HANDLERS);
