import React, { useEffect, useCallback } from "react";
import styles from "./HelpDrawer.module.scss";
import classNames from "classnames";

const HelpDrawer = React.memo(({ showHandle, show }) => {
	const showHandleCallback = useCallback(showHandle, [showHandle]);
	useEffect(() => {
		if (show) {
			const handleEscape = ({ keyCode }) => {
				if (keyCode === 27) {
					showHandleCallback();
				}
			};

			document.addEventListener("keydown", handleEscape);
			return () => {
				document.removeEventListener("keydown", handleEscape);
			};
		}
	}, [show, showHandleCallback]);
	return (
		<div
			className={classNames(show ? styles.drawer : styles.hide, styles.helpBodyContainer)}
			aria-hidden={!show}
		>
			<div className={styles.close} aria-label="Close modal">
				<button
					className={classNames(styles.drawerClose, "btn", "btn-link")}
					onClick={showHandle}
				>
					Close
				</button>
			</div>
			<div className={styles.drawerContent}>
				{" "}
				<div id={styles.helpBodyContainer}>
					<h2 className="mb-4">Morgan Stanley Stock Rating</h2>
					<p>
						<strong>About</strong>
					</p>
					<p>
						Morgan Stanley uses a relative rating system using terms such as Overweight,
						Equal-weight, Not-Rated or Underweight (see definitions below). Morgan Stanley
						does not assign ratings of Buy, Hold or Sell to the stocks we cover.
						Overweight, Equal-weight, Not-Rated and Underweight are not the equivalent of
						buy, hold and sell. Investors should carefully read the definitions of all
						ratings used. In addition, since Morgan Stanley research reports contain more
						complete information concerning the analyst's views, investors should
						carefully read the research report, in its entirety, and not infer the
						contents from the rating alone. In any case, ratings (or research) should not
						be used or relied upon as investment advice. An investor's decision to buy or
						sell a stock should depend on individual circumstances (such as the investor's
						existing holdings) and other considerations.
					</p>
					<p>
						<strong>Underweight</strong>
					</p>
					<p>
						The stock's total return is expected to be below the average total return of
						the analyst's industry (or industry team's) coverage universe, on a
						risk-adjusted basis, over the next 12-18 months.
					</p>
					<p>
						<strong>Equal-weight</strong>
					</p>
					<p>
						The stock's total return is expected to be in line with the average total
						return of the analyst's industry (or industry team's) coverage universe, on a
						risk-adjusted basis, over the next 12-18 months.
					</p>
					<p>
						<strong>Overweight</strong>
					</p>
					<p>
						The stock's total return is expected to exceed the average total return of the
						analyst's industry (or industry team's) coverage universe, on a risk-adjusted
						basis, over the next 12-18 months.
					</p>
					<p>
						<strong>Not Rated</strong>
					</p>
					<p>
						Currently the analyst does not have adequate conviction about the stock's
						total return relative to the average total return of the analyst's industry
						(or industry team's) coverage universe, on a risk-adjusted basis, over the
						next 12-18 months. When NA appears in place of a Morgan Stanley Research
						rating, then the rating for this company is not available because of a Morgan
						Stanley policy. NA appearing for a third-party research provider indicates
						that the ratings for this company are not available because of a Morgan
						Stanley policy.
					</p>
					<h2 className="mb-4">Industry Views</h2>
					<p>
						<strong>Attractive</strong>
					</p>
					<p>
						The analyst expects the performance of his or her industry coverage universe
						over the next 12-18 months to be attractive vs. the relevant broad market
						benchmark named on the cover of the corresponding report.
					</p>
					<p>
						<strong>In-Line</strong>
					</p>
					<p>
						The analyst expects the performance of his or her industry coverage universe
						over the next 12-18 months to be in line with the relevant broad market
						benchmark named on the cover of the corresponding report.
					</p>
					<p>
						<strong>Cautious</strong>
					</p>
					<p>
						The analyst views the performance of his or her industry coverage universe
						over the next 12-18 months with caution vs. the relevant broad market
						benchmark named on the cover of the corresponding report.
					</p>
				</div>
			</div>
		</div>
	);
});

export default HelpDrawer;
