import React from "react";

const Left = (props) => {
	return (
		<svg
			data-value={props.dataValue}
			aria-label={props.ariaLabel}
			onClick={props.onClick}
			xmlns="http://www.w3.org/2000/svg"
			width="11px"
			height="14px"
			viewBox="0 0 9 15"
			version="1.1"
		>
			<title>{props.ariaLabel}</title>
			<g id="page-left" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g
					id={props.dataValue}
					transform="translate(-7.000000, -4.000000)"
					fill={props.color || "#187ABA"}
				>
					<path d="M13.7944182,4.70558179 C14.1841003,4.31589973 14.8181114,4.31811142 15.206077,4.70607704 L15.293923,4.79392296 C15.6838785,5.18387854 15.6774139,5.82258606 15.2881596,6.21184039 L10,11.5 L15.2881596,16.7881596 C15.6812982,17.1812982 15.6818886,17.8181114 15.293923,18.206077 L15.206077,18.293923 C14.8161215,18.6838785 14.1777344,18.6777344 13.7944182,18.2944182 L7.70558179,12.2055818 C7.31589973,11.8158997 7.32226563,11.1777344 7.70558179,10.7944182 L13.7944182,4.70558179 Z" />
				</g>
			</g>
		</svg>
	);
};

export default Left;
