import React, { Component } from "react";
import DataTable from "../../../Lib/Table/DataTable";
import styles from "./StatementDetailsTable.module.scss";
import formatter from "../../../../utils/formatUtils";
import utils from "utils/utils";
import MSSpinner from "../../../Lib/MSSpinner/MSSpinner";
import filterEmptyData from "utils/financialsEmptyColumns";

export class StatementDetailsTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			tableData: {
				headersData: [],
				rowsData: [],
			},
			uniqueCurrencyflag: 0,
		};

		this.parseTableData = this.parseTableData.bind(this);
	}

	componentDidMount() {
		if (this.props.financialStatementsData) {
			this.parseTableData(this.props.financialStatementsData);
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.financialStatementsData !== this.props.financialStatementsData) {
			this.parseTableData(this.props.financialStatementsData);
		}
	}

	shouldFetchData(prevProps) {
		if (!prevProps) {
			return false;
		}
		let fetchTableData = false;
		if (
			prevProps.entityXid !== this.props.entityXid ||
			prevProps.periodType !== this.props.periodType
		) {
			fetchTableData = true;
		}
		return (
			fetchTableData &&
			this.props.entityXid &&
			this.props.financialStatementsData === null
		);
	}

	parseTableData = (data) => {
		let colsArr = [];
		let rowsArr = [];
		this.dynamicHeader = [];
		this.uniqueCurrencies = utils.getUniqueCurrencies(data.currencies);

		if (!data?.fiscalYears || !data?.rows) {
			return;
		}

		data.fiscalYears.map((item, index) =>
			this.createColumnData(
				item,
				index,
				data.fiscalQuarters,
				data.periodEndDates,
				colsArr,
				data.currencies
			)
		);
		let colObj = {};

		// Last Column Trend visible for large devices
		// TODO: update template literals to use JSX React Syntax
		if (!this.props.isMobile) {
			if (
				this.props.periodType === "Annual" &&
				data.fiscalYears &&
				this.uniqueCurrencies.length === 1
			) {
				colObj.CellValue = `Trend <span aria-hidden = true style="font-weight:normal">(${
					data.fiscalYears[data.fiscalQuarters.length - 1]
				}-${data.fiscalYears[0].toString().substr(-2)})</span>`;
			} else if (
				this.props.periodType === "Interim" &&
				data.fiscalYears &&
				data.fiscalQuarters &&
				data.fiscalYears[data.fiscalQuarters.length - 1] &&
				this.uniqueCurrencies.length === 1
			) {
				colObj.CellValue = `Trend <span aria-hidden = true style="font-weight:normal">(Q${
					data.fiscalQuarters[data.fiscalQuarters.length - 1]
				} ${data.fiscalYears[data.fiscalQuarters.length - 1].toString().substr(-2)}-Q${
					data.fiscalQuarters[0]
				} ${data.fiscalYears[0].toString().substr(-2)})</span>`;
			} else {
				colObj.CellValue = "";
			}
			colsArr.push(colObj);
		}
		// ENDS Last Column Trend visible for large devices
		let currency = this.uniqueCurrencies;
		data.rows.map((item) =>
			this.createRowData(item, rowsArr, data.periodEndDates, currency)
		);

		if (rowsArr.length > 0) {
			let obj = filterEmptyData.filterEmptyData(
				rowsArr,
				data.fiscalYears.length,
				colsArr
			);
			this.setState({ tableData: obj });
		} else {
			this.setState({
				tableData: {
					headersData: colsArr,
					rowsData: rowsArr,
				},
			});
		}
	};

	createColumnData(item, index, fiscalQuarters, periodEndDates, colsArr, currencies) {
		let colObj = {};
		colObj.CellValue =
			this.props.periodType === "Annual" ? "Fiscal Year End:" : "Fiscal Qrtr End:";
		if (index === 0) {
			if (periodEndDates && periodEndDates.length > 0) {
				let date = new Date(periodEndDates[0]);
				colObj.CellValue += ` ${date.toLocaleString("default", {
					month: "long",
				})} ${date.getDate()}`;
			}
			colsArr.push(colObj);
			colObj = {};
		}
		if (this.props.periodType === "Annual") {
			colObj.CellValue =
				item + (this.uniqueCurrencies.length === 1 ? "" : `<br/> (${currencies[index]})`);
		} else if (this.props.periodType === "Interim") {
			colObj.CellValue =
				`Q${fiscalQuarters[index]} ${item}` +
				(this.uniqueCurrencies.length === 1 ? "" : `<br/> (${currencies[index]})`);
		} else {
			colObj.CellValue = "";
		}

		colsArr.push(colObj);
	}

	addRowHeader(item, isBold) {
		let tempRowObject = {};
		if (this.props.rowHeaderAsTooltip && this.props.isMobile) {
			tempRowObject.CellValue = utils.buildTooltip(
				"financial-description-tooltip-desc",
				item.dataPointLabel,
				"text",
				<div className={styles.dvRowHeaderContainer}>
					<span
						aria-label="tool tip"
						aria-describedby={"financial-description-tooltip-desc"}
					>
						{item.dataPointLabel}
					</span>
				</div>
			);
		} else {
			tempRowObject.CellValue = item.dataPointLabel;
		}
		if (isBold) {
			tempRowObject.CellBackground = "CellBackground";
			tempRowObject.IsBold = true;
		}

		return tempRowObject;
	}

	createRowData(item, tableRowsArray, periodEndDates, currency) {
		const tempRowArray = [];
		const isBold = item && item.bold === "y";
		const rowTemplate = {
			CellBackground: isBold && "CellBackground",
			IsBold: isBold,
		};

		tempRowArray.push(this.addRowHeader(item, isBold));

		let trend = [];

		if (item.values) {
			item.values.forEach((inneritem) => {
				tempRowArray.push({
					...rowTemplate,
					CellValue: formatter.currencyMagnitude(
						inneritem,
						2,
						currency.length === 1 && currency[0] === "USD" ? "USD" : " "
					),
				});
				trend.push(inneritem);
			});
		} else {
			let arrCount = 0;
			if (periodEndDates && periodEndDates.length) {
				arrCount = periodEndDates.length;
			}

			for (let count = 0; count < arrCount; count++) {
				tempRowArray.push({ ...rowTemplate, CellValue: formatter.options.errorString });
				trend.push(null);
			}
		}

		if (!this.props.isMobile) {
			tempRowArray.push(this.formatDesktopTrends(trend, isBold, currency.length));
		}
		tableRowsArray.push(tempRowArray);
	}

	formatDesktopTrends(trend, isBold, currencyFlag) {
		if (currencyFlag === 1) {
			return {
				IsChart: true,
				CellBackground: isBold && "CellBackground",
				CellValue: [...trend].reverse(),
			};
		} else {
			return {
				CellBackground: isBold && "CellBackground",
			};
		}
	}

	render() {
		let isContentReady =
			this.props.financialStatementsData && this.props.financialStatementsData !== null;

		return (
			<MSSpinner style={styles.spinnerStyle} ready={isContentReady} spinnerSize={"2x"}>
				<div className={styles.incomeStatementTable}>
					<DataTable
						headers={this.state.tableData.headersData}
						rows={this.state.tableData.rowsData}
					/>
				</div>
			</MSSpinner>
		);
	}
}

export default StatementDetailsTable;
